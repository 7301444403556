import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

export type FilterState = {
  armedConflict: number;
  civilUnrest: number;
  criminality: number;
  organizedCrime: number;
  terrorism: number;
  activism: number;
  informationSecurity: number;
  countrySecurity: number;
};

export type SetFilterAction = { filter: keyof FilterState; value: number };

export const initialState: FilterState = {
  armedConflict: 0,
  civilUnrest: 0,
  criminality: 0,
  organizedCrime: 0,
  terrorism: 0,
  activism: 0,
  informationSecurity: 0,
  countrySecurity: 0,
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilter: (draft, action: PayloadAction<SetFilterAction>) => {
      draft[action.payload.filter] = action.payload.value;
    },
  },
});

export const { setFilter } = filtersSlice.actions;

export const selectFilters = (state: RootState) => state.filters;

export default filtersSlice.reducer;
