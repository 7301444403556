const riskData = [
  {
    country: 'Aruba',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Argentina',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Barbados',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Belize',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Bolivia',
    travelRiskRating: 'MEDIUM',
    armedConflict: 2,
    civilUnrest: 7,
    criminality: 4,
    organizedCrime: 5,
    terrorism: 2,
    activism: 3,
    informationSecurity: 5,
    countrySecurity: 5,
  },
  {
    country: 'Bonaire',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Brazil',
    travelRiskRating: 'HIGH',
    armedConflict: 1,
    civilUnrest: 5,
    criminality: 9,
    organizedCrime: 7,
    terrorism: 2,
    activism: 5,
    informationSecurity: 7,
    countrySecurity: 8,
  },
  {
    country: 'Canada',
    travelRiskRating: 'LOW',
    armedConflict: 2,
    civilUnrest: 4,
    criminality: 4,
    organizedCrime: 3,
    terrorism: 4,
    activism: 7,
    informationSecurity: 7,
    countrySecurity: 4,
  },
  {
    country: 'Chile',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Colombia',
    travelRiskRating: 'HIGH',
    armedConflict: 4,
    civilUnrest: 8,
    criminality: 7,
    organizedCrime: 7,
    terrorism: 6,
    activism: 4,
    informationSecurity: 5,
    countrySecurity: 7,
  },
  {
    country: 'Costa Rica',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Cuba',
    travelRiskRating: 'HIGH',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Curacao',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Dominican Republic',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Ecuador',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'El Salvador',
    travelRiskRating: 'HIGH',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'French Guiana',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Grenada',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Guatemala',
    travelRiskRating: 'HIGH',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Guyana',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Haiti',
    travelRiskRating: 'HIGH',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Honduras',
    travelRiskRating: 'HIGH',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Jamaica',
    travelRiskRating: 'HIGH',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Martinique',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Mexico',
    travelRiskRating: 'HIGH',
    armedConflict: 5,
    civilUnrest: 6,
    criminality: 8,
    organizedCrime: 9,
    terrorism: 2,
    activism: 3,
    informationSecurity: 5,
    countrySecurity: 8,
  },
  {
    country: 'Nicaragua',
    travelRiskRating: 'EXTREME',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Panama',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Paraguay',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Peru',
    travelRiskRating: 'MEDIUM',
    armedConflict: 2,
    civilUnrest: 5,
    criminality: 5,
    organizedCrime: 6,
    terrorism: 3,
    activism: 6,
    informationSecurity: 5,
    countrySecurity: 6,
  },
  {
    country: 'Saint Kitts and Nevis',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Saint Lucia',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Saint Vincent and the Grenadines',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Suriname',
    travelRiskRating: 'MEDIUM',
    armedConflict: 2,
    civilUnrest: 5,
    criminality: 5,
    organizedCrime: 4,
    terrorism: 2,
    activism: 3,
    informationSecurity: 4,
    countrySecurity: 4,
  },
  {
    country: 'Bahamas',
    travelRiskRating: 'MEDIUM',
    armedConflict: 2,
    civilUnrest: 3,
    criminality: 6,
    organizedCrime: 5,
    terrorism: 2,
    activism: 4,
    informationSecurity: 7,
    countrySecurity: 5,
  },
  {
    country: 'Trinidad and Tobago',
    travelRiskRating: 'MEDIUM',
    armedConflict: 2,
    civilUnrest: 4,
    criminality: 6,
    organizedCrime: 6,
    terrorism: 4,
    activism: 4,
    informationSecurity: 5,
    countrySecurity: 6,
  },
  {
    country: 'United States',
    travelRiskRating: 'MEDIUM',
    armedConflict: 2,
    civilUnrest: 5,
    criminality: 5,
    organizedCrime: 4,
    terrorism: 5,
    activism: 7,
    informationSecurity: 7,
    countrySecurity: 5,
  },
  {
    country: 'Uruguay',
    travelRiskRating: 'LOW',
    armedConflict: 2,
    civilUnrest: 3,
    criminality: 4,
    organizedCrime: 5,
    terrorism: 2,
    activism: 3,
    informationSecurity: 5,
    countrySecurity: 4,
  },
  {
    country: 'Venezuela',
    travelRiskRating: 'HIGH',
    armedConflict: 2,
    civilUnrest: 6,
    criminality: 8,
    organizedCrime: 8,
    terrorism: 2,
    activism: 2,
    informationSecurity: 9,
    countrySecurity: 9,
  },
  {
    country: 'Australia',
    travelRiskRating: 'LOW',
    armedConflict: 2,
    civilUnrest: 2,
    criminality: 4,
    organizedCrime: 4,
    terrorism: 4,
    activism: 6,
    informationSecurity: 5,
    countrySecurity: 4,
  },
  {
    country: 'Bangladesh',
    travelRiskRating: 'HIGH',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Bhutan',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Brunei',
    travelRiskRating: 'LOW',
    armedConflict: 2,
    civilUnrest: 2,
    criminality: 4,
    organizedCrime: 4,
    terrorism: 2,
    activism: 3,
    informationSecurity: 5,
    countrySecurity: 3,
  },
  {
    country: 'Cambodia',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'China',
    travelRiskRating: 'MEDIUM',
    armedConflict: 4,
    civilUnrest: 4,
    criminality: 4,
    organizedCrime: 6,
    terrorism: 3,
    activism: 4,
    informationSecurity: 11,
    countrySecurity: 5,
  },
  {
    country: 'Fiji',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Hong Kong',
    travelRiskRating: 'LOW',
    armedConflict: 4,
    civilUnrest: 4,
    criminality: 4,
    organizedCrime: 6,
    terrorism: 3,
    activism: 4,
    informationSecurity: 11,
    countrySecurity: 5,
  },
  {
    country: 'India',
    travelRiskRating: 'MEDIUM',
    armedConflict: 5,
    civilUnrest: 6,
    criminality: 6,
    organizedCrime: 6,
    terrorism: 5,
    activism: 4,
    informationSecurity: 7,
    countrySecurity: 6,
  },
  {
    country: 'Indonesia',
    travelRiskRating: 'MEDIUM',
    armedConflict: 4,
    civilUnrest: 6,
    criminality: 5,
    organizedCrime: 5,
    terrorism: 7,
    activism: 5,
    informationSecurity: 6,
    countrySecurity: 6,
  },
  {
    country: 'Japan',
    travelRiskRating: 'LOW',
    armedConflict: 2,
    civilUnrest: 2,
    criminality: 2,
    organizedCrime: 3,
    terrorism: 2,
    activism: 3,
    informationSecurity: 6,
    countrySecurity: 3,
  },
  {
    country: 'Kiribati',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Laos',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Malaysia',
    travelRiskRating: 'MEDIUM',
    armedConflict: 2,
    civilUnrest: 4,
    criminality: 5,
    organizedCrime: 6,
    terrorism: 5,
    activism: 2,
    informationSecurity: 6,
    countrySecurity: 5,
  },
  {
    country: 'Maldives',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Marshall Islands',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Micronesia',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Mongolia',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Myanmar',
    travelRiskRating: 'HIGH',
    armedConflict: 4,
    civilUnrest: 6,
    criminality: 4,
    organizedCrime: 6,
    terrorism: 5,
    activism: 4,
    informationSecurity: 5,
    countrySecurity: 5,
  },
  {
    country: 'Nauru',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Nepal',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'New Zealand',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'North Korea',
    travelRiskRating: 'HIGH',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Palau',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Papua New Guinea',
    travelRiskRating: 'HIGH',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Philippines',
    travelRiskRating: 'HIGH',
    armedConflict: 6,
    civilUnrest: 5,
    criminality: 8,
    organizedCrime: 8,
    terrorism: 7,
    activism: 5,
    informationSecurity: 5,
    countrySecurity: 8,
  },
  {
    country: 'Samoa',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Singapore',
    travelRiskRating: 'LOW',
    armedConflict: 2,
    civilUnrest: 1,
    criminality: 2,
    organizedCrime: 3,
    terrorism: 4,
    activism: 2,
    informationSecurity: 6,
    countrySecurity: 4,
  },
  {
    country: 'Solomon Islands',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'South Korea',
    travelRiskRating: 'LOW',
    armedConflict: 5,
    civilUnrest: 3,
    criminality: 2,
    organizedCrime: 3,
    terrorism: 2,
    activism: 3,
    informationSecurity: 7,
    countrySecurity: 4,
  },
  {
    country: 'Sri Lanka',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Taiwan',
    travelRiskRating: 'LOW',
    armedConflict: 4,
    civilUnrest: 4,
    criminality: 4,
    organizedCrime: 6,
    terrorism: 3,
    activism: 4,
    informationSecurity: 11,
    countrySecurity: 5,
  },
  {
    country: 'Thailand',
    travelRiskRating: 'MEDIUM',
    armedConflict: 4,
    civilUnrest: 7,
    criminality: 5,
    organizedCrime: 6,
    terrorism: 4,
    activism: 3,
    informationSecurity: 6,
    countrySecurity: 6,
  },
  {
    country: 'Timor Leste',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Tonga',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Tuvalu',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Vanuatu',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Vietnam',
    travelRiskRating: 'MEDIUM',
    armedConflict: 4,
    civilUnrest: 4,
    criminality: 4,
    organizedCrime: 6,
    terrorism: 2,
    activism: 2,
    informationSecurity: 6,
    countrySecurity: 4,
  },
  {
    country: 'Albania',
    travelRiskRating: 'MEDIUM',
    armedConflict: 2,
    civilUnrest: 5,
    criminality: 5,
    organizedCrime: 7,
    terrorism: 3,
    activism: 3,
    informationSecurity: 8,
    countrySecurity: 6,
  },
  {
    country: 'Andorra',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Armenia',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Austria',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 3,
    criminality: 3,
    organizedCrime: 3,
    terrorism: 4,
    activism: 4,
    informationSecurity: 5,
    countrySecurity: 3,
  },
  {
    country: 'Azerbaijan',
    travelRiskRating: 'MEDIUM',
    armedConflict: 5,
    civilUnrest: 3,
    criminality: 3,
    organizedCrime: 6,
    terrorism: 4,
    activism: 2,
    informationSecurity: 7,
    countrySecurity: 5,
  },
  {
    country: 'Belarus',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Belgium',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 6,
    criminality: 3,
    organizedCrime: 4,
    terrorism: 5,
    activism: 4,
    informationSecurity: 6,
    countrySecurity: 5,
  },
  {
    country: 'Bosnia and Herzegovina',
    travelRiskRating: 'LOW',
    armedConflict: 3,
    civilUnrest: 5,
    criminality: 3,
    organizedCrime: 7,
    terrorism: 5,
    activism: 1,
    informationSecurity: 7,
    countrySecurity: 6,
  },
  {
    country: 'Bulgaria',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 4,
    criminality: 4,
    organizedCrime: 7,
    terrorism: 3,
    activism: 4,
    informationSecurity: 7,
    countrySecurity: 5,
  },
  {
    country: 'Croatia',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Cyprus',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Czech Republic',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 3,
    criminality: 4,
    organizedCrime: 4,
    terrorism: 3,
    activism: 3,
    informationSecurity: 6,
    countrySecurity: 3,
  },
  {
    country: 'Denmark',
    travelRiskRating: 'LOW',
    armedConflict: 2,
    civilUnrest: 2,
    criminality: 3,
    organizedCrime: 4,
    terrorism: 5,
    activism: 4,
    informationSecurity: 6,
    countrySecurity: 3,
  },
  {
    country: 'Estonia',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Finland',
    travelRiskRating: 'LOW',
    armedConflict: 4,
    civilUnrest: 3,
    criminality: 2,
    organizedCrime: 2,
    terrorism: 3,
    activism: 5,
    informationSecurity: 6,
    countrySecurity: 3,
  },
  {
    country: 'France',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 7,
    criminality: 4,
    organizedCrime: 5,
    terrorism: 8,
    activism: 4,
    informationSecurity: 6,
    countrySecurity: 6,
  },
  {
    country: 'Georgia',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Germany',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 5,
    criminality: 3,
    organizedCrime: 3,
    terrorism: 5,
    activism: 6,
    informationSecurity: 5,
    countrySecurity: 4,
  },
  {
    country: 'Gibraltar',
    travelRiskRating: 'LOW',
    armedConflict: 2,
    civilUnrest: 1,
    criminality: 1,
    organizedCrime: 3,
    terrorism: 2,
    activism: 3,
    informationSecurity: 5,
    countrySecurity: 1,
  },
  {
    country: 'Greece',
    travelRiskRating: 'LOW',
    armedConflict: 3,
    civilUnrest: 7,
    criminality: 4,
    organizedCrime: 5,
    terrorism: 7,
    activism: 5,
    informationSecurity: 5,
    countrySecurity: 6,
  },
  {
    country: 'Greenland',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 2,
    criminality: 2,
    organizedCrime: 1,
    terrorism: 1,
    activism: 6,
    informationSecurity: 5,
    countrySecurity: 4,
  },
  {
    country: 'Hungary',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 4,
    criminality: 3,
    organizedCrime: 4,
    terrorism: 3,
    activism: 2,
    informationSecurity: 5,
    countrySecurity: 3,
  },
  {
    country: 'Iceland',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Ireland',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 3,
    criminality: 3,
    organizedCrime: 4,
    terrorism: 2,
    activism: 6,
    informationSecurity: 5,
    countrySecurity: 4,
  },
  {
    country: 'Italy',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 4,
    criminality: 3,
    organizedCrime: 7,
    terrorism: 4,
    activism: 5,
    informationSecurity: 6,
    countrySecurity: 5,
  },
  {
    country: 'Kazakhstan',
    travelRiskRating: 'MEDIUM',
    armedConflict: 2,
    civilUnrest: 4,
    criminality: 5,
    organizedCrime: 7,
    terrorism: 4,
    activism: 2,
    informationSecurity: 9,
    countrySecurity: 6,
  },
  {
    country: 'Kosovo',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Kyrgyzstan',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Latvia',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Liechtenstein',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Lithuania',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Luxembourg',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 2,
    criminality: 2,
    organizedCrime: 2,
    terrorism: 2,
    activism: 3,
    informationSecurity: 5,
    countrySecurity: 2,
  },
  {
    country: 'Macedonia',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Malta',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Moldova',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Monaco',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 1,
    criminality: 1,
    organizedCrime: 2,
    terrorism: 2,
    activism: 4,
    informationSecurity: 5,
    countrySecurity: 1,
  },
  {
    country: 'Montenegro',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Netherlands',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 5,
    criminality: 2,
    organizedCrime: 4,
    terrorism: 4,
    activism: 8,
    informationSecurity: 5,
    countrySecurity: 4,
  },
  {
    country: 'Norway',
    travelRiskRating: 'LOW',
    armedConflict: 2,
    civilUnrest: 2,
    criminality: 2,
    organizedCrime: 3,
    terrorism: 4,
    activism: 5,
    informationSecurity: 7,
    countrySecurity: 3,
  },
  {
    country: 'Poland',
    travelRiskRating: 'LOW',
    armedConflict: 2,
    civilUnrest: 4,
    criminality: 2,
    organizedCrime: 4,
    terrorism: 2,
    activism: 3,
    informationSecurity: 6,
    countrySecurity: 4,
  },
  {
    country: 'Portugal',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 2,
    criminality: 2,
    organizedCrime: 2,
    terrorism: 2,
    activism: 3,
    informationSecurity: 5,
    countrySecurity: 2,
  },
  {
    country: 'Romania',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 4,
    criminality: 4,
    organizedCrime: 4,
    terrorism: 2,
    activism: 4,
    informationSecurity: 6,
    countrySecurity: 4,
  },
  {
    country: 'Russia',
    travelRiskRating: 'MEDIUM',
    armedConflict: 4,
    civilUnrest: 3,
    criminality: 6,
    organizedCrime: 8,
    terrorism: 6,
    activism: 3,
    informationSecurity: 10,
    countrySecurity: 7,
  },
  {
    country: 'San Marino',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Serbia',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Slovakia',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 4,
    criminality: 3,
    organizedCrime: 5,
    terrorism: 2,
    activism: 2,
    informationSecurity: 5,
    countrySecurity: 3,
  },
  {
    country: 'Slovenia',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 3,
    criminality: 1,
    organizedCrime: 2,
    terrorism: 2,
    activism: 2,
    informationSecurity: 4,
    countrySecurity: 2,
  },
  {
    country: 'Spain',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 5,
    criminality: 4,
    organizedCrime: 5,
    terrorism: 5,
    activism: 3,
    informationSecurity: 5,
    countrySecurity: 5,
  },
  {
    country: 'Sweden',
    travelRiskRating: 'LOW',
    armedConflict: 4,
    civilUnrest: 3,
    criminality: 2,
    organizedCrime: 6,
    terrorism: 4,
    activism: 4,
    informationSecurity: 5,
    countrySecurity: 3,
  },
  {
    country: 'Switzerland',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 3,
    criminality: 3,
    organizedCrime: 3,
    terrorism: 3,
    activism: 4,
    informationSecurity: 5,
    countrySecurity: 3,
  },
  {
    country: 'Tajikistan',
    travelRiskRating: 'MEDIUM',
    armedConflict: 3,
    civilUnrest: 4,
    criminality: 3,
    organizedCrime: 7,
    terrorism: 6,
    activism: 2,
    informationSecurity: 7,
    countrySecurity: 7,
  },
  {
    country: 'Turkey',
    travelRiskRating: 'MEDIUM',
    armedConflict: 6,
    civilUnrest: 4,
    criminality: 4,
    organizedCrime: 5,
    terrorism: 7,
    activism: 3,
    informationSecurity: 6,
    countrySecurity: 6,
  },
  {
    country: 'Turkmenistan',
    travelRiskRating: 'MEDIUM',
    armedConflict: 3,
    civilUnrest: 1,
    criminality: 5,
    organizedCrime: 8,
    terrorism: 2,
    activism: 1,
    informationSecurity: 10,
    countrySecurity: 5,
  },
  {
    country: 'Ukraine',
    travelRiskRating: 'EXTREME',
    armedConflict: 10,
    civilUnrest: 10,
    criminality: 10,
    organizedCrime: 10,
    terrorism: 10,
    activism: 10,
    informationSecurity: 11,
    countrySecurity: 10,
  },
  {
    country: 'United Kingdom',
    travelRiskRating: 'LOW',
    armedConflict: 1,
    civilUnrest: 4,
    criminality: 3,
    organizedCrime: 4,
    terrorism: 7,
    activism: 7,
    informationSecurity: 6,
    countrySecurity: 5,
  },
  {
    country: 'Uzbekistan',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Vatican City',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Afghanistan',
    travelRiskRating: 'EXTREME',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Algeria',
    travelRiskRating: 'MEDIUM',
    armedConflict: 3,
    civilUnrest: 8,
    criminality: 5,
    organizedCrime: 5,
    terrorism: 7,
    activism: 4,
    informationSecurity: 6,
    countrySecurity: 7,
  },
  {
    country: 'Bahrain',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Egypt',
    travelRiskRating: 'MEDIUM',
    armedConflict: 6,
    civilUnrest: 7,
    criminality: 5,
    organizedCrime: 4,
    terrorism: 6,
    activism: 3,
    informationSecurity: 6,
    countrySecurity: 7,
  },
  {
    country: 'Iran',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Iraq',
    travelRiskRating: 'EXTREME',
    armedConflict: 10,
    civilUnrest: 10,
    criminality: 10,
    organizedCrime: 10,
    terrorism: 10,
    activism: 2,
    informationSecurity: 8,
    countrySecurity: 10,
  },
  {
    country: 'Israel',
    travelRiskRating: 'MEDIUM',
    armedConflict: 6,
    civilUnrest: 5,
    criminality: 4,
    organizedCrime: 4,
    terrorism: 6,
    activism: 4,
    informationSecurity: 8,
    countrySecurity: 6,
  },
  {
    country: 'Jordan',
    travelRiskRating: 'MEDIUM',
    armedConflict: 3,
    civilUnrest: 6,
    criminality: 5,
    organizedCrime: 5,
    terrorism: 6,
    activism: 2,
    informationSecurity: 5,
    countrySecurity: 6,
  },
  {
    country: 'Kuwait',
    travelRiskRating: 'LOW',
    armedConflict: 3,
    civilUnrest: 4,
    criminality: 3,
    organizedCrime: 2,
    terrorism: 5,
    activism: 1,
    informationSecurity: 5,
    countrySecurity: 5,
  },
  {
    country: 'Lebanon',
    travelRiskRating: 'MEDIUM',
    armedConflict: 7,
    civilUnrest: 8,
    criminality: 6,
    organizedCrime: 6,
    terrorism: 6,
    activism: 6,
    informationSecurity: 5,
    countrySecurity: 8,
  },
  {
    country: 'Libya',
    travelRiskRating: 'EXTREME',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Mauritania',
    travelRiskRating: 'MEDIUM',
    armedConflict: 3,
    civilUnrest: 5,
    criminality: 6,
    organizedCrime: 6,
    terrorism: 6,
    activism: 2,
    informationSecurity: 2,
    countrySecurity: 6,
  },
  {
    country: 'Morocco',
    travelRiskRating: 'MEDIUM',
    armedConflict: 3,
    civilUnrest: 6,
    criminality: 5,
    organizedCrime: 4,
    terrorism: 6,
    activism: 4,
    informationSecurity: 5,
    countrySecurity: 6,
  },
  {
    country: 'Oman',
    travelRiskRating: 'LOW',
    armedConflict: 2,
    civilUnrest: 6,
    criminality: 3,
    organizedCrime: 3,
    terrorism: 4,
    activism: 6,
    informationSecurity: 5,
    countrySecurity: 6,
  },
  {
    country: 'Pakistan',
    travelRiskRating: 'HIGH',
    armedConflict: 6,
    civilUnrest: 6,
    criminality: 6,
    organizedCrime: 6,
    terrorism: 7,
    activism: 2,
    informationSecurity: 6,
    countrySecurity: 7,
  },
  {
    country: 'Qatar',
    travelRiskRating: 'LOW',
    armedConflict: 3,
    civilUnrest: 2,
    criminality: 3,
    organizedCrime: 3,
    terrorism: 2,
    activism: 3,
    informationSecurity: 6,
    countrySecurity: 4,
  },
  {
    country: 'Saudi Arabia',
    travelRiskRating: 'MEDIUM',
    armedConflict: 7,
    civilUnrest: 3,
    criminality: 3,
    organizedCrime: 3,
    terrorism: 5,
    activism: 2,
    informationSecurity: 6,
    countrySecurity: 7,
  },
  {
    country: 'Syria',
    travelRiskRating: 'EXTREME',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Tunisia',
    travelRiskRating: 'MEDIUM',
    armedConflict: 3,
    civilUnrest: 9,
    criminality: 5,
    organizedCrime: 5,
    terrorism: 7,
    activism: 4,
    informationSecurity: 6,
    countrySecurity: 7,
  },
  {
    country: 'United Arab Emirates',
    travelRiskRating: 'LOW',
    armedConflict: 6,
    civilUnrest: 2,
    criminality: 4,
    organizedCrime: 4,
    terrorism: 5,
    activism: 1,
    informationSecurity: 6,
    countrySecurity: 6,
  },
  {
    country: 'Yemen',
    travelRiskRating: 'EXTREME',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Angola',
    travelRiskRating: 'MEDIUM',
    armedConflict: 6,
    civilUnrest: 5,
    criminality: 5,
    organizedCrime: 5,
    terrorism: 3,
    activism: 3,
    informationSecurity: 7,
    countrySecurity: 6,
  },
  {
    country: 'Benin',
    travelRiskRating: 'MEDIUM',
    armedConflict: 4,
    civilUnrest: 5,
    criminality: 5,
    organizedCrime: 4,
    terrorism: 6,
    activism: 3,
    informationSecurity: 3,
    countrySecurity: 5,
  },
  {
    country: 'Botswana',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Burkina Faso',
    travelRiskRating: 'EXTREME',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Burundi',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Cameroon',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Cape Verde',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Central African Republic',
    travelRiskRating: 'EXTREME',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Chad',
    travelRiskRating: 'EXTREME',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Comoros',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: "Cote d'Ivoire",
    travelRiskRating: 'MEDIUM',
    armedConflict: 5,
    civilUnrest: 6,
    criminality: 7,
    organizedCrime: 7,
    terrorism: 6,
    activism: 3,
    informationSecurity: 5,
    countrySecurity: 6,
  },
  {
    country: 'Democratic Republic of the Congo',
    travelRiskRating: 'EXTREME',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Djibouti',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Equatorial Guinea',
    travelRiskRating: 'LOW',
    armedConflict: 2,
    civilUnrest: 4,
    criminality: 3,
    organizedCrime: 3,
    terrorism: 2,
    activism: 2,
    informationSecurity: 6,
    countrySecurity: 3,
  },
  {
    country: 'Eritrea',
    travelRiskRating: 'MEDIUM',
    armedConflict: 6,
    civilUnrest: 4,
    criminality: 3,
    organizedCrime: 5,
    terrorism: 3,
    activism: 2,
    informationSecurity: 7,
    countrySecurity: 5,
  },
  {
    country: 'Ethiopia',
    travelRiskRating: 'HIGH',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Gabon',
    travelRiskRating: 'LOW',
    armedConflict: 2,
    civilUnrest: 6,
    criminality: 4,
    organizedCrime: 4,
    terrorism: 1,
    activism: 3,
    informationSecurity: 3,
    countrySecurity: 6,
  },
  {
    country: 'Ghana',
    travelRiskRating: 'LOW',
    armedConflict: 4,
    civilUnrest: 5,
    criminality: 5,
    organizedCrime: 7,
    terrorism: 3,
    activism: 4,
    informationSecurity: 6,
    countrySecurity: 5,
  },
  {
    country: 'Guinea',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Guinea Bissau',
    travelRiskRating: 'HIGH',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Kenya',
    travelRiskRating: 'HIGH',
    armedConflict: 6,
    civilUnrest: 7,
    criminality: 8,
    organizedCrime: 7,
    terrorism: 7,
    activism: 3,
    informationSecurity: 7,
    countrySecurity: 7,
  },
  {
    country: 'Lesotho',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Liberia',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Madagascar',
    travelRiskRating: 'MEDIUM',
    armedConflict: 1,
    civilUnrest: 5,
    criminality: 4,
    organizedCrime: 3,
    terrorism: 4,
    activism: 2,
    informationSecurity: 2,
    countrySecurity: 4,
  },
  {
    country: 'Malawi',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Mali',
    travelRiskRating: 'EXTREME',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Mauritius',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Mozambique',
    travelRiskRating: 'MEDIUM',
    armedConflict: 4,
    civilUnrest: 5,
    criminality: 6,
    organizedCrime: 7,
    terrorism: 7,
    activism: 3,
    informationSecurity: 6,
    countrySecurity: 6,
  },
  {
    country: 'Namibia',
    travelRiskRating: 'LOW',
    armedConflict: 2,
    civilUnrest: 3,
    criminality: 4,
    organizedCrime: 3,
    terrorism: 2,
    activism: 2,
    informationSecurity: 3,
    countrySecurity: 3,
  },
  {
    country: 'Niger',
    travelRiskRating: 'EXTREME',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Nigeria',
    travelRiskRating: 'EXTREME',
    armedConflict: 7,
    civilUnrest: 9,
    criminality: 9,
    organizedCrime: 11,
    terrorism: 8,
    activism: 7,
    informationSecurity: 8,
    countrySecurity: 10,
  },
  {
    country: 'Congo',
    travelRiskRating: 'HIGH',
    armedConflict: 5,
    civilUnrest: 5,
    criminality: 5,
    organizedCrime: 5,
    terrorism: 2,
    activism: 2,
    informationSecurity: 4,
    countrySecurity: 5,
  },
  {
    country: 'Rwanda',
    travelRiskRating: 'LOW',
    armedConflict: 7,
    civilUnrest: 3,
    criminality: 3,
    organizedCrime: 3,
    terrorism: 3,
    activism: 3,
    informationSecurity: 5,
    countrySecurity: 3,
  },
  {
    country: 'Sao Tome & Principe',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Senegal',
    travelRiskRating: 'MEDIUM',
    armedConflict: 5,
    civilUnrest: 6,
    criminality: 7,
    organizedCrime: 6,
    terrorism: 6,
    activism: 4,
    informationSecurity: 5,
    countrySecurity: 6,
  },
  {
    country: 'Seychelles',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Sierra Leone',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Somalia',
    travelRiskRating: 'EXTREME',
    armedConflict: 11,
    civilUnrest: 7,
    criminality: 9,
    organizedCrime: 10,
    terrorism: 11,
    activism: 2,
    informationSecurity: 4,
    countrySecurity: 11,
  },
  {
    country: 'South Africa',
    travelRiskRating: 'HIGH',
    armedConflict: 3,
    civilUnrest: 9,
    criminality: 8,
    organizedCrime: 9,
    terrorism: 3,
    activism: 5,
    informationSecurity: 7,
    countrySecurity: 8,
  },
  {
    country: 'South Sudan',
    travelRiskRating: 'EXTREME',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Sudan',
    travelRiskRating: 'HIGH',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Swaziland',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Tanzania',
    travelRiskRating: 'MEDIUM',
    armedConflict: 2,
    civilUnrest: 5,
    criminality: 6,
    organizedCrime: 6,
    terrorism: 5,
    activism: 3,
    informationSecurity: 6,
    countrySecurity: 5,
  },
  {
    country: 'The Gambia',
    travelRiskRating: 'LOW',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
  {
    country: 'Togo',
    travelRiskRating: 'MEDIUM',
    armedConflict: 2,
    civilUnrest: 8,
    criminality: 8,
    organizedCrime: 7,
    terrorism: 3,
    activism: 1,
    informationSecurity: 4,
    countrySecurity: 6,
  },
  {
    country: 'Uganda',
    travelRiskRating: 'MEDIUM',
    armedConflict: 4,
    civilUnrest: 7,
    criminality: 7,
    organizedCrime: 5,
    terrorism: 6,
    activism: 3,
    informationSecurity: 6,
    countrySecurity: 6,
  },
  {
    country: 'Zambia',
    travelRiskRating: 'LOW',
    armedConflict: 2,
    civilUnrest: 5,
    criminality: 3,
    organizedCrime: 4,
    terrorism: 1,
    activism: 1,
    informationSecurity: 3,
    countrySecurity: 3,
  },
  {
    country: 'Zimbabwe',
    travelRiskRating: 'MEDIUM',
    armedConflict: 0,
    civilUnrest: 0,
    criminality: 0,
    organizedCrime: 0,
    terrorism: 0,
    activism: 0,
    informationSecurity: 0,
    countrySecurity: 0,
  },
];

export default riskData;
