import React, { ReactElement, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routepaths from './routes';

const Home = React.lazy(() => import('../pages/home'));

function AppRouter(): ReactElement {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path={routepaths.home} element={<Home />} />
          <Route path="*" element={<span>404</span>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppRouter;
