import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit';
import countriesReducer from '../features/countries/countriesSlice';
import filtersReducer from '../features/filters/filterSlice';

export const store = configureStore({
  reducer: {
    countries: countriesReducer,
    filters: filtersReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
