import geoJson from './geoJson';
import resourceData from './resouceData';
import riskData from './riskData';

type Risk = (typeof riskData)[number];
type RiskIndex = {
  [key: string]: Risk;
};
type Coord = (typeof geoJson)[number]['geometry']['coordinates'];
type Country = Risk & { paths: Coord; overallRating: number; liquids: number; gas: number };
type ResourceIndex = {
  [key: string]: {
    liquids: number;
    gas: number;
  };
};

export type Countries = { [key: string]: Country };

const getRawData = (): Countries => {
  const resourceIndex: ResourceIndex = resourceData.reduce((acc: ResourceIndex, country) => {
    if (acc[country.country]) {
      return {
        ...acc,
        [country.country]: {
          liquids: acc[country.country].liquids + (country.liquids || 0),
          gas: acc[country.country].gas + (country.gas || 0),
        },
      };
    }
    return {
      ...acc,
      [country.country]: {
        liquids: country.liquids || 0,
        gas: country.gas || 0,
      },
    };
  }, {});

  const riskIndex: RiskIndex = riskData.reduce(
    (acc, riskCountry) => ({
      ...acc,
      [riskCountry.country]: riskCountry,
    }),
    {},
  );

  // const countries = geoJson.map((country) => country.properties.name);
  // const getUnmatched = (obj: RiskIndex | ResourceIndex): string[] =>
  //   countries.reduce((acc: string[], country) => {
  //     if (Object.keys(obj).includes(country)) return acc;
  //     return [...acc, country];
  //   }, []);

  // console.log(
  //   `No risk data for:`,
  //   getUnmatched(riskIndex),
  //   ` No resources data for:`,
  //   getUnmatched(resourceIndex).length,
  // );

  const data: Countries = geoJson.reduce((acc, country) => {
    const key = country.properties.name;

    const countryResource: ResourceIndex[string] = resourceIndex[key]
      ? resourceIndex[key]
      : {
          liquids: 0,
          gas: 0,
        };

    const countryRisk: RiskIndex[string] = riskIndex[key]
      ? riskIndex[key]
      : {
          country: key,
          travelRiskRating: 'UNKNOWN',
          armedConflict: 0,
          civilUnrest: 0,
          criminality: 0,
          organizedCrime: 0,
          terrorism: 0,
          activism: 0,
          informationSecurity: 0,
          countrySecurity: 0,
        };

    return {
      ...acc,
      [key]: {
        paths: country.geometry.coordinates,
        overallRating: 0,
        ...countryResource,
        ...countryRisk,
      },
    };
  }, {});

  return data;
};

export default getRawData;
