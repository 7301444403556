import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import createAppAsyncThunk from '../../app/thunk';
import { initialState as filterInitialState, FilterState } from '../filters/filterSlice';

import getRawData, { Countries } from '../../data/data';

export type CountriesState = {
  data: Countries;
  activeCountry: string;
};

const MAX_VALUE = 11;

const getFilters = (filters: FilterState) =>
  new Promise<FilterState>((resolve) => {
    resolve(filters);
  });

export const updateCountries = createAppAsyncThunk('countries/updateCountries', async (_, arg) => {
  const response = await getFilters(arg.getState().filters);
  return response;
});

const calcNewData = (filters?: FilterState): Countries => {
  const rawData = getRawData();

  const countryKeys = Object.keys(rawData) as (keyof Countries)[];
  const filterKeys = Object.keys(filterInitialState) as (keyof FilterState)[];

  const newData = countryKeys.reduce((acc, key) => {
    const newRatings: FilterState = filterKeys.reduce((ratingsAcc, filterKey) => {
      const rating = rawData[key][filterKey] - (!filters ? 0 : filters[filterKey]);
      return { ...ratingsAcc, [filterKey]: rating < 0 ? 0 : rating };
    }, {} as FilterState);

    const totalRating: number = filterKeys.reduce((sumAcc, filterKey) => sumAcc + newRatings[filterKey], 0);
    const maxRating = filterKeys.length * MAX_VALUE;
    const overallRating = Math.round((totalRating / maxRating) * MAX_VALUE);

    const newRatingsData = {
      ...rawData[key],
      ...newRatings,
      overallRating,
    };

    return {
      ...acc,
      [key]: newRatingsData,
    };
  }, {});

  return newData;
};

const initialState: CountriesState = {
  data: calcNewData(),
  activeCountry: '',
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setActiveCountry: (draft, action: PayloadAction<string>) => {
      draft.activeCountry = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCountries.fulfilled, (draft, action) => {
      draft.data = calcNewData(action.payload);
    });
  },
});

export const { setActiveCountry } = countriesSlice.actions;

export const selectCountries = (state: RootState) => state.countries.data;

export const selectActiveCountry = (state: RootState) => state.countries.activeCountry;

export default countriesSlice.reducer;
