import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ShellThemeProvider } from '@sede-x/shell-ds-react-framework';

import { store } from './app/store';

import reportWebVitals from './reportWebVitals';
import AppRouter from './router/Router';

const container = document.getElementById('root');
const root = createRoot(container as Element);
root.render(
  <StrictMode>
    <Provider store={store}>
      <ShellThemeProvider theme="light">
        <AppRouter />
      </ShellThemeProvider>
    </Provider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
