const resourceData = [
  {
    country: 'New Zealand',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 32.0639,
    gas: 98.98759,
  },
  {
    country: 'New Zealand',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 23.2392,
    gas: 83.3069,
  },
  {
    country: 'New Zealand',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 13.8649,
    gas: 67.8091,
  },
  {
    country: 'Australia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 202.9069,
    gas: 667.7235,
  },
  {
    country: 'Australia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 164.735,
    gas: 891.2978,
  },
  {
    country: 'Australia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 363.4833,
    gas: 1812.136,
  },
  {
    country: 'Australia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 261.3407,
    gas: 5843.827,
  },
  {
    country: 'Australia',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 46.5903,
    gas: null,
  },
  {
    country: 'Australia',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 66.68,
  },
  {
    country: 'Australia',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 750.0562,
  },
  {
    country: 'Australia',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 1992.126,
  },
  {
    country: 'Australia',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: null,
    gas: 1114.055,
  },
  {
    country: 'Papua New Guinea',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 28.7022,
    gas: 10.3512,
  },
  {
    country: 'Papua New Guinea',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 122.8151,
    gas: 863.9918,
  },
  {
    country: 'Indonesia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 695.1712,
    gas: 817.6498,
  },
  {
    country: 'Indonesia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 569.1473,
    gas: 1194.998,
  },
  {
    country: 'Indonesia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 102.4457,
    gas: 527.0359,
  },
  {
    country: 'Indonesia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 54.114,
    gas: 289.5289,
  },
  {
    country: 'Indonesia',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 318.7369,
    gas: 0.3367,
  },
  {
    country: 'Indonesia',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 3.0297,
  },
  {
    country: 'Indonesia',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 0.4954,
  },
  {
    country: 'Philippines',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 6.2526,
    gas: 34.8775,
  },
  {
    country: 'Philippines',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 11.5803,
    gas: 40.5848,
  },
  {
    country: 'Philippines',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 1.0206,
    gas: null,
  },
  {
    country: 'Taiwan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.0057,
    gas: 0.0113,
  },
  {
    country: 'Japan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 11.8408,
    gas: 40.8733,
  },
  {
    country: 'Japan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 4.2603,
    gas: 188.1884,
  },
  {
    country: 'Japan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 3.7406,
    gas: null,
  },
  {
    country: 'Japan',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.1451,
    gas: 0.1778,
  },
  {
    country: 'South Korea',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: null,
    gas: 72.7835,
  },
  {
    country: 'Malaysia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 437.5378,
    gas: 473.6275,
  },
  {
    country: 'Malaysia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 204.2721,
    gas: 807.1362,
  },
  {
    country: 'Malaysia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 341.2393,
    gas: 1285.901,
  },
  {
    country: 'Malaysia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 182.6055,
    gas: 369.1699,
  },
  {
    country: 'Malaysia',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 13.7376,
    gas: null,
  },
  {
    country: 'Malaysia/Thailand JDA',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 13.1328,
    gas: 103.1352,
  },
  {
    country: 'Malaysia/Thailand JDA',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 12.6268,
    gas: 198.6096,
  },
  {
    country: 'Malaysia/Thailand JDA',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 4.9576,
    gas: 24.1722,
  },
  {
    country: 'Brunei',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 308.038,
    gas: 372.2257,
  },
  {
    country: 'Brunei',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 54.2034,
    gas: 57.9256,
  },
  {
    country: 'Brunei',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 130.2655,
    gas: 171.88,
  },
  {
    country: 'Brunei',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 43.4122,
    gas: 3.6092,
  },
  {
    country: 'China',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 6038.159,
    gas: 536.7232,
  },
  {
    country: 'China',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 8918.945,
    gas: 2121.815,
  },
  {
    country: 'China',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 5443.53,
    gas: 6158.865,
  },
  {
    country: 'China',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 2476.726,
    gas: 8696.548,
  },
  {
    country: 'China',
    unconventionalCategory: 'Oil shale (kerogen)',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 1180.02,
    gas: null,
  },
  {
    country: 'China',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 412.2155,
    gas: 0.855,
  },
  {
    country: 'China',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 1259.89,
    gas: 2.4227,
  },
  {
    country: 'China',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 1309.435,
    gas: 10.0227,
  },
  {
    country: 'China',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 40.555,
    gas: null,
  },
  {
    country: 'China',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 110.233,
    gas: null,
  },
  {
    country: 'China',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 121.238,
    gas: null,
  },
  {
    country: 'China',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 15.7369,
    gas: 146.6482,
  },
  {
    country: 'China',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 17.1307,
    gas: 461.874,
  },
  {
    country: 'China',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 90.9573,
    gas: 4782.875,
  },
  {
    country: 'China',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 30.9484,
    gas: 3250.694,
  },
  {
    country: 'Mongolia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 9.579,
    gas: null,
  },
  {
    country: 'Mongolia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 68.6595,
    gas: null,
  },
  {
    country: 'Vietnam',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 287.3436,
    gas: 52.7701,
  },
  {
    country: 'Vietnam',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 79.2887,
    gas: 101.8736,
  },
  {
    country: 'Vietnam',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 42.0701,
    gas: 64.4231,
  },
  {
    country: 'Vietnam',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 11.3865,
    gas: 61.997,
  },
  {
    country: 'Thailand',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 225.8578,
    gas: 366.4567,
  },
  {
    country: 'Thailand',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 182.4166,
    gas: 939.1938,
  },
  {
    country: 'Thailand',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 0.1301,
    gas: null,
  },
  {
    country: 'Myanmar',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 4.8845,
    gas: 188.7811,
  },
  {
    country: 'Myanmar',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 1.1444,
    gas: 166.6551,
  },
  {
    country: 'Myanmar',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 234.7887,
  },
  {
    country: 'Myanmar',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: null,
    gas: 228.8794,
  },
  {
    country: 'Bangladesh',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1.7405,
    gas: 321.2532,
  },
  {
    country: 'Bangladesh',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 39.4748,
    gas: 833.8678,
  },
  {
    country: 'Bangladesh',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 0.0153,
    gas: 21.6371,
  },
  {
    country: 'Bangladesh',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: null,
    gas: 6.6281,
  },
  {
    country: 'India',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1313.289,
    gas: 651.9243,
  },
  {
    country: 'India',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 510.5954,
    gas: 378.5178,
  },
  {
    country: 'India',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 432.7632,
    gas: 493.3857,
  },
  {
    country: 'India',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 159.399,
    gas: 520.3391,
  },
  {
    country: 'India',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 7.0599,
    gas: 0.1692,
  },
  {
    country: 'India',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 0.1417,
    gas: 0.1338,
  },
  {
    country: 'India',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 33.9209,
    gas: null,
  },
  {
    country: 'India',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 2.4969,
    gas: null,
  },
  {
    country: 'India',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 11.2693,
    gas: null,
  },
  {
    country: 'India',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 0.0114,
    gas: 5.0906,
  },
  {
    country: 'India',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 12.3018,
    gas: 101.5268,
  },
  {
    country: 'India',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: null,
    gas: 27.5402,
  },
  {
    country: 'Pakistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 53.5417,
    gas: 542.0869,
  },
  {
    country: 'Pakistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 137.5075,
    gas: 1120.511,
  },
  {
    country: 'Pakistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 15.9408,
    gas: 142.7421,
  },
  {
    country: 'Pakistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 210.3005,
    gas: 119.8199,
  },
  {
    country: 'Pakistan',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 0.4974,
    gas: null,
  },
  {
    country: 'Pakistan',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 0.0313,
    gas: 4.9824,
  },
  {
    country: 'Pakistan',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 0.0119,
    gas: 32.9324,
  },
  {
    country: 'Afghanistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.4072,
    gas: 0.0807,
  },
  {
    country: 'Tajikistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.102,
    gas: 0.0761,
  },
  {
    country: 'Tajikistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 1.7433,
    gas: null,
  },
  {
    country: 'Tajikistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 2.3678,
    gas: 3.5904,
  },
  {
    country: 'Kyrgyzstan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.321,
    gas: 0.007,
  },
  {
    country: 'Kazakhstan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1586.336,
    gas: 34.5663,
  },
  {
    country: 'Kazakhstan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 5248.109,
    gas: 2158.228,
  },
  {
    country: 'Kazakhstan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 2792.204,
    gas: 898.67,
  },
  {
    country: 'Kazakhstan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 6126.817,
    gas: 373.9432,
  },
  {
    country: 'Kazakhstan',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 27.4307,
    gas: 0.0564,
  },
  {
    country: 'Kazakhstan',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 168.9964,
    gas: null,
  },
  {
    country: 'Kazakhstan',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 1.8213,
    gas: null,
  },
  {
    country: 'Uzbekistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 133.7044,
    gas: 2045.523,
  },
  {
    country: 'Uzbekistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 108.0415,
    gas: 666.0569,
  },
  {
    country: 'Uzbekistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 48.1922,
    gas: 1087.224,
  },
  {
    country: 'Uzbekistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 82.7527,
    gas: 1121.052,
  },
  {
    country: 'Uzbekistan',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.0026,
    gas: 0.0019,
  },
  {
    country: 'Turkmenistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 685.7613,
    gas: 283.5308,
  },
  {
    country: 'Turkmenistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 388.2682,
    gas: 5076.379,
  },
  {
    country: 'Turkmenistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 168.5217,
    gas: 761.1965,
  },
  {
    country: 'Turkmenistan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 52.4419,
    gas: 4743.055,
  },
  {
    country: 'Azerbaijan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 504.5509,
    gas: 249.2185,
  },
  {
    country: 'Azerbaijan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 2617.488,
    gas: 1092.624,
  },
  {
    country: 'Azerbaijan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 11.759,
    gas: null,
  },
  {
    country: 'Azerbaijan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 332.188,
    gas: 1986.484,
  },
  {
    country: 'Azerbaijan',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 2.0335,
    gas: null,
  },
  {
    country: 'Georgia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.3143,
    gas: 0.0463,
  },
  {
    country: 'Georgia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 0.0491,
    gas: 0.0086,
  },
  {
    country: 'Georgia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 0.4112,
    gas: 1.7231,
  },
  {
    country: 'Iran',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 13564.43,
    gas: 1793.536,
  },
  {
    country: 'Iran',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 23247.4,
    gas: 10499.86,
  },
  {
    country: 'Iran',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 8876.139,
    gas: 9557.641,
  },
  {
    country: 'Iran',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 7525.157,
    gas: 16393.21,
  },
  {
    country: 'Iran',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.0996,
    gas: null,
  },
  {
    country: 'Iraq',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 2054.822,
    gas: 0.067,
  },
  {
    country: 'Iraq',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 3944.005,
    gas: 118.9524,
  },
  {
    country: 'Iraq',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 8381.897,
    gas: 861.1927,
  },
  {
    country: 'Iraq',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 41896.24,
    gas: 3641.822,
  },
  {
    country: 'Iraq',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.0056,
    gas: null,
  },
  {
    country: 'Iraq',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 257.67,
    gas: null,
  },
  {
    country: 'Kuwait',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1.3395,
    gas: 0.0469,
  },
  {
    country: 'Kuwait',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 27433.89,
    gas: 1635.508,
  },
  {
    country: 'Kuwait',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 12164.88,
    gas: 1182.789,
  },
  {
    country: 'Kuwait',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 605.543,
    gas: 41.3724,
  },
  {
    country: 'Kuwait',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 955.0054,
    gas: 75.2632,
  },
  {
    country: 'Kuwait',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 322.0208,
    gas: null,
  },
  {
    country: 'Neutral Zone',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 5346.03,
    gas: 185.218,
  },
  {
    country: 'Saudi Arabia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 4096.845,
    gas: 1.0016,
  },
  {
    country: 'Saudi Arabia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 97971.08,
    gas: 7794.588,
  },
  {
    country: 'Saudi Arabia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 104938.9,
    gas: 13409.58,
  },
  {
    country: 'Saudi Arabia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 25023.38,
    gas: 6816.49,
  },
  {
    country: 'Saudi Arabia',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 12.1864,
    gas: 231.53,
  },
  {
    country: 'Bahrain',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 163.3388,
    gas: 281.5615,
  },
  {
    country: 'Bahrain',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 82.6071,
    gas: 1799.503,
  },
  {
    country: 'Bahrain',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 11.5727,
    gas: null,
  },
  {
    country: 'Qatar',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 627.4634,
    gas: 37.4159,
  },
  {
    country: 'Qatar',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 4518.087,
    gas: 1868.413,
  },
  {
    country: 'Qatar',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 9107.723,
    gas: 22255.29,
  },
  {
    country: 'Qatar',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 2835.878,
    gas: 4832.117,
  },
  {
    country: 'United Arab Emirates',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 261.0276,
    gas: 28.3459,
  },
  {
    country: 'United Arab Emirates',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 19750.09,
    gas: 6346.91,
  },
  {
    country: 'United Arab Emirates',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 17169.21,
    gas: 4166.378,
  },
  {
    country: 'United Arab Emirates',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 7018.694,
    gas: 16.6016,
  },
  {
    country: 'United Arab Emirates',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 3.363,
    gas: 7.854,
  },
  {
    country: 'Oman',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1068.471,
    gas: 464.2229,
  },
  {
    country: 'Oman',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 2098.97,
    gas: 657.2878,
  },
  {
    country: 'Oman',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 321.3018,
    gas: 2100.129,
  },
  {
    country: 'Oman',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 569.6616,
    gas: 493.1241,
  },
  {
    country: 'Oman',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 9.0531,
    gas: null,
  },
  {
    country: 'Oman',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 1177.62,
    gas: null,
  },
  {
    country: 'Oman',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 179.5464,
    gas: 873.7607,
  },
  {
    country: 'Oman',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 129.3512,
    gas: 578.2678,
  },
  {
    country: 'Yemen',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 318.6503,
    gas: 0.281,
  },
  {
    country: 'Yemen',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 121.8868,
    gas: 0.0155,
  },
  {
    country: 'Yemen',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 4.5079,
    gas: null,
  },
  {
    country: 'Yemen',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 116.1344,
    gas: 1449.993,
  },
  {
    country: 'Yemen',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 3.7549,
    gas: null,
  },
  {
    country: 'Syria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 234.6665,
    gas: 14.1769,
  },
  {
    country: 'Syria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 231.5723,
    gas: 144.9656,
  },
  {
    country: 'Syria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 126.0203,
    gas: 95.8281,
  },
  {
    country: 'Syria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 28.0701,
    gas: 3.787,
  },
  {
    country: 'Syria',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 2.7472,
    gas: 0.6292,
  },
  {
    country: 'Turkey',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 100.7879,
    gas: 5.1687,
  },
  {
    country: 'Turkey',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 5.1314,
    gas: 5.9797,
  },
  {
    country: 'Turkey',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 11.4785,
    gas: 0.4743,
  },
  {
    country: 'Turkey',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 9.8718,
    gas: null,
  },
  {
    country: 'Turkey',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 63.5322,
    gas: 0.8042,
  },
  {
    country: 'Turkey',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 61.023,
    gas: null,
  },
  {
    country: 'Israel',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 0.0775,
  },
  {
    country: 'Israel',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 3.2384,
    gas: 900.2959,
  },
  {
    country: 'Israel',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 95.9242,
    gas: 2465.137,
  },
  {
    country: 'Jordan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.0242,
    gas: null,
  },
  {
    country: 'Jordan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 2.1073,
    gas: null,
  },
  {
    country: 'Jordan',
    unconventionalCategory: 'Oil shale (kerogen)',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 298.8017,
    gas: null,
  },
  {
    country: 'Jordan',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 0.7292,
    gas: 12.607,
  },
  {
    country: 'Egypt',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 942.2398,
    gas: 606.0068,
  },
  {
    country: 'Egypt',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 385.6579,
    gas: 482.3583,
  },
  {
    country: 'Egypt',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 296.8588,
    gas: 405.1299,
  },
  {
    country: 'Egypt',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 350.3756,
    gas: 2857.559,
  },
  {
    country: 'Egypt',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 6.0266,
    gas: 0.1344,
  },
  {
    country: 'Egypt',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 33.1187,
    gas: null,
  },
  {
    country: 'Egypt',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 21.1672,
    gas: 77.8082,
  },
  {
    country: 'Egypt',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 1.495,
  },
  {
    country: 'Libya',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 3227.942,
    gas: 389.9091,
  },
  {
    country: 'Libya',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 3195.116,
    gas: 180.6107,
  },
  {
    country: 'Libya',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 2978.241,
    gas: 410.1489,
  },
  {
    country: 'Libya',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 515.2488,
    gas: 110.4751,
  },
  {
    country: 'Chad',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 106.4184,
    gas: null,
  },
  {
    country: 'Chad',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 237.7922,
    gas: null,
  },
  {
    country: 'Chad',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 253.0417,
    gas: 0.8864,
  },
  {
    country: 'Chad',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 78.0906,
    gas: null,
  },
  {
    country: 'Sudan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 102.4572,
    gas: null,
  },
  {
    country: 'Sudan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 65.6815,
    gas: null,
  },
  {
    country: 'Sudan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 6.8526,
    gas: null,
  },
  {
    country: 'South Sudan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 391.6608,
    gas: null,
  },
  {
    country: 'South Sudan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 67.4787,
    gas: null,
  },
  {
    country: 'South Sudan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 77.1494,
    gas: null,
  },
  {
    country: 'South Sudan',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 4.4907,
    gas: null,
  },
  {
    country: 'South Sudan',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 104.5244,
    gas: null,
  },
  {
    country: 'Tanzania',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 41.4726,
  },
  {
    country: 'Tanzania',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 72.4981,
  },
  {
    country: 'Tanzania',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: null,
    gas: 62.0191,
  },
  {
    country: 'Madagascar',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 1.8028,
    gas: null,
  },
  {
    country: 'Mozambique',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 0.5642,
    gas: 307.0554,
  },
  {
    country: 'Mozambique',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 31.8087,
    gas: 661.742,
  },
  {
    country: 'South Africa',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.2479,
    gas: 7.7061,
  },
  {
    country: 'South Africa',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: null,
    gas: 5.9528,
  },
  {
    country: 'Botswana',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: null,
    gas: 7.1302,
  },
  {
    country: 'Angola',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1003.653,
    gas: null,
  },
  {
    country: 'Angola',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 1429.264,
    gas: 166.1,
  },
  {
    country: 'Angola',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 953.6558,
    gas: 0,
  },
  {
    country: 'Angola',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 76.9286,
    gas: null,
  },
  {
    country: 'Democratic Republic of the Congo',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 40.9622,
    gas: null,
  },
  {
    country: 'Congo',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 173.5162,
    gas: null,
  },
  {
    country: 'Congo',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 229.5931,
    gas: 55.2161,
  },
  {
    country: 'Congo',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 350.0049,
    gas: 7.679,
  },
  {
    country: 'Congo',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 175.5886,
    gas: null,
  },
  {
    country: 'Congo',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 30.0565,
    gas: null,
  },
  {
    country: 'Gabon',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 260.3569,
    gas: 0.71,
  },
  {
    country: 'Gabon',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 87.5473,
    gas: 3.068,
  },
  {
    country: 'Gabon',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 177.9032,
    gas: 0.0022,
  },
  {
    country: 'Cameroon',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 60.1691,
    gas: 5.3695,
  },
  {
    country: 'Cameroon',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 0.1099,
    gas: 38.6616,
  },
  {
    country: 'Cameroon',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 41.4467,
    gas: 12.8654,
  },
  {
    country: 'Cameroon',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 4.5083,
    gas: 2.2409,
  },
  {
    country: 'Cameroon',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 9.147,
    gas: null,
  },
  {
    country: 'Nigeria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 2223.594,
    gas: 768.3666,
  },
  {
    country: 'Nigeria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 1415.588,
    gas: 2384.699,
  },
  {
    country: 'Nigeria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 1373.505,
    gas: 840.2206,
  },
  {
    country: 'Nigeria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 990.6683,
    gas: 912.5309,
  },
  {
    country: 'Nigeria',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1.4058,
    gas: 0.0009,
  },
  {
    country: 'Equatorial Guinea',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 181.6843,
    gas: 115.161,
  },
  {
    country: 'Equatorial Guinea',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 18.9547,
    gas: 75.6473,
  },
  {
    country: 'Ghana',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 5.4355,
    gas: null,
  },
  {
    country: 'Ghana',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 209.0721,
    gas: 20.8574,
  },
  {
    country: 'Ghana',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 192.4983,
    gas: 138.6575,
  },
  {
    country: "Cote d'Ivoire",
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 38.2463,
    gas: 32.2809,
  },
  {
    country: "Cote d'Ivoire",
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 2.2692,
    gas: 55.0677,
  },
  {
    country: 'Niger',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 6.8141,
    gas: null,
  },
  {
    country: 'Algeria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 4985.805,
    gas: 2495.688,
  },
  {
    country: 'Algeria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 1409.282,
    gas: 1496.057,
  },
  {
    country: 'Algeria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 1125.226,
    gas: 411.394,
  },
  {
    country: 'Algeria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 906.6629,
    gas: 5425.396,
  },
  {
    country: 'Algeria',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 1.7319,
    gas: 5.4667,
  },
  {
    country: 'Algeria',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 12.4322,
    gas: 122.8686,
  },
  {
    country: 'Tunisia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 57.0876,
    gas: 39.9082,
  },
  {
    country: 'Tunisia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 20.3408,
    gas: 11.9644,
  },
  {
    country: 'Tunisia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 5.2988,
    gas: 3.1792,
  },
  {
    country: 'Tunisia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 11.3594,
    gas: 59.583,
  },
  {
    country: 'Senegal',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 0.5119,
  },
  {
    country: 'Morocco',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.5083,
    gas: 1.0061,
  },
  {
    country: 'Morocco',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 0.0489,
  },
  {
    country: 'Morocco',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 0.0031,
  },
  {
    country: 'Brazil',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 798.8655,
    gas: 175.2595,
  },
  {
    country: 'Brazil',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 1284.663,
    gas: 161.3338,
  },
  {
    country: 'Brazil',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 5823.949,
    gas: 780.5335,
  },
  {
    country: 'Brazil',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 2636.402,
    gas: 526.124,
  },
  {
    country: 'Brazil',
    unconventionalCategory: 'Oil shale (kerogen)',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 998.73,
    gas: null,
  },
  {
    country: 'Brazil',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 267.2429,
    gas: 18.1067,
  },
  {
    country: 'Brazil',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 161.7159,
    gas: 0.74,
  },
  {
    country: 'Brazil',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 98.9743,
    gas: 2.3655,
  },
  {
    country: 'Brazil',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 239.0668,
    gas: 7.1395,
  },
  {
    country: 'Argentina',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 774.2883,
    gas: 502.7459,
  },
  {
    country: 'Argentina',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 363.3473,
    gas: 409.7221,
  },
  {
    country: 'Argentina',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 41.1134,
    gas: 165.8745,
  },
  {
    country: 'Argentina',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 20.8516,
    gas: 19.0014,
  },
  {
    country: 'Argentina',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 2.4481,
    gas: 0.0002,
  },
  {
    country: 'Argentina',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 20.1136,
    gas: 15.7928,
  },
  {
    country: 'Argentina',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 10.7636,
    gas: 0.1166,
  },
  {
    country: 'Argentina',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 4.449,
    gas: 2.7678,
  },
  {
    country: 'Argentina',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 3.6596,
    gas: 1.0322,
  },
  {
    country: 'Argentina',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 8.2244,
    gas: 30.123,
  },
  {
    country: 'Argentina',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 572.4849,
    gas: 211.4749,
  },
  {
    country: 'Argentina',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.6801,
    gas: 1.6349,
  },
  {
    country: 'Argentina',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 2.3001,
    gas: 9.6431,
  },
  {
    country: 'Argentina',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 21.7701,
    gas: 299.3224,
  },
  {
    country: 'Argentina',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 256.423,
    gas: 1392.846,
  },
  {
    country: 'Chile',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 17.1185,
    gas: 22.1577,
  },
  {
    country: 'Chile',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 1.1791,
    gas: 0.1191,
  },
  {
    country: 'Chile',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 0.0696,
    gas: 0.5618,
  },
  {
    country: 'Bolivia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 39.1464,
    gas: 177.2847,
  },
  {
    country: 'Bolivia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 42.5218,
    gas: 173.6884,
  },
  {
    country: 'Bolivia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 14.806,
    gas: 167.0679,
  },
  {
    country: 'Peru',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 67.1495,
    gas: 4.2905,
  },
  {
    country: 'Peru',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 158.54,
    gas: 493.3507,
  },
  {
    country: 'Peru',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 170.3423,
    gas: 693.3479,
  },
  {
    country: 'Peru',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 119.3116,
    gas: 128.8022,
  },
  {
    country: 'Peru',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 5.3152,
    gas: null,
  },
  {
    country: 'Ecuador',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1196.894,
    gas: 17.1963,
  },
  {
    country: 'Ecuador',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 149.9845,
    gas: 0.1924,
  },
  {
    country: 'Ecuador',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 6.416,
    gas: null,
  },
  {
    country: 'Ecuador',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 5.0344,
    gas: 0.4344,
  },
  {
    country: 'Ecuador',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 15.0776,
    gas: null,
  },
  {
    country: 'Ecuador',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 79.931,
    gas: null,
  },
  {
    country: 'Ecuador',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 195.33,
    gas: null,
  },
  {
    country: 'Ecuador',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 63.37,
    gas: null,
  },
  {
    country: 'Colombia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 605.6289,
    gas: 256.4391,
  },
  {
    country: 'Colombia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 342.7868,
    gas: 135.0044,
  },
  {
    country: 'Colombia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 120.8394,
    gas: 46.9483,
  },
  {
    country: 'Colombia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 67.4807,
    gas: 79.0134,
  },
  {
    country: 'Colombia',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 362.8787,
    gas: 2.1553,
  },
  {
    country: 'Colombia',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 613.214,
    gas: 1.0872,
  },
  {
    country: 'Colombia',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 316.6214,
    gas: null,
  },
  {
    country: 'Colombia',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 2.5291,
    gas: null,
  },
  {
    country: 'Colombia',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 0.024,
    gas: 0.0626,
  },
  {
    country: 'Venezuela',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1080.807,
    gas: 447.2397,
  },
  {
    country: 'Venezuela',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 977.7523,
    gas: 277.0625,
  },
  {
    country: 'Venezuela',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 196.4479,
    gas: 161.004,
  },
  {
    country: 'Venezuela',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 156.6768,
    gas: null,
  },
  {
    country: 'Venezuela',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 637.9964,
    gas: 7.4636,
  },
  {
    country: 'Venezuela',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 696.7415,
    gas: 9.0906,
  },
  {
    country: 'Venezuela',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 2058.5,
    gas: null,
  },
  {
    country: 'Guyana',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 418.661,
    gas: null,
  },
  {
    country: 'Guyana',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 547.247,
    gas: null,
  },
  {
    country: 'Suriname',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.6743,
    gas: null,
  },
  {
    country: 'Suriname',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 58.0769,
    gas: null,
  },
  {
    country: 'Trinidad and Tobago',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 36.4567,
    gas: 634.1336,
  },
  {
    country: 'Trinidad and Tobago',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 10.0674,
    gas: 113.3219,
  },
  {
    country: 'Trinidad and Tobago',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 512.7992,
    gas: 544.3673,
  },
  {
    country: 'Trinidad and Tobago',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 22.0572,
    gas: 409.5007,
  },
  {
    country: 'Trinidad and Tobago',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 19.2985,
    gas: 0.0374,
  },
  {
    country: 'Trinidad and Tobago',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 11.1426,
    gas: null,
  },
  {
    country: 'Trinidad and Tobago',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 2.7983,
    gas: null,
  },
  {
    country: 'Barbados',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1.9912,
    gas: 0.0721,
  },
  {
    country: 'Cuba',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 6.8972,
    gas: null,
  },
  {
    country: 'Cuba',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 156.3763,
    gas: null,
  },
  {
    country: 'Guatemala',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 20.1789,
    gas: null,
  },
  {
    country: 'Guatemala',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 0.3893,
    gas: null,
  },
  {
    country: 'Guatemala',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 3.3672,
    gas: 12.6258,
  },
  {
    country: 'Belize',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.8801,
    gas: null,
  },
  {
    country: 'Mexico',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1231.091,
    gas: 522.2618,
  },
  {
    country: 'Mexico',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 2035.331,
    gas: 199.7951,
  },
  {
    country: 'Mexico',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 515.6204,
    gas: 309.4023,
  },
  {
    country: 'Mexico',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 1560.403,
    gas: 539.6677,
  },
  {
    country: 'Mexico',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 100.976,
    gas: 43.9915,
  },
  {
    country: 'Mexico',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 1220.848,
    gas: 94.0974,
  },
  {
    country: 'Mexico',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 1003.342,
    gas: 5.6012,
  },
  {
    country: 'Mexico',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 6.7245,
    gas: 14.275,
  },
  {
    country: 'United States',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 18492.45,
    gas: 9509.488,
  },
  {
    country: 'United States',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 4262.711,
    gas: 2661.013,
  },
  {
    country: 'United States',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 4419.037,
    gas: 3021.664,
  },
  {
    country: 'United States',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 1732.214,
    gas: 114.1209,
  },
  {
    country: 'United States',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1003.533,
    gas: 832.6885,
  },
  {
    country: 'United States',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 29.2217,
    gas: 2.1383,
  },
  {
    country: 'United States',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 138.817,
    gas: 0.136,
  },
  {
    country: 'United States',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 127.1031,
    gas: 80.7577,
  },
  {
    country: 'United States',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 340.3029,
    gas: 150.8324,
  },
  {
    country: 'United States',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 4997.173,
    gas: 1835.601,
  },
  {
    country: 'United States',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 16763.25,
    gas: 6683.609,
  },
  {
    country: 'United States',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 480.025,
    gas: 1899.597,
  },
  {
    country: 'United States',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 614.3466,
    gas: 2000.26,
  },
  {
    country: 'United States',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 2107.561,
    gas: 13142.69,
  },
  {
    country: 'United States',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 2533.901,
    gas: 10206.59,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 2971.832,
    gas: 2216.441,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 600.283,
    gas: 1239.635,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 1115.036,
    gas: 53.2131,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 206.6487,
    gas: 23.9713,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Oil sands',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1153.087,
    gas: 0.4081,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Oil sands',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 3363.895,
    gas: null,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Oil sands',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 5803.01,
    gas: null,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Oil sands',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 25092.72,
    gas: null,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 518.1458,
    gas: 74.811,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 528.6733,
    gas: 4.7272,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 48.7343,
    gas: 0.1307,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 2136.335,
    gas: 73.9531,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 24.5408,
    gas: 8.0356,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 138.3582,
    gas: 49.3427,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 254.1237,
    gas: 164.3442,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 621.8371,
    gas: 427.0949,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 14.1451,
    gas: 220.1272,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 27.7705,
    gas: 374.9244,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 398.1367,
    gas: 816.0102,
  },
  {
    country: 'Canada',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 1121.272,
    gas: 3969.688,
  },
  {
    country: 'Ireland',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 70.5245,
  },
  {
    country: 'United Kingdom',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 912.7222,
    gas: 577.2962,
  },
  {
    country: 'United Kingdom',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 306.1491,
    gas: 313.7341,
  },
  {
    country: 'United Kingdom',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 272.7267,
    gas: 89.4231,
  },
  {
    country: 'United Kingdom',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 598.6812,
    gas: 42.8229,
  },
  {
    country: 'United Kingdom',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 243.6251,
    gas: null,
  },
  {
    country: 'Norway',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 2299.592,
    gas: 1773.077,
  },
  {
    country: 'Norway',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 1396.065,
    gas: 3758.218,
  },
  {
    country: 'Norway',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 2250.801,
    gas: 1213.583,
  },
  {
    country: 'Norway',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 897.4768,
    gas: 2406.931,
  },
  {
    country: 'Denmark',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 191.599,
    gas: 92.1223,
  },
  {
    country: 'Estonia',
    unconventionalCategory: 'Oil shale (kerogen)',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 41.1003,
    gas: null,
  },
  {
    country: 'Estonia',
    unconventionalCategory: 'Oil shale (kerogen)',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 9.2165,
    gas: null,
  },
  {
    country: 'Estonia',
    unconventionalCategory: 'Oil shale (kerogen)',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 61.36,
    gas: null,
  },
  {
    country: 'Lithuania',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1.2479,
    gas: null,
  },
  {
    country: 'Poland',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 6.4626,
    gas: 76.5269,
  },
  {
    country: 'Poland',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 30.3375,
    gas: 54.1635,
  },
  {
    country: 'Poland',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 25.6288,
    gas: 89.0321,
  },
  {
    country: 'Poland',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 5.0325,
    gas: 10.6205,
  },
  {
    country: 'Poland',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 4.1093,
  },
  {
    country: 'Poland',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 1.9708,
  },
  {
    country: 'Poland',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 0.124,
  },
  {
    country: 'Germany',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 70.8386,
    gas: 209.5802,
  },
  {
    country: 'Germany',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 10.7823,
    gas: 13.1834,
  },
  {
    country: 'Germany',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 0.2866,
  },
  {
    country: 'Germany',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 10.8035,
    gas: 0.3394,
  },
  {
    country: 'Germany',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 0.233,
  },
  {
    country: 'Austria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 67.7298,
    gas: 72.7919,
  },
  {
    country: 'Austria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 0.0199,
    gas: 7.9868,
  },
  {
    country: 'Austria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 3.0748,
  },
  {
    country: 'Austria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 5.9876,
    gas: 0.5992,
  },
  {
    country: 'Austria',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.4323,
    gas: 0.1145,
  },
  {
    country: 'France',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 29.2411,
    gas: 0.2926,
  },
  {
    country: 'France',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 18.6526,
    gas: 4.0484,
  },
  {
    country: 'France',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 0.231,
    gas: null,
  },
  {
    country: 'Belgium',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 1.0956,
  },
  {
    country: 'Netherlands',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 13.2855,
    gas: 286.1024,
  },
  {
    country: 'Netherlands',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 0.5051,
    gas: 146.2067,
  },
  {
    country: 'Netherlands',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 1.1575,
    gas: 146.0904,
  },
  {
    country: 'Netherlands',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.0554,
    gas: 0.2837,
  },
  {
    country: 'Netherlands',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 18.7261,
    gas: null,
  },
  {
    country: 'Spain',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 0.0976,
  },
  {
    country: 'Spain',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 0.0973,
    gas: 3.7917,
  },
  {
    country: 'Italy',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 27.4784,
    gas: 81.5666,
  },
  {
    country: 'Italy',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 211.3529,
    gas: 81.479,
  },
  {
    country: 'Italy',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 0.0181,
    gas: 2.2633,
  },
  {
    country: 'Italy',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 165.3068,
    gas: 22.0021,
  },
  {
    country: 'Italy',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 10.5886,
    gas: 0.3059,
  },
  {
    country: 'Slovenia',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 0.0033,
    gas: 0.0892,
  },
  {
    country: 'Croatia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 21.7402,
    gas: 34.1964,
  },
  {
    country: 'Croatia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 1.7958,
  },
  {
    country: 'Croatia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 13.1954,
    gas: 1.6974,
  },
  {
    country: 'Croatia',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.0105,
    gas: null,
  },
  {
    country: 'Serbia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 45.6511,
    gas: 15.7831,
  },
  {
    country: 'Serbia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 5.124,
  },
  {
    country: 'Serbia',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.0292,
    gas: null,
  },
  {
    country: 'Albania',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 48.0766,
    gas: null,
  },
  {
    country: 'Albania',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 3.107,
    gas: null,
  },
  {
    country: 'Albania',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.7903,
    gas: null,
  },
  {
    country: 'Albania',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 0.973,
    gas: 1.2079,
  },
  {
    country: 'Greece',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 14.4119,
    gas: null,
  },
  {
    country: 'Bulgaria',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.0268,
    gas: 0.7718,
  },
  {
    country: 'Bulgaria',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.0045,
    gas: null,
  },
  {
    country: 'Romania',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 75.3454,
    gas: 278.3483,
  },
  {
    country: 'Romania',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 13.4109,
    gas: 95.3387,
  },
  {
    country: 'Romania',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 44.7611,
    gas: 66.3989,
  },
  {
    country: 'Romania',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 20.7085,
    gas: 45.5403,
  },
  {
    country: 'Romania',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 49.2448,
    gas: 0.0017,
  },
  {
    country: 'Romania',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 50.0761,
    gas: null,
  },
  {
    country: 'Hungary',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 31.4206,
    gas: 47.5759,
  },
  {
    country: 'Hungary',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 7.109,
    gas: 38.2043,
  },
  {
    country: 'Hungary',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 30.4845,
    gas: 4.4781,
  },
  {
    country: 'Hungary',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 3.394,
    gas: 0.0329,
  },
  {
    country: 'Hungary',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 0.0092,
  },
  {
    country: 'Hungary',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 0.6131,
  },
  {
    country: 'Czech Republic',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 2.1756,
    gas: 0.3429,
  },
  {
    country: 'Czech Republic',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 4.6967,
    gas: null,
  },
  {
    country: 'Slovakia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 0.5845,
    gas: 1.5641,
  },
  {
    country: 'Slovakia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 0.1114,
  },
  {
    country: 'Ukraine',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 126.6737,
    gas: 924.8851,
  },
  {
    country: 'Ukraine',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 88.6631,
    gas: 309.3442,
  },
  {
    country: 'Ukraine',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 19.5208,
    gas: 197.925,
  },
  {
    country: 'Ukraine',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: null,
    gas: 8.4066,
  },
  {
    country: 'Ukraine',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1.9381,
    gas: 1.5495,
  },
  {
    country: 'Ukraine',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 1.6706,
    gas: 16.599,
  },
  {
    country: 'Ukraine',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 3.9516,
    gas: 10.6095,
  },
  {
    country: 'Belarus',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 70.443,
    gas: null,
  },
  {
    country: 'Belarus',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 39.3124,
    gas: null,
  },
  {
    country: 'Belarus',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 1.731,
    gas: null,
  },
  {
    country: 'Belarus',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 5.8012,
    gas: null,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 13386.97,
    gas: 7198.513,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 21564.12,
    gas: 34988.25,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 33211.52,
    gas: 14537.54,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Conventional',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 15475.1,
    gas: 21655.04,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Oil shale (kerogen)',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 18.64,
    gas: null,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Oil sands',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 16.777,
    gas: null,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Oil sands',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 300.819,
    gas: null,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Oil sands',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 180.3917,
    gas: null,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: 21.4908,
    gas: 0.4087,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 50%-75%',
    commerciality: 'Commercial',
    liquids: 168.1993,
    gas: null,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 123.2716,
    gas: 0.0029,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Extra heavy oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 1262.819,
    gas: null,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Tight oil',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 15.3305,
    gas: 0.0134,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing >75%',
    commerciality: 'Commercial',
    liquids: null,
    gas: 0.0043,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing 25-50%',
    commerciality: 'Commercial',
    liquids: 707.5781,
    gas: 1211.448,
  },
  {
    country: 'Russia',
    unconventionalCategory: 'Unconventional gas',
    lifeCycleCategory: 'Producing',
    lifeCycleDetail: 'Producing early',
    commerciality: 'Commercial',
    liquids: 650.0551,
    gas: 1659.162,
  },
];

export default resourceData;
