const geoJson = [
  {
    type: 'Feature',
    id: 'AFG',
    properties: { name: 'Afghanistan' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 61.210817, lat: 35.650072 },
          { lng: 62.230651, lat: 35.270664 },
          { lng: 62.984662, lat: 35.404041 },
          { lng: 63.193538, lat: 35.857166 },
          { lng: 63.982896, lat: 36.007957 },
          { lng: 64.546479, lat: 36.312073 },
          { lng: 64.746105, lat: 37.111818 },
          { lng: 65.588948, lat: 37.305217 },
          { lng: 65.745631, lat: 37.661164 },
          { lng: 66.217385, lat: 37.39379 },
          { lng: 66.518607, lat: 37.362784 },
          { lng: 67.075782, lat: 37.356144 },
          { lng: 67.83, lat: 37.144994 },
          { lng: 68.135562, lat: 37.023115 },
          { lng: 68.859446, lat: 37.344336 },
          { lng: 69.196273, lat: 37.151144 },
          { lng: 69.518785, lat: 37.608997 },
          { lng: 70.116578, lat: 37.588223 },
          { lng: 70.270574, lat: 37.735165 },
          { lng: 70.376304, lat: 38.138396 },
          { lng: 70.806821, lat: 38.486282 },
          { lng: 71.348131, lat: 38.258905 },
          { lng: 71.239404, lat: 37.953265 },
          { lng: 71.541918, lat: 37.905774 },
          { lng: 71.448693, lat: 37.065645 },
          { lng: 71.844638, lat: 36.738171 },
          { lng: 72.193041, lat: 36.948288 },
          { lng: 72.63689, lat: 37.047558 },
          { lng: 73.260056, lat: 37.495257 },
          { lng: 73.948696, lat: 37.421566 },
          { lng: 74.980002, lat: 37.41999 },
          { lng: 75.158028, lat: 37.133031 },
          { lng: 74.575893, lat: 37.020841 },
          { lng: 74.067552, lat: 36.836176 },
          { lng: 72.920025, lat: 36.720007 },
          { lng: 71.846292, lat: 36.509942 },
          { lng: 71.262348, lat: 36.074388 },
          { lng: 71.498768, lat: 35.650563 },
          { lng: 71.613076, lat: 35.153203 },
          { lng: 71.115019, lat: 34.733126 },
          { lng: 71.156773, lat: 34.348911 },
          { lng: 70.881803, lat: 33.988856 },
          { lng: 69.930543, lat: 34.02012 },
          { lng: 70.323594, lat: 33.358533 },
          { lng: 69.687147, lat: 33.105499 },
          { lng: 69.262522, lat: 32.501944 },
          { lng: 69.317764, lat: 31.901412 },
          { lng: 68.926677, lat: 31.620189 },
          { lng: 68.556932, lat: 31.71331 },
          { lng: 67.792689, lat: 31.58293 },
          { lng: 67.683394, lat: 31.303154 },
          { lng: 66.938891, lat: 31.304911 },
          { lng: 66.381458, lat: 30.738899 },
          { lng: 66.346473, lat: 29.887943 },
          { lng: 65.046862, lat: 29.472181 },
          { lng: 64.350419, lat: 29.560031 },
          { lng: 64.148002, lat: 29.340819 },
          { lng: 63.550261, lat: 29.468331 },
          { lng: 62.549857, lat: 29.318572 },
          { lng: 60.874248, lat: 29.829239 },
          { lng: 61.781222, lat: 30.73585 },
          { lng: 61.699314, lat: 31.379506 },
          { lng: 60.941945, lat: 31.548075 },
          { lng: 60.863655, lat: 32.18292 },
          { lng: 60.536078, lat: 32.981269 },
          { lng: 60.9637, lat: 33.528832 },
          { lng: 60.52843, lat: 33.676446 },
          { lng: 60.803193, lat: 34.404102 },
          { lng: 61.210817, lat: 35.650072 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'AGO',
    properties: { name: 'Angola' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 16.326528, lat: -5.87747 },
          { lng: 16.57318, lat: -6.622645 },
          { lng: 16.860191, lat: -7.222298 },
          { lng: 17.089996, lat: -7.545689 },
          { lng: 17.47297, lat: -8.068551 },
          { lng: 18.134222, lat: -7.987678 },
          { lng: 18.464176, lat: -7.847014 },
          { lng: 19.016752, lat: -7.988246 },
          { lng: 19.166613, lat: -7.738184 },
          { lng: 19.417502, lat: -7.155429 },
          { lng: 20.037723, lat: -7.116361 },
          { lng: 20.091622, lat: -6.94309 },
          { lng: 20.601823, lat: -6.939318 },
          { lng: 20.514748, lat: -7.299606 },
          { lng: 21.728111, lat: -7.290872 },
          { lng: 21.746456, lat: -7.920085 },
          { lng: 21.949131, lat: -8.305901 },
          { lng: 21.801801, lat: -8.908707 },
          { lng: 21.875182, lat: -9.523708 },
          { lng: 22.208753, lat: -9.894796 },
          { lng: 22.155268, lat: -11.084801 },
          { lng: 22.402798, lat: -10.993075 },
          { lng: 22.837345, lat: -11.017622 },
          { lng: 23.456791, lat: -10.867863 },
          { lng: 23.912215, lat: -10.926826 },
          { lng: 24.017894, lat: -11.237298 },
          { lng: 23.904154, lat: -11.722282 },
          { lng: 24.079905, lat: -12.191297 },
          { lng: 23.930922, lat: -12.565848 },
          { lng: 24.016137, lat: -12.911046 },
          { lng: 21.933886, lat: -12.898437 },
          { lng: 21.887843, lat: -16.08031 },
          { lng: 22.562478, lat: -16.898451 },
          { lng: 23.215048, lat: -17.523116 },
          { lng: 21.377176, lat: -17.930636 },
          { lng: 18.956187, lat: -17.789095 },
          { lng: 18.263309, lat: -17.309951 },
          { lng: 14.209707, lat: -17.353101 },
          { lng: 14.058501, lat: -17.423381 },
          { lng: 13.462362, lat: -16.971212 },
          { lng: 12.814081, lat: -16.941343 },
          { lng: 12.215461, lat: -17.111668 },
          { lng: 11.734199, lat: -17.301889 },
          { lng: 11.640096, lat: -16.673142 },
          { lng: 11.778537, lat: -15.793816 },
          { lng: 12.123581, lat: -14.878316 },
          { lng: 12.175619, lat: -14.449144 },
          { lng: 12.500095, lat: -13.5477 },
          { lng: 12.738479, lat: -13.137906 },
          { lng: 13.312914, lat: -12.48363 },
          { lng: 13.633721, lat: -12.038645 },
          { lng: 13.738728, lat: -11.297863 },
          { lng: 13.686379, lat: -10.731076 },
          { lng: 13.387328, lat: -10.373578 },
          { lng: 13.120988, lat: -9.766897 },
          { lng: 12.87537, lat: -9.166934 },
          { lng: 12.929061, lat: -8.959091 },
          { lng: 13.236433, lat: -8.562629 },
          { lng: 12.93304, lat: -7.596539 },
          { lng: 12.728298, lat: -6.927122 },
          { lng: 12.227347, lat: -6.294448 },
          { lng: 12.322432, lat: -6.100092 },
          { lng: 12.735171, lat: -5.965682 },
          { lng: 13.024869, lat: -5.984389 },
          { lng: 13.375597, lat: -5.864241 },
          { lng: 16.326528, lat: -5.87747 },
        ],
        [
          { lng: 12.436688, lat: -5.684304 },
          { lng: 12.182337, lat: -5.789931 },
          { lng: 11.914963, lat: -5.037987 },
          { lng: 12.318608, lat: -4.60623 },
          { lng: 12.62076, lat: -4.438023 },
          { lng: 12.995517, lat: -4.781103 },
          { lng: 12.631612, lat: -4.991271 },
          { lng: 12.468004, lat: -5.248362 },
          { lng: 12.436688, lat: -5.684304 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ALB',
    properties: { name: 'Albania' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 20.590247, lat: 41.855404 },
          { lng: 20.463175, lat: 41.515089 },
          { lng: 20.605182, lat: 41.086226 },
          { lng: 21.02004, lat: 40.842727 },
          { lng: 20.99999, lat: 40.580004 },
          { lng: 20.674997, lat: 40.435 },
          { lng: 20.615, lat: 40.110007 },
          { lng: 20.150016, lat: 39.624998 },
          { lng: 19.98, lat: 39.694993 },
          { lng: 19.960002, lat: 39.915006 },
          { lng: 19.406082, lat: 40.250773 },
          { lng: 19.319059, lat: 40.72723 },
          { lng: 19.40355, lat: 41.409566 },
          { lng: 19.540027, lat: 41.719986 },
          { lng: 19.371769, lat: 41.877548 },
          { lng: 19.304486, lat: 42.195745 },
          { lng: 19.738051, lat: 42.688247 },
          { lng: 19.801613, lat: 42.500093 },
          { lng: 20.0707, lat: 42.58863 },
          { lng: 20.283755, lat: 42.32026 },
          { lng: 20.52295, lat: 42.21787 },
          { lng: 20.590247, lat: 41.855404 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ARE',
    properties: { name: 'United Arab Emirates' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 51.579519, lat: 24.245497 },
          { lng: 51.757441, lat: 24.294073 },
          { lng: 51.794389, lat: 24.019826 },
          { lng: 52.577081, lat: 24.177439 },
          { lng: 53.404007, lat: 24.151317 },
          { lng: 54.008001, lat: 24.121758 },
          { lng: 54.693024, lat: 24.797892 },
          { lng: 55.439025, lat: 25.439145 },
          { lng: 56.070821, lat: 26.055464 },
          { lng: 56.261042, lat: 25.714606 },
          { lng: 56.396847, lat: 24.924732 },
          { lng: 55.886233, lat: 24.920831 },
          { lng: 55.804119, lat: 24.269604 },
          { lng: 55.981214, lat: 24.130543 },
          { lng: 55.528632, lat: 23.933604 },
          { lng: 55.525841, lat: 23.524869 },
          { lng: 55.234489, lat: 23.110993 },
          { lng: 55.208341, lat: 22.70833 },
          { lng: 55.006803, lat: 22.496948 },
          { lng: 52.000733, lat: 23.001154 },
          { lng: 51.617708, lat: 24.014219 },
          { lng: 51.579519, lat: 24.245497 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ARG',
    properties: { name: 'Argentina' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: -65.5, lat: -55.2 },
          { lng: -66.45, lat: -55.25 },
          { lng: -66.95992, lat: -54.89681 },
          { lng: -67.56244, lat: -54.87001 },
          { lng: -68.63335, lat: -54.8695 },
          { lng: -68.63401, lat: -52.63637 },
          { lng: -68.25, lat: -53.1 },
          { lng: -67.75, lat: -53.85 },
          { lng: -66.45, lat: -54.45 },
          { lng: -65.05, lat: -54.7 },
          { lng: -65.5, lat: -55.2 },
        ],
        [
          { lng: -64.964892, lat: -22.075862 },
          { lng: -64.377021, lat: -22.798091 },
          { lng: -63.986838, lat: -21.993644 },
          { lng: -62.846468, lat: -22.034985 },
          { lng: -62.685057, lat: -22.249029 },
          { lng: -60.846565, lat: -23.880713 },
          { lng: -60.028966, lat: -24.032796 },
          { lng: -58.807128, lat: -24.771459 },
          { lng: -57.777217, lat: -25.16234 },
          { lng: -57.63366, lat: -25.603657 },
          { lng: -58.618174, lat: -27.123719 },
          { lng: -57.60976, lat: -27.395899 },
          { lng: -56.486702, lat: -27.548499 },
          { lng: -55.695846, lat: -27.387837 },
          { lng: -54.788795, lat: -26.621786 },
          { lng: -54.625291, lat: -25.739255 },
          { lng: -54.13005, lat: -25.547639 },
          { lng: -53.628349, lat: -26.124865 },
          { lng: -53.648735, lat: -26.923473 },
          { lng: -54.490725, lat: -27.474757 },
          { lng: -55.162286, lat: -27.881915 },
          { lng: -56.2909, lat: -28.852761 },
          { lng: -57.625133, lat: -30.216295 },
          { lng: -57.874937, lat: -31.016556 },
          { lng: -58.14244, lat: -32.044504 },
          { lng: -58.132648, lat: -33.040567 },
          { lng: -58.349611, lat: -33.263189 },
          { lng: -58.427074, lat: -33.909454 },
          { lng: -58.495442, lat: -34.43149 },
          { lng: -57.22583, lat: -35.288027 },
          { lng: -57.362359, lat: -35.97739 },
          { lng: -56.737487, lat: -36.413126 },
          { lng: -56.788285, lat: -36.901572 },
          { lng: -57.749157, lat: -38.183871 },
          { lng: -59.231857, lat: -38.72022 },
          { lng: -61.237445, lat: -38.928425 },
          { lng: -62.335957, lat: -38.827707 },
          { lng: -62.125763, lat: -39.424105 },
          { lng: -62.330531, lat: -40.172586 },
          { lng: -62.145994, lat: -40.676897 },
          { lng: -62.745803, lat: -41.028761 },
          { lng: -63.770495, lat: -41.166789 },
          { lng: -64.73209, lat: -40.802677 },
          { lng: -65.118035, lat: -41.064315 },
          { lng: -64.978561, lat: -42.058001 },
          { lng: -64.303408, lat: -42.359016 },
          { lng: -63.755948, lat: -42.043687 },
          { lng: -63.458059, lat: -42.563138 },
          { lng: -64.378804, lat: -42.873558 },
          { lng: -65.181804, lat: -43.495381 },
          { lng: -65.328823, lat: -44.501366 },
          { lng: -65.565269, lat: -45.036786 },
          { lng: -66.509966, lat: -45.039628 },
          { lng: -67.293794, lat: -45.551896 },
          { lng: -67.580546, lat: -46.301773 },
          { lng: -66.597066, lat: -47.033925 },
          { lng: -65.641027, lat: -47.236135 },
          { lng: -65.985088, lat: -48.133289 },
          { lng: -67.166179, lat: -48.697337 },
          { lng: -67.816088, lat: -49.869669 },
          { lng: -68.728745, lat: -50.264218 },
          { lng: -69.138539, lat: -50.73251 },
          { lng: -68.815561, lat: -51.771104 },
          { lng: -68.149995, lat: -52.349983 },
          { lng: -68.571545, lat: -52.299444 },
          { lng: -69.498362, lat: -52.142761 },
          { lng: -71.914804, lat: -52.009022 },
          { lng: -72.329404, lat: -51.425956 },
          { lng: -72.309974, lat: -50.67701 },
          { lng: -72.975747, lat: -50.74145 },
          { lng: -73.328051, lat: -50.378785 },
          { lng: -73.415436, lat: -49.318436 },
          { lng: -72.648247, lat: -48.878618 },
          { lng: -72.331161, lat: -48.244238 },
          { lng: -72.447355, lat: -47.738533 },
          { lng: -71.917258, lat: -46.884838 },
          { lng: -71.552009, lat: -45.560733 },
          { lng: -71.659316, lat: -44.973689 },
          { lng: -71.222779, lat: -44.784243 },
          { lng: -71.329801, lat: -44.407522 },
          { lng: -71.793623, lat: -44.207172 },
          { lng: -71.464056, lat: -43.787611 },
          { lng: -71.915424, lat: -43.408565 },
          { lng: -72.148898, lat: -42.254888 },
          { lng: -71.746804, lat: -42.051386 },
          { lng: -71.915734, lat: -40.832339 },
          { lng: -71.680761, lat: -39.808164 },
          { lng: -71.413517, lat: -38.916022 },
          { lng: -70.814664, lat: -38.552995 },
          { lng: -71.118625, lat: -37.576827 },
          { lng: -71.121881, lat: -36.658124 },
          { lng: -70.364769, lat: -36.005089 },
          { lng: -70.388049, lat: -35.169688 },
          { lng: -69.817309, lat: -34.193571 },
          { lng: -69.814777, lat: -33.273886 },
          { lng: -70.074399, lat: -33.09121 },
          { lng: -70.535069, lat: -31.36501 },
          { lng: -69.919008, lat: -30.336339 },
          { lng: -70.01355, lat: -29.367923 },
          { lng: -69.65613, lat: -28.459141 },
          { lng: -69.001235, lat: -27.521214 },
          { lng: -68.295542, lat: -26.89934 },
          { lng: -68.5948, lat: -26.506909 },
          { lng: -68.386001, lat: -26.185016 },
          { lng: -68.417653, lat: -24.518555 },
          { lng: -67.328443, lat: -24.025303 },
          { lng: -66.985234, lat: -22.986349 },
          { lng: -67.106674, lat: -22.735925 },
          { lng: -66.273339, lat: -21.83231 },
          { lng: -64.964892, lat: -22.075862 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ARM',
    properties: { name: 'Armenia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 43.582746, lat: 41.092143 },
          { lng: 44.97248, lat: 41.248129 },
          { lng: 45.179496, lat: 40.985354 },
          { lng: 45.560351, lat: 40.81229 },
          { lng: 45.359175, lat: 40.561504 },
          { lng: 45.891907, lat: 40.218476 },
          { lng: 45.610012, lat: 39.899994 },
          { lng: 46.034534, lat: 39.628021 },
          { lng: 46.483499, lat: 39.464155 },
          { lng: 46.50572, lat: 38.770605 },
          { lng: 46.143623, lat: 38.741201 },
          { lng: 45.735379, lat: 39.319719 },
          { lng: 45.739978, lat: 39.473999 },
          { lng: 45.298145, lat: 39.471751 },
          { lng: 45.001987, lat: 39.740004 },
          { lng: 44.79399, lat: 39.713003 },
          { lng: 44.400009, lat: 40.005 },
          { lng: 43.656436, lat: 40.253564 },
          { lng: 43.752658, lat: 40.740201 },
          { lng: 43.582746, lat: 41.092143 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ATF',
    properties: {
      name: 'French Southern and Antarctic Lands',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 68.935, lat: -48.625 },
          { lng: 69.58, lat: -48.94 },
          { lng: 70.525, lat: -49.065 },
          { lng: 70.56, lat: -49.255 },
          { lng: 70.28, lat: -49.71 },
          { lng: 68.745, lat: -49.775 },
          { lng: 68.72, lat: -49.2425 },
          { lng: 68.8675, lat: -48.83 },
          { lng: 68.935, lat: -48.625 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'AUS',
    properties: { name: 'Australia' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 145.397978, lat: -40.792549 },
          { lng: 146.364121, lat: -41.137695 },
          { lng: 146.908584, lat: -41.000546 },
          { lng: 147.689259, lat: -40.808258 },
          { lng: 148.289068, lat: -40.875438 },
          { lng: 148.359865, lat: -42.062445 },
          { lng: 148.017301, lat: -42.407024 },
          { lng: 147.914052, lat: -43.211522 },
          { lng: 147.564564, lat: -42.937689 },
          { lng: 146.870343, lat: -43.634597 },
          { lng: 146.663327, lat: -43.580854 },
          { lng: 146.048378, lat: -43.549745 },
          { lng: 145.43193, lat: -42.693776 },
          { lng: 145.29509, lat: -42.03361 },
          { lng: 144.718071, lat: -41.162552 },
          { lng: 144.743755, lat: -40.703975 },
          { lng: 145.397978, lat: -40.792549 },
        ],
        [
          { lng: 143.561811, lat: -13.763656 },
          { lng: 143.922099, lat: -14.548311 },
          { lng: 144.563714, lat: -14.171176 },
          { lng: 144.894908, lat: -14.594458 },
          { lng: 145.374724, lat: -14.984976 },
          { lng: 145.271991, lat: -15.428205 },
          { lng: 145.48526, lat: -16.285672 },
          { lng: 145.637033, lat: -16.784918 },
          { lng: 145.888904, lat: -16.906926 },
          { lng: 146.160309, lat: -17.761655 },
          { lng: 146.063674, lat: -18.280073 },
          { lng: 146.387478, lat: -18.958274 },
          { lng: 147.471082, lat: -19.480723 },
          { lng: 148.177602, lat: -19.955939 },
          { lng: 148.848414, lat: -20.39121 },
          { lng: 148.717465, lat: -20.633469 },
          { lng: 149.28942, lat: -21.260511 },
          { lng: 149.678337, lat: -22.342512 },
          { lng: 150.077382, lat: -22.122784 },
          { lng: 150.482939, lat: -22.556142 },
          { lng: 150.727265, lat: -22.402405 },
          { lng: 150.899554, lat: -23.462237 },
          { lng: 151.609175, lat: -24.076256 },
          { lng: 152.07354, lat: -24.457887 },
          { lng: 152.855197, lat: -25.267501 },
          { lng: 153.136162, lat: -26.071173 },
          { lng: 153.161949, lat: -26.641319 },
          { lng: 153.092909, lat: -27.2603 },
          { lng: 153.569469, lat: -28.110067 },
          { lng: 153.512108, lat: -28.995077 },
          { lng: 153.339095, lat: -29.458202 },
          { lng: 153.069241, lat: -30.35024 },
          { lng: 153.089602, lat: -30.923642 },
          { lng: 152.891578, lat: -31.640446 },
          { lng: 152.450002, lat: -32.550003 },
          { lng: 151.709117, lat: -33.041342 },
          { lng: 151.343972, lat: -33.816023 },
          { lng: 151.010555, lat: -34.31036 },
          { lng: 150.714139, lat: -35.17346 },
          { lng: 150.32822, lat: -35.671879 },
          { lng: 150.075212, lat: -36.420206 },
          { lng: 149.946124, lat: -37.109052 },
          { lng: 149.997284, lat: -37.425261 },
          { lng: 149.423882, lat: -37.772681 },
          { lng: 148.304622, lat: -37.809061 },
          { lng: 147.381733, lat: -38.219217 },
          { lng: 146.922123, lat: -38.606532 },
          { lng: 146.317922, lat: -39.035757 },
          { lng: 145.489652, lat: -38.593768 },
          { lng: 144.876976, lat: -38.417448 },
          { lng: 145.032212, lat: -37.896188 },
          { lng: 144.485682, lat: -38.085324 },
          { lng: 143.609974, lat: -38.809465 },
          { lng: 142.745427, lat: -38.538268 },
          { lng: 142.17833, lat: -38.380034 },
          { lng: 141.606582, lat: -38.308514 },
          { lng: 140.638579, lat: -38.019333 },
          { lng: 139.992158, lat: -37.402936 },
          { lng: 139.806588, lat: -36.643603 },
          { lng: 139.574148, lat: -36.138362 },
          { lng: 139.082808, lat: -35.732754 },
          { lng: 138.120748, lat: -35.612296 },
          { lng: 138.449462, lat: -35.127261 },
          { lng: 138.207564, lat: -34.384723 },
          { lng: 137.71917, lat: -35.076825 },
          { lng: 136.829406, lat: -35.260535 },
          { lng: 137.352371, lat: -34.707339 },
          { lng: 137.503886, lat: -34.130268 },
          { lng: 137.890116, lat: -33.640479 },
          { lng: 137.810328, lat: -32.900007 },
          { lng: 136.996837, lat: -33.752771 },
          { lng: 136.372069, lat: -34.094766 },
          { lng: 135.989043, lat: -34.890118 },
          { lng: 135.208213, lat: -34.47867 },
          { lng: 135.239218, lat: -33.947953 },
          { lng: 134.613417, lat: -33.222778 },
          { lng: 134.085904, lat: -32.848072 },
          { lng: 134.273903, lat: -32.617234 },
          { lng: 132.990777, lat: -32.011224 },
          { lng: 132.288081, lat: -31.982647 },
          { lng: 131.326331, lat: -31.495803 },
          { lng: 129.535794, lat: -31.590423 },
          { lng: 128.240938, lat: -31.948489 },
          { lng: 127.102867, lat: -32.282267 },
          { lng: 126.148714, lat: -32.215966 },
          { lng: 125.088623, lat: -32.728751 },
          { lng: 124.221648, lat: -32.959487 },
          { lng: 124.028947, lat: -33.483847 },
          { lng: 123.659667, lat: -33.890179 },
          { lng: 122.811036, lat: -33.914467 },
          { lng: 122.183064, lat: -34.003402 },
          { lng: 121.299191, lat: -33.821036 },
          { lng: 120.580268, lat: -33.930177 },
          { lng: 119.893695, lat: -33.976065 },
          { lng: 119.298899, lat: -34.509366 },
          { lng: 119.007341, lat: -34.464149 },
          { lng: 118.505718, lat: -34.746819 },
          { lng: 118.024972, lat: -35.064733 },
          { lng: 117.295507, lat: -35.025459 },
          { lng: 116.625109, lat: -35.025097 },
          { lng: 115.564347, lat: -34.386428 },
          { lng: 115.026809, lat: -34.196517 },
          { lng: 115.048616, lat: -33.623425 },
          { lng: 115.545123, lat: -33.487258 },
          { lng: 115.714674, lat: -33.259572 },
          { lng: 115.679379, lat: -32.900369 },
          { lng: 115.801645, lat: -32.205062 },
          { lng: 115.689611, lat: -31.612437 },
          { lng: 115.160909, lat: -30.601594 },
          { lng: 114.997043, lat: -30.030725 },
          { lng: 115.040038, lat: -29.461095 },
          { lng: 114.641974, lat: -28.810231 },
          { lng: 114.616498, lat: -28.516399 },
          { lng: 114.173579, lat: -28.118077 },
          { lng: 114.048884, lat: -27.334765 },
          { lng: 113.477498, lat: -26.543134 },
          { lng: 113.338953, lat: -26.116545 },
          { lng: 113.778358, lat: -26.549025 },
          { lng: 113.440962, lat: -25.621278 },
          { lng: 113.936901, lat: -25.911235 },
          { lng: 114.232852, lat: -26.298446 },
          { lng: 114.216161, lat: -25.786281 },
          { lng: 113.721255, lat: -24.998939 },
          { lng: 113.625344, lat: -24.683971 },
          { lng: 113.393523, lat: -24.384764 },
          { lng: 113.502044, lat: -23.80635 },
          { lng: 113.706993, lat: -23.560215 },
          { lng: 113.843418, lat: -23.059987 },
          { lng: 113.736552, lat: -22.475475 },
          { lng: 114.149756, lat: -21.755881 },
          { lng: 114.225307, lat: -22.517488 },
          { lng: 114.647762, lat: -21.82952 },
          { lng: 115.460167, lat: -21.495173 },
          { lng: 115.947373, lat: -21.068688 },
          { lng: 116.711615, lat: -20.701682 },
          { lng: 117.166316, lat: -20.623599 },
          { lng: 117.441545, lat: -20.746899 },
          { lng: 118.229559, lat: -20.374208 },
          { lng: 118.836085, lat: -20.263311 },
          { lng: 118.987807, lat: -20.044203 },
          { lng: 119.252494, lat: -19.952942 },
          { lng: 119.805225, lat: -19.976506 },
          { lng: 120.85622, lat: -19.683708 },
          { lng: 121.399856, lat: -19.239756 },
          { lng: 121.655138, lat: -18.705318 },
          { lng: 122.241665, lat: -18.197649 },
          { lng: 122.286624, lat: -17.798603 },
          { lng: 122.312772, lat: -17.254967 },
          { lng: 123.012574, lat: -16.4052 },
          { lng: 123.433789, lat: -17.268558 },
          { lng: 123.859345, lat: -17.069035 },
          { lng: 123.503242, lat: -16.596506 },
          { lng: 123.817073, lat: -16.111316 },
          { lng: 124.258287, lat: -16.327944 },
          { lng: 124.379726, lat: -15.56706 },
          { lng: 124.926153, lat: -15.0751 },
          { lng: 125.167275, lat: -14.680396 },
          { lng: 125.670087, lat: -14.51007 },
          { lng: 125.685796, lat: -14.230656 },
          { lng: 126.125149, lat: -14.347341 },
          { lng: 126.142823, lat: -14.095987 },
          { lng: 126.582589, lat: -13.952791 },
          { lng: 127.065867, lat: -13.817968 },
          { lng: 127.804633, lat: -14.276906 },
          { lng: 128.35969, lat: -14.86917 },
          { lng: 128.985543, lat: -14.875991 },
          { lng: 129.621473, lat: -14.969784 },
          { lng: 129.4096, lat: -14.42067 },
          { lng: 129.888641, lat: -13.618703 },
          { lng: 130.339466, lat: -13.357376 },
          { lng: 130.183506, lat: -13.10752 },
          { lng: 130.617795, lat: -12.536392 },
          { lng: 131.223495, lat: -12.183649 },
          { lng: 131.735091, lat: -12.302453 },
          { lng: 132.575298, lat: -12.114041 },
          { lng: 132.557212, lat: -11.603012 },
          { lng: 131.824698, lat: -11.273782 },
          { lng: 132.357224, lat: -11.128519 },
          { lng: 133.019561, lat: -11.376411 },
          { lng: 133.550846, lat: -11.786515 },
          { lng: 134.393068, lat: -12.042365 },
          { lng: 134.678632, lat: -11.941183 },
          { lng: 135.298491, lat: -12.248606 },
          { lng: 135.882693, lat: -11.962267 },
          { lng: 136.258381, lat: -12.049342 },
          { lng: 136.492475, lat: -11.857209 },
          { lng: 136.95162, lat: -12.351959 },
          { lng: 136.685125, lat: -12.887223 },
          { lng: 136.305407, lat: -13.29123 },
          { lng: 135.961758, lat: -13.324509 },
          { lng: 136.077617, lat: -13.724278 },
          { lng: 135.783836, lat: -14.223989 },
          { lng: 135.428664, lat: -14.715432 },
          { lng: 135.500184, lat: -14.997741 },
          { lng: 136.295175, lat: -15.550265 },
          { lng: 137.06536, lat: -15.870762 },
          { lng: 137.580471, lat: -16.215082 },
          { lng: 138.303217, lat: -16.807604 },
          { lng: 138.585164, lat: -16.806622 },
          { lng: 139.108543, lat: -17.062679 },
          { lng: 139.260575, lat: -17.371601 },
          { lng: 140.215245, lat: -17.710805 },
          { lng: 140.875463, lat: -17.369069 },
          { lng: 141.07111, lat: -16.832047 },
          { lng: 141.274095, lat: -16.38887 },
          { lng: 141.398222, lat: -15.840532 },
          { lng: 141.702183, lat: -15.044921 },
          { lng: 141.56338, lat: -14.561333 },
          { lng: 141.63552, lat: -14.270395 },
          { lng: 141.519869, lat: -13.698078 },
          { lng: 141.65092, lat: -12.944688 },
          { lng: 141.842691, lat: -12.741548 },
          { lng: 141.68699, lat: -12.407614 },
          { lng: 141.928629, lat: -11.877466 },
          { lng: 142.118488, lat: -11.328042 },
          { lng: 142.143706, lat: -11.042737 },
          { lng: 142.51526, lat: -10.668186 },
          { lng: 142.79731, lat: -11.157355 },
          { lng: 142.866763, lat: -11.784707 },
          { lng: 143.115947, lat: -11.90563 },
          { lng: 143.158632, lat: -12.325656 },
          { lng: 143.522124, lat: -12.834358 },
          { lng: 143.597158, lat: -13.400422 },
          { lng: 143.561811, lat: -13.763656 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'AUT',
    properties: { name: 'Austria' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 16.979667, lat: 48.123497 },
          { lng: 16.903754, lat: 47.714866 },
          { lng: 16.340584, lat: 47.712902 },
          { lng: 16.534268, lat: 47.496171 },
          { lng: 16.202298, lat: 46.852386 },
          { lng: 16.011664, lat: 46.683611 },
          { lng: 15.137092, lat: 46.658703 },
          { lng: 14.632472, lat: 46.431817 },
          { lng: 13.806475, lat: 46.509306 },
          { lng: 12.376485, lat: 46.767559 },
          { lng: 12.153088, lat: 47.115393 },
          { lng: 11.164828, lat: 46.941579 },
          { lng: 11.048556, lat: 46.751359 },
          { lng: 10.442701, lat: 46.893546 },
          { lng: 9.932448, lat: 46.920728 },
          { lng: 9.47997, lat: 47.10281 },
          { lng: 9.632932, lat: 47.347601 },
          { lng: 9.594226, lat: 47.525058 },
          { lng: 9.896068, lat: 47.580197 },
          { lng: 10.402084, lat: 47.302488 },
          { lng: 10.544504, lat: 47.566399 },
          { lng: 11.426414, lat: 47.523766 },
          { lng: 12.141357, lat: 47.703083 },
          { lng: 12.62076, lat: 47.672388 },
          { lng: 12.932627, lat: 47.467646 },
          { lng: 13.025851, lat: 47.637584 },
          { lng: 12.884103, lat: 48.289146 },
          { lng: 13.243357, lat: 48.416115 },
          { lng: 13.595946, lat: 48.877172 },
          { lng: 14.338898, lat: 48.555305 },
          { lng: 14.901447, lat: 48.964402 },
          { lng: 15.253416, lat: 49.039074 },
          { lng: 16.029647, lat: 48.733899 },
          { lng: 16.499283, lat: 48.785808 },
          { lng: 16.960288, lat: 48.596982 },
          { lng: 16.879983, lat: 48.470013 },
          { lng: 16.979667, lat: 48.123497 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'AZE',
    properties: { name: 'Azerbaijan' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 45.001987, lat: 39.740004 },
          { lng: 45.298145, lat: 39.471751 },
          { lng: 45.739978, lat: 39.473999 },
          { lng: 45.735379, lat: 39.319719 },
          { lng: 46.143623, lat: 38.741201 },
          { lng: 45.457722, lat: 38.874139 },
          { lng: 44.952688, lat: 39.335765 },
          { lng: 44.79399, lat: 39.713003 },
          { lng: 45.001987, lat: 39.740004 },
        ],
        [
          { lng: 47.373315, lat: 41.219732 },
          { lng: 47.815666, lat: 41.151416 },
          { lng: 47.987283, lat: 41.405819 },
          { lng: 48.584353, lat: 41.80887 },
          { lng: 49.110264, lat: 41.282287 },
          { lng: 49.618915, lat: 40.572924 },
          { lng: 50.08483, lat: 40.526157 },
          { lng: 50.392821, lat: 40.256561 },
          { lng: 49.569202, lat: 40.176101 },
          { lng: 49.395259, lat: 39.399482 },
          { lng: 49.223228, lat: 39.049219 },
          { lng: 48.856532, lat: 38.815486 },
          { lng: 48.883249, lat: 38.320245 },
          { lng: 48.634375, lat: 38.270378 },
          { lng: 48.010744, lat: 38.794015 },
          { lng: 48.355529, lat: 39.288765 },
          { lng: 48.060095, lat: 39.582235 },
          { lng: 47.685079, lat: 39.508364 },
          { lng: 46.50572, lat: 38.770605 },
          { lng: 46.483499, lat: 39.464155 },
          { lng: 46.034534, lat: 39.628021 },
          { lng: 45.610012, lat: 39.899994 },
          { lng: 45.891907, lat: 40.218476 },
          { lng: 45.359175, lat: 40.561504 },
          { lng: 45.560351, lat: 40.81229 },
          { lng: 45.179496, lat: 40.985354 },
          { lng: 44.97248, lat: 41.248129 },
          { lng: 45.217426, lat: 41.411452 },
          { lng: 45.962601, lat: 41.123873 },
          { lng: 46.501637, lat: 41.064445 },
          { lng: 46.637908, lat: 41.181673 },
          { lng: 46.145432, lat: 41.722802 },
          { lng: 46.404951, lat: 41.860675 },
          { lng: 46.686071, lat: 41.827137 },
          { lng: 47.373315, lat: 41.219732 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BDI',
    properties: { name: 'Burundi' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 29.339998, lat: -4.499983 },
          { lng: 29.276384, lat: -3.293907 },
          { lng: 29.024926, lat: -2.839258 },
          { lng: 29.632176, lat: -2.917858 },
          { lng: 29.938359, lat: -2.348487 },
          { lng: 30.469696, lat: -2.413858 },
          { lng: 30.527677, lat: -2.807632 },
          { lng: 30.743013, lat: -3.034285 },
          { lng: 30.752263, lat: -3.35933 },
          { lng: 30.50556, lat: -3.568567 },
          { lng: 30.116333, lat: -4.090138 },
          { lng: 29.753512, lat: -4.452389 },
          { lng: 29.339998, lat: -4.499983 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BEL',
    properties: { name: 'Belgium' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 3.314971, lat: 51.345781 },
          { lng: 4.047071, lat: 51.267259 },
          { lng: 4.973991, lat: 51.475024 },
          { lng: 5.606976, lat: 51.037298 },
          { lng: 6.156658, lat: 50.803721 },
          { lng: 6.043073, lat: 50.128052 },
          { lng: 5.782417, lat: 50.090328 },
          { lng: 5.674052, lat: 49.529484 },
          { lng: 4.799222, lat: 49.985373 },
          { lng: 4.286023, lat: 49.907497 },
          { lng: 3.588184, lat: 50.378992 },
          { lng: 3.123252, lat: 50.780363 },
          { lng: 2.658422, lat: 50.796848 },
          { lng: 2.513573, lat: 51.148506 },
          { lng: 3.314971, lat: 51.345781 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BEN',
    properties: { name: 'Benin' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 2.691702, lat: 6.258817 },
          { lng: 1.865241, lat: 6.142158 },
          { lng: 1.618951, lat: 6.832038 },
          { lng: 1.664478, lat: 9.12859 },
          { lng: 1.463043, lat: 9.334624 },
          { lng: 1.425061, lat: 9.825395 },
          { lng: 1.077795, lat: 10.175607 },
          { lng: 0.772336, lat: 10.470808 },
          { lng: 0.899563, lat: 10.997339 },
          { lng: 1.24347, lat: 11.110511 },
          { lng: 1.447178, lat: 11.547719 },
          { lng: 1.935986, lat: 11.64115 },
          { lng: 2.154474, lat: 11.94015 },
          { lng: 2.490164, lat: 12.233052 },
          { lng: 2.848643, lat: 12.235636 },
          { lng: 3.61118, lat: 11.660167 },
          { lng: 3.572216, lat: 11.327939 },
          { lng: 3.797112, lat: 10.734746 },
          { lng: 3.60007, lat: 10.332186 },
          { lng: 3.705438, lat: 10.06321 },
          { lng: 3.220352, lat: 9.444153 },
          { lng: 2.912308, lat: 9.137608 },
          { lng: 2.723793, lat: 8.506845 },
          { lng: 2.749063, lat: 7.870734 },
          { lng: 2.691702, lat: 6.258817 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BFA',
    properties: { name: 'Burkina Faso' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -2.827496, lat: 9.642461 },
          { lng: -3.511899, lat: 9.900326 },
          { lng: -3.980449, lat: 9.862344 },
          { lng: -4.330247, lat: 9.610835 },
          { lng: -4.779884, lat: 9.821985 },
          { lng: -4.954653, lat: 10.152714 },
          { lng: -5.404342, lat: 10.370737 },
          { lng: -5.470565, lat: 10.95127 },
          { lng: -5.197843, lat: 11.375146 },
          { lng: -5.220942, lat: 11.713859 },
          { lng: -4.427166, lat: 12.542646 },
          { lng: -4.280405, lat: 13.228444 },
          { lng: -4.006391, lat: 13.472485 },
          { lng: -3.522803, lat: 13.337662 },
          { lng: -3.103707, lat: 13.541267 },
          { lng: -2.967694, lat: 13.79815 },
          { lng: -2.191825, lat: 14.246418 },
          { lng: -2.001035, lat: 14.559008 },
          { lng: -1.066363, lat: 14.973815 },
          { lng: -0.515854, lat: 15.116158 },
          { lng: -0.266257, lat: 14.924309 },
          { lng: 0.374892, lat: 14.928908 },
          { lng: 0.295646, lat: 14.444235 },
          { lng: 0.429928, lat: 13.988733 },
          { lng: 0.993046, lat: 13.33575 },
          { lng: 1.024103, lat: 12.851826 },
          { lng: 2.177108, lat: 12.625018 },
          { lng: 2.154474, lat: 11.94015 },
          { lng: 1.935986, lat: 11.64115 },
          { lng: 1.447178, lat: 11.547719 },
          { lng: 1.24347, lat: 11.110511 },
          { lng: 0.899563, lat: 10.997339 },
          { lng: 0.023803, lat: 11.018682 },
          { lng: -0.438702, lat: 11.098341 },
          { lng: -0.761576, lat: 10.93693 },
          { lng: -1.203358, lat: 11.009819 },
          { lng: -2.940409, lat: 10.96269 },
          { lng: -2.963896, lat: 10.395335 },
          { lng: -2.827496, lat: 9.642461 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BGD',
    properties: { name: 'Bangladesh' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 92.672721, lat: 22.041239 },
          { lng: 92.652257, lat: 21.324048 },
          { lng: 92.303234, lat: 21.475485 },
          { lng: 92.368554, lat: 20.670883 },
          { lng: 92.082886, lat: 21.192195 },
          { lng: 92.025215, lat: 21.70157 },
          { lng: 91.834891, lat: 22.182936 },
          { lng: 91.417087, lat: 22.765019 },
          { lng: 90.496006, lat: 22.805017 },
          { lng: 90.586957, lat: 22.392794 },
          { lng: 90.272971, lat: 21.836368 },
          { lng: 89.847467, lat: 22.039146 },
          { lng: 89.70205, lat: 21.857116 },
          { lng: 89.418863, lat: 21.966179 },
          { lng: 89.031961, lat: 22.055708 },
          { lng: 88.876312, lat: 22.879146 },
          { lng: 88.52977, lat: 23.631142 },
          { lng: 88.69994, lat: 24.233715 },
          { lng: 88.084422, lat: 24.501657 },
          { lng: 88.306373, lat: 24.866079 },
          { lng: 88.931554, lat: 25.238692 },
          { lng: 88.209789, lat: 25.768066 },
          { lng: 88.563049, lat: 26.446526 },
          { lng: 89.355094, lat: 26.014407 },
          { lng: 89.832481, lat: 25.965082 },
          { lng: 89.920693, lat: 25.26975 },
          { lng: 90.872211, lat: 25.132601 },
          { lng: 91.799596, lat: 25.147432 },
          { lng: 92.376202, lat: 24.976693 },
          { lng: 91.915093, lat: 24.130414 },
          { lng: 91.46773, lat: 24.072639 },
          { lng: 91.158963, lat: 23.503527 },
          { lng: 91.706475, lat: 22.985264 },
          { lng: 91.869928, lat: 23.624346 },
          { lng: 92.146035, lat: 23.627499 },
          { lng: 92.672721, lat: 22.041239 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BGR',
    properties: { name: 'Bulgaria' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 22.65715, lat: 44.234923 },
          { lng: 22.944832, lat: 43.823785 },
          { lng: 23.332302, lat: 43.897011 },
          { lng: 24.100679, lat: 43.741051 },
          { lng: 25.569272, lat: 43.688445 },
          { lng: 26.065159, lat: 43.943494 },
          { lng: 27.2424, lat: 44.175986 },
          { lng: 27.970107, lat: 43.812468 },
          { lng: 28.558081, lat: 43.707462 },
          { lng: 28.039095, lat: 43.293172 },
          { lng: 27.673898, lat: 42.577892 },
          { lng: 27.99672, lat: 42.007359 },
          { lng: 27.135739, lat: 42.141485 },
          { lng: 26.117042, lat: 41.826905 },
          { lng: 26.106138, lat: 41.328899 },
          { lng: 25.197201, lat: 41.234486 },
          { lng: 24.492645, lat: 41.583896 },
          { lng: 23.692074, lat: 41.309081 },
          { lng: 22.952377, lat: 41.337994 },
          { lng: 22.881374, lat: 41.999297 },
          { lng: 22.380526, lat: 42.32026 },
          { lng: 22.545012, lat: 42.461362 },
          { lng: 22.436595, lat: 42.580321 },
          { lng: 22.604801, lat: 42.898519 },
          { lng: 22.986019, lat: 43.211161 },
          { lng: 22.500157, lat: 43.642814 },
          { lng: 22.410446, lat: 44.008063 },
          { lng: 22.65715, lat: 44.234923 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BHS',
    properties: { name: 'The Bahamas' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: -77.53466, lat: 23.75975 },
          { lng: -77.78, lat: 23.71 },
          { lng: -78.03405, lat: 24.28615 },
          { lng: -78.40848, lat: 24.57564 },
          { lng: -78.19087, lat: 25.2103 },
          { lng: -77.89, lat: 25.17 },
          { lng: -77.54, lat: 24.34 },
          { lng: -77.53466, lat: 23.75975 },
        ],
        [
          { lng: -77.82, lat: 26.58 },
          { lng: -78.91, lat: 26.42 },
          { lng: -78.98, lat: 26.79 },
          { lng: -78.51, lat: 26.87 },
          { lng: -77.85, lat: 26.84 },
          { lng: -77.82, lat: 26.58 },
        ],
        [
          { lng: -77, lat: 26.59 },
          { lng: -77.17255, lat: 25.87918 },
          { lng: -77.35641, lat: 26.00735 },
          { lng: -77.34, lat: 26.53 },
          { lng: -77.78802, lat: 26.92516 },
          { lng: -77.79, lat: 27.04 },
          { lng: -77, lat: 26.59 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BIH',
    properties: { name: 'Bosnia and Herzegovina' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 19.005486, lat: 44.860234 },
          { lng: 19.36803, lat: 44.863 },
          { lng: 19.11761, lat: 44.42307 },
          { lng: 19.59976, lat: 44.03847 },
          { lng: 19.454, lat: 43.5681 },
          { lng: 19.21852, lat: 43.52384 },
          { lng: 19.03165, lat: 43.43253 },
          { lng: 18.70648, lat: 43.20011 },
          { lng: 18.56, lat: 42.65 },
          { lng: 17.674922, lat: 43.028563 },
          { lng: 17.297373, lat: 43.446341 },
          { lng: 16.916156, lat: 43.667722 },
          { lng: 16.456443, lat: 44.04124 },
          { lng: 16.23966, lat: 44.351143 },
          { lng: 15.750026, lat: 44.818712 },
          { lng: 15.959367, lat: 45.233777 },
          { lng: 16.318157, lat: 45.004127 },
          { lng: 16.534939, lat: 45.211608 },
          { lng: 17.002146, lat: 45.233777 },
          { lng: 17.861783, lat: 45.06774 },
          { lng: 18.553214, lat: 45.08159 },
          { lng: 19.005486, lat: 44.860234 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BLR',
    properties: { name: 'Belarus' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 23.484128, lat: 53.912498 },
          { lng: 24.450684, lat: 53.905702 },
          { lng: 25.536354, lat: 54.282423 },
          { lng: 25.768433, lat: 54.846963 },
          { lng: 26.588279, lat: 55.167176 },
          { lng: 26.494331, lat: 55.615107 },
          { lng: 27.10246, lat: 55.783314 },
          { lng: 28.176709, lat: 56.16913 },
          { lng: 29.229513, lat: 55.918344 },
          { lng: 29.371572, lat: 55.670091 },
          { lng: 29.896294, lat: 55.789463 },
          { lng: 30.873909, lat: 55.550976 },
          { lng: 30.971836, lat: 55.081548 },
          { lng: 30.757534, lat: 54.811771 },
          { lng: 31.384472, lat: 54.157056 },
          { lng: 31.791424, lat: 53.974639 },
          { lng: 31.731273, lat: 53.794029 },
          { lng: 32.405599, lat: 53.618045 },
          { lng: 32.693643, lat: 53.351421 },
          { lng: 32.304519, lat: 53.132726 },
          { lng: 31.497644, lat: 53.167427 },
          { lng: 31.305201, lat: 53.073996 },
          { lng: 31.540018, lat: 52.742052 },
          { lng: 31.785998, lat: 52.101678 },
          { lng: 30.927549, lat: 52.042353 },
          { lng: 30.619454, lat: 51.822806 },
          { lng: 30.555117, lat: 51.319503 },
          { lng: 30.157364, lat: 51.416138 },
          { lng: 29.254938, lat: 51.368234 },
          { lng: 28.992835, lat: 51.602044 },
          { lng: 28.617613, lat: 51.427714 },
          { lng: 28.241615, lat: 51.572227 },
          { lng: 27.454066, lat: 51.592303 },
          { lng: 26.337959, lat: 51.832289 },
          { lng: 25.327788, lat: 51.910656 },
          { lng: 24.553106, lat: 51.888461 },
          { lng: 24.005078, lat: 51.617444 },
          { lng: 23.527071, lat: 51.578454 },
          { lng: 23.508002, lat: 52.023647 },
          { lng: 23.199494, lat: 52.486977 },
          { lng: 23.799199, lat: 52.691099 },
          { lng: 23.804935, lat: 53.089731 },
          { lng: 23.527536, lat: 53.470122 },
          { lng: 23.484128, lat: 53.912498 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BLZ',
    properties: { name: 'Belize' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -89.14308, lat: 17.808319 },
          { lng: -89.150909, lat: 17.955468 },
          { lng: -89.029857, lat: 18.001511 },
          { lng: -88.848344, lat: 17.883198 },
          { lng: -88.490123, lat: 18.486831 },
          { lng: -88.300031, lat: 18.499982 },
          { lng: -88.296336, lat: 18.353273 },
          { lng: -88.106813, lat: 18.348674 },
          { lng: -88.123479, lat: 18.076675 },
          { lng: -88.285355, lat: 17.644143 },
          { lng: -88.197867, lat: 17.489475 },
          { lng: -88.302641, lat: 17.131694 },
          { lng: -88.239518, lat: 17.036066 },
          { lng: -88.355428, lat: 16.530774 },
          { lng: -88.551825, lat: 16.265467 },
          { lng: -88.732434, lat: 16.233635 },
          { lng: -88.930613, lat: 15.887273 },
          { lng: -89.229122, lat: 15.886938 },
          { lng: -89.150806, lat: 17.015577 },
          { lng: -89.14308, lat: 17.808319 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BMU',
    properties: { name: 'Bermuda' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -64.7799734332998, lat: 32.3072000581802 },
          { lng: -64.7873319183061, lat: 32.3039237143428 },
          { lng: -64.7946942710173, lat: 32.3032682700388 },
          { lng: -64.8094297981283, lat: 32.3098175728414 },
          { lng: -64.8167896352437, lat: 32.3058845718466 },
          { lng: -64.8101968029642, lat: 32.3022833180511 },
          { lng: -64.7962291465484, lat: 32.2934409732427 },
          { lng: -64.7815086336978, lat: 32.2868973114514 },
          { lng: -64.7997025513437, lat: 32.2796896417328 },
          { lng: -64.8066707691087, lat: 32.2747767569465 },
          { lng: -64.8225587873683, lat: 32.2669111289395 },
          { lng: -64.8287548840306, lat: 32.2669075473817 },
          { lng: -64.8306732143498, lat: 32.2583944840235 },
          { lng: -64.8399924854972, lat: 32.254782282336 },
          { lng: -64.8566090462354, lat: 32.2547740387514 },
          { lng: -64.8682296789446, lat: 32.2616393614322 },
          { lng: -64.8628241459563, lat: 32.2724481933959 },
          { lng: -64.8748651338951, lat: 32.2757120264753 },
          { lng: -64.8717752856644, lat: 32.2819371582026 },
          { lng: -64.8671422127295, lat: 32.2930760547989 },
          { lng: -64.8559068764437, lat: 32.2960321186471 },
          { lng: -64.8597429072279, lat: 32.3015842021933 },
          { lng: -64.8439233486717, lat: 32.3140553852543 },
          { lng: -64.8350242329311, lat: 32.3242161760006 },
          { lng: -64.8338690593672, lat: 32.3294587561557 },
          { lng: -64.8520298651164, lat: 32.3110911879954 },
          { lng: -64.8635922932573, lat: 32.3048469433363 },
          { lng: -64.8686668994079, lat: 32.30910745083 },
          { lng: -64.8721354593415, lat: 32.3041908606301 },
          { lng: -64.8779667328485, lat: 32.3038632800462 },
          { lng: -64.8780046844321, lat: 32.2907757831692 },
          { lng: -64.8849776658292, lat: 32.2819261366004 },
          { lng: -64.8783230004629, lat: 32.2613001418681 },
          { lng: -64.863194968877, lat: 32.2465799485801 },
          { lng: -64.8519819555722, lat: 32.2485519134663 },
          { lng: -64.842311980074, lat: 32.2492123317296 },
          { lng: -64.8388242605209, lat: 32.2475773472534 },
          { lng: -64.8334002575532, lat: 32.2462714714698 },
          { lng: -64.8256389530584, lat: 32.2472637398594 },
          { lng: -64.8205697556026, lat: 32.2531698880328 },
          { lng: -64.8105087275579, lat: 32.2561208974156 },
          { lng: -64.7900177727338, lat: 32.2659446936992 },
          { lng: -64.7745415970416, lat: 32.2718413023427 },
          { lng: -64.7644742436426, lat: 32.2855931353214 },
          { lng: -64.7551803442276, lat: 32.2908326702531 },
          { lng: -64.7423982971436, lat: 32.2996734994024 },
          { lng: -64.7206991797682, lat: 32.3137542201258 },
          { lng: -64.7117851247134, lat: 32.3176823360806 },
          { lng: -64.6962778813133, lat: 32.3275029115532 },
          { lng: -64.6768921127452, lat: 32.3324095397555 },
          { lng: -64.6567136927777, lat: 32.3451776458469 },
          { lng: -64.6532168823499, lat: 32.3494356627941 },
          { lng: -64.6605720384429, lat: 32.3589423487763 },
          { lng: -64.65125819471, lat: 32.3615600906466 },
          { lng: -64.6462011670816, lat: 32.36975169749 },
          { lng: -64.6613227512832, lat: 32.3763135008721 },
          { lng: -64.6690666074397, lat: 32.388444543924 },
          { lng: -64.6834270548595, lat: 32.3854968316788 },
          { lng: -64.6954617672714, lat: 32.3763221285869 },
          { lng: -64.70438689565, lat: 32.3704254760469 },
          { lng: -64.7117569982798, lat: 32.368132600249 },
          { lng: -64.7061764744404, lat: 32.3600110593559 },
          { lng: -64.700531552697, lat: 32.3590601356818 },
          { lng: -64.6940348033967, lat: 32.3640708659835 },
          { lng: -64.6895164826082, lat: 32.3633598579866 },
          { lng: -64.6864150099255, lat: 32.3547797587266 },
          { lng: -64.6824635995504, lat: 32.3540628176846 },
          { lng: -64.6835876652835, lat: 32.3626447677968 },
          { lng: -64.6801998697415, lat: 32.3631199096979 },
          { lng: -64.6672170444687, lat: 32.3597751617473 },
          { lng: -64.6598811264978, lat: 32.3497625771755 },
          { lng: -64.6737331235384, lat: 32.3390281851635 },
          { lng: -64.6887090648183, lat: 32.3342439408053 },
          { lng: -64.706732854446, lat: 32.3429010723036 },
          { lng: -64.7149301576112, lat: 32.3552188753513 },
          { lng: -64.7185967666669, lat: 32.3552239212394 },
          { lng: -64.7214189847314, lat: 32.3518830231342 },
          { lng: -64.7270616067222, lat: 32.3466461715475 },
          { lng: -64.734962460882, lat: 32.3442819830499 },
          { lng: -64.7383521549094, lat: 32.3407216514918 },
          { lng: -64.7411729976333, lat: 32.3311790864627 },
          { lng: -64.7423019216485, lat: 32.323311561213 },
          { lng: -64.7462482354281, lat: 32.318538611581 },
          { lng: -64.7566773739613, lat: 32.3130509130175 },
          { lng: -64.768738200563, lat: 32.3088369816572 },
          { lng: -64.7799734332998, lat: 32.3072000581802 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BOL',
    properties: { name: 'Bolivia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -62.846468, lat: -22.034985 },
          { lng: -63.986838, lat: -21.993644 },
          { lng: -64.377021, lat: -22.798091 },
          { lng: -64.964892, lat: -22.075862 },
          { lng: -66.273339, lat: -21.83231 },
          { lng: -67.106674, lat: -22.735925 },
          { lng: -67.82818, lat: -22.872919 },
          { lng: -68.219913, lat: -21.494347 },
          { lng: -68.757167, lat: -20.372658 },
          { lng: -68.442225, lat: -19.405068 },
          { lng: -68.966818, lat: -18.981683 },
          { lng: -69.100247, lat: -18.260125 },
          { lng: -69.590424, lat: -17.580012 },
          { lng: -68.959635, lat: -16.500698 },
          { lng: -69.389764, lat: -15.660129 },
          { lng: -69.160347, lat: -15.323974 },
          { lng: -69.339535, lat: -14.953195 },
          { lng: -68.948887, lat: -14.453639 },
          { lng: -68.929224, lat: -13.602684 },
          { lng: -68.88008, lat: -12.899729 },
          { lng: -68.66508, lat: -12.5613 },
          { lng: -69.529678, lat: -10.951734 },
          { lng: -68.786158, lat: -11.03638 },
          { lng: -68.271254, lat: -11.014521 },
          { lng: -68.048192, lat: -10.712059 },
          { lng: -67.173801, lat: -10.306812 },
          { lng: -66.646908, lat: -9.931331 },
          { lng: -65.338435, lat: -9.761988 },
          { lng: -65.444837, lat: -10.511451 },
          { lng: -65.321899, lat: -10.895872 },
          { lng: -65.402281, lat: -11.56627 },
          { lng: -64.316353, lat: -12.461978 },
          { lng: -63.196499, lat: -12.627033 },
          { lng: -62.80306, lat: -13.000653 },
          { lng: -62.127081, lat: -13.198781 },
          { lng: -61.713204, lat: -13.489202 },
          { lng: -61.084121, lat: -13.479384 },
          { lng: -60.503304, lat: -13.775955 },
          { lng: -60.459198, lat: -14.354007 },
          { lng: -60.264326, lat: -14.645979 },
          { lng: -60.251149, lat: -15.077219 },
          { lng: -60.542966, lat: -15.09391 },
          { lng: -60.15839, lat: -16.258284 },
          { lng: -58.24122, lat: -16.299573 },
          { lng: -58.388058, lat: -16.877109 },
          { lng: -58.280804, lat: -17.27171 },
          { lng: -57.734558, lat: -17.552468 },
          { lng: -57.498371, lat: -18.174188 },
          { lng: -57.676009, lat: -18.96184 },
          { lng: -57.949997, lat: -19.400004 },
          { lng: -57.853802, lat: -19.969995 },
          { lng: -58.166392, lat: -20.176701 },
          { lng: -58.183471, lat: -19.868399 },
          { lng: -59.115042, lat: -19.356906 },
          { lng: -60.043565, lat: -19.342747 },
          { lng: -61.786326, lat: -19.633737 },
          { lng: -62.265961, lat: -20.513735 },
          { lng: -62.291179, lat: -21.051635 },
          { lng: -62.685057, lat: -22.249029 },
          { lng: -62.846468, lat: -22.034985 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BRA',
    properties: { name: 'Brazil' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -57.625133, lat: -30.216295 },
          { lng: -56.2909, lat: -28.852761 },
          { lng: -55.162286, lat: -27.881915 },
          { lng: -54.490725, lat: -27.474757 },
          { lng: -53.648735, lat: -26.923473 },
          { lng: -53.628349, lat: -26.124865 },
          { lng: -54.13005, lat: -25.547639 },
          { lng: -54.625291, lat: -25.739255 },
          { lng: -54.428946, lat: -25.162185 },
          { lng: -54.293476, lat: -24.5708 },
          { lng: -54.29296, lat: -24.021014 },
          { lng: -54.652834, lat: -23.839578 },
          { lng: -55.027902, lat: -24.001274 },
          { lng: -55.400747, lat: -23.956935 },
          { lng: -55.517639, lat: -23.571998 },
          { lng: -55.610683, lat: -22.655619 },
          { lng: -55.797958, lat: -22.35693 },
          { lng: -56.473317, lat: -22.0863 },
          { lng: -56.88151, lat: -22.282154 },
          { lng: -57.937156, lat: -22.090176 },
          { lng: -57.870674, lat: -20.732688 },
          { lng: -58.166392, lat: -20.176701 },
          { lng: -57.853802, lat: -19.969995 },
          { lng: -57.949997, lat: -19.400004 },
          { lng: -57.676009, lat: -18.96184 },
          { lng: -57.498371, lat: -18.174188 },
          { lng: -57.734558, lat: -17.552468 },
          { lng: -58.280804, lat: -17.27171 },
          { lng: -58.388058, lat: -16.877109 },
          { lng: -58.24122, lat: -16.299573 },
          { lng: -60.15839, lat: -16.258284 },
          { lng: -60.542966, lat: -15.09391 },
          { lng: -60.251149, lat: -15.077219 },
          { lng: -60.264326, lat: -14.645979 },
          { lng: -60.459198, lat: -14.354007 },
          { lng: -60.503304, lat: -13.775955 },
          { lng: -61.084121, lat: -13.479384 },
          { lng: -61.713204, lat: -13.489202 },
          { lng: -62.127081, lat: -13.198781 },
          { lng: -62.80306, lat: -13.000653 },
          { lng: -63.196499, lat: -12.627033 },
          { lng: -64.316353, lat: -12.461978 },
          { lng: -65.402281, lat: -11.56627 },
          { lng: -65.321899, lat: -10.895872 },
          { lng: -65.444837, lat: -10.511451 },
          { lng: -65.338435, lat: -9.761988 },
          { lng: -66.646908, lat: -9.931331 },
          { lng: -67.173801, lat: -10.306812 },
          { lng: -68.048192, lat: -10.712059 },
          { lng: -68.271254, lat: -11.014521 },
          { lng: -68.786158, lat: -11.03638 },
          { lng: -69.529678, lat: -10.951734 },
          { lng: -70.093752, lat: -11.123972 },
          { lng: -70.548686, lat: -11.009147 },
          { lng: -70.481894, lat: -9.490118 },
          { lng: -71.302412, lat: -10.079436 },
          { lng: -72.184891, lat: -10.053598 },
          { lng: -72.563033, lat: -9.520194 },
          { lng: -73.226713, lat: -9.462213 },
          { lng: -73.015383, lat: -9.032833 },
          { lng: -73.571059, lat: -8.424447 },
          { lng: -73.987235, lat: -7.52383 },
          { lng: -73.723401, lat: -7.340999 },
          { lng: -73.724487, lat: -6.918595 },
          { lng: -73.120027, lat: -6.629931 },
          { lng: -73.219711, lat: -6.089189 },
          { lng: -72.964507, lat: -5.741251 },
          { lng: -72.891928, lat: -5.274561 },
          { lng: -71.748406, lat: -4.593983 },
          { lng: -70.928843, lat: -4.401591 },
          { lng: -70.794769, lat: -4.251265 },
          { lng: -69.893635, lat: -4.298187 },
          { lng: -69.444102, lat: -1.556287 },
          { lng: -69.420486, lat: -1.122619 },
          { lng: -69.577065, lat: -0.549992 },
          { lng: -70.020656, lat: -0.185156 },
          { lng: -70.015566, lat: 0.541414 },
          { lng: -69.452396, lat: 0.706159 },
          { lng: -69.252434, lat: 0.602651 },
          { lng: -69.218638, lat: 0.985677 },
          { lng: -69.804597, lat: 1.089081 },
          { lng: -69.816973, lat: 1.714805 },
          { lng: -67.868565, lat: 1.692455 },
          { lng: -67.53781, lat: 2.037163 },
          { lng: -67.259998, lat: 1.719999 },
          { lng: -67.065048, lat: 1.130112 },
          { lng: -66.876326, lat: 1.253361 },
          { lng: -66.325765, lat: 0.724452 },
          { lng: -65.548267, lat: 0.789254 },
          { lng: -65.354713, lat: 1.095282 },
          { lng: -64.611012, lat: 1.328731 },
          { lng: -64.199306, lat: 1.492855 },
          { lng: -64.083085, lat: 1.916369 },
          { lng: -63.368788, lat: 2.2009 },
          { lng: -63.422867, lat: 2.411068 },
          { lng: -64.269999, lat: 2.497006 },
          { lng: -64.408828, lat: 3.126786 },
          { lng: -64.368494, lat: 3.79721 },
          { lng: -64.816064, lat: 4.056445 },
          { lng: -64.628659, lat: 4.148481 },
          { lng: -63.888343, lat: 4.02053 },
          { lng: -63.093198, lat: 3.770571 },
          { lng: -62.804533, lat: 4.006965 },
          { lng: -62.08543, lat: 4.162124 },
          { lng: -60.966893, lat: 4.536468 },
          { lng: -60.601179, lat: 4.918098 },
          { lng: -60.733574, lat: 5.200277 },
          { lng: -60.213683, lat: 5.244486 },
          { lng: -59.980959, lat: 5.014061 },
          { lng: -60.111002, lat: 4.574967 },
          { lng: -59.767406, lat: 4.423503 },
          { lng: -59.53804, lat: 3.958803 },
          { lng: -59.815413, lat: 3.606499 },
          { lng: -59.974525, lat: 2.755233 },
          { lng: -59.718546, lat: 2.24963 },
          { lng: -59.646044, lat: 1.786894 },
          { lng: -59.030862, lat: 1.317698 },
          { lng: -58.540013, lat: 1.268088 },
          { lng: -58.429477, lat: 1.463942 },
          { lng: -58.11345, lat: 1.507195 },
          { lng: -57.660971, lat: 1.682585 },
          { lng: -57.335823, lat: 1.948538 },
          { lng: -56.782704, lat: 1.863711 },
          { lng: -56.539386, lat: 1.899523 },
          { lng: -55.995698, lat: 1.817667 },
          { lng: -55.9056, lat: 2.021996 },
          { lng: -56.073342, lat: 2.220795 },
          { lng: -55.973322, lat: 2.510364 },
          { lng: -55.569755, lat: 2.421506 },
          { lng: -55.097587, lat: 2.523748 },
          { lng: -54.524754, lat: 2.311849 },
          { lng: -54.088063, lat: 2.105557 },
          { lng: -53.778521, lat: 2.376703 },
          { lng: -53.554839, lat: 2.334897 },
          { lng: -53.418465, lat: 2.053389 },
          { lng: -52.939657, lat: 2.124858 },
          { lng: -52.556425, lat: 2.504705 },
          { lng: -52.249338, lat: 3.241094 },
          { lng: -51.657797, lat: 4.156232 },
          { lng: -51.317146, lat: 4.203491 },
          { lng: -51.069771, lat: 3.650398 },
          { lng: -50.508875, lat: 1.901564 },
          { lng: -49.974076, lat: 1.736483 },
          { lng: -49.947101, lat: 1.04619 },
          { lng: -50.699251, lat: 0.222984 },
          { lng: -50.388211, lat: -0.078445 },
          { lng: -48.620567, lat: -0.235489 },
          { lng: -48.584497, lat: -1.237805 },
          { lng: -47.824956, lat: -0.581618 },
          { lng: -46.566584, lat: -0.941028 },
          { lng: -44.905703, lat: -1.55174 },
          { lng: -44.417619, lat: -2.13775 },
          { lng: -44.581589, lat: -2.691308 },
          { lng: -43.418791, lat: -2.38311 },
          { lng: -41.472657, lat: -2.912018 },
          { lng: -39.978665, lat: -2.873054 },
          { lng: -38.500383, lat: -3.700652 },
          { lng: -37.223252, lat: -4.820946 },
          { lng: -36.452937, lat: -5.109404 },
          { lng: -35.597796, lat: -5.149504 },
          { lng: -35.235389, lat: -5.464937 },
          { lng: -34.89603, lat: -6.738193 },
          { lng: -34.729993, lat: -7.343221 },
          { lng: -35.128212, lat: -8.996401 },
          { lng: -35.636967, lat: -9.649282 },
          { lng: -37.046519, lat: -11.040721 },
          { lng: -37.683612, lat: -12.171195 },
          { lng: -38.423877, lat: -13.038119 },
          { lng: -38.673887, lat: -13.057652 },
          { lng: -38.953276, lat: -13.79337 },
          { lng: -38.882298, lat: -15.667054 },
          { lng: -39.161092, lat: -17.208407 },
          { lng: -39.267339, lat: -17.867746 },
          { lng: -39.583521, lat: -18.262296 },
          { lng: -39.760823, lat: -19.599113 },
          { lng: -40.774741, lat: -20.904512 },
          { lng: -40.944756, lat: -21.937317 },
          { lng: -41.754164, lat: -22.370676 },
          { lng: -41.988284, lat: -22.97007 },
          { lng: -43.074704, lat: -22.967693 },
          { lng: -44.647812, lat: -23.351959 },
          { lng: -45.352136, lat: -23.796842 },
          { lng: -46.472093, lat: -24.088969 },
          { lng: -47.648972, lat: -24.885199 },
          { lng: -48.495458, lat: -25.877025 },
          { lng: -48.641005, lat: -26.623698 },
          { lng: -48.474736, lat: -27.175912 },
          { lng: -48.66152, lat: -28.186135 },
          { lng: -48.888457, lat: -28.674115 },
          { lng: -49.587329, lat: -29.224469 },
          { lng: -50.696874, lat: -30.984465 },
          { lng: -51.576226, lat: -31.777698 },
          { lng: -52.256081, lat: -32.24537 },
          { lng: -52.7121, lat: -33.196578 },
          { lng: -53.373662, lat: -33.768378 },
          { lng: -53.650544, lat: -33.202004 },
          { lng: -53.209589, lat: -32.727666 },
          { lng: -53.787952, lat: -32.047243 },
          { lng: -54.572452, lat: -31.494511 },
          { lng: -55.60151, lat: -30.853879 },
          { lng: -55.973245, lat: -30.883076 },
          { lng: -56.976026, lat: -30.109686 },
          { lng: -57.625133, lat: -30.216295 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BRN',
    properties: { name: 'Brunei' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 114.204017, lat: 4.525874 },
          { lng: 114.599961, lat: 4.900011 },
          { lng: 115.45071, lat: 5.44773 },
          { lng: 115.4057, lat: 4.955228 },
          { lng: 115.347461, lat: 4.316636 },
          { lng: 114.869557, lat: 4.348314 },
          { lng: 114.659596, lat: 4.007637 },
          { lng: 114.204017, lat: 4.525874 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BTN',
    properties: { name: 'Bhutan' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 91.696657, lat: 27.771742 },
          { lng: 92.103712, lat: 27.452614 },
          { lng: 92.033484, lat: 26.83831 },
          { lng: 91.217513, lat: 26.808648 },
          { lng: 90.373275, lat: 26.875724 },
          { lng: 89.744528, lat: 26.719403 },
          { lng: 88.835643, lat: 27.098966 },
          { lng: 88.814248, lat: 27.299316 },
          { lng: 89.47581, lat: 28.042759 },
          { lng: 90.015829, lat: 28.296439 },
          { lng: 90.730514, lat: 28.064954 },
          { lng: 91.258854, lat: 28.040614 },
          { lng: 91.696657, lat: 27.771742 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'BWA',
    properties: { name: 'Botswana' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 25.649163, lat: -18.536026 },
          { lng: 25.850391, lat: -18.714413 },
          { lng: 26.164791, lat: -19.293086 },
          { lng: 27.296505, lat: -20.39152 },
          { lng: 27.724747, lat: -20.499059 },
          { lng: 27.727228, lat: -20.851802 },
          { lng: 28.02137, lat: -21.485975 },
          { lng: 28.794656, lat: -21.639454 },
          { lng: 29.432188, lat: -22.091313 },
          { lng: 28.017236, lat: -22.827754 },
          { lng: 27.11941, lat: -23.574323 },
          { lng: 26.786407, lat: -24.240691 },
          { lng: 26.485753, lat: -24.616327 },
          { lng: 25.941652, lat: -24.696373 },
          { lng: 25.765849, lat: -25.174845 },
          { lng: 25.664666, lat: -25.486816 },
          { lng: 25.025171, lat: -25.71967 },
          { lng: 24.211267, lat: -25.670216 },
          { lng: 23.73357, lat: -25.390129 },
          { lng: 23.312097, lat: -25.26869 },
          { lng: 22.824271, lat: -25.500459 },
          { lng: 22.579532, lat: -25.979448 },
          { lng: 22.105969, lat: -26.280256 },
          { lng: 21.605896, lat: -26.726534 },
          { lng: 20.889609, lat: -26.828543 },
          { lng: 20.66647, lat: -26.477453 },
          { lng: 20.758609, lat: -25.868136 },
          { lng: 20.165726, lat: -24.917962 },
          { lng: 19.895768, lat: -24.76779 },
          { lng: 19.895458, lat: -21.849157 },
          { lng: 20.881134, lat: -21.814327 },
          { lng: 20.910641, lat: -18.252219 },
          { lng: 21.65504, lat: -18.219146 },
          { lng: 23.196858, lat: -17.869038 },
          { lng: 23.579006, lat: -18.281261 },
          { lng: 24.217365, lat: -17.889347 },
          { lng: 24.520705, lat: -17.887125 },
          { lng: 25.084443, lat: -17.661816 },
          { lng: 25.264226, lat: -17.73654 },
          { lng: 25.649163, lat: -18.536026 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'CAF',
    properties: { name: 'Central African Republic' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 15.27946, lat: 7.421925 },
          { lng: 16.106232, lat: 7.497088 },
          { lng: 16.290562, lat: 7.754307 },
          { lng: 16.456185, lat: 7.734774 },
          { lng: 16.705988, lat: 7.508328 },
          { lng: 17.96493, lat: 7.890914 },
          { lng: 18.389555, lat: 8.281304 },
          { lng: 18.911022, lat: 8.630895 },
          { lng: 18.81201, lat: 8.982915 },
          { lng: 19.094008, lat: 9.074847 },
          { lng: 20.059685, lat: 9.012706 },
          { lng: 21.000868, lat: 9.475985 },
          { lng: 21.723822, lat: 10.567056 },
          { lng: 22.231129, lat: 10.971889 },
          { lng: 22.864165, lat: 11.142395 },
          { lng: 22.977544, lat: 10.714463 },
          { lng: 23.554304, lat: 10.089255 },
          { lng: 23.55725, lat: 9.681218 },
          { lng: 23.394779, lat: 9.265068 },
          { lng: 23.459013, lat: 8.954286 },
          { lng: 23.805813, lat: 8.666319 },
          { lng: 24.567369, lat: 8.229188 },
          { lng: 25.114932, lat: 7.825104 },
          { lng: 25.124131, lat: 7.500085 },
          { lng: 25.796648, lat: 6.979316 },
          { lng: 26.213418, lat: 6.546603 },
          { lng: 26.465909, lat: 5.946717 },
          { lng: 27.213409, lat: 5.550953 },
          { lng: 27.374226, lat: 5.233944 },
          { lng: 27.044065, lat: 5.127853 },
          { lng: 26.402761, lat: 5.150875 },
          { lng: 25.650455, lat: 5.256088 },
          { lng: 25.278798, lat: 5.170408 },
          { lng: 25.128833, lat: 4.927245 },
          { lng: 24.805029, lat: 4.897247 },
          { lng: 24.410531, lat: 5.108784 },
          { lng: 23.297214, lat: 4.609693 },
          { lng: 22.84148, lat: 4.710126 },
          { lng: 22.704124, lat: 4.633051 },
          { lng: 22.405124, lat: 4.02916 },
          { lng: 21.659123, lat: 4.224342 },
          { lng: 20.927591, lat: 4.322786 },
          { lng: 20.290679, lat: 4.691678 },
          { lng: 19.467784, lat: 5.031528 },
          { lng: 18.932312, lat: 4.709506 },
          { lng: 18.542982, lat: 4.201785 },
          { lng: 18.453065, lat: 3.504386 },
          { lng: 17.8099, lat: 3.560196 },
          { lng: 17.133042, lat: 3.728197 },
          { lng: 16.537058, lat: 3.198255 },
          { lng: 16.012852, lat: 2.26764 },
          { lng: 15.907381, lat: 2.557389 },
          { lng: 15.862732, lat: 3.013537 },
          { lng: 15.405396, lat: 3.335301 },
          { lng: 15.03622, lat: 3.851367 },
          { lng: 14.950953, lat: 4.210389 },
          { lng: 14.478372, lat: 4.732605 },
          { lng: 14.558936, lat: 5.030598 },
          { lng: 14.459407, lat: 5.451761 },
          { lng: 14.53656, lat: 6.226959 },
          { lng: 14.776545, lat: 6.408498 },
          { lng: 15.27946, lat: 7.421925 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'CAN',
    properties: { name: 'Canada' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: -63.6645, lat: 46.55001 },
          { lng: -62.9393, lat: 46.41587 },
          { lng: -62.01208, lat: 46.44314 },
          { lng: -62.50391, lat: 46.03339 },
          { lng: -62.87433, lat: 45.96818 },
          { lng: -64.1428, lat: 46.39265 },
          { lng: -64.39261, lat: 46.72747 },
          { lng: -64.01486, lat: 47.03601 },
          { lng: -63.6645, lat: 46.55001 },
        ],
        [
          { lng: -61.806305, lat: 49.10506 },
          { lng: -62.29318, lat: 49.08717 },
          { lng: -63.58926, lat: 49.40069 },
          { lng: -64.51912, lat: 49.87304 },
          { lng: -64.17322, lat: 49.95718 },
          { lng: -62.85829, lat: 49.70641 },
          { lng: -61.835585, lat: 49.28855 },
          { lng: -61.806305, lat: 49.10506 },
        ],
        [
          { lng: -123.510002, lat: 48.510011 },
          { lng: -124.012891, lat: 48.370846 },
          { lng: -125.655013, lat: 48.825005 },
          { lng: -125.954994, lat: 49.179996 },
          { lng: -126.850004, lat: 49.53 },
          { lng: -127.029993, lat: 49.814996 },
          { lng: -128.059336, lat: 49.994959 },
          { lng: -128.444584, lat: 50.539138 },
          { lng: -128.358414, lat: 50.770648 },
          { lng: -127.308581, lat: 50.552574 },
          { lng: -126.695001, lat: 50.400903 },
          { lng: -125.755007, lat: 50.295018 },
          { lng: -125.415002, lat: 49.950001 },
          { lng: -124.920768, lat: 49.475275 },
          { lng: -123.922509, lat: 49.062484 },
          { lng: -123.510002, lat: 48.510011 },
        ],
        [
          { lng: -56.134036, lat: 50.68701 },
          { lng: -56.795882, lat: 49.812309 },
          { lng: -56.143105, lat: 50.150117 },
          { lng: -55.471492, lat: 49.935815 },
          { lng: -55.822401, lat: 49.587129 },
          { lng: -54.935143, lat: 49.313011 },
          { lng: -54.473775, lat: 49.556691 },
          { lng: -53.476549, lat: 49.249139 },
          { lng: -53.786014, lat: 48.516781 },
          { lng: -53.086134, lat: 48.687804 },
          { lng: -52.958648, lat: 48.157164 },
          { lng: -52.648099, lat: 47.535548 },
          { lng: -53.069158, lat: 46.655499 },
          { lng: -53.521456, lat: 46.618292 },
          { lng: -54.178936, lat: 46.807066 },
          { lng: -53.961869, lat: 47.625207 },
          { lng: -54.240482, lat: 47.752279 },
          { lng: -55.400773, lat: 46.884994 },
          { lng: -55.997481, lat: 46.91972 },
          { lng: -55.291219, lat: 47.389562 },
          { lng: -56.250799, lat: 47.632545 },
          { lng: -57.325229, lat: 47.572807 },
          { lng: -59.266015, lat: 47.603348 },
          { lng: -59.419494, lat: 47.899454 },
          { lng: -58.796586, lat: 48.251525 },
          { lng: -59.231625, lat: 48.523188 },
          { lng: -58.391805, lat: 49.125581 },
          { lng: -57.35869, lat: 50.718274 },
          { lng: -56.73865, lat: 51.287438 },
          { lng: -55.870977, lat: 51.632094 },
          { lng: -55.406974, lat: 51.588273 },
          { lng: -55.600218, lat: 51.317075 },
          { lng: -56.134036, lat: 50.68701 },
        ],
        [
          { lng: -132.710008, lat: 54.040009 },
          { lng: -131.74999, lat: 54.120004 },
          { lng: -132.04948, lat: 52.984621 },
          { lng: -131.179043, lat: 52.180433 },
          { lng: -131.57783, lat: 52.182371 },
          { lng: -132.180428, lat: 52.639707 },
          { lng: -132.549992, lat: 53.100015 },
          { lng: -133.054611, lat: 53.411469 },
          { lng: -133.239664, lat: 53.85108 },
          { lng: -133.180004, lat: 54.169975 },
          { lng: -132.710008, lat: 54.040009 },
        ],
        [
          { lng: -79.26582, lat: 62.158675 },
          { lng: -79.65752, lat: 61.63308 },
          { lng: -80.09956, lat: 61.7181 },
          { lng: -80.36215, lat: 62.01649 },
          { lng: -80.315395, lat: 62.085565 },
          { lng: -79.92939, lat: 62.3856 },
          { lng: -79.52002, lat: 62.36371 },
          { lng: -79.26582, lat: 62.158675 },
        ],
        [
          { lng: -81.89825, lat: 62.7108 },
          { lng: -83.06857, lat: 62.15922 },
          { lng: -83.77462, lat: 62.18231 },
          { lng: -83.99367, lat: 62.4528 },
          { lng: -83.25048, lat: 62.91409 },
          { lng: -81.87699, lat: 62.90458 },
          { lng: -81.89825, lat: 62.7108 },
        ],
        [
          { lng: -85.161308, lat: 65.657285 },
          { lng: -84.975764, lat: 65.217518 },
          { lng: -84.464012, lat: 65.371772 },
          { lng: -83.882626, lat: 65.109618 },
          { lng: -82.787577, lat: 64.766693 },
          { lng: -81.642014, lat: 64.455136 },
          { lng: -81.55344, lat: 63.979609 },
          { lng: -80.817361, lat: 64.057486 },
          { lng: -80.103451, lat: 63.725981 },
          { lng: -80.99102, lat: 63.411246 },
          { lng: -82.547178, lat: 63.651722 },
          { lng: -83.108798, lat: 64.101876 },
          { lng: -84.100417, lat: 63.569712 },
          { lng: -85.523405, lat: 63.052379 },
          { lng: -85.866769, lat: 63.637253 },
          { lng: -87.221983, lat: 63.541238 },
          { lng: -86.35276, lat: 64.035833 },
          { lng: -86.224886, lat: 64.822917 },
          { lng: -85.883848, lat: 65.738778 },
          { lng: -85.161308, lat: 65.657285 },
        ],
        [
          { lng: -75.86588, lat: 67.14886 },
          { lng: -76.98687, lat: 67.09873 },
          { lng: -77.2364, lat: 67.58809 },
          { lng: -76.81166, lat: 68.14856 },
          { lng: -75.89521, lat: 68.28721 },
          { lng: -75.1145, lat: 68.01036 },
          { lng: -75.10333, lat: 67.58202 },
          { lng: -75.21597, lat: 67.44425 },
          { lng: -75.86588, lat: 67.14886 },
        ],
        [
          { lng: -95.647681, lat: 69.10769 },
          { lng: -96.269521, lat: 68.75704 },
          { lng: -97.617401, lat: 69.06003 },
          { lng: -98.431801, lat: 68.9507 },
          { lng: -99.797401, lat: 69.40003 },
          { lng: -98.917401, lat: 69.71003 },
          { lng: -98.218261, lat: 70.14354 },
          { lng: -97.157401, lat: 69.86003 },
          { lng: -96.557401, lat: 69.68003 },
          { lng: -96.257401, lat: 69.49003 },
          { lng: -95.647681, lat: 69.10769 },
        ],
        [
          { lng: -90.5471, lat: 69.49766 },
          { lng: -90.55151, lat: 68.47499 },
          { lng: -89.21515, lat: 69.25873 },
          { lng: -88.01966, lat: 68.61508 },
          { lng: -88.31749, lat: 67.87338 },
          { lng: -87.35017, lat: 67.19872 },
          { lng: -86.30607, lat: 67.92146 },
          { lng: -85.57664, lat: 68.78456 },
          { lng: -85.52197, lat: 69.88211 },
          { lng: -84.10081, lat: 69.80539 },
          { lng: -82.62258, lat: 69.65826 },
          { lng: -81.28043, lat: 69.16202 },
          { lng: -81.2202, lat: 68.66567 },
          { lng: -81.96436, lat: 68.13253 },
          { lng: -81.25928, lat: 67.59716 },
          { lng: -81.38653, lat: 67.11078 },
          { lng: -83.34456, lat: 66.41154 },
          { lng: -84.73542, lat: 66.2573 },
          { lng: -85.76943, lat: 66.55833 },
          { lng: -86.0676, lat: 66.05625 },
          { lng: -87.03143, lat: 65.21297 },
          { lng: -87.32324, lat: 64.77563 },
          { lng: -88.48296, lat: 64.09897 },
          { lng: -89.91444, lat: 64.03273 },
          { lng: -90.70398, lat: 63.61017 },
          { lng: -90.77004, lat: 62.96021 },
          { lng: -91.93342, lat: 62.83508 },
          { lng: -93.15698, lat: 62.02469 },
          { lng: -94.24153, lat: 60.89865 },
          { lng: -94.62931, lat: 60.11021 },
          { lng: -94.6846, lat: 58.94882 },
          { lng: -93.21502, lat: 58.78212 },
          { lng: -92.76462, lat: 57.84571 },
          { lng: -92.29703, lat: 57.08709 },
          { lng: -90.89769, lat: 57.28468 },
          { lng: -89.03953, lat: 56.85172 },
          { lng: -88.03978, lat: 56.47162 },
          { lng: -87.32421, lat: 55.99914 },
          { lng: -86.07121, lat: 55.72383 },
          { lng: -85.01181, lat: 55.3026 },
          { lng: -83.36055, lat: 55.24489 },
          { lng: -82.27285, lat: 55.14832 },
          { lng: -82.4362, lat: 54.28227 },
          { lng: -82.12502, lat: 53.27703 },
          { lng: -81.40075, lat: 52.15788 },
          { lng: -79.91289, lat: 51.20842 },
          { lng: -79.14301, lat: 51.53393 },
          { lng: -78.60191, lat: 52.56208 },
          { lng: -79.12421, lat: 54.14145 },
          { lng: -79.82958, lat: 54.66772 },
          { lng: -78.22874, lat: 55.13645 },
          { lng: -77.0956, lat: 55.83741 },
          { lng: -76.54137, lat: 56.53423 },
          { lng: -76.62319, lat: 57.20263 },
          { lng: -77.30226, lat: 58.05209 },
          { lng: -78.51688, lat: 58.80458 },
          { lng: -77.33676, lat: 59.85261 },
          { lng: -77.77272, lat: 60.75788 },
          { lng: -78.10687, lat: 62.31964 },
          { lng: -77.41067, lat: 62.55053 },
          { lng: -75.69621, lat: 62.2784 },
          { lng: -74.6682, lat: 62.18111 },
          { lng: -73.83988, lat: 62.4438 },
          { lng: -72.90853, lat: 62.10507 },
          { lng: -71.67708, lat: 61.52535 },
          { lng: -71.37369, lat: 61.13717 },
          { lng: -69.59042, lat: 61.06141 },
          { lng: -69.62033, lat: 60.22125 },
          { lng: -69.2879, lat: 58.95736 },
          { lng: -68.37455, lat: 58.80106 },
          { lng: -67.64976, lat: 58.21206 },
          { lng: -66.20178, lat: 58.76731 },
          { lng: -65.24517, lat: 59.87071 },
          { lng: -64.58352, lat: 60.33558 },
          { lng: -63.80475, lat: 59.4426 },
          { lng: -62.50236, lat: 58.16708 },
          { lng: -61.39655, lat: 56.96745 },
          { lng: -61.79866, lat: 56.33945 },
          { lng: -60.46853, lat: 55.77548 },
          { lng: -59.56962, lat: 55.20407 },
          { lng: -57.97508, lat: 54.94549 },
          { lng: -57.3332, lat: 54.6265 },
          { lng: -56.93689, lat: 53.78032 },
          { lng: -56.15811, lat: 53.64749 },
          { lng: -55.75632, lat: 53.27036 },
          { lng: -55.68338, lat: 52.14664 },
          { lng: -56.40916, lat: 51.7707 },
          { lng: -57.12691, lat: 51.41972 },
          { lng: -58.77482, lat: 51.0643 },
          { lng: -60.03309, lat: 50.24277 },
          { lng: -61.72366, lat: 50.08046 },
          { lng: -63.86251, lat: 50.29099 },
          { lng: -65.36331, lat: 50.2982 },
          { lng: -66.39905, lat: 50.22897 },
          { lng: -67.23631, lat: 49.51156 },
          { lng: -68.51114, lat: 49.06836 },
          { lng: -69.95362, lat: 47.74488 },
          { lng: -71.10458, lat: 46.82171 },
          { lng: -70.25522, lat: 46.98606 },
          { lng: -68.65, lat: 48.3 },
          { lng: -66.55243, lat: 49.1331 },
          { lng: -65.05626, lat: 49.23278 },
          { lng: -64.17099, lat: 48.74248 },
          { lng: -65.11545, lat: 48.07085 },
          { lng: -64.79854, lat: 46.99297 },
          { lng: -64.47219, lat: 46.23849 },
          { lng: -63.17329, lat: 45.73902 },
          { lng: -61.52072, lat: 45.88377 },
          { lng: -60.51815, lat: 47.00793 },
          { lng: -60.4486, lat: 46.28264 },
          { lng: -59.80287, lat: 45.9204 },
          { lng: -61.03988, lat: 45.26525 },
          { lng: -63.25471, lat: 44.67014 },
          { lng: -64.24656, lat: 44.26553 },
          { lng: -65.36406, lat: 43.54523 },
          { lng: -66.1234, lat: 43.61867 },
          { lng: -66.16173, lat: 44.46512 },
          { lng: -64.42549, lat: 45.29204 },
          { lng: -66.02605, lat: 45.25931 },
          { lng: -67.13741, lat: 45.13753 },
          { lng: -67.79134, lat: 45.70281 },
          { lng: -67.79046, lat: 47.06636 },
          { lng: -68.23444, lat: 47.35486 },
          { lng: -68.905, lat: 47.185 },
          { lng: -69.237216, lat: 47.447781 },
          { lng: -69.99997, lat: 46.69307 },
          { lng: -70.305, lat: 45.915 },
          { lng: -70.66, lat: 45.46 },
          { lng: -71.08482, lat: 45.30524 },
          { lng: -71.405, lat: 45.255 },
          { lng: -71.50506, lat: 45.0082 },
          { lng: -73.34783, lat: 45.00738 },
          { lng: -74.867, lat: 45.00048 },
          { lng: -75.31821, lat: 44.81645 },
          { lng: -76.375, lat: 44.09631 },
          { lng: -76.5, lat: 44.018459 },
          { lng: -76.820034, lat: 43.628784 },
          { lng: -77.737885, lat: 43.629056 },
          { lng: -78.72028, lat: 43.625089 },
          { lng: -79.171674, lat: 43.466339 },
          { lng: -79.01, lat: 43.27 },
          { lng: -78.92, lat: 42.965 },
          { lng: -78.939362, lat: 42.863611 },
          { lng: -80.247448, lat: 42.3662 },
          { lng: -81.277747, lat: 42.209026 },
          { lng: -82.439278, lat: 41.675105 },
          { lng: -82.690089, lat: 41.675105 },
          { lng: -83.02981, lat: 41.832796 },
          { lng: -83.142, lat: 41.975681 },
          { lng: -83.12, lat: 42.08 },
          { lng: -82.9, lat: 42.43 },
          { lng: -82.43, lat: 42.98 },
          { lng: -82.137642, lat: 43.571088 },
          { lng: -82.337763, lat: 44.44 },
          { lng: -82.550925, lat: 45.347517 },
          { lng: -83.592851, lat: 45.816894 },
          { lng: -83.469551, lat: 45.994686 },
          { lng: -83.616131, lat: 46.116927 },
          { lng: -83.890765, lat: 46.116927 },
          { lng: -84.091851, lat: 46.275419 },
          { lng: -84.14212, lat: 46.512226 },
          { lng: -84.3367, lat: 46.40877 },
          { lng: -84.6049, lat: 46.4396 },
          { lng: -84.543749, lat: 46.538684 },
          { lng: -84.779238, lat: 46.637102 },
          { lng: -84.87608, lat: 46.900083 },
          { lng: -85.652363, lat: 47.220219 },
          { lng: -86.461991, lat: 47.553338 },
          { lng: -87.439793, lat: 47.94 },
          { lng: -88.378114, lat: 48.302918 },
          { lng: -89.272917, lat: 48.019808 },
          { lng: -89.6, lat: 48.01 },
          { lng: -90.83, lat: 48.27 },
          { lng: -91.64, lat: 48.14 },
          { lng: -92.61, lat: 48.45 },
          { lng: -93.63087, lat: 48.60926 },
          { lng: -94.32914, lat: 48.67074 },
          { lng: -94.64, lat: 48.84 },
          { lng: -94.81758, lat: 49.38905 },
          { lng: -95.15609, lat: 49.38425 },
          { lng: -95.15907, lat: 49 },
          { lng: -97.22872, lat: 49.0007 },
          { lng: -100.65, lat: 49 },
          { lng: -104.04826, lat: 48.99986 },
          { lng: -107.05, lat: 49 },
          { lng: -110.05, lat: 49 },
          { lng: -113, lat: 49 },
          { lng: -116.04818, lat: 49 },
          { lng: -117.03121, lat: 49 },
          { lng: -120, lat: 49 },
          { lng: -122.84, lat: 49 },
          { lng: -122.97421, lat: 49.002538 },
          { lng: -124.91024, lat: 49.98456 },
          { lng: -125.62461, lat: 50.41656 },
          { lng: -127.43561, lat: 50.83061 },
          { lng: -127.99276, lat: 51.71583 },
          { lng: -127.85032, lat: 52.32961 },
          { lng: -129.12979, lat: 52.75538 },
          { lng: -129.30523, lat: 53.56159 },
          { lng: -130.51497, lat: 54.28757 },
          { lng: -130.53611, lat: 54.80278 },
          { lng: -129.98, lat: 55.285 },
          { lng: -130.00778, lat: 55.91583 },
          { lng: -131.70781, lat: 56.55212 },
          { lng: -132.73042, lat: 57.69289 },
          { lng: -133.35556, lat: 58.41028 },
          { lng: -134.27111, lat: 58.86111 },
          { lng: -134.945, lat: 59.27056 },
          { lng: -135.47583, lat: 59.78778 },
          { lng: -136.47972, lat: 59.46389 },
          { lng: -137.4525, lat: 58.905 },
          { lng: -138.34089, lat: 59.56211 },
          { lng: -139.039, lat: 60 },
          { lng: -140.013, lat: 60.27682 },
          { lng: -140.99778, lat: 60.30639 },
          { lng: -140.9925, lat: 66.00003 },
          { lng: -140.986, lat: 69.712 },
          { lng: -139.12052, lat: 69.47102 },
          { lng: -137.54636, lat: 68.99002 },
          { lng: -136.50358, lat: 68.89804 },
          { lng: -135.62576, lat: 69.31512 },
          { lng: -134.41464, lat: 69.62743 },
          { lng: -132.92925, lat: 69.50534 },
          { lng: -131.43136, lat: 69.94451 },
          { lng: -129.79471, lat: 70.19369 },
          { lng: -129.10773, lat: 69.77927 },
          { lng: -128.36156, lat: 70.01286 },
          { lng: -128.13817, lat: 70.48384 },
          { lng: -127.44712, lat: 70.37721 },
          { lng: -125.75632, lat: 69.48058 },
          { lng: -124.42483, lat: 70.1584 },
          { lng: -124.28968, lat: 69.39969 },
          { lng: -123.06108, lat: 69.56372 },
          { lng: -122.6835, lat: 69.85553 },
          { lng: -121.47226, lat: 69.79778 },
          { lng: -119.94288, lat: 69.37786 },
          { lng: -117.60268, lat: 69.01128 },
          { lng: -116.22643, lat: 68.84151 },
          { lng: -115.2469, lat: 68.90591 },
          { lng: -113.89794, lat: 68.3989 },
          { lng: -115.30489, lat: 67.90261 },
          { lng: -113.49727, lat: 67.68815 },
          { lng: -110.798, lat: 67.80612 },
          { lng: -109.94619, lat: 67.98104 },
          { lng: -108.8802, lat: 67.38144 },
          { lng: -107.79239, lat: 67.88736 },
          { lng: -108.81299, lat: 68.31164 },
          { lng: -108.16721, lat: 68.65392 },
          { lng: -106.95, lat: 68.7 },
          { lng: -106.15, lat: 68.8 },
          { lng: -105.34282, lat: 68.56122 },
          { lng: -104.33791, lat: 68.018 },
          { lng: -103.22115, lat: 68.09775 },
          { lng: -101.45433, lat: 67.64689 },
          { lng: -99.90195, lat: 67.80566 },
          { lng: -98.4432, lat: 67.78165 },
          { lng: -98.5586, lat: 68.40394 },
          { lng: -97.66948, lat: 68.57864 },
          { lng: -96.11991, lat: 68.23939 },
          { lng: -96.12588, lat: 67.29338 },
          { lng: -95.48943, lat: 68.0907 },
          { lng: -94.685, lat: 68.06383 },
          { lng: -94.23282, lat: 69.06903 },
          { lng: -95.30408, lat: 69.68571 },
          { lng: -96.47131, lat: 70.08976 },
          { lng: -96.39115, lat: 71.19482 },
          { lng: -95.2088, lat: 71.92053 },
          { lng: -93.88997, lat: 71.76015 },
          { lng: -92.87818, lat: 71.31869 },
          { lng: -91.51964, lat: 70.19129 },
          { lng: -92.40692, lat: 69.69997 },
          { lng: -90.5471, lat: 69.49766 },
        ],
        [
          { lng: -114.16717, lat: 73.12145 },
          { lng: -114.66634, lat: 72.65277 },
          { lng: -112.44102, lat: 72.9554 },
          { lng: -111.05039, lat: 72.4504 },
          { lng: -109.92035, lat: 72.96113 },
          { lng: -109.00654, lat: 72.63335 },
          { lng: -108.18835, lat: 71.65089 },
          { lng: -107.68599, lat: 72.06548 },
          { lng: -108.39639, lat: 73.08953 },
          { lng: -107.51645, lat: 73.23598 },
          { lng: -106.52259, lat: 73.07601 },
          { lng: -105.40246, lat: 72.67259 },
          { lng: -104.77484, lat: 71.6984 },
          { lng: -104.46476, lat: 70.99297 },
          { lng: -102.78537, lat: 70.49776 },
          { lng: -100.98078, lat: 70.02432 },
          { lng: -101.08929, lat: 69.58447 },
          { lng: -102.73116, lat: 69.50402 },
          { lng: -102.09329, lat: 69.11962 },
          { lng: -102.43024, lat: 68.75282 },
          { lng: -104.24, lat: 68.91 },
          { lng: -105.96, lat: 69.18 },
          { lng: -107.12254, lat: 69.11922 },
          { lng: -109, lat: 68.78 },
          { lng: -111.534149, lat: 68.630059 },
          { lng: -113.3132, lat: 68.53554 },
          { lng: -113.85496, lat: 69.00744 },
          { lng: -115.22, lat: 69.28 },
          { lng: -116.10794, lat: 69.16821 },
          { lng: -117.34, lat: 69.96 },
          { lng: -116.67473, lat: 70.06655 },
          { lng: -115.13112, lat: 70.2373 },
          { lng: -113.72141, lat: 70.19237 },
          { lng: -112.4161, lat: 70.36638 },
          { lng: -114.35, lat: 70.6 },
          { lng: -116.48684, lat: 70.52045 },
          { lng: -117.9048, lat: 70.54056 },
          { lng: -118.43238, lat: 70.9092 },
          { lng: -116.11311, lat: 71.30918 },
          { lng: -117.65568, lat: 71.2952 },
          { lng: -119.40199, lat: 71.55859 },
          { lng: -118.56267, lat: 72.30785 },
          { lng: -117.86642, lat: 72.70594 },
          { lng: -115.18909, lat: 73.31459 },
          { lng: -114.16717, lat: 73.12145 },
        ],
        [
          { lng: -104.5, lat: 73.42 },
          { lng: -105.38, lat: 72.76 },
          { lng: -106.94, lat: 73.46 },
          { lng: -106.6, lat: 73.6 },
          { lng: -105.26, lat: 73.64 },
          { lng: -104.5, lat: 73.42 },
        ],
        [
          { lng: -76.34, lat: 73.102685 },
          { lng: -76.251404, lat: 72.826385 },
          { lng: -77.314438, lat: 72.855545 },
          { lng: -78.39167, lat: 72.876656 },
          { lng: -79.486252, lat: 72.742203 },
          { lng: -79.775833, lat: 72.802902 },
          { lng: -80.876099, lat: 73.333183 },
          { lng: -80.833885, lat: 73.693184 },
          { lng: -80.353058, lat: 73.75972 },
          { lng: -78.064438, lat: 73.651932 },
          { lng: -76.34, lat: 73.102685 },
        ],
        [
          { lng: -86.562179, lat: 73.157447 },
          { lng: -85.774371, lat: 72.534126 },
          { lng: -84.850112, lat: 73.340278 },
          { lng: -82.31559, lat: 73.750951 },
          { lng: -80.600088, lat: 72.716544 },
          { lng: -80.748942, lat: 72.061907 },
          { lng: -78.770639, lat: 72.352173 },
          { lng: -77.824624, lat: 72.749617 },
          { lng: -75.605845, lat: 72.243678 },
          { lng: -74.228616, lat: 71.767144 },
          { lng: -74.099141, lat: 71.33084 },
          { lng: -72.242226, lat: 71.556925 },
          { lng: -71.200015, lat: 70.920013 },
          { lng: -68.786054, lat: 70.525024 },
          { lng: -67.91497, lat: 70.121948 },
          { lng: -66.969033, lat: 69.186087 },
          { lng: -68.805123, lat: 68.720198 },
          { lng: -66.449866, lat: 68.067163 },
          { lng: -64.862314, lat: 67.847539 },
          { lng: -63.424934, lat: 66.928473 },
          { lng: -61.851981, lat: 66.862121 },
          { lng: -62.163177, lat: 66.160251 },
          { lng: -63.918444, lat: 64.998669 },
          { lng: -65.14886, lat: 65.426033 },
          { lng: -66.721219, lat: 66.388041 },
          { lng: -68.015016, lat: 66.262726 },
          { lng: -68.141287, lat: 65.689789 },
          { lng: -67.089646, lat: 65.108455 },
          { lng: -65.73208, lat: 64.648406 },
          { lng: -65.320168, lat: 64.382737 },
          { lng: -64.669406, lat: 63.392927 },
          { lng: -65.013804, lat: 62.674185 },
          { lng: -66.275045, lat: 62.945099 },
          { lng: -68.783186, lat: 63.74567 },
          { lng: -67.369681, lat: 62.883966 },
          { lng: -66.328297, lat: 62.280075 },
          { lng: -66.165568, lat: 61.930897 },
          { lng: -68.877367, lat: 62.330149 },
          { lng: -71.023437, lat: 62.910708 },
          { lng: -72.235379, lat: 63.397836 },
          { lng: -71.886278, lat: 63.679989 },
          { lng: -73.378306, lat: 64.193963 },
          { lng: -74.834419, lat: 64.679076 },
          { lng: -74.818503, lat: 64.389093 },
          { lng: -77.70998, lat: 64.229542 },
          { lng: -78.555949, lat: 64.572906 },
          { lng: -77.897281, lat: 65.309192 },
          { lng: -76.018274, lat: 65.326969 },
          { lng: -73.959795, lat: 65.454765 },
          { lng: -74.293883, lat: 65.811771 },
          { lng: -73.944912, lat: 66.310578 },
          { lng: -72.651167, lat: 67.284576 },
          { lng: -72.92606, lat: 67.726926 },
          { lng: -73.311618, lat: 68.069437 },
          { lng: -74.843307, lat: 68.554627 },
          { lng: -76.869101, lat: 68.894736 },
          { lng: -76.228649, lat: 69.147769 },
          { lng: -77.28737, lat: 69.76954 },
          { lng: -78.168634, lat: 69.826488 },
          { lng: -78.957242, lat: 70.16688 },
          { lng: -79.492455, lat: 69.871808 },
          { lng: -81.305471, lat: 69.743185 },
          { lng: -84.944706, lat: 69.966634 },
          { lng: -87.060003, lat: 70.260001 },
          { lng: -88.681713, lat: 70.410741 },
          { lng: -89.51342, lat: 70.762038 },
          { lng: -88.467721, lat: 71.218186 },
          { lng: -89.888151, lat: 71.222552 },
          { lng: -90.20516, lat: 72.235074 },
          { lng: -89.436577, lat: 73.129464 },
          { lng: -88.408242, lat: 73.537889 },
          { lng: -85.826151, lat: 73.803816 },
          { lng: -86.562179, lat: 73.157447 },
        ],
        [
          { lng: -100.35642, lat: 73.84389 },
          { lng: -99.16387, lat: 73.63339 },
          { lng: -97.38, lat: 73.76 },
          { lng: -97.12, lat: 73.47 },
          { lng: -98.05359, lat: 72.99052 },
          { lng: -96.54, lat: 72.56 },
          { lng: -96.72, lat: 71.66 },
          { lng: -98.35966, lat: 71.27285 },
          { lng: -99.32286, lat: 71.35639 },
          { lng: -100.01482, lat: 71.73827 },
          { lng: -102.5, lat: 72.51 },
          { lng: -102.48, lat: 72.83 },
          { lng: -100.43836, lat: 72.70588 },
          { lng: -101.54, lat: 73.36 },
          { lng: -100.35642, lat: 73.84389 },
        ],
        [
          { lng: -93.196296, lat: 72.771992 },
          { lng: -94.269047, lat: 72.024596 },
          { lng: -95.409856, lat: 72.061881 },
          { lng: -96.033745, lat: 72.940277 },
          { lng: -96.018268, lat: 73.43743 },
          { lng: -95.495793, lat: 73.862417 },
          { lng: -94.503658, lat: 74.134907 },
          { lng: -92.420012, lat: 74.100025 },
          { lng: -90.509793, lat: 73.856732 },
          { lng: -92.003965, lat: 72.966244 },
          { lng: -93.196296, lat: 72.771992 },
        ],
        [
          { lng: -120.46, lat: 71.383602 },
          { lng: -123.09219, lat: 70.90164 },
          { lng: -123.62, lat: 71.34 },
          { lng: -125.928949, lat: 71.868688 },
          { lng: -125.5, lat: 72.292261 },
          { lng: -124.80729, lat: 73.02256 },
          { lng: -123.94, lat: 73.68 },
          { lng: -124.91775, lat: 74.29275 },
          { lng: -121.53788, lat: 74.44893 },
          { lng: -120.10978, lat: 74.24135 },
          { lng: -117.55564, lat: 74.18577 },
          { lng: -116.58442, lat: 73.89607 },
          { lng: -115.51081, lat: 73.47519 },
          { lng: -116.76794, lat: 73.22292 },
          { lng: -119.22, lat: 72.52 },
          { lng: -120.46, lat: 71.82 },
          { lng: -120.46, lat: 71.383602 },
        ],
        [
          { lng: -93.612756, lat: 74.979997 },
          { lng: -94.156909, lat: 74.592347 },
          { lng: -95.608681, lat: 74.666864 },
          { lng: -96.820932, lat: 74.927623 },
          { lng: -96.288587, lat: 75.377828 },
          { lng: -94.85082, lat: 75.647218 },
          { lng: -93.977747, lat: 75.29649 },
          { lng: -93.612756, lat: 74.979997 },
        ],
        [
          { lng: -98.5, lat: 76.72 },
          { lng: -97.735585, lat: 76.25656 },
          { lng: -97.704415, lat: 75.74344 },
          { lng: -98.16, lat: 75 },
          { lng: -99.80874, lat: 74.89744 },
          { lng: -100.88366, lat: 75.05736 },
          { lng: -100.86292, lat: 75.64075 },
          { lng: -102.50209, lat: 75.5638 },
          { lng: -102.56552, lat: 76.3366 },
          { lng: -101.48973, lat: 76.30537 },
          { lng: -99.98349, lat: 76.64634 },
          { lng: -98.57699, lat: 76.58859 },
          { lng: -98.5, lat: 76.72 },
        ],
        [
          { lng: -108.21141, lat: 76.20168 },
          { lng: -107.81943, lat: 75.84552 },
          { lng: -106.92893, lat: 76.01282 },
          { lng: -105.881, lat: 75.9694 },
          { lng: -105.70498, lat: 75.47951 },
          { lng: -106.31347, lat: 75.00527 },
          { lng: -109.7, lat: 74.85 },
          { lng: -112.22307, lat: 74.41696 },
          { lng: -113.74381, lat: 74.39427 },
          { lng: -113.87135, lat: 74.72029 },
          { lng: -111.79421, lat: 75.1625 },
          { lng: -116.31221, lat: 75.04343 },
          { lng: -117.7104, lat: 75.2222 },
          { lng: -116.34602, lat: 76.19903 },
          { lng: -115.40487, lat: 76.47887 },
          { lng: -112.59056, lat: 76.14134 },
          { lng: -110.81422, lat: 75.54919 },
          { lng: -109.0671, lat: 75.47321 },
          { lng: -110.49726, lat: 76.42982 },
          { lng: -109.5811, lat: 76.79417 },
          { lng: -108.54859, lat: 76.67832 },
          { lng: -108.21141, lat: 76.20168 },
        ],
        [
          { lng: -94.684086, lat: 77.097878 },
          { lng: -93.573921, lat: 76.776296 },
          { lng: -91.605023, lat: 76.778518 },
          { lng: -90.741846, lat: 76.449597 },
          { lng: -90.969661, lat: 76.074013 },
          { lng: -89.822238, lat: 75.847774 },
          { lng: -89.187083, lat: 75.610166 },
          { lng: -87.838276, lat: 75.566189 },
          { lng: -86.379192, lat: 75.482421 },
          { lng: -84.789625, lat: 75.699204 },
          { lng: -82.753445, lat: 75.784315 },
          { lng: -81.128531, lat: 75.713983 },
          { lng: -80.057511, lat: 75.336849 },
          { lng: -79.833933, lat: 74.923127 },
          { lng: -80.457771, lat: 74.657304 },
          { lng: -81.948843, lat: 74.442459 },
          { lng: -83.228894, lat: 74.564028 },
          { lng: -86.097452, lat: 74.410032 },
          { lng: -88.15035, lat: 74.392307 },
          { lng: -89.764722, lat: 74.515555 },
          { lng: -92.422441, lat: 74.837758 },
          { lng: -92.768285, lat: 75.38682 },
          { lng: -92.889906, lat: 75.882655 },
          { lng: -93.893824, lat: 76.319244 },
          { lng: -95.962457, lat: 76.441381 },
          { lng: -97.121379, lat: 76.751078 },
          { lng: -96.745123, lat: 77.161389 },
          { lng: -94.684086, lat: 77.097878 },
        ],
        [
          { lng: -116.198587, lat: 77.645287 },
          { lng: -116.335813, lat: 76.876962 },
          { lng: -117.106051, lat: 76.530032 },
          { lng: -118.040412, lat: 76.481172 },
          { lng: -119.899318, lat: 76.053213 },
          { lng: -121.499995, lat: 75.900019 },
          { lng: -122.854924, lat: 76.116543 },
          { lng: -122.854925, lat: 76.116543 },
          { lng: -121.157535, lat: 76.864508 },
          { lng: -119.103939, lat: 77.51222 },
          { lng: -117.570131, lat: 77.498319 },
          { lng: -116.198587, lat: 77.645287 },
        ],
        [
          { lng: -93.840003, lat: 77.519997 },
          { lng: -94.295608, lat: 77.491343 },
          { lng: -96.169654, lat: 77.555111 },
          { lng: -96.436304, lat: 77.834629 },
          { lng: -94.422577, lat: 77.820005 },
          { lng: -93.720656, lat: 77.634331 },
          { lng: -93.840003, lat: 77.519997 },
        ],
        [
          { lng: -110.186938, lat: 77.697015 },
          { lng: -112.051191, lat: 77.409229 },
          { lng: -113.534279, lat: 77.732207 },
          { lng: -112.724587, lat: 78.05105 },
          { lng: -111.264443, lat: 78.152956 },
          { lng: -109.854452, lat: 77.996325 },
          { lng: -110.186938, lat: 77.697015 },
        ],
        [
          { lng: -109.663146, lat: 78.601973 },
          { lng: -110.881314, lat: 78.40692 },
          { lng: -112.542091, lat: 78.407902 },
          { lng: -112.525891, lat: 78.550555 },
          { lng: -111.50001, lat: 78.849994 },
          { lng: -110.963661, lat: 78.804441 },
          { lng: -109.663146, lat: 78.601973 },
        ],
        [
          { lng: -95.830295, lat: 78.056941 },
          { lng: -97.309843, lat: 77.850597 },
          { lng: -98.124289, lat: 78.082857 },
          { lng: -98.552868, lat: 78.458105 },
          { lng: -98.631984, lat: 78.87193 },
          { lng: -97.337231, lat: 78.831984 },
          { lng: -96.754399, lat: 78.765813 },
          { lng: -95.559278, lat: 78.418315 },
          { lng: -95.830295, lat: 78.056941 },
        ],
        [
          { lng: -100.060192, lat: 78.324754 },
          { lng: -99.670939, lat: 77.907545 },
          { lng: -101.30394, lat: 78.018985 },
          { lng: -102.949809, lat: 78.343229 },
          { lng: -105.176133, lat: 78.380332 },
          { lng: -104.210429, lat: 78.67742 },
          { lng: -105.41958, lat: 78.918336 },
          { lng: -105.492289, lat: 79.301594 },
          { lng: -103.529282, lat: 79.165349 },
          { lng: -100.825158, lat: 78.800462 },
          { lng: -100.060192, lat: 78.324754 },
        ],
        [
          { lng: -87.02, lat: 79.66 },
          { lng: -85.81435, lat: 79.3369 },
          { lng: -87.18756, lat: 79.0393 },
          { lng: -89.03535, lat: 78.28723 },
          { lng: -90.80436, lat: 78.21533 },
          { lng: -92.87669, lat: 78.34333 },
          { lng: -93.95116, lat: 78.75099 },
          { lng: -93.93574, lat: 79.11373 },
          { lng: -93.14524, lat: 79.3801 },
          { lng: -94.974, lat: 79.37248 },
          { lng: -96.07614, lat: 79.70502 },
          { lng: -96.70972, lat: 80.15777 },
          { lng: -96.01644, lat: 80.60233 },
          { lng: -95.32345, lat: 80.90729 },
          { lng: -94.29843, lat: 80.97727 },
          { lng: -94.73542, lat: 81.20646 },
          { lng: -92.40984, lat: 81.25739 },
          { lng: -91.13289, lat: 80.72345 },
          { lng: -89.45, lat: 80.509322 },
          { lng: -87.81, lat: 80.32 },
          { lng: -87.02, lat: 79.66 },
        ],
        [
          { lng: -68.5, lat: 83.106322 },
          { lng: -65.82735, lat: 83.02801 },
          { lng: -63.68, lat: 82.9 },
          { lng: -61.85, lat: 82.6286 },
          { lng: -61.89388, lat: 82.36165 },
          { lng: -64.334, lat: 81.92775 },
          { lng: -66.75342, lat: 81.72527 },
          { lng: -67.65755, lat: 81.50141 },
          { lng: -65.48031, lat: 81.50657 },
          { lng: -67.84, lat: 80.9 },
          { lng: -69.4697, lat: 80.61683 },
          { lng: -71.18, lat: 79.8 },
          { lng: -73.2428, lat: 79.63415 },
          { lng: -73.88, lat: 79.430162 },
          { lng: -76.90773, lat: 79.32309 },
          { lng: -75.52924, lat: 79.19766 },
          { lng: -76.22046, lat: 79.01907 },
          { lng: -75.39345, lat: 78.52581 },
          { lng: -76.34354, lat: 78.18296 },
          { lng: -77.88851, lat: 77.89991 },
          { lng: -78.36269, lat: 77.50859 },
          { lng: -79.75951, lat: 77.20968 },
          { lng: -79.61965, lat: 76.98336 },
          { lng: -77.91089, lat: 77.022045 },
          { lng: -77.88911, lat: 76.777955 },
          { lng: -80.56125, lat: 76.17812 },
          { lng: -83.17439, lat: 76.45403 },
          { lng: -86.11184, lat: 76.29901 },
          { lng: -87.6, lat: 76.42 },
          { lng: -89.49068, lat: 76.47239 },
          { lng: -89.6161, lat: 76.95213 },
          { lng: -87.76739, lat: 77.17833 },
          { lng: -88.26, lat: 77.9 },
          { lng: -87.65, lat: 77.970222 },
          { lng: -84.97634, lat: 77.53873 },
          { lng: -86.34, lat: 78.18 },
          { lng: -87.96192, lat: 78.37181 },
          { lng: -87.15198, lat: 78.75867 },
          { lng: -85.37868, lat: 78.9969 },
          { lng: -85.09495, lat: 79.34543 },
          { lng: -86.50734, lat: 79.73624 },
          { lng: -86.93179, lat: 80.25145 },
          { lng: -84.19844, lat: 80.20836 },
          { lng: -83.408696, lat: 80.1 },
          { lng: -81.84823, lat: 80.46442 },
          { lng: -84.1, lat: 80.58 },
          { lng: -87.59895, lat: 80.51627 },
          { lng: -89.36663, lat: 80.85569 },
          { lng: -90.2, lat: 81.26 },
          { lng: -91.36786, lat: 81.5531 },
          { lng: -91.58702, lat: 81.89429 },
          { lng: -90.1, lat: 82.085 },
          { lng: -88.93227, lat: 82.11751 },
          { lng: -86.97024, lat: 82.27961 },
          { lng: -85.5, lat: 82.652273 },
          { lng: -84.260005, lat: 82.6 },
          { lng: -83.18, lat: 82.32 },
          { lng: -82.42, lat: 82.86 },
          { lng: -81.1, lat: 83.02 },
          { lng: -79.30664, lat: 83.13056 },
          { lng: -76.25, lat: 83.172059 },
          { lng: -75.71878, lat: 83.06404 },
          { lng: -72.83153, lat: 83.23324 },
          { lng: -70.665765, lat: 83.169781 },
          { lng: -68.5, lat: 83.106322 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'CHE',
    properties: { name: 'Switzerland' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 9.594226, lat: 47.525058 },
          { lng: 9.632932, lat: 47.347601 },
          { lng: 9.47997, lat: 47.10281 },
          { lng: 9.932448, lat: 46.920728 },
          { lng: 10.442701, lat: 46.893546 },
          { lng: 10.363378, lat: 46.483571 },
          { lng: 9.922837, lat: 46.314899 },
          { lng: 9.182882, lat: 46.440215 },
          { lng: 8.966306, lat: 46.036932 },
          { lng: 8.489952, lat: 46.005151 },
          { lng: 8.31663, lat: 46.163642 },
          { lng: 7.755992, lat: 45.82449 },
          { lng: 7.273851, lat: 45.776948 },
          { lng: 6.843593, lat: 45.991147 },
          { lng: 6.5001, lat: 46.429673 },
          { lng: 6.022609, lat: 46.27299 },
          { lng: 6.037389, lat: 46.725779 },
          { lng: 6.768714, lat: 47.287708 },
          { lng: 6.736571, lat: 47.541801 },
          { lng: 7.192202, lat: 47.449766 },
          { lng: 7.466759, lat: 47.620582 },
          { lng: 8.317301, lat: 47.61358 },
          { lng: 8.522612, lat: 47.830828 },
          { lng: 9.594226, lat: 47.525058 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'CHL',
    properties: { name: 'Chile' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: -68.63401, lat: -52.63637 },
          { lng: -68.63335, lat: -54.8695 },
          { lng: -67.56244, lat: -54.87001 },
          { lng: -66.95992, lat: -54.89681 },
          { lng: -67.29103, lat: -55.30124 },
          { lng: -68.14863, lat: -55.61183 },
          { lng: -68.639991, lat: -55.580018 },
          { lng: -69.2321, lat: -55.49906 },
          { lng: -69.95809, lat: -55.19843 },
          { lng: -71.00568, lat: -55.05383 },
          { lng: -72.2639, lat: -54.49514 },
          { lng: -73.2852, lat: -53.95752 },
          { lng: -74.66253, lat: -52.83749 },
          { lng: -73.8381, lat: -53.04743 },
          { lng: -72.43418, lat: -53.7154 },
          { lng: -71.10773, lat: -54.07433 },
          { lng: -70.59178, lat: -53.61583 },
          { lng: -70.26748, lat: -52.93123 },
          { lng: -69.34565, lat: -52.5183 },
          { lng: -68.63401, lat: -52.63637 },
        ],
        [
          { lng: -68.219913, lat: -21.494347 },
          { lng: -67.82818, lat: -22.872919 },
          { lng: -67.106674, lat: -22.735925 },
          { lng: -66.985234, lat: -22.986349 },
          { lng: -67.328443, lat: -24.025303 },
          { lng: -68.417653, lat: -24.518555 },
          { lng: -68.386001, lat: -26.185016 },
          { lng: -68.5948, lat: -26.506909 },
          { lng: -68.295542, lat: -26.89934 },
          { lng: -69.001235, lat: -27.521214 },
          { lng: -69.65613, lat: -28.459141 },
          { lng: -70.01355, lat: -29.367923 },
          { lng: -69.919008, lat: -30.336339 },
          { lng: -70.535069, lat: -31.36501 },
          { lng: -70.074399, lat: -33.09121 },
          { lng: -69.814777, lat: -33.273886 },
          { lng: -69.817309, lat: -34.193571 },
          { lng: -70.388049, lat: -35.169688 },
          { lng: -70.364769, lat: -36.005089 },
          { lng: -71.121881, lat: -36.658124 },
          { lng: -71.118625, lat: -37.576827 },
          { lng: -70.814664, lat: -38.552995 },
          { lng: -71.413517, lat: -38.916022 },
          { lng: -71.680761, lat: -39.808164 },
          { lng: -71.915734, lat: -40.832339 },
          { lng: -71.746804, lat: -42.051386 },
          { lng: -72.148898, lat: -42.254888 },
          { lng: -71.915424, lat: -43.408565 },
          { lng: -71.464056, lat: -43.787611 },
          { lng: -71.793623, lat: -44.207172 },
          { lng: -71.329801, lat: -44.407522 },
          { lng: -71.222779, lat: -44.784243 },
          { lng: -71.659316, lat: -44.973689 },
          { lng: -71.552009, lat: -45.560733 },
          { lng: -71.917258, lat: -46.884838 },
          { lng: -72.447355, lat: -47.738533 },
          { lng: -72.331161, lat: -48.244238 },
          { lng: -72.648247, lat: -48.878618 },
          { lng: -73.415436, lat: -49.318436 },
          { lng: -73.328051, lat: -50.378785 },
          { lng: -72.975747, lat: -50.74145 },
          { lng: -72.309974, lat: -50.67701 },
          { lng: -72.329404, lat: -51.425956 },
          { lng: -71.914804, lat: -52.009022 },
          { lng: -69.498362, lat: -52.142761 },
          { lng: -68.571545, lat: -52.299444 },
          { lng: -69.461284, lat: -52.291951 },
          { lng: -69.94278, lat: -52.537931 },
          { lng: -70.845102, lat: -52.899201 },
          { lng: -71.006332, lat: -53.833252 },
          { lng: -71.429795, lat: -53.856455 },
          { lng: -72.557943, lat: -53.53141 },
          { lng: -73.702757, lat: -52.835069 },
          { lng: -73.702757, lat: -52.83507 },
          { lng: -74.946763, lat: -52.262754 },
          { lng: -75.260026, lat: -51.629355 },
          { lng: -74.976632, lat: -51.043396 },
          { lng: -75.479754, lat: -50.378372 },
          { lng: -75.608015, lat: -48.673773 },
          { lng: -75.18277, lat: -47.711919 },
          { lng: -74.126581, lat: -46.939253 },
          { lng: -75.644395, lat: -46.647643 },
          { lng: -74.692154, lat: -45.763976 },
          { lng: -74.351709, lat: -44.103044 },
          { lng: -73.240356, lat: -44.454961 },
          { lng: -72.717804, lat: -42.383356 },
          { lng: -73.3889, lat: -42.117532 },
          { lng: -73.701336, lat: -43.365776 },
          { lng: -74.331943, lat: -43.224958 },
          { lng: -74.017957, lat: -41.794813 },
          { lng: -73.677099, lat: -39.942213 },
          { lng: -73.217593, lat: -39.258689 },
          { lng: -73.505559, lat: -38.282883 },
          { lng: -73.588061, lat: -37.156285 },
          { lng: -73.166717, lat: -37.12378 },
          { lng: -72.553137, lat: -35.50884 },
          { lng: -71.861732, lat: -33.909093 },
          { lng: -71.43845, lat: -32.418899 },
          { lng: -71.668721, lat: -30.920645 },
          { lng: -71.370083, lat: -30.095682 },
          { lng: -71.489894, lat: -28.861442 },
          { lng: -70.905124, lat: -27.64038 },
          { lng: -70.724954, lat: -25.705924 },
          { lng: -70.403966, lat: -23.628997 },
          { lng: -70.091246, lat: -21.393319 },
          { lng: -70.16442, lat: -19.756468 },
          { lng: -70.372572, lat: -18.347975 },
          { lng: -69.858444, lat: -18.092694 },
          { lng: -69.590424, lat: -17.580012 },
          { lng: -69.100247, lat: -18.260125 },
          { lng: -68.966818, lat: -18.981683 },
          { lng: -68.442225, lat: -19.405068 },
          { lng: -68.757167, lat: -20.372658 },
          { lng: -68.219913, lat: -21.494347 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'CHN',
    properties: { name: 'China' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 110.339188, lat: 18.678395 },
          { lng: 109.47521, lat: 18.197701 },
          { lng: 108.655208, lat: 18.507682 },
          { lng: 108.626217, lat: 19.367888 },
          { lng: 109.119056, lat: 19.821039 },
          { lng: 110.211599, lat: 20.101254 },
          { lng: 110.786551, lat: 20.077534 },
          { lng: 111.010051, lat: 19.69593 },
          { lng: 110.570647, lat: 19.255879 },
          { lng: 110.339188, lat: 18.678395 },
        ],
        [
          { lng: 127.657407, lat: 49.76027 },
          { lng: 129.397818, lat: 49.4406 },
          { lng: 130.582293, lat: 48.729687 },
          { lng: 130.987282, lat: 47.790132 },
          { lng: 132.506672, lat: 47.78897 },
          { lng: 133.373596, lat: 48.183442 },
          { lng: 135.026311, lat: 48.47823 },
          { lng: 134.500814, lat: 47.57844 },
          { lng: 134.112362, lat: 47.212467 },
          { lng: 133.769644, lat: 46.116927 },
          { lng: 133.097127, lat: 45.144066 },
          { lng: 131.883454, lat: 45.321162 },
          { lng: 131.025212, lat: 44.967953 },
          { lng: 131.288555, lat: 44.11152 },
          { lng: 131.144688, lat: 42.92999 },
          { lng: 130.633866, lat: 42.903015 },
          { lng: 130.640016, lat: 42.395009 },
          { lng: 129.994267, lat: 42.985387 },
          { lng: 129.596669, lat: 42.424982 },
          { lng: 128.052215, lat: 41.994285 },
          { lng: 128.208433, lat: 41.466772 },
          { lng: 127.343783, lat: 41.503152 },
          { lng: 126.869083, lat: 41.816569 },
          { lng: 126.182045, lat: 41.107336 },
          { lng: 125.079942, lat: 40.569824 },
          { lng: 124.265625, lat: 39.928493 },
          { lng: 122.86757, lat: 39.637788 },
          { lng: 122.131388, lat: 39.170452 },
          { lng: 121.054554, lat: 38.897471 },
          { lng: 121.585995, lat: 39.360854 },
          { lng: 121.376757, lat: 39.750261 },
          { lng: 122.168595, lat: 40.422443 },
          { lng: 121.640359, lat: 40.94639 },
          { lng: 120.768629, lat: 40.593388 },
          { lng: 119.639602, lat: 39.898056 },
          { lng: 119.023464, lat: 39.252333 },
          { lng: 118.042749, lat: 39.204274 },
          { lng: 117.532702, lat: 38.737636 },
          { lng: 118.059699, lat: 38.061476 },
          { lng: 118.87815, lat: 37.897325 },
          { lng: 118.911636, lat: 37.448464 },
          { lng: 119.702802, lat: 37.156389 },
          { lng: 120.823457, lat: 37.870428 },
          { lng: 121.711259, lat: 37.481123 },
          { lng: 122.357937, lat: 37.454484 },
          { lng: 122.519995, lat: 36.930614 },
          { lng: 121.104164, lat: 36.651329 },
          { lng: 120.637009, lat: 36.11144 },
          { lng: 119.664562, lat: 35.609791 },
          { lng: 119.151208, lat: 34.909859 },
          { lng: 120.227525, lat: 34.360332 },
          { lng: 120.620369, lat: 33.376723 },
          { lng: 121.229014, lat: 32.460319 },
          { lng: 121.908146, lat: 31.692174 },
          { lng: 121.891919, lat: 30.949352 },
          { lng: 121.264257, lat: 30.676267 },
          { lng: 121.503519, lat: 30.142915 },
          { lng: 122.092114, lat: 29.83252 },
          { lng: 121.938428, lat: 29.018022 },
          { lng: 121.684439, lat: 28.225513 },
          { lng: 121.125661, lat: 28.135673 },
          { lng: 120.395473, lat: 27.053207 },
          { lng: 119.585497, lat: 25.740781 },
          { lng: 118.656871, lat: 24.547391 },
          { lng: 117.281606, lat: 23.624501 },
          { lng: 115.890735, lat: 22.782873 },
          { lng: 114.763827, lat: 22.668074 },
          { lng: 114.152547, lat: 22.22376 },
          { lng: 113.80678, lat: 22.54834 },
          { lng: 113.241078, lat: 22.051367 },
          { lng: 111.843592, lat: 21.550494 },
          { lng: 110.785466, lat: 21.397144 },
          { lng: 110.444039, lat: 20.341033 },
          { lng: 109.889861, lat: 20.282457 },
          { lng: 109.627655, lat: 21.008227 },
          { lng: 109.864488, lat: 21.395051 },
          { lng: 108.522813, lat: 21.715212 },
          { lng: 108.05018, lat: 21.55238 },
          { lng: 107.04342, lat: 21.811899 },
          { lng: 106.567273, lat: 22.218205 },
          { lng: 106.725403, lat: 22.794268 },
          { lng: 105.811247, lat: 22.976892 },
          { lng: 105.329209, lat: 23.352063 },
          { lng: 104.476858, lat: 22.81915 },
          { lng: 103.504515, lat: 22.703757 },
          { lng: 102.706992, lat: 22.708795 },
          { lng: 102.170436, lat: 22.464753 },
          { lng: 101.652018, lat: 22.318199 },
          { lng: 101.80312, lat: 21.174367 },
          { lng: 101.270026, lat: 21.201652 },
          { lng: 101.180005, lat: 21.436573 },
          { lng: 101.150033, lat: 21.849984 },
          { lng: 100.416538, lat: 21.558839 },
          { lng: 99.983489, lat: 21.742937 },
          { lng: 99.240899, lat: 22.118314 },
          { lng: 99.531992, lat: 22.949039 },
          { lng: 98.898749, lat: 23.142722 },
          { lng: 98.660262, lat: 24.063286 },
          { lng: 97.60472, lat: 23.897405 },
          { lng: 97.724609, lat: 25.083637 },
          { lng: 98.671838, lat: 25.918703 },
          { lng: 98.712094, lat: 26.743536 },
          { lng: 98.68269, lat: 27.508812 },
          { lng: 98.246231, lat: 27.747221 },
          { lng: 97.911988, lat: 28.335945 },
          { lng: 97.327114, lat: 28.261583 },
          { lng: 96.248833, lat: 28.411031 },
          { lng: 96.586591, lat: 28.83098 },
          { lng: 96.117679, lat: 29.452802 },
          { lng: 95.404802, lat: 29.031717 },
          { lng: 94.56599, lat: 29.277438 },
          { lng: 93.413348, lat: 28.640629 },
          { lng: 92.503119, lat: 27.896876 },
          { lng: 91.696657, lat: 27.771742 },
          { lng: 91.258854, lat: 28.040614 },
          { lng: 90.730514, lat: 28.064954 },
          { lng: 90.015829, lat: 28.296439 },
          { lng: 89.47581, lat: 28.042759 },
          { lng: 88.814248, lat: 27.299316 },
          { lng: 88.730326, lat: 28.086865 },
          { lng: 88.120441, lat: 27.876542 },
          { lng: 86.954517, lat: 27.974262 },
          { lng: 85.82332, lat: 28.203576 },
          { lng: 85.011638, lat: 28.642774 },
          { lng: 84.23458, lat: 28.839894 },
          { lng: 83.898993, lat: 29.320226 },
          { lng: 83.337115, lat: 29.463732 },
          { lng: 82.327513, lat: 30.115268 },
          { lng: 81.525804, lat: 30.422717 },
          { lng: 81.111256, lat: 30.183481 },
          { lng: 79.721367, lat: 30.882715 },
          { lng: 78.738894, lat: 31.515906 },
          { lng: 78.458446, lat: 32.618164 },
          { lng: 79.176129, lat: 32.48378 },
          { lng: 79.208892, lat: 32.994395 },
          { lng: 78.811086, lat: 33.506198 },
          { lng: 78.912269, lat: 34.321936 },
          { lng: 77.837451, lat: 35.49401 },
          { lng: 76.192848, lat: 35.898403 },
          { lng: 75.896897, lat: 36.666806 },
          { lng: 75.158028, lat: 37.133031 },
          { lng: 74.980002, lat: 37.41999 },
          { lng: 74.829986, lat: 37.990007 },
          { lng: 74.864816, lat: 38.378846 },
          { lng: 74.257514, lat: 38.606507 },
          { lng: 73.928852, lat: 38.505815 },
          { lng: 73.675379, lat: 39.431237 },
          { lng: 73.960013, lat: 39.660008 },
          { lng: 73.822244, lat: 39.893973 },
          { lng: 74.776862, lat: 40.366425 },
          { lng: 75.467828, lat: 40.562072 },
          { lng: 76.526368, lat: 40.427946 },
          { lng: 76.904484, lat: 41.066486 },
          { lng: 78.187197, lat: 41.185316 },
          { lng: 78.543661, lat: 41.582243 },
          { lng: 80.11943, lat: 42.123941 },
          { lng: 80.25999, lat: 42.349999 },
          { lng: 80.18015, lat: 42.920068 },
          { lng: 80.866206, lat: 43.180362 },
          { lng: 79.966106, lat: 44.917517 },
          { lng: 81.947071, lat: 45.317027 },
          { lng: 82.458926, lat: 45.53965 },
          { lng: 83.180484, lat: 47.330031 },
          { lng: 85.16429, lat: 47.000956 },
          { lng: 85.720484, lat: 47.452969 },
          { lng: 85.768233, lat: 48.455751 },
          { lng: 86.598776, lat: 48.549182 },
          { lng: 87.35997, lat: 49.214981 },
          { lng: 87.751264, lat: 49.297198 },
          { lng: 88.013832, lat: 48.599463 },
          { lng: 88.854298, lat: 48.069082 },
          { lng: 90.280826, lat: 47.693549 },
          { lng: 90.970809, lat: 46.888146 },
          { lng: 90.585768, lat: 45.719716 },
          { lng: 90.94554, lat: 45.286073 },
          { lng: 92.133891, lat: 45.115076 },
          { lng: 93.480734, lat: 44.975472 },
          { lng: 94.688929, lat: 44.352332 },
          { lng: 95.306875, lat: 44.241331 },
          { lng: 95.762455, lat: 43.319449 },
          { lng: 96.349396, lat: 42.725635 },
          { lng: 97.451757, lat: 42.74889 },
          { lng: 99.515817, lat: 42.524691 },
          { lng: 100.845866, lat: 42.663804 },
          { lng: 101.83304, lat: 42.514873 },
          { lng: 103.312278, lat: 41.907468 },
          { lng: 104.522282, lat: 41.908347 },
          { lng: 104.964994, lat: 41.59741 },
          { lng: 106.129316, lat: 42.134328 },
          { lng: 107.744773, lat: 42.481516 },
          { lng: 109.243596, lat: 42.519446 },
          { lng: 110.412103, lat: 42.871234 },
          { lng: 111.129682, lat: 43.406834 },
          { lng: 111.829588, lat: 43.743118 },
          { lng: 111.667737, lat: 44.073176 },
          { lng: 111.348377, lat: 44.457442 },
          { lng: 111.873306, lat: 45.102079 },
          { lng: 112.436062, lat: 45.011646 },
          { lng: 113.463907, lat: 44.808893 },
          { lng: 114.460332, lat: 45.339817 },
          { lng: 115.985096, lat: 45.727235 },
          { lng: 116.717868, lat: 46.388202 },
          { lng: 117.421701, lat: 46.672733 },
          { lng: 118.874326, lat: 46.805412 },
          { lng: 119.66327, lat: 46.69268 },
          { lng: 119.772824, lat: 47.048059 },
          { lng: 118.866574, lat: 47.74706 },
          { lng: 118.064143, lat: 48.06673 },
          { lng: 117.295507, lat: 47.697709 },
          { lng: 116.308953, lat: 47.85341 },
          { lng: 115.742837, lat: 47.726545 },
          { lng: 115.485282, lat: 48.135383 },
          { lng: 116.191802, lat: 49.134598 },
          { lng: 116.678801, lat: 49.888531 },
          { lng: 117.879244, lat: 49.510983 },
          { lng: 119.288461, lat: 50.142883 },
          { lng: 119.279366, lat: 50.582908 },
          { lng: 120.18205, lat: 51.643566 },
          { lng: 120.738191, lat: 51.964115 },
          { lng: 120.725789, lat: 52.516226 },
          { lng: 120.177089, lat: 52.753886 },
          { lng: 121.003085, lat: 53.251401 },
          { lng: 122.245748, lat: 53.431726 },
          { lng: 123.571507, lat: 53.458804 },
          { lng: 125.068211, lat: 53.161045 },
          { lng: 125.946349, lat: 52.792799 },
          { lng: 126.564399, lat: 51.784255 },
          { lng: 126.939157, lat: 51.353894 },
          { lng: 127.287456, lat: 50.739797 },
          { lng: 127.657407, lat: 49.76027 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'CIV',
    properties: { name: "Cote d'Ivoire" },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -2.856125, lat: 4.994476 },
          { lng: -3.311084, lat: 4.984296 },
          { lng: -4.00882, lat: 5.179813 },
          { lng: -4.649917, lat: 5.168264 },
          { lng: -5.834496, lat: 4.993701 },
          { lng: -6.528769, lat: 4.705088 },
          { lng: -7.518941, lat: 4.338288 },
          { lng: -7.712159, lat: 4.364566 },
          { lng: -7.635368, lat: 5.188159 },
          { lng: -7.539715, lat: 5.313345 },
          { lng: -7.570153, lat: 5.707352 },
          { lng: -7.993693, lat: 6.12619 },
          { lng: -8.311348, lat: 6.193033 },
          { lng: -8.60288, lat: 6.467564 },
          { lng: -8.385452, lat: 6.911801 },
          { lng: -8.485446, lat: 7.395208 },
          { lng: -8.439298, lat: 7.686043 },
          { lng: -8.280703, lat: 7.68718 },
          { lng: -8.221792, lat: 8.123329 },
          { lng: -8.299049, lat: 8.316444 },
          { lng: -8.203499, lat: 8.455453 },
          { lng: -7.8321, lat: 8.575704 },
          { lng: -8.079114, lat: 9.376224 },
          { lng: -8.309616, lat: 9.789532 },
          { lng: -8.229337, lat: 10.12902 },
          { lng: -8.029944, lat: 10.206535 },
          { lng: -7.89959, lat: 10.297382 },
          { lng: -7.622759, lat: 10.147236 },
          { lng: -6.850507, lat: 10.138994 },
          { lng: -6.666461, lat: 10.430811 },
          { lng: -6.493965, lat: 10.411303 },
          { lng: -6.205223, lat: 10.524061 },
          { lng: -6.050452, lat: 10.096361 },
          { lng: -5.816926, lat: 10.222555 },
          { lng: -5.404342, lat: 10.370737 },
          { lng: -4.954653, lat: 10.152714 },
          { lng: -4.779884, lat: 9.821985 },
          { lng: -4.330247, lat: 9.610835 },
          { lng: -3.980449, lat: 9.862344 },
          { lng: -3.511899, lat: 9.900326 },
          { lng: -2.827496, lat: 9.642461 },
          { lng: -2.56219, lat: 8.219628 },
          { lng: -2.983585, lat: 7.379705 },
          { lng: -3.24437, lat: 6.250472 },
          { lng: -2.810701, lat: 5.389051 },
          { lng: -2.856125, lat: 4.994476 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'CMR',
    properties: { name: 'Cameroon' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 13.075822, lat: 2.267097 },
          { lng: 12.951334, lat: 2.321616 },
          { lng: 12.35938, lat: 2.192812 },
          { lng: 11.751665, lat: 2.326758 },
          { lng: 11.276449, lat: 2.261051 },
          { lng: 9.649158, lat: 2.283866 },
          { lng: 9.795196, lat: 3.073404 },
          { lng: 9.404367, lat: 3.734527 },
          { lng: 8.948116, lat: 3.904129 },
          { lng: 8.744924, lat: 4.352215 },
          { lng: 8.488816, lat: 4.495617 },
          { lng: 8.500288, lat: 4.771983 },
          { lng: 8.757533, lat: 5.479666 },
          { lng: 9.233163, lat: 6.444491 },
          { lng: 9.522706, lat: 6.453482 },
          { lng: 10.118277, lat: 7.03877 },
          { lng: 10.497375, lat: 7.055358 },
          { lng: 11.058788, lat: 6.644427 },
          { lng: 11.745774, lat: 6.981383 },
          { lng: 11.839309, lat: 7.397042 },
          { lng: 12.063946, lat: 7.799808 },
          { lng: 12.218872, lat: 8.305824 },
          { lng: 12.753672, lat: 8.717763 },
          { lng: 12.955468, lat: 9.417772 },
          { lng: 13.1676, lat: 9.640626 },
          { lng: 13.308676, lat: 10.160362 },
          { lng: 13.57295, lat: 10.798566 },
          { lng: 14.415379, lat: 11.572369 },
          { lng: 14.468192, lat: 11.904752 },
          { lng: 14.577178, lat: 12.085361 },
          { lng: 14.181336, lat: 12.483657 },
          { lng: 14.213531, lat: 12.802035 },
          { lng: 14.495787, lat: 12.859396 },
          { lng: 14.893386, lat: 12.219048 },
          { lng: 14.960152, lat: 11.555574 },
          { lng: 14.923565, lat: 10.891325 },
          { lng: 15.467873, lat: 9.982337 },
          { lng: 14.909354, lat: 9.992129 },
          { lng: 14.627201, lat: 9.920919 },
          { lng: 14.171466, lat: 10.021378 },
          { lng: 13.954218, lat: 9.549495 },
          { lng: 14.544467, lat: 8.965861 },
          { lng: 14.979996, lat: 8.796104 },
          { lng: 15.120866, lat: 8.38215 },
          { lng: 15.436092, lat: 7.692812 },
          { lng: 15.27946, lat: 7.421925 },
          { lng: 14.776545, lat: 6.408498 },
          { lng: 14.53656, lat: 6.226959 },
          { lng: 14.459407, lat: 5.451761 },
          { lng: 14.558936, lat: 5.030598 },
          { lng: 14.478372, lat: 4.732605 },
          { lng: 14.950953, lat: 4.210389 },
          { lng: 15.03622, lat: 3.851367 },
          { lng: 15.405396, lat: 3.335301 },
          { lng: 15.862732, lat: 3.013537 },
          { lng: 15.907381, lat: 2.557389 },
          { lng: 16.012852, lat: 2.26764 },
          { lng: 15.940919, lat: 1.727673 },
          { lng: 15.146342, lat: 1.964015 },
          { lng: 14.337813, lat: 2.227875 },
          { lng: 13.075822, lat: 2.267097 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'COD',
    properties: {
      name: 'Democratic Republic of the Congo',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 30.83386, lat: 3.509166 },
          { lng: 30.773347, lat: 2.339883 },
          { lng: 31.174149, lat: 2.204465 },
          { lng: 30.85267, lat: 1.849396 },
          { lng: 30.468508, lat: 1.583805 },
          { lng: 30.086154, lat: 1.062313 },
          { lng: 29.875779, lat: 0.59738 },
          { lng: 29.819503, lat: -0.20531 },
          { lng: 29.587838, lat: -0.587406 },
          { lng: 29.579466, lat: -1.341313 },
          { lng: 29.291887, lat: -1.620056 },
          { lng: 29.254835, lat: -2.21511 },
          { lng: 29.117479, lat: -2.292211 },
          { lng: 29.024926, lat: -2.839258 },
          { lng: 29.276384, lat: -3.293907 },
          { lng: 29.339998, lat: -4.499983 },
          { lng: 29.519987, lat: -5.419979 },
          { lng: 29.419993, lat: -5.939999 },
          { lng: 29.620032, lat: -6.520015 },
          { lng: 30.199997, lat: -7.079981 },
          { lng: 30.740015, lat: -8.340007 },
          { lng: 30.346086, lat: -8.238257 },
          { lng: 29.002912, lat: -8.407032 },
          { lng: 28.734867, lat: -8.526559 },
          { lng: 28.449871, lat: -9.164918 },
          { lng: 28.673682, lat: -9.605925 },
          { lng: 28.49607, lat: -10.789884 },
          { lng: 28.372253, lat: -11.793647 },
          { lng: 28.642417, lat: -11.971569 },
          { lng: 29.341548, lat: -12.360744 },
          { lng: 29.616001, lat: -12.178895 },
          { lng: 29.699614, lat: -13.257227 },
          { lng: 28.934286, lat: -13.248958 },
          { lng: 28.523562, lat: -12.698604 },
          { lng: 28.155109, lat: -12.272481 },
          { lng: 27.388799, lat: -12.132747 },
          { lng: 27.16442, lat: -11.608748 },
          { lng: 26.553088, lat: -11.92444 },
          { lng: 25.75231, lat: -11.784965 },
          { lng: 25.418118, lat: -11.330936 },
          { lng: 24.78317, lat: -11.238694 },
          { lng: 24.314516, lat: -11.262826 },
          { lng: 24.257155, lat: -10.951993 },
          { lng: 23.912215, lat: -10.926826 },
          { lng: 23.456791, lat: -10.867863 },
          { lng: 22.837345, lat: -11.017622 },
          { lng: 22.402798, lat: -10.993075 },
          { lng: 22.155268, lat: -11.084801 },
          { lng: 22.208753, lat: -9.894796 },
          { lng: 21.875182, lat: -9.523708 },
          { lng: 21.801801, lat: -8.908707 },
          { lng: 21.949131, lat: -8.305901 },
          { lng: 21.746456, lat: -7.920085 },
          { lng: 21.728111, lat: -7.290872 },
          { lng: 20.514748, lat: -7.299606 },
          { lng: 20.601823, lat: -6.939318 },
          { lng: 20.091622, lat: -6.94309 },
          { lng: 20.037723, lat: -7.116361 },
          { lng: 19.417502, lat: -7.155429 },
          { lng: 19.166613, lat: -7.738184 },
          { lng: 19.016752, lat: -7.988246 },
          { lng: 18.464176, lat: -7.847014 },
          { lng: 18.134222, lat: -7.987678 },
          { lng: 17.47297, lat: -8.068551 },
          { lng: 17.089996, lat: -7.545689 },
          { lng: 16.860191, lat: -7.222298 },
          { lng: 16.57318, lat: -6.622645 },
          { lng: 16.326528, lat: -5.87747 },
          { lng: 13.375597, lat: -5.864241 },
          { lng: 13.024869, lat: -5.984389 },
          { lng: 12.735171, lat: -5.965682 },
          { lng: 12.322432, lat: -6.100092 },
          { lng: 12.182337, lat: -5.789931 },
          { lng: 12.436688, lat: -5.684304 },
          { lng: 12.468004, lat: -5.248362 },
          { lng: 12.631612, lat: -4.991271 },
          { lng: 12.995517, lat: -4.781103 },
          { lng: 13.25824, lat: -4.882957 },
          { lng: 13.600235, lat: -4.500138 },
          { lng: 14.144956, lat: -4.510009 },
          { lng: 14.209035, lat: -4.793092 },
          { lng: 14.582604, lat: -4.970239 },
          { lng: 15.170992, lat: -4.343507 },
          { lng: 15.75354, lat: -3.855165 },
          { lng: 16.00629, lat: -3.535133 },
          { lng: 15.972803, lat: -2.712392 },
          { lng: 16.407092, lat: -1.740927 },
          { lng: 16.865307, lat: -1.225816 },
          { lng: 17.523716, lat: -0.74383 },
          { lng: 17.638645, lat: -0.424832 },
          { lng: 17.663553, lat: -0.058084 },
          { lng: 17.82654, lat: 0.288923 },
          { lng: 17.774192, lat: 0.855659 },
          { lng: 17.898835, lat: 1.741832 },
          { lng: 18.094276, lat: 2.365722 },
          { lng: 18.393792, lat: 2.900443 },
          { lng: 18.453065, lat: 3.504386 },
          { lng: 18.542982, lat: 4.201785 },
          { lng: 18.932312, lat: 4.709506 },
          { lng: 19.467784, lat: 5.031528 },
          { lng: 20.290679, lat: 4.691678 },
          { lng: 20.927591, lat: 4.322786 },
          { lng: 21.659123, lat: 4.224342 },
          { lng: 22.405124, lat: 4.02916 },
          { lng: 22.704124, lat: 4.633051 },
          { lng: 22.84148, lat: 4.710126 },
          { lng: 23.297214, lat: 4.609693 },
          { lng: 24.410531, lat: 5.108784 },
          { lng: 24.805029, lat: 4.897247 },
          { lng: 25.128833, lat: 4.927245 },
          { lng: 25.278798, lat: 5.170408 },
          { lng: 25.650455, lat: 5.256088 },
          { lng: 26.402761, lat: 5.150875 },
          { lng: 27.044065, lat: 5.127853 },
          { lng: 27.374226, lat: 5.233944 },
          { lng: 27.979977, lat: 4.408413 },
          { lng: 28.428994, lat: 4.287155 },
          { lng: 28.696678, lat: 4.455077 },
          { lng: 29.159078, lat: 4.389267 },
          { lng: 29.715995, lat: 4.600805 },
          { lng: 29.9535, lat: 4.173699 },
          { lng: 30.83386, lat: 3.509166 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'COG',
    properties: { name: 'Congo' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 12.995517, lat: -4.781103 },
          { lng: 12.62076, lat: -4.438023 },
          { lng: 12.318608, lat: -4.60623 },
          { lng: 11.914963, lat: -5.037987 },
          { lng: 11.093773, lat: -3.978827 },
          { lng: 11.855122, lat: -3.426871 },
          { lng: 11.478039, lat: -2.765619 },
          { lng: 11.820964, lat: -2.514161 },
          { lng: 12.495703, lat: -2.391688 },
          { lng: 12.575284, lat: -1.948511 },
          { lng: 13.109619, lat: -2.42874 },
          { lng: 13.992407, lat: -2.470805 },
          { lng: 14.29921, lat: -1.998276 },
          { lng: 14.425456, lat: -1.333407 },
          { lng: 14.316418, lat: -0.552627 },
          { lng: 13.843321, lat: 0.038758 },
          { lng: 14.276266, lat: 1.19693 },
          { lng: 14.026669, lat: 1.395677 },
          { lng: 13.282631, lat: 1.314184 },
          { lng: 13.003114, lat: 1.830896 },
          { lng: 13.075822, lat: 2.267097 },
          { lng: 14.337813, lat: 2.227875 },
          { lng: 15.146342, lat: 1.964015 },
          { lng: 15.940919, lat: 1.727673 },
          { lng: 16.012852, lat: 2.26764 },
          { lng: 16.537058, lat: 3.198255 },
          { lng: 17.133042, lat: 3.728197 },
          { lng: 17.8099, lat: 3.560196 },
          { lng: 18.453065, lat: 3.504386 },
          { lng: 18.393792, lat: 2.900443 },
          { lng: 18.094276, lat: 2.365722 },
          { lng: 17.898835, lat: 1.741832 },
          { lng: 17.774192, lat: 0.855659 },
          { lng: 17.82654, lat: 0.288923 },
          { lng: 17.663553, lat: -0.058084 },
          { lng: 17.638645, lat: -0.424832 },
          { lng: 17.523716, lat: -0.74383 },
          { lng: 16.865307, lat: -1.225816 },
          { lng: 16.407092, lat: -1.740927 },
          { lng: 15.972803, lat: -2.712392 },
          { lng: 16.00629, lat: -3.535133 },
          { lng: 15.75354, lat: -3.855165 },
          { lng: 15.170992, lat: -4.343507 },
          { lng: 14.582604, lat: -4.970239 },
          { lng: 14.209035, lat: -4.793092 },
          { lng: 14.144956, lat: -4.510009 },
          { lng: 13.600235, lat: -4.500138 },
          { lng: 13.25824, lat: -4.882957 },
          { lng: 12.995517, lat: -4.781103 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'COL',
    properties: { name: 'Colombia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -75.373223, lat: -0.152032 },
          { lng: -75.801466, lat: 0.084801 },
          { lng: -76.292314, lat: 0.416047 },
          { lng: -76.57638, lat: 0.256936 },
          { lng: -77.424984, lat: 0.395687 },
          { lng: -77.668613, lat: 0.825893 },
          { lng: -77.855061, lat: 0.809925 },
          { lng: -78.855259, lat: 1.380924 },
          { lng: -78.990935, lat: 1.69137 },
          { lng: -78.617831, lat: 1.766404 },
          { lng: -78.662118, lat: 2.267355 },
          { lng: -78.42761, lat: 2.629556 },
          { lng: -77.931543, lat: 2.696606 },
          { lng: -77.510431, lat: 3.325017 },
          { lng: -77.12769, lat: 3.849636 },
          { lng: -77.496272, lat: 4.087606 },
          { lng: -77.307601, lat: 4.667984 },
          { lng: -77.533221, lat: 5.582812 },
          { lng: -77.318815, lat: 5.845354 },
          { lng: -77.476661, lat: 6.691116 },
          { lng: -77.881571, lat: 7.223771 },
          { lng: -77.753414, lat: 7.70984 },
          { lng: -77.431108, lat: 7.638061 },
          { lng: -77.242566, lat: 7.935278 },
          { lng: -77.474723, lat: 8.524286 },
          { lng: -77.353361, lat: 8.670505 },
          { lng: -76.836674, lat: 8.638749 },
          { lng: -76.086384, lat: 9.336821 },
          { lng: -75.6746, lat: 9.443248 },
          { lng: -75.664704, lat: 9.774003 },
          { lng: -75.480426, lat: 10.61899 },
          { lng: -74.906895, lat: 11.083045 },
          { lng: -74.276753, lat: 11.102036 },
          { lng: -74.197223, lat: 11.310473 },
          { lng: -73.414764, lat: 11.227015 },
          { lng: -72.627835, lat: 11.731972 },
          { lng: -72.238195, lat: 11.95555 },
          { lng: -71.75409, lat: 12.437303 },
          { lng: -71.399822, lat: 12.376041 },
          { lng: -71.137461, lat: 12.112982 },
          { lng: -71.331584, lat: 11.776284 },
          { lng: -71.973922, lat: 11.608672 },
          { lng: -72.227575, lat: 11.108702 },
          { lng: -72.614658, lat: 10.821975 },
          { lng: -72.905286, lat: 10.450344 },
          { lng: -73.027604, lat: 9.73677 },
          { lng: -73.304952, lat: 9.152 },
          { lng: -72.78873, lat: 9.085027 },
          { lng: -72.660495, lat: 8.625288 },
          { lng: -72.439862, lat: 8.405275 },
          { lng: -72.360901, lat: 8.002638 },
          { lng: -72.479679, lat: 7.632506 },
          { lng: -72.444487, lat: 7.423785 },
          { lng: -72.198352, lat: 7.340431 },
          { lng: -71.960176, lat: 6.991615 },
          { lng: -70.674234, lat: 7.087785 },
          { lng: -70.093313, lat: 6.960376 },
          { lng: -69.38948, lat: 6.099861 },
          { lng: -68.985319, lat: 6.206805 },
          { lng: -68.265052, lat: 6.153268 },
          { lng: -67.695087, lat: 6.267318 },
          { lng: -67.34144, lat: 6.095468 },
          { lng: -67.521532, lat: 5.55687 },
          { lng: -67.744697, lat: 5.221129 },
          { lng: -67.823012, lat: 4.503937 },
          { lng: -67.621836, lat: 3.839482 },
          { lng: -67.337564, lat: 3.542342 },
          { lng: -67.303173, lat: 3.318454 },
          { lng: -67.809938, lat: 2.820655 },
          { lng: -67.447092, lat: 2.600281 },
          { lng: -67.181294, lat: 2.250638 },
          { lng: -66.876326, lat: 1.253361 },
          { lng: -67.065048, lat: 1.130112 },
          { lng: -67.259998, lat: 1.719999 },
          { lng: -67.53781, lat: 2.037163 },
          { lng: -67.868565, lat: 1.692455 },
          { lng: -69.816973, lat: 1.714805 },
          { lng: -69.804597, lat: 1.089081 },
          { lng: -69.218638, lat: 0.985677 },
          { lng: -69.252434, lat: 0.602651 },
          { lng: -69.452396, lat: 0.706159 },
          { lng: -70.015566, lat: 0.541414 },
          { lng: -70.020656, lat: -0.185156 },
          { lng: -69.577065, lat: -0.549992 },
          { lng: -69.420486, lat: -1.122619 },
          { lng: -69.444102, lat: -1.556287 },
          { lng: -69.893635, lat: -4.298187 },
          { lng: -70.394044, lat: -3.766591 },
          { lng: -70.692682, lat: -3.742872 },
          { lng: -70.047709, lat: -2.725156 },
          { lng: -70.813476, lat: -2.256865 },
          { lng: -71.413646, lat: -2.342802 },
          { lng: -71.774761, lat: -2.16979 },
          { lng: -72.325787, lat: -2.434218 },
          { lng: -73.070392, lat: -2.308954 },
          { lng: -73.659504, lat: -1.260491 },
          { lng: -74.122395, lat: -1.002833 },
          { lng: -74.441601, lat: -0.53082 },
          { lng: -75.106625, lat: -0.057205 },
          { lng: -75.373223, lat: -0.152032 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'CRI',
    properties: { name: 'Costa Rica' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -82.965783, lat: 8.225028 },
          { lng: -83.508437, lat: 8.446927 },
          { lng: -83.711474, lat: 8.656836 },
          { lng: -83.596313, lat: 8.830443 },
          { lng: -83.632642, lat: 9.051386 },
          { lng: -83.909886, lat: 9.290803 },
          { lng: -84.303402, lat: 9.487354 },
          { lng: -84.647644, lat: 9.615537 },
          { lng: -84.713351, lat: 9.908052 },
          { lng: -84.97566, lat: 10.086723 },
          { lng: -84.911375, lat: 9.795992 },
          { lng: -85.110923, lat: 9.55704 },
          { lng: -85.339488, lat: 9.834542 },
          { lng: -85.660787, lat: 9.933347 },
          { lng: -85.797445, lat: 10.134886 },
          { lng: -85.791709, lat: 10.439337 },
          { lng: -85.659314, lat: 10.754331 },
          { lng: -85.941725, lat: 10.895278 },
          { lng: -85.71254, lat: 11.088445 },
          { lng: -85.561852, lat: 11.217119 },
          { lng: -84.903003, lat: 10.952303 },
          { lng: -84.673069, lat: 11.082657 },
          { lng: -84.355931, lat: 10.999226 },
          { lng: -84.190179, lat: 10.79345 },
          { lng: -83.895054, lat: 10.726839 },
          { lng: -83.655612, lat: 10.938764 },
          { lng: -83.40232, lat: 10.395438 },
          { lng: -83.015677, lat: 9.992982 },
          { lng: -82.546196, lat: 9.566135 },
          { lng: -82.932891, lat: 9.476812 },
          { lng: -82.927155, lat: 9.07433 },
          { lng: -82.719183, lat: 8.925709 },
          { lng: -82.868657, lat: 8.807266 },
          { lng: -82.829771, lat: 8.626295 },
          { lng: -82.913176, lat: 8.423517 },
          { lng: -82.965783, lat: 8.225028 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'CUB',
    properties: { name: 'Cuba' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -82.268151, lat: 23.188611 },
          { lng: -81.404457, lat: 23.117271 },
          { lng: -80.618769, lat: 23.10598 },
          { lng: -79.679524, lat: 22.765303 },
          { lng: -79.281486, lat: 22.399202 },
          { lng: -78.347434, lat: 22.512166 },
          { lng: -77.993296, lat: 22.277194 },
          { lng: -77.146422, lat: 21.657851 },
          { lng: -76.523825, lat: 21.20682 },
          { lng: -76.19462, lat: 21.220565 },
          { lng: -75.598222, lat: 21.016624 },
          { lng: -75.67106, lat: 20.735091 },
          { lng: -74.933896, lat: 20.693905 },
          { lng: -74.178025, lat: 20.284628 },
          { lng: -74.296648, lat: 20.050379 },
          { lng: -74.961595, lat: 19.923435 },
          { lng: -75.63468, lat: 19.873774 },
          { lng: -76.323656, lat: 19.952891 },
          { lng: -77.755481, lat: 19.855481 },
          { lng: -77.085108, lat: 20.413354 },
          { lng: -77.492655, lat: 20.673105 },
          { lng: -78.137292, lat: 20.739949 },
          { lng: -78.482827, lat: 21.028613 },
          { lng: -78.719867, lat: 21.598114 },
          { lng: -79.285, lat: 21.559175 },
          { lng: -80.217475, lat: 21.827324 },
          { lng: -80.517535, lat: 22.037079 },
          { lng: -81.820943, lat: 22.192057 },
          { lng: -82.169992, lat: 22.387109 },
          { lng: -81.795002, lat: 22.636965 },
          { lng: -82.775898, lat: 22.68815 },
          { lng: -83.494459, lat: 22.168518 },
          { lng: -83.9088, lat: 22.154565 },
          { lng: -84.052151, lat: 21.910575 },
          { lng: -84.54703, lat: 21.801228 },
          { lng: -84.974911, lat: 21.896028 },
          { lng: -84.447062, lat: 22.20495 },
          { lng: -84.230357, lat: 22.565755 },
          { lng: -83.77824, lat: 22.788118 },
          { lng: -83.267548, lat: 22.983042 },
          { lng: -82.510436, lat: 23.078747 },
          { lng: -82.268151, lat: 23.188611 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: '-99',
    properties: { name: 'Northern Cyprus' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 32.73178, lat: 35.140026 },
          { lng: 32.802474, lat: 35.145504 },
          { lng: 32.946961, lat: 35.386703 },
          { lng: 33.667227, lat: 35.373216 },
          { lng: 34.576474, lat: 35.671596 },
          { lng: 33.900804, lat: 35.245756 },
          { lng: 33.973617, lat: 35.058506 },
          { lng: 33.86644, lat: 35.093595 },
          { lng: 33.675392, lat: 35.017863 },
          { lng: 33.525685, lat: 35.038688 },
          { lng: 33.475817, lat: 35.000345 },
          { lng: 33.455922, lat: 35.101424 },
          { lng: 33.383833, lat: 35.162712 },
          { lng: 33.190977, lat: 35.173125 },
          { lng: 32.919572, lat: 35.087833 },
          { lng: 32.73178, lat: 35.140026 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'CYP',
    properties: { name: 'Cyprus' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 33.973617, lat: 35.058506 },
          { lng: 34.004881, lat: 34.978098 },
          { lng: 32.979827, lat: 34.571869 },
          { lng: 32.490296, lat: 34.701655 },
          { lng: 32.256667, lat: 35.103232 },
          { lng: 32.73178, lat: 35.140026 },
          { lng: 32.919572, lat: 35.087833 },
          { lng: 33.190977, lat: 35.173125 },
          { lng: 33.383833, lat: 35.162712 },
          { lng: 33.455922, lat: 35.101424 },
          { lng: 33.475817, lat: 35.000345 },
          { lng: 33.525685, lat: 35.038688 },
          { lng: 33.675392, lat: 35.017863 },
          { lng: 33.86644, lat: 35.093595 },
          { lng: 33.973617, lat: 35.058506 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'CZE',
    properties: { name: 'Czech Republic' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 16.960288, lat: 48.596982 },
          { lng: 16.499283, lat: 48.785808 },
          { lng: 16.029647, lat: 48.733899 },
          { lng: 15.253416, lat: 49.039074 },
          { lng: 14.901447, lat: 48.964402 },
          { lng: 14.338898, lat: 48.555305 },
          { lng: 13.595946, lat: 48.877172 },
          { lng: 13.031329, lat: 49.307068 },
          { lng: 12.521024, lat: 49.547415 },
          { lng: 12.415191, lat: 49.969121 },
          { lng: 12.240111, lat: 50.266338 },
          { lng: 12.966837, lat: 50.484076 },
          { lng: 13.338132, lat: 50.733234 },
          { lng: 14.056228, lat: 50.926918 },
          { lng: 14.307013, lat: 51.117268 },
          { lng: 14.570718, lat: 51.002339 },
          { lng: 15.016996, lat: 51.106674 },
          { lng: 15.490972, lat: 50.78473 },
          { lng: 16.238627, lat: 50.697733 },
          { lng: 16.176253, lat: 50.422607 },
          { lng: 16.719476, lat: 50.215747 },
          { lng: 16.868769, lat: 50.473974 },
          { lng: 17.554567, lat: 50.362146 },
          { lng: 17.649445, lat: 50.049038 },
          { lng: 18.392914, lat: 49.988629 },
          { lng: 18.853144, lat: 49.49623 },
          { lng: 18.554971, lat: 49.495015 },
          { lng: 18.399994, lat: 49.315001 },
          { lng: 18.170498, lat: 49.271515 },
          { lng: 18.104973, lat: 49.043983 },
          { lng: 17.913512, lat: 48.996493 },
          { lng: 17.886485, lat: 48.903475 },
          { lng: 17.545007, lat: 48.800019 },
          { lng: 17.101985, lat: 48.816969 },
          { lng: 16.960288, lat: 48.596982 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'DEU',
    properties: { name: 'Germany' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 9.921906, lat: 54.983104 },
          { lng: 9.93958, lat: 54.596642 },
          { lng: 10.950112, lat: 54.363607 },
          { lng: 10.939467, lat: 54.008693 },
          { lng: 11.956252, lat: 54.196486 },
          { lng: 12.51844, lat: 54.470371 },
          { lng: 13.647467, lat: 54.075511 },
          { lng: 14.119686, lat: 53.757029 },
          { lng: 14.353315, lat: 53.248171 },
          { lng: 14.074521, lat: 52.981263 },
          { lng: 14.4376, lat: 52.62485 },
          { lng: 14.685026, lat: 52.089947 },
          { lng: 14.607098, lat: 51.745188 },
          { lng: 15.016996, lat: 51.106674 },
          { lng: 14.570718, lat: 51.002339 },
          { lng: 14.307013, lat: 51.117268 },
          { lng: 14.056228, lat: 50.926918 },
          { lng: 13.338132, lat: 50.733234 },
          { lng: 12.966837, lat: 50.484076 },
          { lng: 12.240111, lat: 50.266338 },
          { lng: 12.415191, lat: 49.969121 },
          { lng: 12.521024, lat: 49.547415 },
          { lng: 13.031329, lat: 49.307068 },
          { lng: 13.595946, lat: 48.877172 },
          { lng: 13.243357, lat: 48.416115 },
          { lng: 12.884103, lat: 48.289146 },
          { lng: 13.025851, lat: 47.637584 },
          { lng: 12.932627, lat: 47.467646 },
          { lng: 12.62076, lat: 47.672388 },
          { lng: 12.141357, lat: 47.703083 },
          { lng: 11.426414, lat: 47.523766 },
          { lng: 10.544504, lat: 47.566399 },
          { lng: 10.402084, lat: 47.302488 },
          { lng: 9.896068, lat: 47.580197 },
          { lng: 9.594226, lat: 47.525058 },
          { lng: 8.522612, lat: 47.830828 },
          { lng: 8.317301, lat: 47.61358 },
          { lng: 7.466759, lat: 47.620582 },
          { lng: 7.593676, lat: 48.333019 },
          { lng: 8.099279, lat: 49.017784 },
          { lng: 6.65823, lat: 49.201958 },
          { lng: 6.18632, lat: 49.463803 },
          { lng: 6.242751, lat: 49.902226 },
          { lng: 6.043073, lat: 50.128052 },
          { lng: 6.156658, lat: 50.803721 },
          { lng: 5.988658, lat: 51.851616 },
          { lng: 6.589397, lat: 51.852029 },
          { lng: 6.84287, lat: 52.22844 },
          { lng: 7.092053, lat: 53.144043 },
          { lng: 6.90514, lat: 53.482162 },
          { lng: 7.100425, lat: 53.693932 },
          { lng: 7.936239, lat: 53.748296 },
          { lng: 8.121706, lat: 53.527792 },
          { lng: 8.800734, lat: 54.020786 },
          { lng: 8.572118, lat: 54.395646 },
          { lng: 8.526229, lat: 54.962744 },
          { lng: 9.282049, lat: 54.830865 },
          { lng: 9.921906, lat: 54.983104 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'DJI',
    properties: { name: 'Djibouti' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 43.081226, lat: 12.699639 },
          { lng: 43.317852, lat: 12.390148 },
          { lng: 43.286381, lat: 11.974928 },
          { lng: 42.715874, lat: 11.735641 },
          { lng: 43.145305, lat: 11.46204 },
          { lng: 42.776852, lat: 10.926879 },
          { lng: 42.55493, lat: 11.10511 },
          { lng: 42.31414, lat: 11.0342 },
          { lng: 41.75557, lat: 11.05091 },
          { lng: 41.73959, lat: 11.35511 },
          { lng: 41.66176, lat: 11.6312 },
          { lng: 42, lat: 12.1 },
          { lng: 42.35156, lat: 12.54223 },
          { lng: 42.779642, lat: 12.455416 },
          { lng: 43.081226, lat: 12.699639 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'DNK',
    properties: { name: 'Denmark' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 12.690006, lat: 55.609991 },
          { lng: 12.089991, lat: 54.800015 },
          { lng: 11.043543, lat: 55.364864 },
          { lng: 10.903914, lat: 55.779955 },
          { lng: 12.370904, lat: 56.111407 },
          { lng: 12.690006, lat: 55.609991 },
        ],
        [
          { lng: 10.912182, lat: 56.458621 },
          { lng: 10.667804, lat: 56.081383 },
          { lng: 10.369993, lat: 56.190007 },
          { lng: 9.649985, lat: 55.469999 },
          { lng: 9.921906, lat: 54.983104 },
          { lng: 9.282049, lat: 54.830865 },
          { lng: 8.526229, lat: 54.962744 },
          { lng: 8.120311, lat: 55.517723 },
          { lng: 8.089977, lat: 56.540012 },
          { lng: 8.256582, lat: 56.809969 },
          { lng: 8.543438, lat: 57.110003 },
          { lng: 9.424469, lat: 57.172066 },
          { lng: 9.775559, lat: 57.447941 },
          { lng: 10.580006, lat: 57.730017 },
          { lng: 10.546106, lat: 57.215733 },
          { lng: 10.25, lat: 56.890016 },
          { lng: 10.369993, lat: 56.609982 },
          { lng: 10.912182, lat: 56.458621 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'DOM',
    properties: { name: 'Dominican Republic' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -71.712361, lat: 19.714456 },
          { lng: -71.587304, lat: 19.884911 },
          { lng: -70.806706, lat: 19.880286 },
          { lng: -70.214365, lat: 19.622885 },
          { lng: -69.950815, lat: 19.648 },
          { lng: -69.76925, lat: 19.293267 },
          { lng: -69.222126, lat: 19.313214 },
          { lng: -69.254346, lat: 19.015196 },
          { lng: -68.809412, lat: 18.979074 },
          { lng: -68.317943, lat: 18.612198 },
          { lng: -68.689316, lat: 18.205142 },
          { lng: -69.164946, lat: 18.422648 },
          { lng: -69.623988, lat: 18.380713 },
          { lng: -69.952934, lat: 18.428307 },
          { lng: -70.133233, lat: 18.245915 },
          { lng: -70.517137, lat: 18.184291 },
          { lng: -70.669298, lat: 18.426886 },
          { lng: -70.99995, lat: 18.283329 },
          { lng: -71.40021, lat: 17.598564 },
          { lng: -71.657662, lat: 17.757573 },
          { lng: -71.708305, lat: 18.044997 },
          { lng: -71.687738, lat: 18.31666 },
          { lng: -71.945112, lat: 18.6169 },
          { lng: -71.701303, lat: 18.785417 },
          { lng: -71.624873, lat: 19.169838 },
          { lng: -71.712361, lat: 19.714456 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'DZA',
    properties: { name: 'Algeria' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 11.999506, lat: 23.471668 },
          { lng: 8.572893, lat: 21.565661 },
          { lng: 5.677566, lat: 19.601207 },
          { lng: 4.267419, lat: 19.155265 },
          { lng: 3.158133, lat: 19.057364 },
          { lng: 3.146661, lat: 19.693579 },
          { lng: 2.683588, lat: 19.85623 },
          { lng: 2.060991, lat: 20.142233 },
          { lng: 1.823228, lat: 20.610809 },
          { lng: -1.550055, lat: 22.792666 },
          { lng: -4.923337, lat: 24.974574 },
          { lng: -8.6844, lat: 27.395744 },
          { lng: -8.665124, lat: 27.589479 },
          { lng: -8.66559, lat: 27.656426 },
          { lng: -8.674116, lat: 28.841289 },
          { lng: -7.059228, lat: 29.579228 },
          { lng: -6.060632, lat: 29.7317 },
          { lng: -5.242129, lat: 30.000443 },
          { lng: -4.859646, lat: 30.501188 },
          { lng: -3.690441, lat: 30.896952 },
          { lng: -3.647498, lat: 31.637294 },
          { lng: -3.06898, lat: 31.724498 },
          { lng: -2.616605, lat: 32.094346 },
          { lng: -1.307899, lat: 32.262889 },
          { lng: -1.124551, lat: 32.651522 },
          { lng: -1.388049, lat: 32.864015 },
          { lng: -1.733455, lat: 33.919713 },
          { lng: -1.792986, lat: 34.527919 },
          { lng: -2.169914, lat: 35.168396 },
          { lng: -1.208603, lat: 35.714849 },
          { lng: -0.127454, lat: 35.888662 },
          { lng: 0.503877, lat: 36.301273 },
          { lng: 1.466919, lat: 36.605647 },
          { lng: 3.161699, lat: 36.783905 },
          { lng: 4.815758, lat: 36.865037 },
          { lng: 5.32012, lat: 36.716519 },
          { lng: 6.26182, lat: 37.110655 },
          { lng: 7.330385, lat: 37.118381 },
          { lng: 7.737078, lat: 36.885708 },
          { lng: 8.420964, lat: 36.946427 },
          { lng: 8.217824, lat: 36.433177 },
          { lng: 8.376368, lat: 35.479876 },
          { lng: 8.140981, lat: 34.655146 },
          { lng: 7.524482, lat: 34.097376 },
          { lng: 7.612642, lat: 33.344115 },
          { lng: 8.430473, lat: 32.748337 },
          { lng: 8.439103, lat: 32.506285 },
          { lng: 9.055603, lat: 32.102692 },
          { lng: 9.48214, lat: 30.307556 },
          { lng: 9.805634, lat: 29.424638 },
          { lng: 9.859998, lat: 28.95999 },
          { lng: 9.683885, lat: 28.144174 },
          { lng: 9.756128, lat: 27.688259 },
          { lng: 9.629056, lat: 27.140953 },
          { lng: 9.716286, lat: 26.512206 },
          { lng: 9.319411, lat: 26.094325 },
          { lng: 9.910693, lat: 25.365455 },
          { lng: 9.948261, lat: 24.936954 },
          { lng: 10.303847, lat: 24.379313 },
          { lng: 10.771364, lat: 24.562532 },
          { lng: 11.560669, lat: 24.097909 },
          { lng: 11.999506, lat: 23.471668 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ECU',
    properties: { name: 'Ecuador' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -80.302561, lat: -3.404856 },
          { lng: -79.770293, lat: -2.657512 },
          { lng: -79.986559, lat: -2.220794 },
          { lng: -80.368784, lat: -2.685159 },
          { lng: -80.967765, lat: -2.246943 },
          { lng: -80.764806, lat: -1.965048 },
          { lng: -80.933659, lat: -1.057455 },
          { lng: -80.58337, lat: -0.906663 },
          { lng: -80.399325, lat: -0.283703 },
          { lng: -80.020898, lat: 0.36034 },
          { lng: -80.09061, lat: 0.768429 },
          { lng: -79.542762, lat: 0.982938 },
          { lng: -78.855259, lat: 1.380924 },
          { lng: -77.855061, lat: 0.809925 },
          { lng: -77.668613, lat: 0.825893 },
          { lng: -77.424984, lat: 0.395687 },
          { lng: -76.57638, lat: 0.256936 },
          { lng: -76.292314, lat: 0.416047 },
          { lng: -75.801466, lat: 0.084801 },
          { lng: -75.373223, lat: -0.152032 },
          { lng: -75.233723, lat: -0.911417 },
          { lng: -75.544996, lat: -1.56161 },
          { lng: -76.635394, lat: -2.608678 },
          { lng: -77.837905, lat: -3.003021 },
          { lng: -78.450684, lat: -3.873097 },
          { lng: -78.639897, lat: -4.547784 },
          { lng: -79.205289, lat: -4.959129 },
          { lng: -79.624979, lat: -4.454198 },
          { lng: -80.028908, lat: -4.346091 },
          { lng: -80.442242, lat: -4.425724 },
          { lng: -80.469295, lat: -4.059287 },
          { lng: -80.184015, lat: -3.821162 },
          { lng: -80.302561, lat: -3.404856 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'EGY',
    properties: { name: 'Egypt' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 34.9226, lat: 29.50133 },
          { lng: 34.64174, lat: 29.09942 },
          { lng: 34.42655, lat: 28.34399 },
          { lng: 34.15451, lat: 27.8233 },
          { lng: 33.92136, lat: 27.6487 },
          { lng: 33.58811, lat: 27.97136 },
          { lng: 33.13676, lat: 28.41765 },
          { lng: 32.42323, lat: 29.85108 },
          { lng: 32.32046, lat: 29.76043 },
          { lng: 32.73482, lat: 28.70523 },
          { lng: 33.34876, lat: 27.69989 },
          { lng: 34.10455, lat: 26.14227 },
          { lng: 34.47387, lat: 25.59856 },
          { lng: 34.79507, lat: 25.03375 },
          { lng: 35.69241, lat: 23.92671 },
          { lng: 35.49372, lat: 23.75237 },
          { lng: 35.52598, lat: 23.10244 },
          { lng: 36.69069, lat: 22.20485 },
          { lng: 36.86623, lat: 22 },
          { lng: 32.9, lat: 22 },
          { lng: 29.02, lat: 22 },
          { lng: 25, lat: 22 },
          { lng: 25, lat: 25.6825 },
          { lng: 25, lat: 29.238655 },
          { lng: 24.70007, lat: 30.04419 },
          { lng: 24.95762, lat: 30.6616 },
          { lng: 24.80287, lat: 31.08929 },
          { lng: 25.16482, lat: 31.56915 },
          { lng: 26.49533, lat: 31.58568 },
          { lng: 27.45762, lat: 31.32126 },
          { lng: 28.45048, lat: 31.02577 },
          { lng: 28.91353, lat: 30.87005 },
          { lng: 29.68342, lat: 31.18686 },
          { lng: 30.09503, lat: 31.4734 },
          { lng: 30.97693, lat: 31.55586 },
          { lng: 31.68796, lat: 31.4296 },
          { lng: 31.96041, lat: 30.9336 },
          { lng: 32.19247, lat: 31.26034 },
          { lng: 32.99392, lat: 31.02407 },
          { lng: 33.7734, lat: 30.96746 },
          { lng: 34.26544, lat: 31.21936 },
          { lng: 34.9226, lat: 29.50133 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ERI',
    properties: { name: 'Eritrea' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 42.35156, lat: 12.54223 },
          { lng: 42.00975, lat: 12.86582 },
          { lng: 41.59856, lat: 13.45209 },
          { lng: 41.155194, lat: 13.77332 },
          { lng: 40.8966, lat: 14.11864 },
          { lng: 40.026219, lat: 14.519579 },
          { lng: 39.34061, lat: 14.53155 },
          { lng: 39.0994, lat: 14.74064 },
          { lng: 38.51295, lat: 14.50547 },
          { lng: 37.90607, lat: 14.95943 },
          { lng: 37.59377, lat: 14.2131 },
          { lng: 36.42951, lat: 14.42211 },
          { lng: 36.323189, lat: 14.822481 },
          { lng: 36.75386, lat: 16.291874 },
          { lng: 36.85253, lat: 16.95655 },
          { lng: 37.16747, lat: 17.26314 },
          { lng: 37.904, lat: 17.42754 },
          { lng: 38.41009, lat: 17.998307 },
          { lng: 38.990623, lat: 16.840626 },
          { lng: 39.26611, lat: 15.922723 },
          { lng: 39.814294, lat: 15.435647 },
          { lng: 41.179275, lat: 14.49108 },
          { lng: 41.734952, lat: 13.921037 },
          { lng: 42.276831, lat: 13.343992 },
          { lng: 42.589576, lat: 13.000421 },
          { lng: 43.081226, lat: 12.699639 },
          { lng: 42.779642, lat: 12.455416 },
          { lng: 42.35156, lat: 12.54223 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ESP',
    properties: { name: 'Spain' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -9.034818, lat: 41.880571 },
          { lng: -8.984433, lat: 42.592775 },
          { lng: -9.392884, lat: 43.026625 },
          { lng: -7.97819, lat: 43.748338 },
          { lng: -6.754492, lat: 43.567909 },
          { lng: -5.411886, lat: 43.57424 },
          { lng: -4.347843, lat: 43.403449 },
          { lng: -3.517532, lat: 43.455901 },
          { lng: -1.901351, lat: 43.422802 },
          { lng: -1.502771, lat: 43.034014 },
          { lng: 0.338047, lat: 42.579546 },
          { lng: 0.701591, lat: 42.795734 },
          { lng: 1.826793, lat: 42.343385 },
          { lng: 2.985999, lat: 42.473015 },
          { lng: 3.039484, lat: 41.89212 },
          { lng: 2.091842, lat: 41.226089 },
          { lng: 0.810525, lat: 41.014732 },
          { lng: 0.721331, lat: 40.678318 },
          { lng: 0.106692, lat: 40.123934 },
          { lng: -0.278711, lat: 39.309978 },
          { lng: 0.111291, lat: 38.738514 },
          { lng: -0.467124, lat: 38.292366 },
          { lng: -0.683389, lat: 37.642354 },
          { lng: -1.438382, lat: 37.443064 },
          { lng: -2.146453, lat: 36.674144 },
          { lng: -3.415781, lat: 36.6589 },
          { lng: -4.368901, lat: 36.677839 },
          { lng: -4.995219, lat: 36.324708 },
          { lng: -5.37716, lat: 35.94685 },
          { lng: -5.866432, lat: 36.029817 },
          { lng: -6.236694, lat: 36.367677 },
          { lng: -6.520191, lat: 36.942913 },
          { lng: -7.453726, lat: 37.097788 },
          { lng: -7.537105, lat: 37.428904 },
          { lng: -7.166508, lat: 37.803894 },
          { lng: -7.029281, lat: 38.075764 },
          { lng: -7.374092, lat: 38.373059 },
          { lng: -7.098037, lat: 39.030073 },
          { lng: -7.498632, lat: 39.629571 },
          { lng: -7.066592, lat: 39.711892 },
          { lng: -7.026413, lat: 40.184524 },
          { lng: -6.86402, lat: 40.330872 },
          { lng: -6.851127, lat: 41.111083 },
          { lng: -6.389088, lat: 41.381815 },
          { lng: -6.668606, lat: 41.883387 },
          { lng: -7.251309, lat: 41.918346 },
          { lng: -7.422513, lat: 41.792075 },
          { lng: -8.013175, lat: 41.790886 },
          { lng: -8.263857, lat: 42.280469 },
          { lng: -8.671946, lat: 42.134689 },
          { lng: -9.034818, lat: 41.880571 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'EST',
    properties: { name: 'Estonia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 24.312863, lat: 57.793424 },
          { lng: 24.428928, lat: 58.383413 },
          { lng: 24.061198, lat: 58.257375 },
          { lng: 23.42656, lat: 58.612753 },
          { lng: 23.339795, lat: 59.18724 },
          { lng: 24.604214, lat: 59.465854 },
          { lng: 25.864189, lat: 59.61109 },
          { lng: 26.949136, lat: 59.445803 },
          { lng: 27.981114, lat: 59.475388 },
          { lng: 28.131699, lat: 59.300825 },
          { lng: 27.420166, lat: 58.724581 },
          { lng: 27.716686, lat: 57.791899 },
          { lng: 27.288185, lat: 57.474528 },
          { lng: 26.463532, lat: 57.476389 },
          { lng: 25.60281, lat: 57.847529 },
          { lng: 25.164594, lat: 57.970157 },
          { lng: 24.312863, lat: 57.793424 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ETH',
    properties: { name: 'Ethiopia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 37.90607, lat: 14.95943 },
          { lng: 38.51295, lat: 14.50547 },
          { lng: 39.0994, lat: 14.74064 },
          { lng: 39.34061, lat: 14.53155 },
          { lng: 40.02625, lat: 14.51959 },
          { lng: 40.8966, lat: 14.11864 },
          { lng: 41.1552, lat: 13.77333 },
          { lng: 41.59856, lat: 13.45209 },
          { lng: 42.00975, lat: 12.86582 },
          { lng: 42.35156, lat: 12.54223 },
          { lng: 42, lat: 12.1 },
          { lng: 41.66176, lat: 11.6312 },
          { lng: 41.73959, lat: 11.35511 },
          { lng: 41.75557, lat: 11.05091 },
          { lng: 42.31414, lat: 11.0342 },
          { lng: 42.55493, lat: 11.10511 },
          { lng: 42.776852, lat: 10.926879 },
          { lng: 42.55876, lat: 10.57258 },
          { lng: 42.92812, lat: 10.02194 },
          { lng: 43.29699, lat: 9.54048 },
          { lng: 43.67875, lat: 9.18358 },
          { lng: 46.94834, lat: 7.99688 },
          { lng: 47.78942, lat: 8.003 },
          { lng: 44.9636, lat: 5.00162 },
          { lng: 43.66087, lat: 4.95755 },
          { lng: 42.76967, lat: 4.25259 },
          { lng: 42.12861, lat: 4.23413 },
          { lng: 41.855083, lat: 3.918912 },
          { lng: 41.1718, lat: 3.91909 },
          { lng: 40.76848, lat: 4.25702 },
          { lng: 39.85494, lat: 3.83879 },
          { lng: 39.559384, lat: 3.42206 },
          { lng: 38.89251, lat: 3.50074 },
          { lng: 38.67114, lat: 3.61607 },
          { lng: 38.43697, lat: 3.58851 },
          { lng: 38.120915, lat: 3.598605 },
          { lng: 36.855093, lat: 4.447864 },
          { lng: 36.159079, lat: 4.447864 },
          { lng: 35.817448, lat: 4.776966 },
          { lng: 35.817448, lat: 5.338232 },
          { lng: 35.298007, lat: 5.506 },
          { lng: 34.70702, lat: 6.59422 },
          { lng: 34.25032, lat: 6.82607 },
          { lng: 34.0751, lat: 7.22595 },
          { lng: 33.56829, lat: 7.71334 },
          { lng: 32.95418, lat: 7.78497 },
          { lng: 33.2948, lat: 8.35458 },
          { lng: 33.8255, lat: 8.37916 },
          { lng: 33.97498, lat: 8.68456 },
          { lng: 33.96162, lat: 9.58358 },
          { lng: 34.25745, lat: 10.63009 },
          { lng: 34.73115, lat: 10.91017 },
          { lng: 34.83163, lat: 11.31896 },
          { lng: 35.26049, lat: 12.08286 },
          { lng: 35.86363, lat: 12.57828 },
          { lng: 36.27022, lat: 13.56333 },
          { lng: 36.42951, lat: 14.42211 },
          { lng: 37.59377, lat: 14.2131 },
          { lng: 37.90607, lat: 14.95943 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'FIN',
    properties: { name: 'Finland' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 28.59193, lat: 69.064777 },
          { lng: 28.445944, lat: 68.364613 },
          { lng: 29.977426, lat: 67.698297 },
          { lng: 29.054589, lat: 66.944286 },
          { lng: 30.21765, lat: 65.80598 },
          { lng: 29.54443, lat: 64.948672 },
          { lng: 30.444685, lat: 64.204453 },
          { lng: 30.035872, lat: 63.552814 },
          { lng: 31.516092, lat: 62.867687 },
          { lng: 31.139991, lat: 62.357693 },
          { lng: 30.211107, lat: 61.780028 },
          { lng: 28.069998, lat: 60.503517 },
          { lng: 26.255173, lat: 60.423961 },
          { lng: 24.496624, lat: 60.057316 },
          { lng: 22.869695, lat: 59.846373 },
          { lng: 22.290764, lat: 60.391921 },
          { lng: 21.322244, lat: 60.72017 },
          { lng: 21.544866, lat: 61.705329 },
          { lng: 21.059211, lat: 62.607393 },
          { lng: 21.536029, lat: 63.189735 },
          { lng: 22.442744, lat: 63.81781 },
          { lng: 24.730512, lat: 64.902344 },
          { lng: 25.398068, lat: 65.111427 },
          { lng: 25.294043, lat: 65.534346 },
          { lng: 23.903379, lat: 66.006927 },
          { lng: 23.56588, lat: 66.396051 },
          { lng: 23.539473, lat: 67.936009 },
          { lng: 21.978535, lat: 68.616846 },
          { lng: 20.645593, lat: 69.106247 },
          { lng: 21.244936, lat: 69.370443 },
          { lng: 22.356238, lat: 68.841741 },
          { lng: 23.66205, lat: 68.891247 },
          { lng: 24.735679, lat: 68.649557 },
          { lng: 25.689213, lat: 69.092114 },
          { lng: 26.179622, lat: 69.825299 },
          { lng: 27.732292, lat: 70.164193 },
          { lng: 29.015573, lat: 69.766491 },
          { lng: 28.59193, lat: 69.064777 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'FJI',
    properties: { name: 'Fiji' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 178.3736, lat: -17.33992 },
          { lng: 178.71806, lat: -17.62846 },
          { lng: 178.55271, lat: -18.15059 },
          { lng: 177.93266, lat: -18.28799 },
          { lng: 177.38146, lat: -18.16432 },
          { lng: 177.28504, lat: -17.72465 },
          { lng: 177.67087, lat: -17.38114 },
          { lng: 178.12557, lat: -17.50481 },
          { lng: 178.3736, lat: -17.33992 },
        ],
        [
          { lng: 179.364143, lat: -16.801354 },
          { lng: 178.725059, lat: -17.012042 },
          { lng: 178.596839, lat: -16.63915 },
          { lng: 179.096609, lat: -16.433984 },
          { lng: 179.413509, lat: -16.379054 },
          { lng: 180, lat: -16.067133 },
          { lng: 180, lat: -16.555217 },
          { lng: 179.364143, lat: -16.801354 },
        ],
        [
          { lng: -179.917369, lat: -16.501783 },
          { lng: -180, lat: -16.555217 },
          { lng: -180, lat: -16.067133 },
          { lng: -179.79332, lat: -16.020882 },
          { lng: -179.917369, lat: -16.501783 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'FLK',
    properties: { name: 'Falkland Islands' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -61.2, lat: -51.85 },
          { lng: -60, lat: -51.25 },
          { lng: -59.15, lat: -51.5 },
          { lng: -58.55, lat: -51.1 },
          { lng: -57.75, lat: -51.55 },
          { lng: -58.05, lat: -51.9 },
          { lng: -59.4, lat: -52.2 },
          { lng: -59.85, lat: -51.85 },
          { lng: -60.7, lat: -52.3 },
          { lng: -61.2, lat: -51.85 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'FRA',
    properties: { name: 'France' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 9.560016, lat: 42.152492 },
          { lng: 9.229752, lat: 41.380007 },
          { lng: 8.775723, lat: 41.583612 },
          { lng: 8.544213, lat: 42.256517 },
          { lng: 8.746009, lat: 42.628122 },
          { lng: 9.390001, lat: 43.009985 },
          { lng: 9.560016, lat: 42.152492 },
        ],
        [
          { lng: 3.588184, lat: 50.378992 },
          { lng: 4.286023, lat: 49.907497 },
          { lng: 4.799222, lat: 49.985373 },
          { lng: 5.674052, lat: 49.529484 },
          { lng: 5.897759, lat: 49.442667 },
          { lng: 6.18632, lat: 49.463803 },
          { lng: 6.65823, lat: 49.201958 },
          { lng: 8.099279, lat: 49.017784 },
          { lng: 7.593676, lat: 48.333019 },
          { lng: 7.466759, lat: 47.620582 },
          { lng: 7.192202, lat: 47.449766 },
          { lng: 6.736571, lat: 47.541801 },
          { lng: 6.768714, lat: 47.287708 },
          { lng: 6.037389, lat: 46.725779 },
          { lng: 6.022609, lat: 46.27299 },
          { lng: 6.5001, lat: 46.429673 },
          { lng: 6.843593, lat: 45.991147 },
          { lng: 6.802355, lat: 45.70858 },
          { lng: 7.096652, lat: 45.333099 },
          { lng: 6.749955, lat: 45.028518 },
          { lng: 7.007562, lat: 44.254767 },
          { lng: 7.549596, lat: 44.127901 },
          { lng: 7.435185, lat: 43.693845 },
          { lng: 6.529245, lat: 43.128892 },
          { lng: 4.556963, lat: 43.399651 },
          { lng: 3.100411, lat: 43.075201 },
          { lng: 2.985999, lat: 42.473015 },
          { lng: 1.826793, lat: 42.343385 },
          { lng: 0.701591, lat: 42.795734 },
          { lng: 0.338047, lat: 42.579546 },
          { lng: -1.502771, lat: 43.034014 },
          { lng: -1.901351, lat: 43.422802 },
          { lng: -1.384225, lat: 44.02261 },
          { lng: -1.193798, lat: 46.014918 },
          { lng: -2.225724, lat: 47.064363 },
          { lng: -2.963276, lat: 47.570327 },
          { lng: -4.491555, lat: 47.954954 },
          { lng: -4.59235, lat: 48.68416 },
          { lng: -3.295814, lat: 48.901692 },
          { lng: -1.616511, lat: 48.644421 },
          { lng: -1.933494, lat: 49.776342 },
          { lng: -0.989469, lat: 49.347376 },
          { lng: 1.338761, lat: 50.127173 },
          { lng: 1.639001, lat: 50.946606 },
          { lng: 2.513573, lat: 51.148506 },
          { lng: 2.658422, lat: 50.796848 },
          { lng: 3.123252, lat: 50.780363 },
          { lng: 3.588184, lat: 50.378992 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'GAB',
    properties: { name: 'Gabon' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 11.093773, lat: -3.978827 },
          { lng: 10.066135, lat: -2.969483 },
          { lng: 9.405245, lat: -2.144313 },
          { lng: 8.797996, lat: -1.111301 },
          { lng: 8.830087, lat: -0.779074 },
          { lng: 9.04842, lat: -0.459351 },
          { lng: 9.291351, lat: 0.268666 },
          { lng: 9.492889, lat: 1.01012 },
          { lng: 9.830284, lat: 1.067894 },
          { lng: 11.285079, lat: 1.057662 },
          { lng: 11.276449, lat: 2.261051 },
          { lng: 11.751665, lat: 2.326758 },
          { lng: 12.35938, lat: 2.192812 },
          { lng: 12.951334, lat: 2.321616 },
          { lng: 13.075822, lat: 2.267097 },
          { lng: 13.003114, lat: 1.830896 },
          { lng: 13.282631, lat: 1.314184 },
          { lng: 14.026669, lat: 1.395677 },
          { lng: 14.276266, lat: 1.19693 },
          { lng: 13.843321, lat: 0.038758 },
          { lng: 14.316418, lat: -0.552627 },
          { lng: 14.425456, lat: -1.333407 },
          { lng: 14.29921, lat: -1.998276 },
          { lng: 13.992407, lat: -2.470805 },
          { lng: 13.109619, lat: -2.42874 },
          { lng: 12.575284, lat: -1.948511 },
          { lng: 12.495703, lat: -2.391688 },
          { lng: 11.820964, lat: -2.514161 },
          { lng: 11.478039, lat: -2.765619 },
          { lng: 11.855122, lat: -3.426871 },
          { lng: 11.093773, lat: -3.978827 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'GBR',
    properties: { name: 'United Kingdom' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: -5.661949, lat: 54.554603 },
          { lng: -6.197885, lat: 53.867565 },
          { lng: -6.95373, lat: 54.073702 },
          { lng: -7.572168, lat: 54.059956 },
          { lng: -7.366031, lat: 54.595841 },
          { lng: -7.572168, lat: 55.131622 },
          { lng: -6.733847, lat: 55.17286 },
          { lng: -5.661949, lat: 54.554603 },
        ],
        [
          { lng: -3.005005, lat: 58.635 },
          { lng: -4.073828, lat: 57.553025 },
          { lng: -3.055002, lat: 57.690019 },
          { lng: -1.959281, lat: 57.6848 },
          { lng: -2.219988, lat: 56.870017 },
          { lng: -3.119003, lat: 55.973793 },
          { lng: -2.085009, lat: 55.909998 },
          { lng: -2.005676, lat: 55.804903 },
          { lng: -1.114991, lat: 54.624986 },
          { lng: -0.430485, lat: 54.464376 },
          { lng: 0.184981, lat: 53.325014 },
          { lng: 0.469977, lat: 52.929999 },
          { lng: 1.681531, lat: 52.73952 },
          { lng: 1.559988, lat: 52.099998 },
          { lng: 1.050562, lat: 51.806761 },
          { lng: 1.449865, lat: 51.289428 },
          { lng: 0.550334, lat: 50.765739 },
          { lng: -0.787517, lat: 50.774989 },
          { lng: -2.489998, lat: 50.500019 },
          { lng: -2.956274, lat: 50.69688 },
          { lng: -3.617448, lat: 50.228356 },
          { lng: -4.542508, lat: 50.341837 },
          { lng: -5.245023, lat: 49.96 },
          { lng: -5.776567, lat: 50.159678 },
          { lng: -4.30999, lat: 51.210001 },
          { lng: -3.414851, lat: 51.426009 },
          { lng: -3.422719, lat: 51.426848 },
          { lng: -4.984367, lat: 51.593466 },
          { lng: -5.267296, lat: 51.9914 },
          { lng: -4.222347, lat: 52.301356 },
          { lng: -4.770013, lat: 52.840005 },
          { lng: -4.579999, lat: 53.495004 },
          { lng: -3.093831, lat: 53.404547 },
          { lng: -3.09208, lat: 53.404441 },
          { lng: -2.945009, lat: 53.985 },
          { lng: -3.614701, lat: 54.600937 },
          { lng: -3.630005, lat: 54.615013 },
          { lng: -4.844169, lat: 54.790971 },
          { lng: -5.082527, lat: 55.061601 },
          { lng: -4.719112, lat: 55.508473 },
          { lng: -5.047981, lat: 55.783986 },
          { lng: -5.586398, lat: 55.311146 },
          { lng: -5.644999, lat: 56.275015 },
          { lng: -6.149981, lat: 56.78501 },
          { lng: -5.786825, lat: 57.818848 },
          { lng: -5.009999, lat: 58.630013 },
          { lng: -4.211495, lat: 58.550845 },
          { lng: -3.005005, lat: 58.635 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'GEO',
    properties: { name: 'Georgia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 41.554084, lat: 41.535656 },
          { lng: 41.703171, lat: 41.962943 },
          { lng: 41.45347, lat: 42.645123 },
          { lng: 40.875469, lat: 43.013628 },
          { lng: 40.321394, lat: 43.128634 },
          { lng: 39.955009, lat: 43.434998 },
          { lng: 40.076965, lat: 43.553104 },
          { lng: 40.922185, lat: 43.382159 },
          { lng: 42.394395, lat: 43.220308 },
          { lng: 43.756017, lat: 42.740828 },
          { lng: 43.9312, lat: 42.554974 },
          { lng: 44.537623, lat: 42.711993 },
          { lng: 45.470279, lat: 42.502781 },
          { lng: 45.77641, lat: 42.092444 },
          { lng: 46.404951, lat: 41.860675 },
          { lng: 46.145432, lat: 41.722802 },
          { lng: 46.637908, lat: 41.181673 },
          { lng: 46.501637, lat: 41.064445 },
          { lng: 45.962601, lat: 41.123873 },
          { lng: 45.217426, lat: 41.411452 },
          { lng: 44.97248, lat: 41.248129 },
          { lng: 43.582746, lat: 41.092143 },
          { lng: 42.619549, lat: 41.583173 },
          { lng: 41.554084, lat: 41.535656 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'GHA',
    properties: { name: 'Ghana' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 1.060122, lat: 5.928837 },
          { lng: -0.507638, lat: 5.343473 },
          { lng: -1.063625, lat: 5.000548 },
          { lng: -1.964707, lat: 4.710462 },
          { lng: -2.856125, lat: 4.994476 },
          { lng: -2.810701, lat: 5.389051 },
          { lng: -3.24437, lat: 6.250472 },
          { lng: -2.983585, lat: 7.379705 },
          { lng: -2.56219, lat: 8.219628 },
          { lng: -2.827496, lat: 9.642461 },
          { lng: -2.963896, lat: 10.395335 },
          { lng: -2.940409, lat: 10.96269 },
          { lng: -1.203358, lat: 11.009819 },
          { lng: -0.761576, lat: 10.93693 },
          { lng: -0.438702, lat: 11.098341 },
          { lng: 0.023803, lat: 11.018682 },
          { lng: -0.049785, lat: 10.706918 },
          { lng: 0.36758, lat: 10.191213 },
          { lng: 0.365901, lat: 9.465004 },
          { lng: 0.461192, lat: 8.677223 },
          { lng: 0.712029, lat: 8.312465 },
          { lng: 0.490957, lat: 7.411744 },
          { lng: 0.570384, lat: 6.914359 },
          { lng: 0.836931, lat: 6.279979 },
          { lng: 1.060122, lat: 5.928837 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'GIN',
    properties: { name: 'Guinea' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -8.439298, lat: 7.686043 },
          { lng: -8.722124, lat: 7.711674 },
          { lng: -8.926065, lat: 7.309037 },
          { lng: -9.208786, lat: 7.313921 },
          { lng: -9.403348, lat: 7.526905 },
          { lng: -9.33728, lat: 7.928534 },
          { lng: -9.755342, lat: 8.541055 },
          { lng: -10.016567, lat: 8.428504 },
          { lng: -10.230094, lat: 8.406206 },
          { lng: -10.505477, lat: 8.348896 },
          { lng: -10.494315, lat: 8.715541 },
          { lng: -10.65477, lat: 8.977178 },
          { lng: -10.622395, lat: 9.26791 },
          { lng: -10.839152, lat: 9.688246 },
          { lng: -11.117481, lat: 10.045873 },
          { lng: -11.917277, lat: 10.046984 },
          { lng: -12.150338, lat: 9.858572 },
          { lng: -12.425929, lat: 9.835834 },
          { lng: -12.596719, lat: 9.620188 },
          { lng: -12.711958, lat: 9.342712 },
          { lng: -13.24655, lat: 8.903049 },
          { lng: -13.685154, lat: 9.494744 },
          { lng: -14.074045, lat: 9.886167 },
          { lng: -14.330076, lat: 10.01572 },
          { lng: -14.579699, lat: 10.214467 },
          { lng: -14.693232, lat: 10.656301 },
          { lng: -14.839554, lat: 10.876572 },
          { lng: -15.130311, lat: 11.040412 },
          { lng: -14.685687, lat: 11.527824 },
          { lng: -14.382192, lat: 11.509272 },
          { lng: -14.121406, lat: 11.677117 },
          { lng: -13.9008, lat: 11.678719 },
          { lng: -13.743161, lat: 11.811269 },
          { lng: -13.828272, lat: 12.142644 },
          { lng: -13.718744, lat: 12.247186 },
          { lng: -13.700476, lat: 12.586183 },
          { lng: -13.217818, lat: 12.575874 },
          { lng: -12.499051, lat: 12.33209 },
          { lng: -12.278599, lat: 12.35444 },
          { lng: -12.203565, lat: 12.465648 },
          { lng: -11.658301, lat: 12.386583 },
          { lng: -11.513943, lat: 12.442988 },
          { lng: -11.456169, lat: 12.076834 },
          { lng: -11.297574, lat: 12.077971 },
          { lng: -11.036556, lat: 12.211245 },
          { lng: -10.87083, lat: 12.177887 },
          { lng: -10.593224, lat: 11.923975 },
          { lng: -10.165214, lat: 11.844084 },
          { lng: -9.890993, lat: 12.060479 },
          { lng: -9.567912, lat: 12.194243 },
          { lng: -9.327616, lat: 12.334286 },
          { lng: -9.127474, lat: 12.30806 },
          { lng: -8.905265, lat: 12.088358 },
          { lng: -8.786099, lat: 11.812561 },
          { lng: -8.376305, lat: 11.393646 },
          { lng: -8.581305, lat: 11.136246 },
          { lng: -8.620321, lat: 10.810891 },
          { lng: -8.407311, lat: 10.909257 },
          { lng: -8.282357, lat: 10.792597 },
          { lng: -8.335377, lat: 10.494812 },
          { lng: -8.029944, lat: 10.206535 },
          { lng: -8.229337, lat: 10.12902 },
          { lng: -8.309616, lat: 9.789532 },
          { lng: -8.079114, lat: 9.376224 },
          { lng: -7.8321, lat: 8.575704 },
          { lng: -8.203499, lat: 8.455453 },
          { lng: -8.299049, lat: 8.316444 },
          { lng: -8.221792, lat: 8.123329 },
          { lng: -8.280703, lat: 7.68718 },
          { lng: -8.439298, lat: 7.686043 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'GMB',
    properties: { name: 'Gambia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -16.841525, lat: 13.151394 },
          { lng: -16.713729, lat: 13.594959 },
          { lng: -15.624596, lat: 13.623587 },
          { lng: -15.39877, lat: 13.860369 },
          { lng: -15.081735, lat: 13.876492 },
          { lng: -14.687031, lat: 13.630357 },
          { lng: -14.376714, lat: 13.62568 },
          { lng: -14.046992, lat: 13.794068 },
          { lng: -13.844963, lat: 13.505042 },
          { lng: -14.277702, lat: 13.280585 },
          { lng: -14.712197, lat: 13.298207 },
          { lng: -15.141163, lat: 13.509512 },
          { lng: -15.511813, lat: 13.27857 },
          { lng: -15.691001, lat: 13.270353 },
          { lng: -15.931296, lat: 13.130284 },
          { lng: -16.841525, lat: 13.151394 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'GNB',
    properties: { name: 'Guinea Bissau' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -15.130311, lat: 11.040412 },
          { lng: -15.66418, lat: 11.458474 },
          { lng: -16.085214, lat: 11.524594 },
          { lng: -16.314787, lat: 11.806515 },
          { lng: -16.308947, lat: 11.958702 },
          { lng: -16.613838, lat: 12.170911 },
          { lng: -16.677452, lat: 12.384852 },
          { lng: -16.147717, lat: 12.547762 },
          { lng: -15.816574, lat: 12.515567 },
          { lng: -15.548477, lat: 12.62817 },
          { lng: -13.700476, lat: 12.586183 },
          { lng: -13.718744, lat: 12.247186 },
          { lng: -13.828272, lat: 12.142644 },
          { lng: -13.743161, lat: 11.811269 },
          { lng: -13.9008, lat: 11.678719 },
          { lng: -14.121406, lat: 11.677117 },
          { lng: -14.382192, lat: 11.509272 },
          { lng: -14.685687, lat: 11.527824 },
          { lng: -15.130311, lat: 11.040412 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'GNQ',
    properties: { name: 'Equatorial Guinea' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 9.492889, lat: 1.01012 },
          { lng: 9.305613, lat: 1.160911 },
          { lng: 9.649158, lat: 2.283866 },
          { lng: 11.276449, lat: 2.261051 },
          { lng: 11.285079, lat: 1.057662 },
          { lng: 9.830284, lat: 1.067894 },
          { lng: 9.492889, lat: 1.01012 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'GRC',
    properties: { name: 'Greece' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 23.69998, lat: 35.705004 },
          { lng: 24.246665, lat: 35.368022 },
          { lng: 25.025015, lat: 35.424996 },
          { lng: 25.769208, lat: 35.354018 },
          { lng: 25.745023, lat: 35.179998 },
          { lng: 26.290003, lat: 35.29999 },
          { lng: 26.164998, lat: 35.004995 },
          { lng: 24.724982, lat: 34.919988 },
          { lng: 24.735007, lat: 35.084991 },
          { lng: 23.514978, lat: 35.279992 },
          { lng: 23.69998, lat: 35.705004 },
        ],
        [
          { lng: 26.604196, lat: 41.562115 },
          { lng: 26.294602, lat: 40.936261 },
          { lng: 26.056942, lat: 40.824123 },
          { lng: 25.447677, lat: 40.852545 },
          { lng: 24.925848, lat: 40.947062 },
          { lng: 23.714811, lat: 40.687129 },
          { lng: 24.407999, lat: 40.124993 },
          { lng: 23.899968, lat: 39.962006 },
          { lng: 23.342999, lat: 39.960998 },
          { lng: 22.813988, lat: 40.476005 },
          { lng: 22.626299, lat: 40.256561 },
          { lng: 22.849748, lat: 39.659311 },
          { lng: 23.350027, lat: 39.190011 },
          { lng: 22.973099, lat: 38.970903 },
          { lng: 23.530016, lat: 38.510001 },
          { lng: 24.025025, lat: 38.219993 },
          { lng: 24.040011, lat: 37.655015 },
          { lng: 23.115003, lat: 37.920011 },
          { lng: 23.409972, lat: 37.409991 },
          { lng: 22.774972, lat: 37.30501 },
          { lng: 23.154225, lat: 36.422506 },
          { lng: 22.490028, lat: 36.41 },
          { lng: 21.670026, lat: 36.844986 },
          { lng: 21.295011, lat: 37.644989 },
          { lng: 21.120034, lat: 38.310323 },
          { lng: 20.730032, lat: 38.769985 },
          { lng: 20.217712, lat: 39.340235 },
          { lng: 20.150016, lat: 39.624998 },
          { lng: 20.615, lat: 40.110007 },
          { lng: 20.674997, lat: 40.435 },
          { lng: 20.99999, lat: 40.580004 },
          { lng: 21.02004, lat: 40.842727 },
          { lng: 21.674161, lat: 40.931275 },
          { lng: 22.055378, lat: 41.149866 },
          { lng: 22.597308, lat: 41.130487 },
          { lng: 22.76177, lat: 41.3048 },
          { lng: 22.952377, lat: 41.337994 },
          { lng: 23.692074, lat: 41.309081 },
          { lng: 24.492645, lat: 41.583896 },
          { lng: 25.197201, lat: 41.234486 },
          { lng: 26.106138, lat: 41.328899 },
          { lng: 26.117042, lat: 41.826905 },
          { lng: 26.604196, lat: 41.562115 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'GRL',
    properties: { name: 'Greenland' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -46.76379, lat: 82.62796 },
          { lng: -43.40644, lat: 83.22516 },
          { lng: -39.89753, lat: 83.18018 },
          { lng: -38.62214, lat: 83.54905 },
          { lng: -35.08787, lat: 83.64513 },
          { lng: -27.10046, lat: 83.51966 },
          { lng: -20.84539, lat: 82.72669 },
          { lng: -22.69182, lat: 82.34165 },
          { lng: -26.51753, lat: 82.29765 },
          { lng: -31.9, lat: 82.2 },
          { lng: -31.39646, lat: 82.02154 },
          { lng: -27.85666, lat: 82.13178 },
          { lng: -24.84448, lat: 81.78697 },
          { lng: -22.90328, lat: 82.09317 },
          { lng: -22.07175, lat: 81.73449 },
          { lng: -23.16961, lat: 81.15271 },
          { lng: -20.62363, lat: 81.52462 },
          { lng: -15.76818, lat: 81.91245 },
          { lng: -12.77018, lat: 81.71885 },
          { lng: -12.20855, lat: 81.29154 },
          { lng: -16.28533, lat: 80.58004 },
          { lng: -16.85, lat: 80.35 },
          { lng: -20.04624, lat: 80.17708 },
          { lng: -17.73035, lat: 80.12912 },
          { lng: -18.9, lat: 79.4 },
          { lng: -19.70499, lat: 78.75128 },
          { lng: -19.67353, lat: 77.63859 },
          { lng: -18.47285, lat: 76.98565 },
          { lng: -20.03503, lat: 76.94434 },
          { lng: -21.67944, lat: 76.62795 },
          { lng: -19.83407, lat: 76.09808 },
          { lng: -19.59896, lat: 75.24838 },
          { lng: -20.66818, lat: 75.15585 },
          { lng: -19.37281, lat: 74.29561 },
          { lng: -21.59422, lat: 74.22382 },
          { lng: -20.43454, lat: 73.81713 },
          { lng: -20.76234, lat: 73.46436 },
          { lng: -22.17221, lat: 73.30955 },
          { lng: -23.56593, lat: 73.30663 },
          { lng: -22.31311, lat: 72.62928 },
          { lng: -22.29954, lat: 72.18409 },
          { lng: -24.27834, lat: 72.59788 },
          { lng: -24.79296, lat: 72.3302 },
          { lng: -23.44296, lat: 72.08016 },
          { lng: -22.13281, lat: 71.46898 },
          { lng: -21.75356, lat: 70.66369 },
          { lng: -23.53603, lat: 70.471 },
          { lng: -24.30702, lat: 70.85649 },
          { lng: -25.54341, lat: 71.43094 },
          { lng: -25.20135, lat: 70.75226 },
          { lng: -26.36276, lat: 70.22646 },
          { lng: -23.72742, lat: 70.18401 },
          { lng: -22.34902, lat: 70.12946 },
          { lng: -25.02927, lat: 69.2588 },
          { lng: -27.74737, lat: 68.47046 },
          { lng: -30.67371, lat: 68.12503 },
          { lng: -31.77665, lat: 68.12078 },
          { lng: -32.81105, lat: 67.73547 },
          { lng: -34.20196, lat: 66.67974 },
          { lng: -36.35284, lat: 65.9789 },
          { lng: -37.04378, lat: 65.93768 },
          { lng: -38.37505, lat: 65.69213 },
          { lng: -39.81222, lat: 65.45848 },
          { lng: -40.66899, lat: 64.83997 },
          { lng: -40.68281, lat: 64.13902 },
          { lng: -41.1887, lat: 63.48246 },
          { lng: -42.81938, lat: 62.68233 },
          { lng: -42.41666, lat: 61.90093 },
          { lng: -42.86619, lat: 61.07404 },
          { lng: -43.3784, lat: 60.09772 },
          { lng: -44.7875, lat: 60.03676 },
          { lng: -46.26364, lat: 60.85328 },
          { lng: -48.26294, lat: 60.85843 },
          { lng: -49.23308, lat: 61.40681 },
          { lng: -49.90039, lat: 62.38336 },
          { lng: -51.63325, lat: 63.62691 },
          { lng: -52.14014, lat: 64.27842 },
          { lng: -52.27659, lat: 65.1767 },
          { lng: -53.66166, lat: 66.09957 },
          { lng: -53.30161, lat: 66.8365 },
          { lng: -53.96911, lat: 67.18899 },
          { lng: -52.9804, lat: 68.35759 },
          { lng: -51.47536, lat: 68.72958 },
          { lng: -51.08041, lat: 69.14781 },
          { lng: -50.87122, lat: 69.9291 },
          { lng: -52.013585, lat: 69.574925 },
          { lng: -52.55792, lat: 69.42616 },
          { lng: -53.45629, lat: 69.283625 },
          { lng: -54.68336, lat: 69.61003 },
          { lng: -54.75001, lat: 70.28932 },
          { lng: -54.35884, lat: 70.821315 },
          { lng: -53.431315, lat: 70.835755 },
          { lng: -51.39014, lat: 70.56978 },
          { lng: -53.10937, lat: 71.20485 },
          { lng: -54.00422, lat: 71.54719 },
          { lng: -55, lat: 71.406537 },
          { lng: -55.83468, lat: 71.65444 },
          { lng: -54.71819, lat: 72.58625 },
          { lng: -55.32634, lat: 72.95861 },
          { lng: -56.12003, lat: 73.64977 },
          { lng: -57.32363, lat: 74.71026 },
          { lng: -58.59679, lat: 75.09861 },
          { lng: -58.58516, lat: 75.51727 },
          { lng: -61.26861, lat: 76.10238 },
          { lng: -63.39165, lat: 76.1752 },
          { lng: -66.06427, lat: 76.13486 },
          { lng: -68.50438, lat: 76.06141 },
          { lng: -69.66485, lat: 76.37975 },
          { lng: -71.40257, lat: 77.00857 },
          { lng: -68.77671, lat: 77.32312 },
          { lng: -66.76397, lat: 77.37595 },
          { lng: -71.04293, lat: 77.63595 },
          { lng: -73.297, lat: 78.04419 },
          { lng: -73.15938, lat: 78.43271 },
          { lng: -69.37345, lat: 78.91388 },
          { lng: -65.7107, lat: 79.39436 },
          { lng: -65.3239, lat: 79.75814 },
          { lng: -68.02298, lat: 80.11721 },
          { lng: -67.15129, lat: 80.51582 },
          { lng: -63.68925, lat: 81.21396 },
          { lng: -62.23444, lat: 81.3211 },
          { lng: -62.65116, lat: 81.77042 },
          { lng: -60.28249, lat: 82.03363 },
          { lng: -57.20744, lat: 82.19074 },
          { lng: -54.13442, lat: 82.19962 },
          { lng: -53.04328, lat: 81.88833 },
          { lng: -50.39061, lat: 82.43883 },
          { lng: -48.00386, lat: 82.06481 },
          { lng: -46.59984, lat: 81.985945 },
          { lng: -44.523, lat: 81.6607 },
          { lng: -46.9007, lat: 82.19979 },
          { lng: -46.76379, lat: 82.62796 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'GTM',
    properties: { name: 'Guatemala' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -90.095555, lat: 13.735338 },
          { lng: -90.608624, lat: 13.909771 },
          { lng: -91.23241, lat: 13.927832 },
          { lng: -91.689747, lat: 14.126218 },
          { lng: -92.22775, lat: 14.538829 },
          { lng: -92.20323, lat: 14.830103 },
          { lng: -92.087216, lat: 15.064585 },
          { lng: -92.229249, lat: 15.251447 },
          { lng: -91.74796, lat: 16.066565 },
          { lng: -90.464473, lat: 16.069562 },
          { lng: -90.438867, lat: 16.41011 },
          { lng: -90.600847, lat: 16.470778 },
          { lng: -90.711822, lat: 16.687483 },
          { lng: -91.08167, lat: 16.918477 },
          { lng: -91.453921, lat: 17.252177 },
          { lng: -91.002269, lat: 17.254658 },
          { lng: -91.00152, lat: 17.817595 },
          { lng: -90.067934, lat: 17.819326 },
          { lng: -89.14308, lat: 17.808319 },
          { lng: -89.150806, lat: 17.015577 },
          { lng: -89.229122, lat: 15.886938 },
          { lng: -88.930613, lat: 15.887273 },
          { lng: -88.604586, lat: 15.70638 },
          { lng: -88.518364, lat: 15.855389 },
          { lng: -88.225023, lat: 15.727722 },
          { lng: -88.68068, lat: 15.346247 },
          { lng: -89.154811, lat: 15.066419 },
          { lng: -89.22522, lat: 14.874286 },
          { lng: -89.145535, lat: 14.678019 },
          { lng: -89.353326, lat: 14.424133 },
          { lng: -89.587343, lat: 14.362586 },
          { lng: -89.534219, lat: 14.244816 },
          { lng: -89.721934, lat: 14.134228 },
          { lng: -90.064678, lat: 13.88197 },
          { lng: -90.095555, lat: 13.735338 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'GUF',
    properties: { name: 'French Guiana' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -52.556425, lat: 2.504705 },
          { lng: -52.939657, lat: 2.124858 },
          { lng: -53.418465, lat: 2.053389 },
          { lng: -53.554839, lat: 2.334897 },
          { lng: -53.778521, lat: 2.376703 },
          { lng: -54.088063, lat: 2.105557 },
          { lng: -54.524754, lat: 2.311849 },
          { lng: -54.27123, lat: 2.738748 },
          { lng: -54.184284, lat: 3.194172 },
          { lng: -54.011504, lat: 3.62257 },
          { lng: -54.399542, lat: 4.212611 },
          { lng: -54.478633, lat: 4.896756 },
          { lng: -53.958045, lat: 5.756548 },
          { lng: -53.618453, lat: 5.646529 },
          { lng: -52.882141, lat: 5.409851 },
          { lng: -51.823343, lat: 4.565768 },
          { lng: -51.657797, lat: 4.156232 },
          { lng: -52.249338, lat: 3.241094 },
          { lng: -52.556425, lat: 2.504705 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'GUY',
    properties: { name: 'Guyana' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -59.758285, lat: 8.367035 },
          { lng: -59.101684, lat: 7.999202 },
          { lng: -58.482962, lat: 7.347691 },
          { lng: -58.454876, lat: 6.832787 },
          { lng: -58.078103, lat: 6.809094 },
          { lng: -57.542219, lat: 6.321268 },
          { lng: -57.147436, lat: 5.97315 },
          { lng: -57.307246, lat: 5.073567 },
          { lng: -57.914289, lat: 4.812626 },
          { lng: -57.86021, lat: 4.576801 },
          { lng: -58.044694, lat: 4.060864 },
          { lng: -57.601569, lat: 3.334655 },
          { lng: -57.281433, lat: 3.333492 },
          { lng: -57.150098, lat: 2.768927 },
          { lng: -56.539386, lat: 1.899523 },
          { lng: -56.782704, lat: 1.863711 },
          { lng: -57.335823, lat: 1.948538 },
          { lng: -57.660971, lat: 1.682585 },
          { lng: -58.11345, lat: 1.507195 },
          { lng: -58.429477, lat: 1.463942 },
          { lng: -58.540013, lat: 1.268088 },
          { lng: -59.030862, lat: 1.317698 },
          { lng: -59.646044, lat: 1.786894 },
          { lng: -59.718546, lat: 2.24963 },
          { lng: -59.974525, lat: 2.755233 },
          { lng: -59.815413, lat: 3.606499 },
          { lng: -59.53804, lat: 3.958803 },
          { lng: -59.767406, lat: 4.423503 },
          { lng: -60.111002, lat: 4.574967 },
          { lng: -59.980959, lat: 5.014061 },
          { lng: -60.213683, lat: 5.244486 },
          { lng: -60.733574, lat: 5.200277 },
          { lng: -61.410303, lat: 5.959068 },
          { lng: -61.139415, lat: 6.234297 },
          { lng: -61.159336, lat: 6.696077 },
          { lng: -60.543999, lat: 6.856584 },
          { lng: -60.295668, lat: 7.043911 },
          { lng: -60.637973, lat: 7.415 },
          { lng: -60.550588, lat: 7.779603 },
          { lng: -59.758285, lat: 8.367035 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'HND',
    properties: { name: 'Honduras' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -87.316654, lat: 12.984686 },
          { lng: -87.489409, lat: 13.297535 },
          { lng: -87.793111, lat: 13.38448 },
          { lng: -87.723503, lat: 13.78505 },
          { lng: -87.859515, lat: 13.893312 },
          { lng: -88.065343, lat: 13.964626 },
          { lng: -88.503998, lat: 13.845486 },
          { lng: -88.541231, lat: 13.980155 },
          { lng: -88.843073, lat: 14.140507 },
          { lng: -89.058512, lat: 14.340029 },
          { lng: -89.353326, lat: 14.424133 },
          { lng: -89.145535, lat: 14.678019 },
          { lng: -89.22522, lat: 14.874286 },
          { lng: -89.154811, lat: 15.066419 },
          { lng: -88.68068, lat: 15.346247 },
          { lng: -88.225023, lat: 15.727722 },
          { lng: -88.121153, lat: 15.688655 },
          { lng: -87.901813, lat: 15.864458 },
          { lng: -87.61568, lat: 15.878799 },
          { lng: -87.522921, lat: 15.797279 },
          { lng: -87.367762, lat: 15.84694 },
          { lng: -86.903191, lat: 15.756713 },
          { lng: -86.440946, lat: 15.782835 },
          { lng: -86.119234, lat: 15.893449 },
          { lng: -86.001954, lat: 16.005406 },
          { lng: -85.683317, lat: 15.953652 },
          { lng: -85.444004, lat: 15.885749 },
          { lng: -85.182444, lat: 15.909158 },
          { lng: -84.983722, lat: 15.995923 },
          { lng: -84.52698, lat: 15.857224 },
          { lng: -84.368256, lat: 15.835158 },
          { lng: -84.063055, lat: 15.648244 },
          { lng: -83.773977, lat: 15.424072 },
          { lng: -83.410381, lat: 15.270903 },
          { lng: -83.147219, lat: 14.995829 },
          { lng: -83.489989, lat: 15.016267 },
          { lng: -83.628585, lat: 14.880074 },
          { lng: -83.975721, lat: 14.749436 },
          { lng: -84.228342, lat: 14.748764 },
          { lng: -84.449336, lat: 14.621614 },
          { lng: -84.649582, lat: 14.666805 },
          { lng: -84.820037, lat: 14.819587 },
          { lng: -84.924501, lat: 14.790493 },
          { lng: -85.052787, lat: 14.551541 },
          { lng: -85.148751, lat: 14.560197 },
          { lng: -85.165365, lat: 14.35437 },
          { lng: -85.514413, lat: 14.079012 },
          { lng: -85.698665, lat: 13.960078 },
          { lng: -85.801295, lat: 13.836055 },
          { lng: -86.096264, lat: 14.038187 },
          { lng: -86.312142, lat: 13.771356 },
          { lng: -86.520708, lat: 13.778487 },
          { lng: -86.755087, lat: 13.754845 },
          { lng: -86.733822, lat: 13.263093 },
          { lng: -86.880557, lat: 13.254204 },
          { lng: -87.005769, lat: 13.025794 },
          { lng: -87.316654, lat: 12.984686 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'HRV',
    properties: { name: 'Croatia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 18.829838, lat: 45.908878 },
          { lng: 19.072769, lat: 45.521511 },
          { lng: 19.390476, lat: 45.236516 },
          { lng: 19.005486, lat: 44.860234 },
          { lng: 18.553214, lat: 45.08159 },
          { lng: 17.861783, lat: 45.06774 },
          { lng: 17.002146, lat: 45.233777 },
          { lng: 16.534939, lat: 45.211608 },
          { lng: 16.318157, lat: 45.004127 },
          { lng: 15.959367, lat: 45.233777 },
          { lng: 15.750026, lat: 44.818712 },
          { lng: 16.23966, lat: 44.351143 },
          { lng: 16.456443, lat: 44.04124 },
          { lng: 16.916156, lat: 43.667722 },
          { lng: 17.297373, lat: 43.446341 },
          { lng: 17.674922, lat: 43.028563 },
          { lng: 18.56, lat: 42.65 },
          { lng: 18.450016, lat: 42.479991 },
          { lng: 17.50997, lat: 42.849995 },
          { lng: 16.930006, lat: 43.209998 },
          { lng: 16.015385, lat: 43.507215 },
          { lng: 15.174454, lat: 44.243191 },
          { lng: 15.37625, lat: 44.317915 },
          { lng: 14.920309, lat: 44.738484 },
          { lng: 14.901602, lat: 45.07606 },
          { lng: 14.258748, lat: 45.233777 },
          { lng: 13.952255, lat: 44.802124 },
          { lng: 13.656976, lat: 45.136935 },
          { lng: 13.679403, lat: 45.484149 },
          { lng: 13.71506, lat: 45.500324 },
          { lng: 14.411968, lat: 45.466166 },
          { lng: 14.595109, lat: 45.634941 },
          { lng: 14.935244, lat: 45.471695 },
          { lng: 15.327675, lat: 45.452316 },
          { lng: 15.323954, lat: 45.731783 },
          { lng: 15.67153, lat: 45.834154 },
          { lng: 15.768733, lat: 46.238108 },
          { lng: 16.564808, lat: 46.503751 },
          { lng: 16.882515, lat: 46.380632 },
          { lng: 17.630066, lat: 45.951769 },
          { lng: 18.456062, lat: 45.759481 },
          { lng: 18.829838, lat: 45.908878 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'HTI',
    properties: { name: 'Haiti' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -73.189791, lat: 19.915684 },
          { lng: -72.579673, lat: 19.871501 },
          { lng: -71.712361, lat: 19.714456 },
          { lng: -71.624873, lat: 19.169838 },
          { lng: -71.701303, lat: 18.785417 },
          { lng: -71.945112, lat: 18.6169 },
          { lng: -71.687738, lat: 18.31666 },
          { lng: -71.708305, lat: 18.044997 },
          { lng: -72.372476, lat: 18.214961 },
          { lng: -72.844411, lat: 18.145611 },
          { lng: -73.454555, lat: 18.217906 },
          { lng: -73.922433, lat: 18.030993 },
          { lng: -74.458034, lat: 18.34255 },
          { lng: -74.369925, lat: 18.664908 },
          { lng: -73.449542, lat: 18.526053 },
          { lng: -72.694937, lat: 18.445799 },
          { lng: -72.334882, lat: 18.668422 },
          { lng: -72.79165, lat: 19.101625 },
          { lng: -72.784105, lat: 19.483591 },
          { lng: -73.415022, lat: 19.639551 },
          { lng: -73.189791, lat: 19.915684 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'HUN',
    properties: { name: 'Hungary' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 16.202298, lat: 46.852386 },
          { lng: 16.534268, lat: 47.496171 },
          { lng: 16.340584, lat: 47.712902 },
          { lng: 16.903754, lat: 47.714866 },
          { lng: 16.979667, lat: 48.123497 },
          { lng: 17.488473, lat: 47.867466 },
          { lng: 17.857133, lat: 47.758429 },
          { lng: 18.696513, lat: 47.880954 },
          { lng: 18.777025, lat: 48.081768 },
          { lng: 19.174365, lat: 48.111379 },
          { lng: 19.661364, lat: 48.266615 },
          { lng: 19.769471, lat: 48.202691 },
          { lng: 20.239054, lat: 48.327567 },
          { lng: 20.473562, lat: 48.56285 },
          { lng: 20.801294, lat: 48.623854 },
          { lng: 21.872236, lat: 48.319971 },
          { lng: 22.085608, lat: 48.422264 },
          { lng: 22.64082, lat: 48.15024 },
          { lng: 22.710531, lat: 47.882194 },
          { lng: 22.099768, lat: 47.672439 },
          { lng: 21.626515, lat: 46.994238 },
          { lng: 21.021952, lat: 46.316088 },
          { lng: 20.220192, lat: 46.127469 },
          { lng: 19.596045, lat: 46.17173 },
          { lng: 18.829838, lat: 45.908878 },
          { lng: 18.456062, lat: 45.759481 },
          { lng: 17.630066, lat: 45.951769 },
          { lng: 16.882515, lat: 46.380632 },
          { lng: 16.564808, lat: 46.503751 },
          { lng: 16.370505, lat: 46.841327 },
          { lng: 16.202298, lat: 46.852386 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'IDN',
    properties: { name: 'Indonesia' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 120.715609, lat: -10.239581 },
          { lng: 120.295014, lat: -10.25865 },
          { lng: 118.967808, lat: -9.557969 },
          { lng: 119.90031, lat: -9.36134 },
          { lng: 120.425756, lat: -9.665921 },
          { lng: 120.775502, lat: -9.969675 },
          { lng: 120.715609, lat: -10.239581 },
        ],
        [
          { lng: 124.43595, lat: -10.140001 },
          { lng: 123.579982, lat: -10.359987 },
          { lng: 123.459989, lat: -10.239995 },
          { lng: 123.550009, lat: -9.900016 },
          { lng: 123.980009, lat: -9.290027 },
          { lng: 124.968682, lat: -8.89279 },
          { lng: 125.07002, lat: -9.089987 },
          { lng: 125.08852, lat: -9.393173 },
          { lng: 124.43595, lat: -10.140001 },
        ],
        [
          { lng: 117.900018, lat: -8.095681 },
          { lng: 118.260616, lat: -8.362383 },
          { lng: 118.87846, lat: -8.280683 },
          { lng: 119.126507, lat: -8.705825 },
          { lng: 117.970402, lat: -8.906639 },
          { lng: 117.277731, lat: -9.040895 },
          { lng: 116.740141, lat: -9.032937 },
          { lng: 117.083737, lat: -8.457158 },
          { lng: 117.632024, lat: -8.449303 },
          { lng: 117.900018, lat: -8.095681 },
        ],
        [
          { lng: 122.903537, lat: -8.094234 },
          { lng: 122.756983, lat: -8.649808 },
          { lng: 121.254491, lat: -8.933666 },
          { lng: 119.924391, lat: -8.810418 },
          { lng: 119.920929, lat: -8.444859 },
          { lng: 120.715092, lat: -8.236965 },
          { lng: 121.341669, lat: -8.53674 },
          { lng: 122.007365, lat: -8.46062 },
          { lng: 122.903537, lat: -8.094234 },
        ],
        [
          { lng: 108.623479, lat: -6.777674 },
          { lng: 110.539227, lat: -6.877358 },
          { lng: 110.759576, lat: -6.465186 },
          { lng: 112.614811, lat: -6.946036 },
          { lng: 112.978768, lat: -7.594213 },
          { lng: 114.478935, lat: -7.776528 },
          { lng: 115.705527, lat: -8.370807 },
          { lng: 114.564511, lat: -8.751817 },
          { lng: 113.464734, lat: -8.348947 },
          { lng: 112.559672, lat: -8.376181 },
          { lng: 111.522061, lat: -8.302129 },
          { lng: 110.58615, lat: -8.122605 },
          { lng: 109.427667, lat: -7.740664 },
          { lng: 108.693655, lat: -7.6416 },
          { lng: 108.277763, lat: -7.766657 },
          { lng: 106.454102, lat: -7.3549 },
          { lng: 106.280624, lat: -6.9249 },
          { lng: 105.365486, lat: -6.851416 },
          { lng: 106.051646, lat: -5.895919 },
          { lng: 107.265009, lat: -5.954985 },
          { lng: 108.072091, lat: -6.345762 },
          { lng: 108.486846, lat: -6.421985 },
          { lng: 108.623479, lat: -6.777674 },
        ],
        [
          { lng: 134.724624, lat: -6.214401 },
          { lng: 134.210134, lat: -6.895238 },
          { lng: 134.112776, lat: -6.142467 },
          { lng: 134.290336, lat: -5.783058 },
          { lng: 134.499625, lat: -5.445042 },
          { lng: 134.727002, lat: -5.737582 },
          { lng: 134.724624, lat: -6.214401 },
        ],
        [
          { lng: 127.249215, lat: -3.459065 },
          { lng: 126.874923, lat: -3.790983 },
          { lng: 126.183802, lat: -3.607376 },
          { lng: 125.989034, lat: -3.177273 },
          { lng: 127.000651, lat: -3.129318 },
          { lng: 127.249215, lat: -3.459065 },
        ],
        [
          { lng: 130.471344, lat: -3.093764 },
          { lng: 130.834836, lat: -3.858472 },
          { lng: 129.990547, lat: -3.446301 },
          { lng: 129.155249, lat: -3.362637 },
          { lng: 128.590684, lat: -3.428679 },
          { lng: 127.898891, lat: -3.393436 },
          { lng: 128.135879, lat: -2.84365 },
          { lng: 129.370998, lat: -2.802154 },
          { lng: 130.471344, lat: -3.093764 },
        ],
        [
          { lng: 134.143368, lat: -1.151867 },
          { lng: 134.422627, lat: -2.769185 },
          { lng: 135.457603, lat: -3.367753 },
          { lng: 136.293314, lat: -2.307042 },
          { lng: 137.440738, lat: -1.703513 },
          { lng: 138.329727, lat: -1.702686 },
          { lng: 139.184921, lat: -2.051296 },
          { lng: 139.926684, lat: -2.409052 },
          { lng: 141.00021, lat: -2.600151 },
          { lng: 141.017057, lat: -5.859022 },
          { lng: 141.033852, lat: -9.117893 },
          { lng: 140.143415, lat: -8.297168 },
          { lng: 139.127767, lat: -8.096043 },
          { lng: 138.881477, lat: -8.380935 },
          { lng: 137.614474, lat: -8.411683 },
          { lng: 138.039099, lat: -7.597882 },
          { lng: 138.668621, lat: -7.320225 },
          { lng: 138.407914, lat: -6.232849 },
          { lng: 137.92784, lat: -5.393366 },
          { lng: 135.98925, lat: -4.546544 },
          { lng: 135.164598, lat: -4.462931 },
          { lng: 133.66288, lat: -3.538853 },
          { lng: 133.367705, lat: -4.024819 },
          { lng: 132.983956, lat: -4.112979 },
          { lng: 132.756941, lat: -3.746283 },
          { lng: 132.753789, lat: -3.311787 },
          { lng: 131.989804, lat: -2.820551 },
          { lng: 133.066845, lat: -2.460418 },
          { lng: 133.780031, lat: -2.479848 },
          { lng: 133.696212, lat: -2.214542 },
          { lng: 132.232373, lat: -2.212526 },
          { lng: 131.836222, lat: -1.617162 },
          { lng: 130.94284, lat: -1.432522 },
          { lng: 130.519558, lat: -0.93772 },
          { lng: 131.867538, lat: -0.695461 },
          { lng: 132.380116, lat: -0.369538 },
          { lng: 133.985548, lat: -0.78021 },
          { lng: 134.143368, lat: -1.151867 },
        ],
        [
          { lng: 125.240501, lat: 1.419836 },
          { lng: 124.437035, lat: 0.427881 },
          { lng: 123.685505, lat: 0.235593 },
          { lng: 122.723083, lat: 0.431137 },
          { lng: 121.056725, lat: 0.381217 },
          { lng: 120.183083, lat: 0.237247 },
          { lng: 120.04087, lat: -0.519658 },
          { lng: 120.935905, lat: -1.408906 },
          { lng: 121.475821, lat: -0.955962 },
          { lng: 123.340565, lat: -0.615673 },
          { lng: 123.258399, lat: -1.076213 },
          { lng: 122.822715, lat: -0.930951 },
          { lng: 122.38853, lat: -1.516858 },
          { lng: 121.508274, lat: -1.904483 },
          { lng: 122.454572, lat: -3.186058 },
          { lng: 122.271896, lat: -3.5295 },
          { lng: 123.170963, lat: -4.683693 },
          { lng: 123.162333, lat: -5.340604 },
          { lng: 122.628515, lat: -5.634591 },
          { lng: 122.236394, lat: -5.282933 },
          { lng: 122.719569, lat: -4.464172 },
          { lng: 121.738234, lat: -4.851331 },
          { lng: 121.489463, lat: -4.574553 },
          { lng: 121.619171, lat: -4.188478 },
          { lng: 120.898182, lat: -3.602105 },
          { lng: 120.972389, lat: -2.627643 },
          { lng: 120.305453, lat: -2.931604 },
          { lng: 120.390047, lat: -4.097579 },
          { lng: 120.430717, lat: -5.528241 },
          { lng: 119.796543, lat: -5.6734 },
          { lng: 119.366906, lat: -5.379878 },
          { lng: 119.653606, lat: -4.459417 },
          { lng: 119.498835, lat: -3.494412 },
          { lng: 119.078344, lat: -3.487022 },
          { lng: 118.767769, lat: -2.801999 },
          { lng: 119.180974, lat: -2.147104 },
          { lng: 119.323394, lat: -1.353147 },
          { lng: 119.825999, lat: 0.154254 },
          { lng: 120.035702, lat: 0.566477 },
          { lng: 120.885779, lat: 1.309223 },
          { lng: 121.666817, lat: 1.013944 },
          { lng: 122.927567, lat: 0.875192 },
          { lng: 124.077522, lat: 0.917102 },
          { lng: 125.065989, lat: 1.643259 },
          { lng: 125.240501, lat: 1.419836 },
        ],
        [
          { lng: 128.688249, lat: 1.132386 },
          { lng: 128.635952, lat: 0.258486 },
          { lng: 128.12017, lat: 0.356413 },
          { lng: 127.968034, lat: -0.252077 },
          { lng: 128.379999, lat: -0.780004 },
          { lng: 128.100016, lat: -0.899996 },
          { lng: 127.696475, lat: -0.266598 },
          { lng: 127.39949, lat: 1.011722 },
          { lng: 127.600512, lat: 1.810691 },
          { lng: 127.932378, lat: 2.174596 },
          { lng: 128.004156, lat: 1.628531 },
          { lng: 128.594559, lat: 1.540811 },
          { lng: 128.688249, lat: 1.132386 },
        ],
        [
          { lng: 117.875627, lat: 1.827641 },
          { lng: 118.996747, lat: 0.902219 },
          { lng: 117.811858, lat: 0.784242 },
          { lng: 117.478339, lat: 0.102475 },
          { lng: 117.521644, lat: -0.803723 },
          { lng: 116.560048, lat: -1.487661 },
          { lng: 116.533797, lat: -2.483517 },
          { lng: 116.148084, lat: -4.012726 },
          { lng: 116.000858, lat: -3.657037 },
          { lng: 114.864803, lat: -4.106984 },
          { lng: 114.468652, lat: -3.495704 },
          { lng: 113.755672, lat: -3.43917 },
          { lng: 113.256994, lat: -3.118776 },
          { lng: 112.068126, lat: -3.478392 },
          { lng: 111.703291, lat: -2.994442 },
          { lng: 111.04824, lat: -3.049426 },
          { lng: 110.223846, lat: -2.934032 },
          { lng: 110.070936, lat: -1.592874 },
          { lng: 109.571948, lat: -1.314907 },
          { lng: 109.091874, lat: -0.459507 },
          { lng: 108.952658, lat: 0.415375 },
          { lng: 109.069136, lat: 1.341934 },
          { lng: 109.66326, lat: 2.006467 },
          { lng: 109.830227, lat: 1.338136 },
          { lng: 110.514061, lat: 0.773131 },
          { lng: 111.159138, lat: 0.976478 },
          { lng: 111.797548, lat: 0.904441 },
          { lng: 112.380252, lat: 1.410121 },
          { lng: 112.859809, lat: 1.49779 },
          { lng: 113.80585, lat: 1.217549 },
          { lng: 114.621355, lat: 1.430688 },
          { lng: 115.134037, lat: 2.821482 },
          { lng: 115.519078, lat: 3.169238 },
          { lng: 115.865517, lat: 4.306559 },
          { lng: 117.015214, lat: 4.306094 },
          { lng: 117.882035, lat: 4.137551 },
          { lng: 117.313232, lat: 3.234428 },
          { lng: 118.04833, lat: 2.28769 },
          { lng: 117.875627, lat: 1.827641 },
        ],
        [
          { lng: 105.817655, lat: -5.852356 },
          { lng: 104.710384, lat: -5.873285 },
          { lng: 103.868213, lat: -5.037315 },
          { lng: 102.584261, lat: -4.220259 },
          { lng: 102.156173, lat: -3.614146 },
          { lng: 101.399113, lat: -2.799777 },
          { lng: 100.902503, lat: -2.050262 },
          { lng: 100.141981, lat: -0.650348 },
          { lng: 99.26374, lat: 0.183142 },
          { lng: 98.970011, lat: 1.042882 },
          { lng: 98.601351, lat: 1.823507 },
          { lng: 97.699598, lat: 2.453184 },
          { lng: 97.176942, lat: 3.308791 },
          { lng: 96.424017, lat: 3.86886 },
          { lng: 95.380876, lat: 4.970782 },
          { lng: 95.293026, lat: 5.479821 },
          { lng: 95.936863, lat: 5.439513 },
          { lng: 97.484882, lat: 5.246321 },
          { lng: 98.369169, lat: 4.26837 },
          { lng: 99.142559, lat: 3.59035 },
          { lng: 99.693998, lat: 3.174329 },
          { lng: 100.641434, lat: 2.099381 },
          { lng: 101.658012, lat: 2.083697 },
          { lng: 102.498271, lat: 1.3987 },
          { lng: 103.07684, lat: 0.561361 },
          { lng: 103.838396, lat: 0.104542 },
          { lng: 103.437645, lat: -0.711946 },
          { lng: 104.010789, lat: -1.059212 },
          { lng: 104.369991, lat: -1.084843 },
          { lng: 104.53949, lat: -1.782372 },
          { lng: 104.887893, lat: -2.340425 },
          { lng: 105.622111, lat: -2.428844 },
          { lng: 106.108593, lat: -3.061777 },
          { lng: 105.857446, lat: -4.305525 },
          { lng: 105.817655, lat: -5.852356 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'IND',
    properties: { name: 'India' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 77.837451, lat: 35.49401 },
          { lng: 78.912269, lat: 34.321936 },
          { lng: 78.811086, lat: 33.506198 },
          { lng: 79.208892, lat: 32.994395 },
          { lng: 79.176129, lat: 32.48378 },
          { lng: 78.458446, lat: 32.618164 },
          { lng: 78.738894, lat: 31.515906 },
          { lng: 79.721367, lat: 30.882715 },
          { lng: 81.111256, lat: 30.183481 },
          { lng: 80.476721, lat: 29.729865 },
          { lng: 80.088425, lat: 28.79447 },
          { lng: 81.057203, lat: 28.416095 },
          { lng: 81.999987, lat: 27.925479 },
          { lng: 83.304249, lat: 27.364506 },
          { lng: 84.675018, lat: 27.234901 },
          { lng: 85.251779, lat: 26.726198 },
          { lng: 86.024393, lat: 26.630985 },
          { lng: 87.227472, lat: 26.397898 },
          { lng: 88.060238, lat: 26.414615 },
          { lng: 88.174804, lat: 26.810405 },
          { lng: 88.043133, lat: 27.445819 },
          { lng: 88.120441, lat: 27.876542 },
          { lng: 88.730326, lat: 28.086865 },
          { lng: 88.814248, lat: 27.299316 },
          { lng: 88.835643, lat: 27.098966 },
          { lng: 89.744528, lat: 26.719403 },
          { lng: 90.373275, lat: 26.875724 },
          { lng: 91.217513, lat: 26.808648 },
          { lng: 92.033484, lat: 26.83831 },
          { lng: 92.103712, lat: 27.452614 },
          { lng: 91.696657, lat: 27.771742 },
          { lng: 92.503119, lat: 27.896876 },
          { lng: 93.413348, lat: 28.640629 },
          { lng: 94.56599, lat: 29.277438 },
          { lng: 95.404802, lat: 29.031717 },
          { lng: 96.117679, lat: 29.452802 },
          { lng: 96.586591, lat: 28.83098 },
          { lng: 96.248833, lat: 28.411031 },
          { lng: 97.327114, lat: 28.261583 },
          { lng: 97.402561, lat: 27.882536 },
          { lng: 97.051989, lat: 27.699059 },
          { lng: 97.133999, lat: 27.083774 },
          { lng: 96.419366, lat: 27.264589 },
          { lng: 95.124768, lat: 26.573572 },
          { lng: 95.155153, lat: 26.001307 },
          { lng: 94.603249, lat: 25.162495 },
          { lng: 94.552658, lat: 24.675238 },
          { lng: 94.106742, lat: 23.850741 },
          { lng: 93.325188, lat: 24.078556 },
          { lng: 93.286327, lat: 23.043658 },
          { lng: 93.060294, lat: 22.703111 },
          { lng: 93.166128, lat: 22.27846 },
          { lng: 92.672721, lat: 22.041239 },
          { lng: 92.146035, lat: 23.627499 },
          { lng: 91.869928, lat: 23.624346 },
          { lng: 91.706475, lat: 22.985264 },
          { lng: 91.158963, lat: 23.503527 },
          { lng: 91.46773, lat: 24.072639 },
          { lng: 91.915093, lat: 24.130414 },
          { lng: 92.376202, lat: 24.976693 },
          { lng: 91.799596, lat: 25.147432 },
          { lng: 90.872211, lat: 25.132601 },
          { lng: 89.920693, lat: 25.26975 },
          { lng: 89.832481, lat: 25.965082 },
          { lng: 89.355094, lat: 26.014407 },
          { lng: 88.563049, lat: 26.446526 },
          { lng: 88.209789, lat: 25.768066 },
          { lng: 88.931554, lat: 25.238692 },
          { lng: 88.306373, lat: 24.866079 },
          { lng: 88.084422, lat: 24.501657 },
          { lng: 88.69994, lat: 24.233715 },
          { lng: 88.52977, lat: 23.631142 },
          { lng: 88.876312, lat: 22.879146 },
          { lng: 89.031961, lat: 22.055708 },
          { lng: 88.888766, lat: 21.690588 },
          { lng: 88.208497, lat: 21.703172 },
          { lng: 86.975704, lat: 21.495562 },
          { lng: 87.033169, lat: 20.743308 },
          { lng: 86.499351, lat: 20.151638 },
          { lng: 85.060266, lat: 19.478579 },
          { lng: 83.941006, lat: 18.30201 },
          { lng: 83.189217, lat: 17.671221 },
          { lng: 82.192792, lat: 17.016636 },
          { lng: 82.191242, lat: 16.556664 },
          { lng: 81.692719, lat: 16.310219 },
          { lng: 80.791999, lat: 15.951972 },
          { lng: 80.324896, lat: 15.899185 },
          { lng: 80.025069, lat: 15.136415 },
          { lng: 80.233274, lat: 13.835771 },
          { lng: 80.286294, lat: 13.006261 },
          { lng: 79.862547, lat: 12.056215 },
          { lng: 79.857999, lat: 10.357275 },
          { lng: 79.340512, lat: 10.308854 },
          { lng: 78.885345, lat: 9.546136 },
          { lng: 79.18972, lat: 9.216544 },
          { lng: 78.277941, lat: 8.933047 },
          { lng: 77.941165, lat: 8.252959 },
          { lng: 77.539898, lat: 7.965535 },
          { lng: 76.592979, lat: 8.899276 },
          { lng: 76.130061, lat: 10.29963 },
          { lng: 75.746467, lat: 11.308251 },
          { lng: 75.396101, lat: 11.781245 },
          { lng: 74.864816, lat: 12.741936 },
          { lng: 74.616717, lat: 13.992583 },
          { lng: 74.443859, lat: 14.617222 },
          { lng: 73.534199, lat: 15.990652 },
          { lng: 73.119909, lat: 17.92857 },
          { lng: 72.820909, lat: 19.208234 },
          { lng: 72.824475, lat: 20.419503 },
          { lng: 72.630533, lat: 21.356009 },
          { lng: 71.175273, lat: 20.757441 },
          { lng: 70.470459, lat: 20.877331 },
          { lng: 69.16413, lat: 22.089298 },
          { lng: 69.644928, lat: 22.450775 },
          { lng: 69.349597, lat: 22.84318 },
          { lng: 68.176645, lat: 23.691965 },
          { lng: 68.842599, lat: 24.359134 },
          { lng: 71.04324, lat: 24.356524 },
          { lng: 70.844699, lat: 25.215102 },
          { lng: 70.282873, lat: 25.722229 },
          { lng: 70.168927, lat: 26.491872 },
          { lng: 69.514393, lat: 26.940966 },
          { lng: 70.616496, lat: 27.989196 },
          { lng: 71.777666, lat: 27.91318 },
          { lng: 72.823752, lat: 28.961592 },
          { lng: 73.450638, lat: 29.976413 },
          { lng: 74.42138, lat: 30.979815 },
          { lng: 74.405929, lat: 31.692639 },
          { lng: 75.258642, lat: 32.271105 },
          { lng: 74.451559, lat: 32.7649 },
          { lng: 74.104294, lat: 33.441473 },
          { lng: 73.749948, lat: 34.317699 },
          { lng: 74.240203, lat: 34.748887 },
          { lng: 75.757061, lat: 34.504923 },
          { lng: 76.871722, lat: 34.653544 },
          { lng: 77.837451, lat: 35.49401 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'IRL',
    properties: { name: 'Ireland' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -6.197885, lat: 53.867565 },
          { lng: -6.032985, lat: 53.153164 },
          { lng: -6.788857, lat: 52.260118 },
          { lng: -8.561617, lat: 51.669301 },
          { lng: -9.977086, lat: 51.820455 },
          { lng: -9.166283, lat: 52.864629 },
          { lng: -9.688525, lat: 53.881363 },
          { lng: -8.327987, lat: 54.664519 },
          { lng: -7.572168, lat: 55.131622 },
          { lng: -7.366031, lat: 54.595841 },
          { lng: -7.572168, lat: 54.059956 },
          { lng: -6.95373, lat: 54.073702 },
          { lng: -6.197885, lat: 53.867565 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'IRN',
    properties: { name: 'Iran' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 53.921598, lat: 37.198918 },
          { lng: 54.800304, lat: 37.392421 },
          { lng: 55.511578, lat: 37.964117 },
          { lng: 56.180375, lat: 37.935127 },
          { lng: 56.619366, lat: 38.121394 },
          { lng: 57.330434, lat: 38.029229 },
          { lng: 58.436154, lat: 37.522309 },
          { lng: 59.234762, lat: 37.412988 },
          { lng: 60.377638, lat: 36.527383 },
          { lng: 61.123071, lat: 36.491597 },
          { lng: 61.210817, lat: 35.650072 },
          { lng: 60.803193, lat: 34.404102 },
          { lng: 60.52843, lat: 33.676446 },
          { lng: 60.9637, lat: 33.528832 },
          { lng: 60.536078, lat: 32.981269 },
          { lng: 60.863655, lat: 32.18292 },
          { lng: 60.941945, lat: 31.548075 },
          { lng: 61.699314, lat: 31.379506 },
          { lng: 61.781222, lat: 30.73585 },
          { lng: 60.874248, lat: 29.829239 },
          { lng: 61.369309, lat: 29.303276 },
          { lng: 61.771868, lat: 28.699334 },
          { lng: 62.72783, lat: 28.259645 },
          { lng: 62.755426, lat: 27.378923 },
          { lng: 63.233898, lat: 27.217047 },
          { lng: 63.316632, lat: 26.756532 },
          { lng: 61.874187, lat: 26.239975 },
          { lng: 61.497363, lat: 25.078237 },
          { lng: 59.616134, lat: 25.380157 },
          { lng: 58.525761, lat: 25.609962 },
          { lng: 57.397251, lat: 25.739902 },
          { lng: 56.970766, lat: 26.966106 },
          { lng: 56.492139, lat: 27.143305 },
          { lng: 55.72371, lat: 26.964633 },
          { lng: 54.71509, lat: 26.480658 },
          { lng: 53.493097, lat: 26.812369 },
          { lng: 52.483598, lat: 27.580849 },
          { lng: 51.520763, lat: 27.86569 },
          { lng: 50.852948, lat: 28.814521 },
          { lng: 50.115009, lat: 30.147773 },
          { lng: 49.57685, lat: 29.985715 },
          { lng: 48.941333, lat: 30.31709 },
          { lng: 48.567971, lat: 29.926778 },
          { lng: 48.014568, lat: 30.452457 },
          { lng: 48.004698, lat: 30.985137 },
          { lng: 47.685286, lat: 30.984853 },
          { lng: 47.849204, lat: 31.709176 },
          { lng: 47.334661, lat: 32.469155 },
          { lng: 46.109362, lat: 33.017287 },
          { lng: 45.416691, lat: 33.967798 },
          { lng: 45.64846, lat: 34.748138 },
          { lng: 46.151788, lat: 35.093259 },
          { lng: 46.07634, lat: 35.677383 },
          { lng: 45.420618, lat: 35.977546 },
          { lng: 44.77267, lat: 37.17045 },
          { lng: 44.225756, lat: 37.971584 },
          { lng: 44.421403, lat: 38.281281 },
          { lng: 44.109225, lat: 39.428136 },
          { lng: 44.79399, lat: 39.713003 },
          { lng: 44.952688, lat: 39.335765 },
          { lng: 45.457722, lat: 38.874139 },
          { lng: 46.143623, lat: 38.741201 },
          { lng: 46.50572, lat: 38.770605 },
          { lng: 47.685079, lat: 39.508364 },
          { lng: 48.060095, lat: 39.582235 },
          { lng: 48.355529, lat: 39.288765 },
          { lng: 48.010744, lat: 38.794015 },
          { lng: 48.634375, lat: 38.270378 },
          { lng: 48.883249, lat: 38.320245 },
          { lng: 49.199612, lat: 37.582874 },
          { lng: 50.147771, lat: 37.374567 },
          { lng: 50.842354, lat: 36.872814 },
          { lng: 52.264025, lat: 36.700422 },
          { lng: 53.82579, lat: 36.965031 },
          { lng: 53.921598, lat: 37.198918 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'IRQ',
    properties: { name: 'Iraq' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 45.420618, lat: 35.977546 },
          { lng: 46.07634, lat: 35.677383 },
          { lng: 46.151788, lat: 35.093259 },
          { lng: 45.64846, lat: 34.748138 },
          { lng: 45.416691, lat: 33.967798 },
          { lng: 46.109362, lat: 33.017287 },
          { lng: 47.334661, lat: 32.469155 },
          { lng: 47.849204, lat: 31.709176 },
          { lng: 47.685286, lat: 30.984853 },
          { lng: 48.004698, lat: 30.985137 },
          { lng: 48.014568, lat: 30.452457 },
          { lng: 48.567971, lat: 29.926778 },
          { lng: 47.974519, lat: 29.975819 },
          { lng: 47.302622, lat: 30.05907 },
          { lng: 46.568713, lat: 29.099025 },
          { lng: 44.709499, lat: 29.178891 },
          { lng: 41.889981, lat: 31.190009 },
          { lng: 40.399994, lat: 31.889992 },
          { lng: 39.195468, lat: 32.161009 },
          { lng: 38.792341, lat: 33.378686 },
          { lng: 41.006159, lat: 34.419372 },
          { lng: 41.383965, lat: 35.628317 },
          { lng: 41.289707, lat: 36.358815 },
          { lng: 41.837064, lat: 36.605854 },
          { lng: 42.349591, lat: 37.229873 },
          { lng: 42.779126, lat: 37.385264 },
          { lng: 43.942259, lat: 37.256228 },
          { lng: 44.293452, lat: 37.001514 },
          { lng: 44.772699, lat: 37.170445 },
          { lng: 45.420618, lat: 35.977546 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ISL',
    properties: { name: 'Iceland' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -14.508695, lat: 66.455892 },
          { lng: -14.739637, lat: 65.808748 },
          { lng: -13.609732, lat: 65.126671 },
          { lng: -14.909834, lat: 64.364082 },
          { lng: -17.794438, lat: 63.678749 },
          { lng: -18.656246, lat: 63.496383 },
          { lng: -19.972755, lat: 63.643635 },
          { lng: -22.762972, lat: 63.960179 },
          { lng: -21.778484, lat: 64.402116 },
          { lng: -23.955044, lat: 64.89113 },
          { lng: -22.184403, lat: 65.084968 },
          { lng: -22.227423, lat: 65.378594 },
          { lng: -24.326184, lat: 65.611189 },
          { lng: -23.650515, lat: 66.262519 },
          { lng: -22.134922, lat: 66.410469 },
          { lng: -20.576284, lat: 65.732112 },
          { lng: -19.056842, lat: 66.276601 },
          { lng: -17.798624, lat: 65.993853 },
          { lng: -16.167819, lat: 66.526792 },
          { lng: -14.508695, lat: 66.455892 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ISR',
    properties: { name: 'Israel' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 35.719918, lat: 32.709192 },
          { lng: 35.545665, lat: 32.393992 },
          { lng: 35.18393, lat: 32.532511 },
          { lng: 34.974641, lat: 31.866582 },
          { lng: 35.225892, lat: 31.754341 },
          { lng: 34.970507, lat: 31.616778 },
          { lng: 34.927408, lat: 31.353435 },
          { lng: 35.397561, lat: 31.489086 },
          { lng: 35.420918, lat: 31.100066 },
          { lng: 34.922603, lat: 29.501326 },
          { lng: 34.265433, lat: 31.219361 },
          { lng: 34.556372, lat: 31.548824 },
          { lng: 34.488107, lat: 31.605539 },
          { lng: 34.752587, lat: 32.072926 },
          { lng: 34.955417, lat: 32.827376 },
          { lng: 35.098457, lat: 33.080539 },
          { lng: 35.126053, lat: 33.0909 },
          { lng: 35.460709, lat: 33.08904 },
          { lng: 35.552797, lat: 33.264275 },
          { lng: 35.821101, lat: 33.277426 },
          { lng: 35.836397, lat: 32.868123 },
          { lng: 35.700798, lat: 32.716014 },
          { lng: 35.719918, lat: 32.709192 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ITA',
    properties: { name: 'Italy' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 15.520376, lat: 38.231155 },
          { lng: 15.160243, lat: 37.444046 },
          { lng: 15.309898, lat: 37.134219 },
          { lng: 15.099988, lat: 36.619987 },
          { lng: 14.335229, lat: 36.996631 },
          { lng: 13.826733, lat: 37.104531 },
          { lng: 12.431004, lat: 37.61295 },
          { lng: 12.570944, lat: 38.126381 },
          { lng: 13.741156, lat: 38.034966 },
          { lng: 14.761249, lat: 38.143874 },
          { lng: 15.520376, lat: 38.231155 },
        ],
        [
          { lng: 9.210012, lat: 41.209991 },
          { lng: 9.809975, lat: 40.500009 },
          { lng: 9.669519, lat: 39.177376 },
          { lng: 9.214818, lat: 39.240473 },
          { lng: 8.806936, lat: 38.906618 },
          { lng: 8.428302, lat: 39.171847 },
          { lng: 8.388253, lat: 40.378311 },
          { lng: 8.159998, lat: 40.950007 },
          { lng: 8.709991, lat: 40.899984 },
          { lng: 9.210012, lat: 41.209991 },
        ],
        [
          { lng: 12.376485, lat: 46.767559 },
          { lng: 13.806475, lat: 46.509306 },
          { lng: 13.69811, lat: 46.016778 },
          { lng: 13.93763, lat: 45.591016 },
          { lng: 13.141606, lat: 45.736692 },
          { lng: 12.328581, lat: 45.381778 },
          { lng: 12.383875, lat: 44.885374 },
          { lng: 12.261453, lat: 44.600482 },
          { lng: 12.589237, lat: 44.091366 },
          { lng: 13.526906, lat: 43.587727 },
          { lng: 14.029821, lat: 42.761008 },
          { lng: 15.14257, lat: 41.95514 },
          { lng: 15.926191, lat: 41.961315 },
          { lng: 16.169897, lat: 41.740295 },
          { lng: 15.889346, lat: 41.541082 },
          { lng: 16.785002, lat: 41.179606 },
          { lng: 17.519169, lat: 40.877143 },
          { lng: 18.376687, lat: 40.355625 },
          { lng: 18.480247, lat: 40.168866 },
          { lng: 18.293385, lat: 39.810774 },
          { lng: 17.73838, lat: 40.277671 },
          { lng: 16.869596, lat: 40.442235 },
          { lng: 16.448743, lat: 39.795401 },
          { lng: 17.17149, lat: 39.4247 },
          { lng: 17.052841, lat: 38.902871 },
          { lng: 16.635088, lat: 38.843572 },
          { lng: 16.100961, lat: 37.985899 },
          { lng: 15.684087, lat: 37.908849 },
          { lng: 15.687963, lat: 38.214593 },
          { lng: 15.891981, lat: 38.750942 },
          { lng: 16.109332, lat: 38.964547 },
          { lng: 15.718814, lat: 39.544072 },
          { lng: 15.413613, lat: 40.048357 },
          { lng: 14.998496, lat: 40.172949 },
          { lng: 14.703268, lat: 40.60455 },
          { lng: 14.060672, lat: 40.786348 },
          { lng: 13.627985, lat: 41.188287 },
          { lng: 12.888082, lat: 41.25309 },
          { lng: 12.106683, lat: 41.704535 },
          { lng: 11.191906, lat: 42.355425 },
          { lng: 10.511948, lat: 42.931463 },
          { lng: 10.200029, lat: 43.920007 },
          { lng: 9.702488, lat: 44.036279 },
          { lng: 8.888946, lat: 44.366336 },
          { lng: 8.428561, lat: 44.231228 },
          { lng: 7.850767, lat: 43.767148 },
          { lng: 7.435185, lat: 43.693845 },
          { lng: 7.549596, lat: 44.127901 },
          { lng: 7.007562, lat: 44.254767 },
          { lng: 6.749955, lat: 45.028518 },
          { lng: 7.096652, lat: 45.333099 },
          { lng: 6.802355, lat: 45.70858 },
          { lng: 6.843593, lat: 45.991147 },
          { lng: 7.273851, lat: 45.776948 },
          { lng: 7.755992, lat: 45.82449 },
          { lng: 8.31663, lat: 46.163642 },
          { lng: 8.489952, lat: 46.005151 },
          { lng: 8.966306, lat: 46.036932 },
          { lng: 9.182882, lat: 46.440215 },
          { lng: 9.922837, lat: 46.314899 },
          { lng: 10.363378, lat: 46.483571 },
          { lng: 10.442701, lat: 46.893546 },
          { lng: 11.048556, lat: 46.751359 },
          { lng: 11.164828, lat: 46.941579 },
          { lng: 12.153088, lat: 47.115393 },
          { lng: 12.376485, lat: 46.767559 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'JAM',
    properties: { name: 'Jamaica' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -77.569601, lat: 18.490525 },
          { lng: -76.896619, lat: 18.400867 },
          { lng: -76.365359, lat: 18.160701 },
          { lng: -76.199659, lat: 17.886867 },
          { lng: -76.902561, lat: 17.868238 },
          { lng: -77.206341, lat: 17.701116 },
          { lng: -77.766023, lat: 17.861597 },
          { lng: -78.337719, lat: 18.225968 },
          { lng: -78.217727, lat: 18.454533 },
          { lng: -77.797365, lat: 18.524218 },
          { lng: -77.569601, lat: 18.490525 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'JOR',
    properties: { name: 'Jordan' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 35.545665, lat: 32.393992 },
          { lng: 35.719918, lat: 32.709192 },
          { lng: 36.834062, lat: 32.312938 },
          { lng: 38.792341, lat: 33.378686 },
          { lng: 39.195468, lat: 32.161009 },
          { lng: 39.004886, lat: 32.010217 },
          { lng: 37.002166, lat: 31.508413 },
          { lng: 37.998849, lat: 30.5085 },
          { lng: 37.66812, lat: 30.338665 },
          { lng: 37.503582, lat: 30.003776 },
          { lng: 36.740528, lat: 29.865283 },
          { lng: 36.501214, lat: 29.505254 },
          { lng: 36.068941, lat: 29.197495 },
          { lng: 34.956037, lat: 29.356555 },
          { lng: 34.922603, lat: 29.501326 },
          { lng: 35.420918, lat: 31.100066 },
          { lng: 35.397561, lat: 31.489086 },
          { lng: 35.545252, lat: 31.782505 },
          { lng: 35.545665, lat: 32.393992 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'JPN',
    properties: { name: 'Japan' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 134.638428, lat: 34.149234 },
          { lng: 134.766379, lat: 33.806335 },
          { lng: 134.203416, lat: 33.201178 },
          { lng: 133.79295, lat: 33.521985 },
          { lng: 133.280268, lat: 33.28957 },
          { lng: 133.014858, lat: 32.704567 },
          { lng: 132.363115, lat: 32.989382 },
          { lng: 132.371176, lat: 33.463642 },
          { lng: 132.924373, lat: 34.060299 },
          { lng: 133.492968, lat: 33.944621 },
          { lng: 133.904106, lat: 34.364931 },
          { lng: 134.638428, lat: 34.149234 },
        ],
        [
          { lng: 140.976388, lat: 37.142074 },
          { lng: 140.59977, lat: 36.343983 },
          { lng: 140.774074, lat: 35.842877 },
          { lng: 140.253279, lat: 35.138114 },
          { lng: 138.975528, lat: 34.6676 },
          { lng: 137.217599, lat: 34.606286 },
          { lng: 135.792983, lat: 33.464805 },
          { lng: 135.120983, lat: 33.849071 },
          { lng: 135.079435, lat: 34.596545 },
          { lng: 133.340316, lat: 34.375938 },
          { lng: 132.156771, lat: 33.904933 },
          { lng: 130.986145, lat: 33.885761 },
          { lng: 132.000036, lat: 33.149992 },
          { lng: 131.33279, lat: 31.450355 },
          { lng: 130.686318, lat: 31.029579 },
          { lng: 130.20242, lat: 31.418238 },
          { lng: 130.447676, lat: 32.319475 },
          { lng: 129.814692, lat: 32.61031 },
          { lng: 129.408463, lat: 33.296056 },
          { lng: 130.353935, lat: 33.604151 },
          { lng: 130.878451, lat: 34.232743 },
          { lng: 131.884229, lat: 34.749714 },
          { lng: 132.617673, lat: 35.433393 },
          { lng: 134.608301, lat: 35.731618 },
          { lng: 135.677538, lat: 35.527134 },
          { lng: 136.723831, lat: 37.304984 },
          { lng: 137.390612, lat: 36.827391 },
          { lng: 138.857602, lat: 37.827485 },
          { lng: 139.426405, lat: 38.215962 },
          { lng: 140.05479, lat: 39.438807 },
          { lng: 139.883379, lat: 40.563312 },
          { lng: 140.305783, lat: 41.195005 },
          { lng: 141.368973, lat: 41.37856 },
          { lng: 141.914263, lat: 39.991616 },
          { lng: 141.884601, lat: 39.180865 },
          { lng: 140.959489, lat: 38.174001 },
          { lng: 140.976388, lat: 37.142074 },
        ],
        [
          { lng: 143.910162, lat: 44.1741 },
          { lng: 144.613427, lat: 43.960883 },
          { lng: 145.320825, lat: 44.384733 },
          { lng: 145.543137, lat: 43.262088 },
          { lng: 144.059662, lat: 42.988358 },
          { lng: 143.18385, lat: 41.995215 },
          { lng: 141.611491, lat: 42.678791 },
          { lng: 141.067286, lat: 41.584594 },
          { lng: 139.955106, lat: 41.569556 },
          { lng: 139.817544, lat: 42.563759 },
          { lng: 140.312087, lat: 43.333273 },
          { lng: 141.380549, lat: 43.388825 },
          { lng: 141.671952, lat: 44.772125 },
          { lng: 141.967645, lat: 45.551483 },
          { lng: 143.14287, lat: 44.510358 },
          { lng: 143.910162, lat: 44.1741 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'KAZ',
    properties: { name: 'Kazakhstan' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 70.962315, lat: 42.266154 },
          { lng: 70.388965, lat: 42.081308 },
          { lng: 69.070027, lat: 41.384244 },
          { lng: 68.632483, lat: 40.668681 },
          { lng: 68.259896, lat: 40.662325 },
          { lng: 67.985856, lat: 41.135991 },
          { lng: 66.714047, lat: 41.168444 },
          { lng: 66.510649, lat: 41.987644 },
          { lng: 66.023392, lat: 41.994646 },
          { lng: 66.098012, lat: 42.99766 },
          { lng: 64.900824, lat: 43.728081 },
          { lng: 63.185787, lat: 43.650075 },
          { lng: 62.0133, lat: 43.504477 },
          { lng: 61.05832, lat: 44.405817 },
          { lng: 60.239972, lat: 44.784037 },
          { lng: 58.689989, lat: 45.500014 },
          { lng: 58.503127, lat: 45.586804 },
          { lng: 55.928917, lat: 44.995858 },
          { lng: 55.968191, lat: 41.308642 },
          { lng: 55.455251, lat: 41.259859 },
          { lng: 54.755345, lat: 42.043971 },
          { lng: 54.079418, lat: 42.324109 },
          { lng: 52.944293, lat: 42.116034 },
          { lng: 52.50246, lat: 41.783316 },
          { lng: 52.446339, lat: 42.027151 },
          { lng: 52.692112, lat: 42.443895 },
          { lng: 52.501426, lat: 42.792298 },
          { lng: 51.342427, lat: 43.132975 },
          { lng: 50.891292, lat: 44.031034 },
          { lng: 50.339129, lat: 44.284016 },
          { lng: 50.305643, lat: 44.609836 },
          { lng: 51.278503, lat: 44.514854 },
          { lng: 51.316899, lat: 45.245998 },
          { lng: 52.16739, lat: 45.408391 },
          { lng: 53.040876, lat: 45.259047 },
          { lng: 53.220866, lat: 46.234646 },
          { lng: 53.042737, lat: 46.853006 },
          { lng: 52.042023, lat: 46.804637 },
          { lng: 51.191945, lat: 47.048705 },
          { lng: 50.034083, lat: 46.60899 },
          { lng: 49.10116, lat: 46.39933 },
          { lng: 48.593241, lat: 46.561034 },
          { lng: 48.694734, lat: 47.075628 },
          { lng: 48.057253, lat: 47.743753 },
          { lng: 47.315231, lat: 47.715847 },
          { lng: 46.466446, lat: 48.394152 },
          { lng: 47.043672, lat: 49.152039 },
          { lng: 46.751596, lat: 49.356006 },
          { lng: 47.54948, lat: 50.454698 },
          { lng: 48.577841, lat: 49.87476 },
          { lng: 48.702382, lat: 50.605128 },
          { lng: 50.766648, lat: 51.692762 },
          { lng: 52.328724, lat: 51.718652 },
          { lng: 54.532878, lat: 51.02624 },
          { lng: 55.716941, lat: 50.621717 },
          { lng: 56.777961, lat: 51.043551 },
          { lng: 58.363291, lat: 51.063653 },
          { lng: 59.642282, lat: 50.545442 },
          { lng: 59.932807, lat: 50.842194 },
          { lng: 61.337424, lat: 50.79907 },
          { lng: 61.588003, lat: 51.272659 },
          { lng: 59.967534, lat: 51.96042 },
          { lng: 60.927269, lat: 52.447548 },
          { lng: 60.739993, lat: 52.719986 },
          { lng: 61.699986, lat: 52.979996 },
          { lng: 60.978066, lat: 53.664993 },
          { lng: 61.436591, lat: 54.006265 },
          { lng: 65.178534, lat: 54.354228 },
          { lng: 65.666876, lat: 54.601267 },
          { lng: 68.1691, lat: 54.970392 },
          { lng: 69.068167, lat: 55.38525 },
          { lng: 70.865267, lat: 55.169734 },
          { lng: 71.180131, lat: 54.133285 },
          { lng: 72.22415, lat: 54.376655 },
          { lng: 73.508516, lat: 54.035617 },
          { lng: 73.425679, lat: 53.48981 },
          { lng: 74.384845, lat: 53.546861 },
          { lng: 76.8911, lat: 54.490524 },
          { lng: 76.525179, lat: 54.177003 },
          { lng: 77.800916, lat: 53.404415 },
          { lng: 80.03556, lat: 50.864751 },
          { lng: 80.568447, lat: 51.388336 },
          { lng: 81.945986, lat: 50.812196 },
          { lng: 83.383004, lat: 51.069183 },
          { lng: 83.935115, lat: 50.889246 },
          { lng: 84.416377, lat: 50.3114 },
          { lng: 85.11556, lat: 50.117303 },
          { lng: 85.54127, lat: 49.692859 },
          { lng: 86.829357, lat: 49.826675 },
          { lng: 87.35997, lat: 49.214981 },
          { lng: 86.598776, lat: 48.549182 },
          { lng: 85.768233, lat: 48.455751 },
          { lng: 85.720484, lat: 47.452969 },
          { lng: 85.16429, lat: 47.000956 },
          { lng: 83.180484, lat: 47.330031 },
          { lng: 82.458926, lat: 45.53965 },
          { lng: 81.947071, lat: 45.317027 },
          { lng: 79.966106, lat: 44.917517 },
          { lng: 80.866206, lat: 43.180362 },
          { lng: 80.18015, lat: 42.920068 },
          { lng: 80.25999, lat: 42.349999 },
          { lng: 79.643645, lat: 42.496683 },
          { lng: 79.142177, lat: 42.856092 },
          { lng: 77.658392, lat: 42.960686 },
          { lng: 76.000354, lat: 42.988022 },
          { lng: 75.636965, lat: 42.8779 },
          { lng: 74.212866, lat: 43.298339 },
          { lng: 73.645304, lat: 43.091272 },
          { lng: 73.489758, lat: 42.500894 },
          { lng: 71.844638, lat: 42.845395 },
          { lng: 71.186281, lat: 42.704293 },
          { lng: 70.962315, lat: 42.266154 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'KEN',
    properties: { name: 'Kenya' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 40.993, lat: -0.85829 },
          { lng: 41.58513, lat: -1.68325 },
          { lng: 40.88477, lat: -2.08255 },
          { lng: 40.63785, lat: -2.49979 },
          { lng: 40.26304, lat: -2.57309 },
          { lng: 40.12119, lat: -3.27768 },
          { lng: 39.80006, lat: -3.68116 },
          { lng: 39.60489, lat: -4.34653 },
          { lng: 39.20222, lat: -4.67677 },
          { lng: 37.7669, lat: -3.67712 },
          { lng: 37.69869, lat: -3.09699 },
          { lng: 34.07262, lat: -1.05982 },
          { lng: 33.903711, lat: -0.95 },
          { lng: 33.893569, lat: 0.109814 },
          { lng: 34.18, lat: 0.515 },
          { lng: 34.6721, lat: 1.17694 },
          { lng: 35.03599, lat: 1.90584 },
          { lng: 34.59607, lat: 3.05374 },
          { lng: 34.47913, lat: 3.5556 },
          { lng: 34.005, lat: 4.249885 },
          { lng: 34.620196, lat: 4.847123 },
          { lng: 35.298007, lat: 5.506 },
          { lng: 35.817448, lat: 5.338232 },
          { lng: 35.817448, lat: 4.776966 },
          { lng: 36.159079, lat: 4.447864 },
          { lng: 36.855093, lat: 4.447864 },
          { lng: 38.120915, lat: 3.598605 },
          { lng: 38.43697, lat: 3.58851 },
          { lng: 38.67114, lat: 3.61607 },
          { lng: 38.89251, lat: 3.50074 },
          { lng: 39.559384, lat: 3.42206 },
          { lng: 39.85494, lat: 3.83879 },
          { lng: 40.76848, lat: 4.25702 },
          { lng: 41.1718, lat: 3.91909 },
          { lng: 41.855083, lat: 3.918912 },
          { lng: 40.98105, lat: 2.78452 },
          { lng: 40.993, lat: -0.85829 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'KGZ',
    properties: { name: 'Kyrgyzstan' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 70.962315, lat: 42.266154 },
          { lng: 71.186281, lat: 42.704293 },
          { lng: 71.844638, lat: 42.845395 },
          { lng: 73.489758, lat: 42.500894 },
          { lng: 73.645304, lat: 43.091272 },
          { lng: 74.212866, lat: 43.298339 },
          { lng: 75.636965, lat: 42.8779 },
          { lng: 76.000354, lat: 42.988022 },
          { lng: 77.658392, lat: 42.960686 },
          { lng: 79.142177, lat: 42.856092 },
          { lng: 79.643645, lat: 42.496683 },
          { lng: 80.25999, lat: 42.349999 },
          { lng: 80.11943, lat: 42.123941 },
          { lng: 78.543661, lat: 41.582243 },
          { lng: 78.187197, lat: 41.185316 },
          { lng: 76.904484, lat: 41.066486 },
          { lng: 76.526368, lat: 40.427946 },
          { lng: 75.467828, lat: 40.562072 },
          { lng: 74.776862, lat: 40.366425 },
          { lng: 73.822244, lat: 39.893973 },
          { lng: 73.960013, lat: 39.660008 },
          { lng: 73.675379, lat: 39.431237 },
          { lng: 71.784694, lat: 39.279463 },
          { lng: 70.549162, lat: 39.604198 },
          { lng: 69.464887, lat: 39.526683 },
          { lng: 69.55961, lat: 40.103211 },
          { lng: 70.648019, lat: 39.935754 },
          { lng: 71.014198, lat: 40.244366 },
          { lng: 71.774875, lat: 40.145844 },
          { lng: 73.055417, lat: 40.866033 },
          { lng: 71.870115, lat: 41.3929 },
          { lng: 71.157859, lat: 41.143587 },
          { lng: 70.420022, lat: 41.519998 },
          { lng: 71.259248, lat: 42.167711 },
          { lng: 70.962315, lat: 42.266154 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'KHM',
    properties: { name: 'Cambodia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 103.49728, lat: 10.632555 },
          { lng: 103.09069, lat: 11.153661 },
          { lng: 102.584932, lat: 12.186595 },
          { lng: 102.348099, lat: 13.394247 },
          { lng: 102.988422, lat: 14.225721 },
          { lng: 104.281418, lat: 14.416743 },
          { lng: 105.218777, lat: 14.273212 },
          { lng: 106.043946, lat: 13.881091 },
          { lng: 106.496373, lat: 14.570584 },
          { lng: 107.382727, lat: 14.202441 },
          { lng: 107.614548, lat: 13.535531 },
          { lng: 107.491403, lat: 12.337206 },
          { lng: 105.810524, lat: 11.567615 },
          { lng: 106.24967, lat: 10.961812 },
          { lng: 105.199915, lat: 10.88931 },
          { lng: 104.334335, lat: 10.486544 },
          { lng: 103.49728, lat: 10.632555 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'KOR',
    properties: { name: 'South Korea' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 128.349716, lat: 38.612243 },
          { lng: 129.21292, lat: 37.432392 },
          { lng: 129.46045, lat: 36.784189 },
          { lng: 129.468304, lat: 35.632141 },
          { lng: 129.091377, lat: 35.082484 },
          { lng: 128.18585, lat: 34.890377 },
          { lng: 127.386519, lat: 34.475674 },
          { lng: 126.485748, lat: 34.390046 },
          { lng: 126.37392, lat: 34.93456 },
          { lng: 126.559231, lat: 35.684541 },
          { lng: 126.117398, lat: 36.725485 },
          { lng: 126.860143, lat: 36.893924 },
          { lng: 126.174759, lat: 37.749686 },
          { lng: 126.237339, lat: 37.840378 },
          { lng: 126.68372, lat: 37.804773 },
          { lng: 127.073309, lat: 38.256115 },
          { lng: 127.780035, lat: 38.304536 },
          { lng: 128.205746, lat: 38.370397 },
          { lng: 128.349716, lat: 38.612243 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'CS-KM',
    properties: { name: 'Kosovo' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 20.76216, lat: 42.05186 },
          { lng: 20.71731, lat: 41.84711 },
          { lng: 20.59023, lat: 41.85541 },
          { lng: 20.52295, lat: 42.21787 },
          { lng: 20.28374, lat: 42.32025 },
          { lng: 20.0707, lat: 42.58863 },
          { lng: 20.25758, lat: 42.81275 },
          { lng: 20.49679, lat: 42.88469 },
          { lng: 20.63508, lat: 43.21671 },
          { lng: 20.81448, lat: 43.27205 },
          { lng: 20.95651, lat: 43.13094 },
          { lng: 21.143395, lat: 43.068685 },
          { lng: 21.27421, lat: 42.90959 },
          { lng: 21.43866, lat: 42.86255 },
          { lng: 21.63302, lat: 42.67717 },
          { lng: 21.77505, lat: 42.6827 },
          { lng: 21.66292, lat: 42.43922 },
          { lng: 21.54332, lat: 42.32025 },
          { lng: 21.576636, lat: 42.245224 },
          { lng: 21.3527, lat: 42.2068 },
          { lng: 20.76216, lat: 42.05186 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'KWT',
    properties: { name: 'Kuwait' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 47.974519, lat: 29.975819 },
          { lng: 48.183189, lat: 29.534477 },
          { lng: 48.093943, lat: 29.306299 },
          { lng: 48.416094, lat: 28.552004 },
          { lng: 47.708851, lat: 28.526063 },
          { lng: 47.459822, lat: 29.002519 },
          { lng: 46.568713, lat: 29.099025 },
          { lng: 47.302622, lat: 30.05907 },
          { lng: 47.974519, lat: 29.975819 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'LAO',
    properties: { name: 'Laos' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 105.218777, lat: 14.273212 },
          { lng: 105.544338, lat: 14.723934 },
          { lng: 105.589039, lat: 15.570316 },
          { lng: 104.779321, lat: 16.441865 },
          { lng: 104.716947, lat: 17.428859 },
          { lng: 103.956477, lat: 18.240954 },
          { lng: 103.200192, lat: 18.309632 },
          { lng: 102.998706, lat: 17.961695 },
          { lng: 102.413005, lat: 17.932782 },
          { lng: 102.113592, lat: 18.109102 },
          { lng: 101.059548, lat: 17.512497 },
          { lng: 101.035931, lat: 18.408928 },
          { lng: 101.282015, lat: 19.462585 },
          { lng: 100.606294, lat: 19.508344 },
          { lng: 100.548881, lat: 20.109238 },
          { lng: 100.115988, lat: 20.41785 },
          { lng: 100.329101, lat: 20.786122 },
          { lng: 101.180005, lat: 21.436573 },
          { lng: 101.270026, lat: 21.201652 },
          { lng: 101.80312, lat: 21.174367 },
          { lng: 101.652018, lat: 22.318199 },
          { lng: 102.170436, lat: 22.464753 },
          { lng: 102.754896, lat: 21.675137 },
          { lng: 103.203861, lat: 20.766562 },
          { lng: 104.435, lat: 20.758733 },
          { lng: 104.822574, lat: 19.886642 },
          { lng: 104.183388, lat: 19.624668 },
          { lng: 103.896532, lat: 19.265181 },
          { lng: 105.094598, lat: 18.666975 },
          { lng: 105.925762, lat: 17.485315 },
          { lng: 106.556008, lat: 16.604284 },
          { lng: 107.312706, lat: 15.908538 },
          { lng: 107.564525, lat: 15.202173 },
          { lng: 107.382727, lat: 14.202441 },
          { lng: 106.496373, lat: 14.570584 },
          { lng: 106.043946, lat: 13.881091 },
          { lng: 105.218777, lat: 14.273212 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'LBN',
    properties: { name: 'Lebanon' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 35.821101, lat: 33.277426 },
          { lng: 35.552797, lat: 33.264275 },
          { lng: 35.460709, lat: 33.08904 },
          { lng: 35.126053, lat: 33.0909 },
          { lng: 35.482207, lat: 33.90545 },
          { lng: 35.979592, lat: 34.610058 },
          { lng: 35.998403, lat: 34.644914 },
          { lng: 36.448194, lat: 34.593935 },
          { lng: 36.61175, lat: 34.201789 },
          { lng: 36.06646, lat: 33.824912 },
          { lng: 35.821101, lat: 33.277426 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'LBR',
    properties: { name: 'Liberia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -7.712159, lat: 4.364566 },
          { lng: -7.974107, lat: 4.355755 },
          { lng: -9.004794, lat: 4.832419 },
          { lng: -9.91342, lat: 5.593561 },
          { lng: -10.765384, lat: 6.140711 },
          { lng: -11.438779, lat: 6.785917 },
          { lng: -11.199802, lat: 7.105846 },
          { lng: -11.146704, lat: 7.396706 },
          { lng: -10.695595, lat: 7.939464 },
          { lng: -10.230094, lat: 8.406206 },
          { lng: -10.016567, lat: 8.428504 },
          { lng: -9.755342, lat: 8.541055 },
          { lng: -9.33728, lat: 7.928534 },
          { lng: -9.403348, lat: 7.526905 },
          { lng: -9.208786, lat: 7.313921 },
          { lng: -8.926065, lat: 7.309037 },
          { lng: -8.722124, lat: 7.711674 },
          { lng: -8.439298, lat: 7.686043 },
          { lng: -8.485446, lat: 7.395208 },
          { lng: -8.385452, lat: 6.911801 },
          { lng: -8.60288, lat: 6.467564 },
          { lng: -8.311348, lat: 6.193033 },
          { lng: -7.993693, lat: 6.12619 },
          { lng: -7.570153, lat: 5.707352 },
          { lng: -7.539715, lat: 5.313345 },
          { lng: -7.635368, lat: 5.188159 },
          { lng: -7.712159, lat: 4.364566 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'LBY',
    properties: { name: 'Libya' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 14.8513, lat: 22.86295 },
          { lng: 14.143871, lat: 22.491289 },
          { lng: 13.581425, lat: 23.040506 },
          { lng: 11.999506, lat: 23.471668 },
          { lng: 11.560669, lat: 24.097909 },
          { lng: 10.771364, lat: 24.562532 },
          { lng: 10.303847, lat: 24.379313 },
          { lng: 9.948261, lat: 24.936954 },
          { lng: 9.910693, lat: 25.365455 },
          { lng: 9.319411, lat: 26.094325 },
          { lng: 9.716286, lat: 26.512206 },
          { lng: 9.629056, lat: 27.140953 },
          { lng: 9.756128, lat: 27.688259 },
          { lng: 9.683885, lat: 28.144174 },
          { lng: 9.859998, lat: 28.95999 },
          { lng: 9.805634, lat: 29.424638 },
          { lng: 9.48214, lat: 30.307556 },
          { lng: 9.970017, lat: 30.539325 },
          { lng: 10.056575, lat: 30.961831 },
          { lng: 9.950225, lat: 31.37607 },
          { lng: 10.636901, lat: 31.761421 },
          { lng: 10.94479, lat: 32.081815 },
          { lng: 11.432253, lat: 32.368903 },
          { lng: 11.488787, lat: 33.136996 },
          { lng: 12.66331, lat: 32.79278 },
          { lng: 13.08326, lat: 32.87882 },
          { lng: 13.91868, lat: 32.71196 },
          { lng: 15.24563, lat: 32.26508 },
          { lng: 15.71394, lat: 31.37626 },
          { lng: 16.61162, lat: 31.18218 },
          { lng: 18.02109, lat: 30.76357 },
          { lng: 19.08641, lat: 30.26639 },
          { lng: 19.57404, lat: 30.52582 },
          { lng: 20.05335, lat: 30.98576 },
          { lng: 19.82033, lat: 31.75179 },
          { lng: 20.13397, lat: 32.2382 },
          { lng: 20.85452, lat: 32.7068 },
          { lng: 21.54298, lat: 32.8432 },
          { lng: 22.89576, lat: 32.63858 },
          { lng: 23.2368, lat: 32.19149 },
          { lng: 23.60913, lat: 32.18726 },
          { lng: 23.9275, lat: 32.01667 },
          { lng: 24.92114, lat: 31.89936 },
          { lng: 25.16482, lat: 31.56915 },
          { lng: 24.80287, lat: 31.08929 },
          { lng: 24.95762, lat: 30.6616 },
          { lng: 24.70007, lat: 30.04419 },
          { lng: 25, lat: 29.238655 },
          { lng: 25, lat: 25.6825 },
          { lng: 25, lat: 22 },
          { lng: 25, lat: 20.00304 },
          { lng: 23.85, lat: 20 },
          { lng: 23.83766, lat: 19.58047 },
          { lng: 19.84926, lat: 21.49509 },
          { lng: 15.86085, lat: 23.40972 },
          { lng: 14.8513, lat: 22.86295 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'LKA',
    properties: { name: 'Sri Lanka' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 81.787959, lat: 7.523055 },
          { lng: 81.637322, lat: 6.481775 },
          { lng: 81.21802, lat: 6.197141 },
          { lng: 80.348357, lat: 5.96837 },
          { lng: 79.872469, lat: 6.763463 },
          { lng: 79.695167, lat: 8.200843 },
          { lng: 80.147801, lat: 9.824078 },
          { lng: 80.838818, lat: 9.268427 },
          { lng: 81.304319, lat: 8.564206 },
          { lng: 81.787959, lat: 7.523055 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'LSO',
    properties: { name: 'Lesotho' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 28.978263, lat: -28.955597 },
          { lng: 29.325166, lat: -29.257387 },
          { lng: 29.018415, lat: -29.743766 },
          { lng: 28.8484, lat: -30.070051 },
          { lng: 28.291069, lat: -30.226217 },
          { lng: 28.107205, lat: -30.545732 },
          { lng: 27.749397, lat: -30.645106 },
          { lng: 26.999262, lat: -29.875954 },
          { lng: 27.532511, lat: -29.242711 },
          { lng: 28.074338, lat: -28.851469 },
          { lng: 28.5417, lat: -28.647502 },
          { lng: 28.978263, lat: -28.955597 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'LTU',
    properties: { name: 'Lithuania' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 22.731099, lat: 54.327537 },
          { lng: 22.651052, lat: 54.582741 },
          { lng: 22.757764, lat: 54.856574 },
          { lng: 22.315724, lat: 55.015299 },
          { lng: 21.268449, lat: 55.190482 },
          { lng: 21.0558, lat: 56.031076 },
          { lng: 22.201157, lat: 56.337802 },
          { lng: 23.878264, lat: 56.273671 },
          { lng: 24.860684, lat: 56.372528 },
          { lng: 25.000934, lat: 56.164531 },
          { lng: 25.533047, lat: 56.100297 },
          { lng: 26.494331, lat: 55.615107 },
          { lng: 26.588279, lat: 55.167176 },
          { lng: 25.768433, lat: 54.846963 },
          { lng: 25.536354, lat: 54.282423 },
          { lng: 24.450684, lat: 53.905702 },
          { lng: 23.484128, lat: 53.912498 },
          { lng: 23.243987, lat: 54.220567 },
          { lng: 22.731099, lat: 54.327537 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'LUX',
    properties: { name: 'Luxembourg' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 6.043073, lat: 50.128052 },
          { lng: 6.242751, lat: 49.902226 },
          { lng: 6.18632, lat: 49.463803 },
          { lng: 5.897759, lat: 49.442667 },
          { lng: 5.674052, lat: 49.529484 },
          { lng: 5.782417, lat: 50.090328 },
          { lng: 6.043073, lat: 50.128052 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'LVA',
    properties: { name: 'Latvia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 21.0558, lat: 56.031076 },
          { lng: 21.090424, lat: 56.783873 },
          { lng: 21.581866, lat: 57.411871 },
          { lng: 22.524341, lat: 57.753374 },
          { lng: 23.318453, lat: 57.006236 },
          { lng: 24.12073, lat: 57.025693 },
          { lng: 24.312863, lat: 57.793424 },
          { lng: 25.164594, lat: 57.970157 },
          { lng: 25.60281, lat: 57.847529 },
          { lng: 26.463532, lat: 57.476389 },
          { lng: 27.288185, lat: 57.474528 },
          { lng: 27.770016, lat: 57.244258 },
          { lng: 27.855282, lat: 56.759326 },
          { lng: 28.176709, lat: 56.16913 },
          { lng: 27.10246, lat: 55.783314 },
          { lng: 26.494331, lat: 55.615107 },
          { lng: 25.533047, lat: 56.100297 },
          { lng: 25.000934, lat: 56.164531 },
          { lng: 24.860684, lat: 56.372528 },
          { lng: 23.878264, lat: 56.273671 },
          { lng: 22.201157, lat: 56.337802 },
          { lng: 21.0558, lat: 56.031076 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'MAR',
    properties: { name: 'Morocco' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -5.193863, lat: 35.755182 },
          { lng: -4.591006, lat: 35.330712 },
          { lng: -3.640057, lat: 35.399855 },
          { lng: -2.604306, lat: 35.179093 },
          { lng: -2.169914, lat: 35.168396 },
          { lng: -1.792986, lat: 34.527919 },
          { lng: -1.733455, lat: 33.919713 },
          { lng: -1.388049, lat: 32.864015 },
          { lng: -1.124551, lat: 32.651522 },
          { lng: -1.307899, lat: 32.262889 },
          { lng: -2.616605, lat: 32.094346 },
          { lng: -3.06898, lat: 31.724498 },
          { lng: -3.647498, lat: 31.637294 },
          { lng: -3.690441, lat: 30.896952 },
          { lng: -4.859646, lat: 30.501188 },
          { lng: -5.242129, lat: 30.000443 },
          { lng: -6.060632, lat: 29.7317 },
          { lng: -7.059228, lat: 29.579228 },
          { lng: -8.674116, lat: 28.841289 },
          { lng: -8.66559, lat: 27.656426 },
          { lng: -8.817809, lat: 27.656426 },
          { lng: -8.817828, lat: 27.656426 },
          { lng: -8.794884, lat: 27.120696 },
          { lng: -9.413037, lat: 27.088476 },
          { lng: -9.735343, lat: 26.860945 },
          { lng: -10.189424, lat: 26.860945 },
          { lng: -10.551263, lat: 26.990808 },
          { lng: -11.392555, lat: 26.883424 },
          { lng: -11.71822, lat: 26.104092 },
          { lng: -12.030759, lat: 26.030866 },
          { lng: -12.500963, lat: 24.770116 },
          { lng: -13.89111, lat: 23.691009 },
          { lng: -14.221168, lat: 22.310163 },
          { lng: -14.630833, lat: 21.86094 },
          { lng: -14.750955, lat: 21.5006 },
          { lng: -17.002962, lat: 21.420734 },
          { lng: -17.020428, lat: 21.42231 },
          { lng: -16.973248, lat: 21.885745 },
          { lng: -16.589137, lat: 22.158234 },
          { lng: -16.261922, lat: 22.67934 },
          { lng: -16.326414, lat: 23.017768 },
          { lng: -15.982611, lat: 23.723358 },
          { lng: -15.426004, lat: 24.359134 },
          { lng: -15.089332, lat: 24.520261 },
          { lng: -14.824645, lat: 25.103533 },
          { lng: -14.800926, lat: 25.636265 },
          { lng: -14.43994, lat: 26.254418 },
          { lng: -13.773805, lat: 26.618892 },
          { lng: -13.139942, lat: 27.640148 },
          { lng: -13.121613, lat: 27.654148 },
          { lng: -12.618837, lat: 28.038186 },
          { lng: -11.688919, lat: 28.148644 },
          { lng: -10.900957, lat: 28.832142 },
          { lng: -10.399592, lat: 29.098586 },
          { lng: -9.564811, lat: 29.933574 },
          { lng: -9.814718, lat: 31.177736 },
          { lng: -9.434793, lat: 32.038096 },
          { lng: -9.300693, lat: 32.564679 },
          { lng: -8.657476, lat: 33.240245 },
          { lng: -7.654178, lat: 33.697065 },
          { lng: -6.912544, lat: 34.110476 },
          { lng: -6.244342, lat: 35.145865 },
          { lng: -5.929994, lat: 35.759988 },
          { lng: -5.193863, lat: 35.755182 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'MDA',
    properties: { name: 'Moldova' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 26.619337, lat: 48.220726 },
          { lng: 26.857824, lat: 48.368211 },
          { lng: 27.522537, lat: 48.467119 },
          { lng: 28.259547, lat: 48.155562 },
          { lng: 28.670891, lat: 48.118149 },
          { lng: 29.122698, lat: 47.849095 },
          { lng: 29.050868, lat: 47.510227 },
          { lng: 29.415135, lat: 47.346645 },
          { lng: 29.559674, lat: 46.928583 },
          { lng: 29.908852, lat: 46.674361 },
          { lng: 29.83821, lat: 46.525326 },
          { lng: 30.024659, lat: 46.423937 },
          { lng: 29.759972, lat: 46.349988 },
          { lng: 29.170654, lat: 46.379262 },
          { lng: 29.072107, lat: 46.517678 },
          { lng: 28.862972, lat: 46.437889 },
          { lng: 28.933717, lat: 46.25883 },
          { lng: 28.659987, lat: 45.939987 },
          { lng: 28.485269, lat: 45.596907 },
          { lng: 28.233554, lat: 45.488283 },
          { lng: 28.054443, lat: 45.944586 },
          { lng: 28.160018, lat: 46.371563 },
          { lng: 28.12803, lat: 46.810476 },
          { lng: 27.551166, lat: 47.405117 },
          { lng: 27.233873, lat: 47.826771 },
          { lng: 26.924176, lat: 48.123264 },
          { lng: 26.619337, lat: 48.220726 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'MDG',
    properties: { name: 'Madagascar' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 49.543519, lat: -12.469833 },
          { lng: 49.808981, lat: -12.895285 },
          { lng: 50.056511, lat: -13.555761 },
          { lng: 50.217431, lat: -14.758789 },
          { lng: 50.476537, lat: -15.226512 },
          { lng: 50.377111, lat: -15.706069 },
          { lng: 50.200275, lat: -16.000263 },
          { lng: 49.860606, lat: -15.414253 },
          { lng: 49.672607, lat: -15.710204 },
          { lng: 49.863344, lat: -16.451037 },
          { lng: 49.774564, lat: -16.875042 },
          { lng: 49.498612, lat: -17.106036 },
          { lng: 49.435619, lat: -17.953064 },
          { lng: 49.041792, lat: -19.118781 },
          { lng: 48.548541, lat: -20.496888 },
          { lng: 47.930749, lat: -22.391501 },
          { lng: 47.547723, lat: -23.781959 },
          { lng: 47.095761, lat: -24.94163 },
          { lng: 46.282478, lat: -25.178463 },
          { lng: 45.409508, lat: -25.601434 },
          { lng: 44.833574, lat: -25.346101 },
          { lng: 44.03972, lat: -24.988345 },
          { lng: 43.763768, lat: -24.460677 },
          { lng: 43.697778, lat: -23.574116 },
          { lng: 43.345654, lat: -22.776904 },
          { lng: 43.254187, lat: -22.057413 },
          { lng: 43.433298, lat: -21.336475 },
          { lng: 43.893683, lat: -21.163307 },
          { lng: 43.89637, lat: -20.830459 },
          { lng: 44.374325, lat: -20.072366 },
          { lng: 44.464397, lat: -19.435454 },
          { lng: 44.232422, lat: -18.961995 },
          { lng: 44.042976, lat: -18.331387 },
          { lng: 43.963084, lat: -17.409945 },
          { lng: 44.312469, lat: -16.850496 },
          { lng: 44.446517, lat: -16.216219 },
          { lng: 44.944937, lat: -16.179374 },
          { lng: 45.502732, lat: -15.974373 },
          { lng: 45.872994, lat: -15.793454 },
          { lng: 46.312243, lat: -15.780018 },
          { lng: 46.882183, lat: -15.210182 },
          { lng: 47.70513, lat: -14.594303 },
          { lng: 48.005215, lat: -14.091233 },
          { lng: 47.869047, lat: -13.663869 },
          { lng: 48.293828, lat: -13.784068 },
          { lng: 48.84506, lat: -13.089175 },
          { lng: 48.863509, lat: -12.487868 },
          { lng: 49.194651, lat: -12.040557 },
          { lng: 49.543519, lat: -12.469833 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'MEX',
    properties: { name: 'Mexico' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -97.140008, lat: 25.869997 },
          { lng: -97.528072, lat: 24.992144 },
          { lng: -97.702946, lat: 24.272343 },
          { lng: -97.776042, lat: 22.93258 },
          { lng: -97.872367, lat: 22.444212 },
          { lng: -97.699044, lat: 21.898689 },
          { lng: -97.38896, lat: 21.411019 },
          { lng: -97.189333, lat: 20.635433 },
          { lng: -96.525576, lat: 19.890931 },
          { lng: -96.292127, lat: 19.320371 },
          { lng: -95.900885, lat: 18.828024 },
          { lng: -94.839063, lat: 18.562717 },
          { lng: -94.42573, lat: 18.144371 },
          { lng: -93.548651, lat: 18.423837 },
          { lng: -92.786114, lat: 18.524839 },
          { lng: -92.037348, lat: 18.704569 },
          { lng: -91.407903, lat: 18.876083 },
          { lng: -90.77187, lat: 19.28412 },
          { lng: -90.53359, lat: 19.867418 },
          { lng: -90.451476, lat: 20.707522 },
          { lng: -90.278618, lat: 20.999855 },
          { lng: -89.601321, lat: 21.261726 },
          { lng: -88.543866, lat: 21.493675 },
          { lng: -87.658417, lat: 21.458846 },
          { lng: -87.05189, lat: 21.543543 },
          { lng: -86.811982, lat: 21.331515 },
          { lng: -86.845908, lat: 20.849865 },
          { lng: -87.383291, lat: 20.255405 },
          { lng: -87.621054, lat: 19.646553 },
          { lng: -87.43675, lat: 19.472403 },
          { lng: -87.58656, lat: 19.04013 },
          { lng: -87.837191, lat: 18.259816 },
          { lng: -88.090664, lat: 18.516648 },
          { lng: -88.300031, lat: 18.499982 },
          { lng: -88.490123, lat: 18.486831 },
          { lng: -88.848344, lat: 17.883198 },
          { lng: -89.029857, lat: 18.001511 },
          { lng: -89.150909, lat: 17.955468 },
          { lng: -89.14308, lat: 17.808319 },
          { lng: -90.067934, lat: 17.819326 },
          { lng: -91.00152, lat: 17.817595 },
          { lng: -91.002269, lat: 17.254658 },
          { lng: -91.453921, lat: 17.252177 },
          { lng: -91.08167, lat: 16.918477 },
          { lng: -90.711822, lat: 16.687483 },
          { lng: -90.600847, lat: 16.470778 },
          { lng: -90.438867, lat: 16.41011 },
          { lng: -90.464473, lat: 16.069562 },
          { lng: -91.74796, lat: 16.066565 },
          { lng: -92.229249, lat: 15.251447 },
          { lng: -92.087216, lat: 15.064585 },
          { lng: -92.20323, lat: 14.830103 },
          { lng: -92.22775, lat: 14.538829 },
          { lng: -93.359464, lat: 15.61543 },
          { lng: -93.875169, lat: 15.940164 },
          { lng: -94.691656, lat: 16.200975 },
          { lng: -95.250227, lat: 16.128318 },
          { lng: -96.053382, lat: 15.752088 },
          { lng: -96.557434, lat: 15.653515 },
          { lng: -97.263592, lat: 15.917065 },
          { lng: -98.01303, lat: 16.107312 },
          { lng: -98.947676, lat: 16.566043 },
          { lng: -99.697397, lat: 16.706164 },
          { lng: -100.829499, lat: 17.171071 },
          { lng: -101.666089, lat: 17.649026 },
          { lng: -101.918528, lat: 17.91609 },
          { lng: -102.478132, lat: 17.975751 },
          { lng: -103.50099, lat: 18.292295 },
          { lng: -103.917527, lat: 18.748572 },
          { lng: -104.99201, lat: 19.316134 },
          { lng: -105.493038, lat: 19.946767 },
          { lng: -105.731396, lat: 20.434102 },
          { lng: -105.397773, lat: 20.531719 },
          { lng: -105.500661, lat: 20.816895 },
          { lng: -105.270752, lat: 21.076285 },
          { lng: -105.265817, lat: 21.422104 },
          { lng: -105.603161, lat: 21.871146 },
          { lng: -105.693414, lat: 22.26908 },
          { lng: -106.028716, lat: 22.773752 },
          { lng: -106.90998, lat: 23.767774 },
          { lng: -107.915449, lat: 24.548915 },
          { lng: -108.401905, lat: 25.172314 },
          { lng: -109.260199, lat: 25.580609 },
          { lng: -109.444089, lat: 25.824884 },
          { lng: -109.291644, lat: 26.442934 },
          { lng: -109.801458, lat: 26.676176 },
          { lng: -110.391732, lat: 27.162115 },
          { lng: -110.641019, lat: 27.859876 },
          { lng: -111.178919, lat: 27.941241 },
          { lng: -111.759607, lat: 28.467953 },
          { lng: -112.228235, lat: 28.954409 },
          { lng: -112.271824, lat: 29.266844 },
          { lng: -112.809594, lat: 30.021114 },
          { lng: -113.163811, lat: 30.786881 },
          { lng: -113.148669, lat: 31.170966 },
          { lng: -113.871881, lat: 31.567608 },
          { lng: -114.205737, lat: 31.524045 },
          { lng: -114.776451, lat: 31.799532 },
          { lng: -114.9367, lat: 31.393485 },
          { lng: -114.771232, lat: 30.913617 },
          { lng: -114.673899, lat: 30.162681 },
          { lng: -114.330974, lat: 29.750432 },
          { lng: -113.588875, lat: 29.061611 },
          { lng: -113.424053, lat: 28.826174 },
          { lng: -113.271969, lat: 28.754783 },
          { lng: -113.140039, lat: 28.411289 },
          { lng: -112.962298, lat: 28.42519 },
          { lng: -112.761587, lat: 27.780217 },
          { lng: -112.457911, lat: 27.525814 },
          { lng: -112.244952, lat: 27.171727 },
          { lng: -111.616489, lat: 26.662817 },
          { lng: -111.284675, lat: 25.73259 },
          { lng: -110.987819, lat: 25.294606 },
          { lng: -110.710007, lat: 24.826004 },
          { lng: -110.655049, lat: 24.298595 },
          { lng: -110.172856, lat: 24.265548 },
          { lng: -109.771847, lat: 23.811183 },
          { lng: -109.409104, lat: 23.364672 },
          { lng: -109.433392, lat: 23.185588 },
          { lng: -109.854219, lat: 22.818272 },
          { lng: -110.031392, lat: 22.823078 },
          { lng: -110.295071, lat: 23.430973 },
          { lng: -110.949501, lat: 24.000964 },
          { lng: -111.670568, lat: 24.484423 },
          { lng: -112.182036, lat: 24.738413 },
          { lng: -112.148989, lat: 25.470125 },
          { lng: -112.300711, lat: 26.012004 },
          { lng: -112.777297, lat: 26.32196 },
          { lng: -113.464671, lat: 26.768186 },
          { lng: -113.59673, lat: 26.63946 },
          { lng: -113.848937, lat: 26.900064 },
          { lng: -114.465747, lat: 27.14209 },
          { lng: -115.055142, lat: 27.722727 },
          { lng: -114.982253, lat: 27.7982 },
          { lng: -114.570366, lat: 27.741485 },
          { lng: -114.199329, lat: 28.115003 },
          { lng: -114.162018, lat: 28.566112 },
          { lng: -114.931842, lat: 29.279479 },
          { lng: -115.518654, lat: 29.556362 },
          { lng: -115.887365, lat: 30.180794 },
          { lng: -116.25835, lat: 30.836464 },
          { lng: -116.721526, lat: 31.635744 },
          { lng: -117.12776, lat: 32.53534 },
          { lng: -115.99135, lat: 32.61239 },
          { lng: -114.72139, lat: 32.72083 },
          { lng: -114.815, lat: 32.52528 },
          { lng: -113.30498, lat: 32.03914 },
          { lng: -111.02361, lat: 31.33472 },
          { lng: -109.035, lat: 31.34194 },
          { lng: -108.24194, lat: 31.34222 },
          { lng: -108.24, lat: 31.754854 },
          { lng: -106.50759, lat: 31.75452 },
          { lng: -106.1429, lat: 31.39995 },
          { lng: -105.63159, lat: 31.08383 },
          { lng: -105.03737, lat: 30.64402 },
          { lng: -104.70575, lat: 30.12173 },
          { lng: -104.45697, lat: 29.57196 },
          { lng: -103.94, lat: 29.27 },
          { lng: -103.11, lat: 28.97 },
          { lng: -102.48, lat: 29.76 },
          { lng: -101.6624, lat: 29.7793 },
          { lng: -100.9576, lat: 29.38071 },
          { lng: -100.45584, lat: 28.69612 },
          { lng: -100.11, lat: 28.11 },
          { lng: -99.52, lat: 27.54 },
          { lng: -99.3, lat: 26.84 },
          { lng: -99.02, lat: 26.37 },
          { lng: -98.24, lat: 26.06 },
          { lng: -97.53, lat: 25.84 },
          { lng: -97.140008, lat: 25.869997 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'MKD',
    properties: { name: 'Macedonia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 20.59023, lat: 41.85541 },
          { lng: 20.71731, lat: 41.84711 },
          { lng: 20.76216, lat: 42.05186 },
          { lng: 21.3527, lat: 42.2068 },
          { lng: 21.576636, lat: 42.245224 },
          { lng: 21.91708, lat: 42.30364 },
          { lng: 22.380526, lat: 42.32026 },
          { lng: 22.881374, lat: 41.999297 },
          { lng: 22.952377, lat: 41.337994 },
          { lng: 22.76177, lat: 41.3048 },
          { lng: 22.597308, lat: 41.130487 },
          { lng: 22.055378, lat: 41.149866 },
          { lng: 21.674161, lat: 40.931275 },
          { lng: 21.02004, lat: 40.842727 },
          { lng: 20.60518, lat: 41.08622 },
          { lng: 20.46315, lat: 41.51509 },
          { lng: 20.59023, lat: 41.85541 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'MLI',
    properties: { name: 'Mali' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -12.17075, lat: 14.616834 },
          { lng: -11.834208, lat: 14.799097 },
          { lng: -11.666078, lat: 15.388208 },
          { lng: -11.349095, lat: 15.411256 },
          { lng: -10.650791, lat: 15.132746 },
          { lng: -10.086846, lat: 15.330486 },
          { lng: -9.700255, lat: 15.264107 },
          { lng: -9.550238, lat: 15.486497 },
          { lng: -5.537744, lat: 15.50169 },
          { lng: -5.315277, lat: 16.201854 },
          { lng: -5.488523, lat: 16.325102 },
          { lng: -5.971129, lat: 20.640833 },
          { lng: -6.453787, lat: 24.956591 },
          { lng: -4.923337, lat: 24.974574 },
          { lng: -1.550055, lat: 22.792666 },
          { lng: 1.823228, lat: 20.610809 },
          { lng: 2.060991, lat: 20.142233 },
          { lng: 2.683588, lat: 19.85623 },
          { lng: 3.146661, lat: 19.693579 },
          { lng: 3.158133, lat: 19.057364 },
          { lng: 4.267419, lat: 19.155265 },
          { lng: 4.27021, lat: 16.852227 },
          { lng: 3.723422, lat: 16.184284 },
          { lng: 3.638259, lat: 15.56812 },
          { lng: 2.749993, lat: 15.409525 },
          { lng: 1.385528, lat: 15.323561 },
          { lng: 1.015783, lat: 14.968182 },
          { lng: 0.374892, lat: 14.928908 },
          { lng: -0.266257, lat: 14.924309 },
          { lng: -0.515854, lat: 15.116158 },
          { lng: -1.066363, lat: 14.973815 },
          { lng: -2.001035, lat: 14.559008 },
          { lng: -2.191825, lat: 14.246418 },
          { lng: -2.967694, lat: 13.79815 },
          { lng: -3.103707, lat: 13.541267 },
          { lng: -3.522803, lat: 13.337662 },
          { lng: -4.006391, lat: 13.472485 },
          { lng: -4.280405, lat: 13.228444 },
          { lng: -4.427166, lat: 12.542646 },
          { lng: -5.220942, lat: 11.713859 },
          { lng: -5.197843, lat: 11.375146 },
          { lng: -5.470565, lat: 10.95127 },
          { lng: -5.404342, lat: 10.370737 },
          { lng: -5.816926, lat: 10.222555 },
          { lng: -6.050452, lat: 10.096361 },
          { lng: -6.205223, lat: 10.524061 },
          { lng: -6.493965, lat: 10.411303 },
          { lng: -6.666461, lat: 10.430811 },
          { lng: -6.850507, lat: 10.138994 },
          { lng: -7.622759, lat: 10.147236 },
          { lng: -7.89959, lat: 10.297382 },
          { lng: -8.029944, lat: 10.206535 },
          { lng: -8.335377, lat: 10.494812 },
          { lng: -8.282357, lat: 10.792597 },
          { lng: -8.407311, lat: 10.909257 },
          { lng: -8.620321, lat: 10.810891 },
          { lng: -8.581305, lat: 11.136246 },
          { lng: -8.376305, lat: 11.393646 },
          { lng: -8.786099, lat: 11.812561 },
          { lng: -8.905265, lat: 12.088358 },
          { lng: -9.127474, lat: 12.30806 },
          { lng: -9.327616, lat: 12.334286 },
          { lng: -9.567912, lat: 12.194243 },
          { lng: -9.890993, lat: 12.060479 },
          { lng: -10.165214, lat: 11.844084 },
          { lng: -10.593224, lat: 11.923975 },
          { lng: -10.87083, lat: 12.177887 },
          { lng: -11.036556, lat: 12.211245 },
          { lng: -11.297574, lat: 12.077971 },
          { lng: -11.456169, lat: 12.076834 },
          { lng: -11.513943, lat: 12.442988 },
          { lng: -11.467899, lat: 12.754519 },
          { lng: -11.553398, lat: 13.141214 },
          { lng: -11.927716, lat: 13.422075 },
          { lng: -12.124887, lat: 13.994727 },
          { lng: -12.17075, lat: 14.616834 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'MLT',
    properties: { name: 'Malta' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 14.566171, lat: 35.852721 },
          { lng: 14.532684, lat: 35.820191 },
          { lng: 14.436463, lat: 35.821664 },
          { lng: 14.352334, lat: 35.872281 },
          { lng: 14.3513, lat: 35.978399 },
          { lng: 14.448348, lat: 35.957444 },
          { lng: 14.537025, lat: 35.886285 },
          { lng: 14.566171, lat: 35.852721 },
        ],
        [
          { lng: 14.313473, lat: 36.027569 },
          { lng: 14.253632, lat: 36.012143 },
          { lng: 14.194204, lat: 36.042245 },
          { lng: 14.180354, lat: 36.060383 },
          { lng: 14.263243, lat: 36.075809 },
          { lng: 14.303758, lat: 36.062295 },
          { lng: 14.320914, lat: 36.03625 },
          { lng: 14.313473, lat: 36.027569 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'MMR',
    properties: { name: 'Myanmar' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 99.543309, lat: 20.186598 },
          { lng: 98.959676, lat: 19.752981 },
          { lng: 98.253724, lat: 19.708203 },
          { lng: 97.797783, lat: 18.62708 },
          { lng: 97.375896, lat: 18.445438 },
          { lng: 97.859123, lat: 17.567946 },
          { lng: 98.493761, lat: 16.837836 },
          { lng: 98.903348, lat: 16.177824 },
          { lng: 98.537376, lat: 15.308497 },
          { lng: 98.192074, lat: 15.123703 },
          { lng: 98.430819, lat: 14.622028 },
          { lng: 99.097755, lat: 13.827503 },
          { lng: 99.212012, lat: 13.269294 },
          { lng: 99.196354, lat: 12.804748 },
          { lng: 99.587286, lat: 11.892763 },
          { lng: 99.038121, lat: 10.960546 },
          { lng: 98.553551, lat: 9.93296 },
          { lng: 98.457174, lat: 10.675266 },
          { lng: 98.764546, lat: 11.441292 },
          { lng: 98.428339, lat: 12.032987 },
          { lng: 98.509574, lat: 13.122378 },
          { lng: 98.103604, lat: 13.64046 },
          { lng: 97.777732, lat: 14.837286 },
          { lng: 97.597072, lat: 16.100568 },
          { lng: 97.16454, lat: 16.928734 },
          { lng: 96.505769, lat: 16.427241 },
          { lng: 95.369352, lat: 15.71439 },
          { lng: 94.808405, lat: 15.803454 },
          { lng: 94.188804, lat: 16.037936 },
          { lng: 94.533486, lat: 17.27724 },
          { lng: 94.324817, lat: 18.213514 },
          { lng: 93.540988, lat: 19.366493 },
          { lng: 93.663255, lat: 19.726962 },
          { lng: 93.078278, lat: 19.855145 },
          { lng: 92.368554, lat: 20.670883 },
          { lng: 92.303234, lat: 21.475485 },
          { lng: 92.652257, lat: 21.324048 },
          { lng: 92.672721, lat: 22.041239 },
          { lng: 93.166128, lat: 22.27846 },
          { lng: 93.060294, lat: 22.703111 },
          { lng: 93.286327, lat: 23.043658 },
          { lng: 93.325188, lat: 24.078556 },
          { lng: 94.106742, lat: 23.850741 },
          { lng: 94.552658, lat: 24.675238 },
          { lng: 94.603249, lat: 25.162495 },
          { lng: 95.155153, lat: 26.001307 },
          { lng: 95.124768, lat: 26.573572 },
          { lng: 96.419366, lat: 27.264589 },
          { lng: 97.133999, lat: 27.083774 },
          { lng: 97.051989, lat: 27.699059 },
          { lng: 97.402561, lat: 27.882536 },
          { lng: 97.327114, lat: 28.261583 },
          { lng: 97.911988, lat: 28.335945 },
          { lng: 98.246231, lat: 27.747221 },
          { lng: 98.68269, lat: 27.508812 },
          { lng: 98.712094, lat: 26.743536 },
          { lng: 98.671838, lat: 25.918703 },
          { lng: 97.724609, lat: 25.083637 },
          { lng: 97.60472, lat: 23.897405 },
          { lng: 98.660262, lat: 24.063286 },
          { lng: 98.898749, lat: 23.142722 },
          { lng: 99.531992, lat: 22.949039 },
          { lng: 99.240899, lat: 22.118314 },
          { lng: 99.983489, lat: 21.742937 },
          { lng: 100.416538, lat: 21.558839 },
          { lng: 101.150033, lat: 21.849984 },
          { lng: 101.180005, lat: 21.436573 },
          { lng: 100.329101, lat: 20.786122 },
          { lng: 100.115988, lat: 20.41785 },
          { lng: 99.543309, lat: 20.186598 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'MNE',
    properties: { name: 'Montenegro' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 19.801613, lat: 42.500093 },
          { lng: 19.738051, lat: 42.688247 },
          { lng: 19.30449, lat: 42.19574 },
          { lng: 19.37177, lat: 41.87755 },
          { lng: 19.16246, lat: 41.95502 },
          { lng: 18.88214, lat: 42.28151 },
          { lng: 18.45, lat: 42.48 },
          { lng: 18.56, lat: 42.65 },
          { lng: 18.70648, lat: 43.20011 },
          { lng: 19.03165, lat: 43.43253 },
          { lng: 19.21852, lat: 43.52384 },
          { lng: 19.48389, lat: 43.35229 },
          { lng: 19.63, lat: 43.21378 },
          { lng: 19.95857, lat: 43.10604 },
          { lng: 20.3398, lat: 42.89852 },
          { lng: 20.25758, lat: 42.81275 },
          { lng: 20.0707, lat: 42.58863 },
          { lng: 19.801613, lat: 42.500093 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'MNG',
    properties: { name: 'Mongolia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 87.751264, lat: 49.297198 },
          { lng: 88.805567, lat: 49.470521 },
          { lng: 90.713667, lat: 50.331812 },
          { lng: 92.234712, lat: 50.802171 },
          { lng: 93.104219, lat: 50.49529 },
          { lng: 94.147566, lat: 50.480537 },
          { lng: 94.815949, lat: 50.013433 },
          { lng: 95.814028, lat: 49.977467 },
          { lng: 97.259728, lat: 49.726061 },
          { lng: 98.231762, lat: 50.422401 },
          { lng: 97.82574, lat: 51.010995 },
          { lng: 98.861491, lat: 52.047366 },
          { lng: 99.981732, lat: 51.634006 },
          { lng: 100.88948, lat: 51.516856 },
          { lng: 102.065223, lat: 51.259921 },
          { lng: 102.255909, lat: 50.510561 },
          { lng: 103.676545, lat: 50.089966 },
          { lng: 104.621552, lat: 50.275329 },
          { lng: 105.886591, lat: 50.406019 },
          { lng: 106.888804, lat: 50.274296 },
          { lng: 107.868176, lat: 49.793705 },
          { lng: 108.475167, lat: 49.282548 },
          { lng: 109.402449, lat: 49.292961 },
          { lng: 110.662011, lat: 49.130128 },
          { lng: 111.581231, lat: 49.377968 },
          { lng: 112.89774, lat: 49.543565 },
          { lng: 114.362456, lat: 50.248303 },
          { lng: 114.96211, lat: 50.140247 },
          { lng: 115.485695, lat: 49.805177 },
          { lng: 116.678801, lat: 49.888531 },
          { lng: 116.191802, lat: 49.134598 },
          { lng: 115.485282, lat: 48.135383 },
          { lng: 115.742837, lat: 47.726545 },
          { lng: 116.308953, lat: 47.85341 },
          { lng: 117.295507, lat: 47.697709 },
          { lng: 118.064143, lat: 48.06673 },
          { lng: 118.866574, lat: 47.74706 },
          { lng: 119.772824, lat: 47.048059 },
          { lng: 119.66327, lat: 46.69268 },
          { lng: 118.874326, lat: 46.805412 },
          { lng: 117.421701, lat: 46.672733 },
          { lng: 116.717868, lat: 46.388202 },
          { lng: 115.985096, lat: 45.727235 },
          { lng: 114.460332, lat: 45.339817 },
          { lng: 113.463907, lat: 44.808893 },
          { lng: 112.436062, lat: 45.011646 },
          { lng: 111.873306, lat: 45.102079 },
          { lng: 111.348377, lat: 44.457442 },
          { lng: 111.667737, lat: 44.073176 },
          { lng: 111.829588, lat: 43.743118 },
          { lng: 111.129682, lat: 43.406834 },
          { lng: 110.412103, lat: 42.871234 },
          { lng: 109.243596, lat: 42.519446 },
          { lng: 107.744773, lat: 42.481516 },
          { lng: 106.129316, lat: 42.134328 },
          { lng: 104.964994, lat: 41.59741 },
          { lng: 104.522282, lat: 41.908347 },
          { lng: 103.312278, lat: 41.907468 },
          { lng: 101.83304, lat: 42.514873 },
          { lng: 100.845866, lat: 42.663804 },
          { lng: 99.515817, lat: 42.524691 },
          { lng: 97.451757, lat: 42.74889 },
          { lng: 96.349396, lat: 42.725635 },
          { lng: 95.762455, lat: 43.319449 },
          { lng: 95.306875, lat: 44.241331 },
          { lng: 94.688929, lat: 44.352332 },
          { lng: 93.480734, lat: 44.975472 },
          { lng: 92.133891, lat: 45.115076 },
          { lng: 90.94554, lat: 45.286073 },
          { lng: 90.585768, lat: 45.719716 },
          { lng: 90.970809, lat: 46.888146 },
          { lng: 90.280826, lat: 47.693549 },
          { lng: 88.854298, lat: 48.069082 },
          { lng: 88.013832, lat: 48.599463 },
          { lng: 87.751264, lat: 49.297198 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'MOZ',
    properties: { name: 'Mozambique' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 34.559989, lat: -11.52002 },
          { lng: 35.312398, lat: -11.439146 },
          { lng: 36.514082, lat: -11.720938 },
          { lng: 36.775151, lat: -11.594537 },
          { lng: 37.471284, lat: -11.568751 },
          { lng: 37.827645, lat: -11.268769 },
          { lng: 38.427557, lat: -11.285202 },
          { lng: 39.52103, lat: -10.896854 },
          { lng: 40.316589, lat: -10.317096 },
          { lng: 40.478387, lat: -10.765441 },
          { lng: 40.437253, lat: -11.761711 },
          { lng: 40.560811, lat: -12.639177 },
          { lng: 40.59962, lat: -14.201975 },
          { lng: 40.775475, lat: -14.691764 },
          { lng: 40.477251, lat: -15.406294 },
          { lng: 40.089264, lat: -16.100774 },
          { lng: 39.452559, lat: -16.720891 },
          { lng: 38.538351, lat: -17.101023 },
          { lng: 37.411133, lat: -17.586368 },
          { lng: 36.281279, lat: -18.659688 },
          { lng: 35.896497, lat: -18.84226 },
          { lng: 35.1984, lat: -19.552811 },
          { lng: 34.786383, lat: -19.784012 },
          { lng: 34.701893, lat: -20.497043 },
          { lng: 35.176127, lat: -21.254361 },
          { lng: 35.373428, lat: -21.840837 },
          { lng: 35.385848, lat: -22.14 },
          { lng: 35.562546, lat: -22.09 },
          { lng: 35.533935, lat: -23.070788 },
          { lng: 35.371774, lat: -23.535359 },
          { lng: 35.60747, lat: -23.706563 },
          { lng: 35.458746, lat: -24.12261 },
          { lng: 35.040735, lat: -24.478351 },
          { lng: 34.215824, lat: -24.816314 },
          { lng: 33.01321, lat: -25.357573 },
          { lng: 32.574632, lat: -25.727318 },
          { lng: 32.660363, lat: -26.148584 },
          { lng: 32.915955, lat: -26.215867 },
          { lng: 32.83012, lat: -26.742192 },
          { lng: 32.071665, lat: -26.73382 },
          { lng: 31.985779, lat: -26.29178 },
          { lng: 31.837778, lat: -25.843332 },
          { lng: 31.752408, lat: -25.484284 },
          { lng: 31.930589, lat: -24.369417 },
          { lng: 31.670398, lat: -23.658969 },
          { lng: 31.191409, lat: -22.25151 },
          { lng: 32.244988, lat: -21.116489 },
          { lng: 32.508693, lat: -20.395292 },
          { lng: 32.659743, lat: -20.30429 },
          { lng: 32.772708, lat: -19.715592 },
          { lng: 32.611994, lat: -19.419383 },
          { lng: 32.654886, lat: -18.67209 },
          { lng: 32.849861, lat: -17.979057 },
          { lng: 32.847639, lat: -16.713398 },
          { lng: 32.328239, lat: -16.392074 },
          { lng: 31.852041, lat: -16.319417 },
          { lng: 31.636498, lat: -16.07199 },
          { lng: 31.173064, lat: -15.860944 },
          { lng: 30.338955, lat: -15.880839 },
          { lng: 30.274256, lat: -15.507787 },
          { lng: 30.179481, lat: -14.796099 },
          { lng: 33.214025, lat: -13.97186 },
          { lng: 33.7897, lat: -14.451831 },
          { lng: 34.064825, lat: -14.35995 },
          { lng: 34.459633, lat: -14.61301 },
          { lng: 34.517666, lat: -15.013709 },
          { lng: 34.307291, lat: -15.478641 },
          { lng: 34.381292, lat: -16.18356 },
          { lng: 35.03381, lat: -16.8013 },
          { lng: 35.339063, lat: -16.10744 },
          { lng: 35.771905, lat: -15.896859 },
          { lng: 35.686845, lat: -14.611046 },
          { lng: 35.267956, lat: -13.887834 },
          { lng: 34.907151, lat: -13.565425 },
          { lng: 34.559989, lat: -13.579998 },
          { lng: 34.280006, lat: -12.280025 },
          { lng: 34.559989, lat: -11.52002 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'MRT',
    properties: { name: 'Mauritania' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -12.17075, lat: 14.616834 },
          { lng: -12.830658, lat: 15.303692 },
          { lng: -13.435738, lat: 16.039383 },
          { lng: -14.099521, lat: 16.304302 },
          { lng: -14.577348, lat: 16.598264 },
          { lng: -15.135737, lat: 16.587282 },
          { lng: -15.623666, lat: 16.369337 },
          { lng: -16.12069, lat: 16.455663 },
          { lng: -16.463098, lat: 16.135036 },
          { lng: -16.549708, lat: 16.673892 },
          { lng: -16.270552, lat: 17.166963 },
          { lng: -16.146347, lat: 18.108482 },
          { lng: -16.256883, lat: 19.096716 },
          { lng: -16.377651, lat: 19.593817 },
          { lng: -16.277838, lat: 20.092521 },
          { lng: -16.536324, lat: 20.567866 },
          { lng: -17.063423, lat: 20.999752 },
          { lng: -16.845194, lat: 21.333323 },
          { lng: -12.929102, lat: 21.327071 },
          { lng: -13.118754, lat: 22.77122 },
          { lng: -12.874222, lat: 23.284832 },
          { lng: -11.937224, lat: 23.374594 },
          { lng: -11.969419, lat: 25.933353 },
          { lng: -8.687294, lat: 25.881056 },
          { lng: -8.6844, lat: 27.395744 },
          { lng: -4.923337, lat: 24.974574 },
          { lng: -6.453787, lat: 24.956591 },
          { lng: -5.971129, lat: 20.640833 },
          { lng: -5.488523, lat: 16.325102 },
          { lng: -5.315277, lat: 16.201854 },
          { lng: -5.537744, lat: 15.50169 },
          { lng: -9.550238, lat: 15.486497 },
          { lng: -9.700255, lat: 15.264107 },
          { lng: -10.086846, lat: 15.330486 },
          { lng: -10.650791, lat: 15.132746 },
          { lng: -11.349095, lat: 15.411256 },
          { lng: -11.666078, lat: 15.388208 },
          { lng: -11.834208, lat: 14.799097 },
          { lng: -12.17075, lat: 14.616834 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'MWI',
    properties: { name: 'Malawi' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 34.559989, lat: -11.52002 },
          { lng: 34.280006, lat: -12.280025 },
          { lng: 34.559989, lat: -13.579998 },
          { lng: 34.907151, lat: -13.565425 },
          { lng: 35.267956, lat: -13.887834 },
          { lng: 35.686845, lat: -14.611046 },
          { lng: 35.771905, lat: -15.896859 },
          { lng: 35.339063, lat: -16.10744 },
          { lng: 35.03381, lat: -16.8013 },
          { lng: 34.381292, lat: -16.18356 },
          { lng: 34.307291, lat: -15.478641 },
          { lng: 34.517666, lat: -15.013709 },
          { lng: 34.459633, lat: -14.61301 },
          { lng: 34.064825, lat: -14.35995 },
          { lng: 33.7897, lat: -14.451831 },
          { lng: 33.214025, lat: -13.97186 },
          { lng: 32.688165, lat: -13.712858 },
          { lng: 32.991764, lat: -12.783871 },
          { lng: 33.306422, lat: -12.435778 },
          { lng: 33.114289, lat: -11.607198 },
          { lng: 33.31531, lat: -10.79655 },
          { lng: 33.485688, lat: -10.525559 },
          { lng: 33.231388, lat: -9.676722 },
          { lng: 32.759375, lat: -9.230599 },
          { lng: 33.739729, lat: -9.417151 },
          { lng: 33.940838, lat: -9.693674 },
          { lng: 34.280006, lat: -10.16 },
          { lng: 34.559989, lat: -11.52002 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'MYS',
    properties: { name: 'Malaysia' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 101.075516, lat: 6.204867 },
          { lng: 101.154219, lat: 5.691384 },
          { lng: 101.814282, lat: 5.810808 },
          { lng: 102.141187, lat: 6.221636 },
          { lng: 102.371147, lat: 6.128205 },
          { lng: 102.961705, lat: 5.524495 },
          { lng: 103.381215, lat: 4.855001 },
          { lng: 103.438575, lat: 4.181606 },
          { lng: 103.332122, lat: 3.726698 },
          { lng: 103.429429, lat: 3.382869 },
          { lng: 103.502448, lat: 2.791019 },
          { lng: 103.854674, lat: 2.515454 },
          { lng: 104.247932, lat: 1.631141 },
          { lng: 104.228811, lat: 1.293048 },
          { lng: 103.519707, lat: 1.226334 },
          { lng: 102.573615, lat: 1.967115 },
          { lng: 101.390638, lat: 2.760814 },
          { lng: 101.27354, lat: 3.270292 },
          { lng: 100.695435, lat: 3.93914 },
          { lng: 100.557408, lat: 4.76728 },
          { lng: 100.196706, lat: 5.312493 },
          { lng: 100.30626, lat: 6.040562 },
          { lng: 100.085757, lat: 6.464489 },
          { lng: 100.259596, lat: 6.642825 },
          { lng: 101.075516, lat: 6.204867 },
        ],
        [
          { lng: 118.618321, lat: 4.478202 },
          { lng: 117.882035, lat: 4.137551 },
          { lng: 117.015214, lat: 4.306094 },
          { lng: 115.865517, lat: 4.306559 },
          { lng: 115.519078, lat: 3.169238 },
          { lng: 115.134037, lat: 2.821482 },
          { lng: 114.621355, lat: 1.430688 },
          { lng: 113.80585, lat: 1.217549 },
          { lng: 112.859809, lat: 1.49779 },
          { lng: 112.380252, lat: 1.410121 },
          { lng: 111.797548, lat: 0.904441 },
          { lng: 111.159138, lat: 0.976478 },
          { lng: 110.514061, lat: 0.773131 },
          { lng: 109.830227, lat: 1.338136 },
          { lng: 109.66326, lat: 2.006467 },
          { lng: 110.396135, lat: 1.663775 },
          { lng: 111.168853, lat: 1.850637 },
          { lng: 111.370081, lat: 2.697303 },
          { lng: 111.796928, lat: 2.885897 },
          { lng: 112.995615, lat: 3.102395 },
          { lng: 113.712935, lat: 3.893509 },
          { lng: 114.204017, lat: 4.525874 },
          { lng: 114.659596, lat: 4.007637 },
          { lng: 114.869557, lat: 4.348314 },
          { lng: 115.347461, lat: 4.316636 },
          { lng: 115.4057, lat: 4.955228 },
          { lng: 115.45071, lat: 5.44773 },
          { lng: 116.220741, lat: 6.143191 },
          { lng: 116.725103, lat: 6.924771 },
          { lng: 117.129626, lat: 6.928053 },
          { lng: 117.643393, lat: 6.422166 },
          { lng: 117.689075, lat: 5.98749 },
          { lng: 118.347691, lat: 5.708696 },
          { lng: 119.181904, lat: 5.407836 },
          { lng: 119.110694, lat: 5.016128 },
          { lng: 118.439727, lat: 4.966519 },
          { lng: 118.618321, lat: 4.478202 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'NAM',
    properties: { name: 'Namibia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 16.344977, lat: -28.576705 },
          { lng: 15.601818, lat: -27.821247 },
          { lng: 15.210472, lat: -27.090956 },
          { lng: 14.989711, lat: -26.117372 },
          { lng: 14.743214, lat: -25.39292 },
          { lng: 14.408144, lat: -23.853014 },
          { lng: 14.385717, lat: -22.656653 },
          { lng: 14.257714, lat: -22.111208 },
          { lng: 13.868642, lat: -21.699037 },
          { lng: 13.352498, lat: -20.872834 },
          { lng: 12.826845, lat: -19.673166 },
          { lng: 12.608564, lat: -19.045349 },
          { lng: 11.794919, lat: -18.069129 },
          { lng: 11.734199, lat: -17.301889 },
          { lng: 12.215461, lat: -17.111668 },
          { lng: 12.814081, lat: -16.941343 },
          { lng: 13.462362, lat: -16.971212 },
          { lng: 14.058501, lat: -17.423381 },
          { lng: 14.209707, lat: -17.353101 },
          { lng: 18.263309, lat: -17.309951 },
          { lng: 18.956187, lat: -17.789095 },
          { lng: 21.377176, lat: -17.930636 },
          { lng: 23.215048, lat: -17.523116 },
          { lng: 24.033862, lat: -17.295843 },
          { lng: 24.682349, lat: -17.353411 },
          { lng: 25.07695, lat: -17.578823 },
          { lng: 25.084443, lat: -17.661816 },
          { lng: 24.520705, lat: -17.887125 },
          { lng: 24.217365, lat: -17.889347 },
          { lng: 23.579006, lat: -18.281261 },
          { lng: 23.196858, lat: -17.869038 },
          { lng: 21.65504, lat: -18.219146 },
          { lng: 20.910641, lat: -18.252219 },
          { lng: 20.881134, lat: -21.814327 },
          { lng: 19.895458, lat: -21.849157 },
          { lng: 19.895768, lat: -24.76779 },
          { lng: 19.894734, lat: -28.461105 },
          { lng: 19.002127, lat: -28.972443 },
          { lng: 18.464899, lat: -29.045462 },
          { lng: 17.836152, lat: -28.856378 },
          { lng: 17.387497, lat: -28.783514 },
          { lng: 17.218929, lat: -28.355943 },
          { lng: 16.824017, lat: -28.082162 },
          { lng: 16.344977, lat: -28.576705 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'NCL',
    properties: { name: 'New Caledonia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 165.77999, lat: -21.080005 },
          { lng: 166.599991, lat: -21.700019 },
          { lng: 167.120011, lat: -22.159991 },
          { lng: 166.740035, lat: -22.399976 },
          { lng: 166.189732, lat: -22.129708 },
          { lng: 165.474375, lat: -21.679607 },
          { lng: 164.829815, lat: -21.14982 },
          { lng: 164.167995, lat: -20.444747 },
          { lng: 164.029606, lat: -20.105646 },
          { lng: 164.459967, lat: -20.120012 },
          { lng: 165.020036, lat: -20.459991 },
          { lng: 165.460009, lat: -20.800022 },
          { lng: 165.77999, lat: -21.080005 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'NER',
    properties: { name: 'Niger' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 2.154474, lat: 11.94015 },
          { lng: 2.177108, lat: 12.625018 },
          { lng: 1.024103, lat: 12.851826 },
          { lng: 0.993046, lat: 13.33575 },
          { lng: 0.429928, lat: 13.988733 },
          { lng: 0.295646, lat: 14.444235 },
          { lng: 0.374892, lat: 14.928908 },
          { lng: 1.015783, lat: 14.968182 },
          { lng: 1.385528, lat: 15.323561 },
          { lng: 2.749993, lat: 15.409525 },
          { lng: 3.638259, lat: 15.56812 },
          { lng: 3.723422, lat: 16.184284 },
          { lng: 4.27021, lat: 16.852227 },
          { lng: 4.267419, lat: 19.155265 },
          { lng: 5.677566, lat: 19.601207 },
          { lng: 8.572893, lat: 21.565661 },
          { lng: 11.999506, lat: 23.471668 },
          { lng: 13.581425, lat: 23.040506 },
          { lng: 14.143871, lat: 22.491289 },
          { lng: 14.8513, lat: 22.86295 },
          { lng: 15.096888, lat: 21.308519 },
          { lng: 15.471077, lat: 21.048457 },
          { lng: 15.487148, lat: 20.730415 },
          { lng: 15.903247, lat: 20.387619 },
          { lng: 15.685741, lat: 19.95718 },
          { lng: 15.300441, lat: 17.92795 },
          { lng: 15.247731, lat: 16.627306 },
          { lng: 13.972202, lat: 15.684366 },
          { lng: 13.540394, lat: 14.367134 },
          { lng: 13.956699, lat: 13.996691 },
          { lng: 13.954477, lat: 13.353449 },
          { lng: 14.595781, lat: 13.330427 },
          { lng: 14.495787, lat: 12.859396 },
          { lng: 14.213531, lat: 12.802035 },
          { lng: 14.181336, lat: 12.483657 },
          { lng: 13.995353, lat: 12.461565 },
          { lng: 13.318702, lat: 13.556356 },
          { lng: 13.083987, lat: 13.596147 },
          { lng: 12.302071, lat: 13.037189 },
          { lng: 11.527803, lat: 13.32898 },
          { lng: 10.989593, lat: 13.387323 },
          { lng: 10.701032, lat: 13.246918 },
          { lng: 10.114814, lat: 13.277252 },
          { lng: 9.524928, lat: 12.851102 },
          { lng: 9.014933, lat: 12.826659 },
          { lng: 7.804671, lat: 13.343527 },
          { lng: 7.330747, lat: 13.098038 },
          { lng: 6.820442, lat: 13.115091 },
          { lng: 6.445426, lat: 13.492768 },
          { lng: 5.443058, lat: 13.865924 },
          { lng: 4.368344, lat: 13.747482 },
          { lng: 4.107946, lat: 13.531216 },
          { lng: 3.967283, lat: 12.956109 },
          { lng: 3.680634, lat: 12.552903 },
          { lng: 3.61118, lat: 11.660167 },
          { lng: 2.848643, lat: 12.235636 },
          { lng: 2.490164, lat: 12.233052 },
          { lng: 2.154474, lat: 11.94015 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'NGA',
    properties: { name: 'Nigeria' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 8.500288, lat: 4.771983 },
          { lng: 7.462108, lat: 4.412108 },
          { lng: 7.082596, lat: 4.464689 },
          { lng: 6.698072, lat: 4.240594 },
          { lng: 5.898173, lat: 4.262453 },
          { lng: 5.362805, lat: 4.887971 },
          { lng: 5.033574, lat: 5.611802 },
          { lng: 4.325607, lat: 6.270651 },
          { lng: 3.57418, lat: 6.2583 },
          { lng: 2.691702, lat: 6.258817 },
          { lng: 2.749063, lat: 7.870734 },
          { lng: 2.723793, lat: 8.506845 },
          { lng: 2.912308, lat: 9.137608 },
          { lng: 3.220352, lat: 9.444153 },
          { lng: 3.705438, lat: 10.06321 },
          { lng: 3.60007, lat: 10.332186 },
          { lng: 3.797112, lat: 10.734746 },
          { lng: 3.572216, lat: 11.327939 },
          { lng: 3.61118, lat: 11.660167 },
          { lng: 3.680634, lat: 12.552903 },
          { lng: 3.967283, lat: 12.956109 },
          { lng: 4.107946, lat: 13.531216 },
          { lng: 4.368344, lat: 13.747482 },
          { lng: 5.443058, lat: 13.865924 },
          { lng: 6.445426, lat: 13.492768 },
          { lng: 6.820442, lat: 13.115091 },
          { lng: 7.330747, lat: 13.098038 },
          { lng: 7.804671, lat: 13.343527 },
          { lng: 9.014933, lat: 12.826659 },
          { lng: 9.524928, lat: 12.851102 },
          { lng: 10.114814, lat: 13.277252 },
          { lng: 10.701032, lat: 13.246918 },
          { lng: 10.989593, lat: 13.387323 },
          { lng: 11.527803, lat: 13.32898 },
          { lng: 12.302071, lat: 13.037189 },
          { lng: 13.083987, lat: 13.596147 },
          { lng: 13.318702, lat: 13.556356 },
          { lng: 13.995353, lat: 12.461565 },
          { lng: 14.181336, lat: 12.483657 },
          { lng: 14.577178, lat: 12.085361 },
          { lng: 14.468192, lat: 11.904752 },
          { lng: 14.415379, lat: 11.572369 },
          { lng: 13.57295, lat: 10.798566 },
          { lng: 13.308676, lat: 10.160362 },
          { lng: 13.1676, lat: 9.640626 },
          { lng: 12.955468, lat: 9.417772 },
          { lng: 12.753672, lat: 8.717763 },
          { lng: 12.218872, lat: 8.305824 },
          { lng: 12.063946, lat: 7.799808 },
          { lng: 11.839309, lat: 7.397042 },
          { lng: 11.745774, lat: 6.981383 },
          { lng: 11.058788, lat: 6.644427 },
          { lng: 10.497375, lat: 7.055358 },
          { lng: 10.118277, lat: 7.03877 },
          { lng: 9.522706, lat: 6.453482 },
          { lng: 9.233163, lat: 6.444491 },
          { lng: 8.757533, lat: 5.479666 },
          { lng: 8.500288, lat: 4.771983 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'NIC',
    properties: { name: 'Nicaragua' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -85.71254, lat: 11.088445 },
          { lng: -86.058488, lat: 11.403439 },
          { lng: -86.52585, lat: 11.806877 },
          { lng: -86.745992, lat: 12.143962 },
          { lng: -87.167516, lat: 12.458258 },
          { lng: -87.668493, lat: 12.90991 },
          { lng: -87.557467, lat: 13.064552 },
          { lng: -87.392386, lat: 12.914018 },
          { lng: -87.316654, lat: 12.984686 },
          { lng: -87.005769, lat: 13.025794 },
          { lng: -86.880557, lat: 13.254204 },
          { lng: -86.733822, lat: 13.263093 },
          { lng: -86.755087, lat: 13.754845 },
          { lng: -86.520708, lat: 13.778487 },
          { lng: -86.312142, lat: 13.771356 },
          { lng: -86.096264, lat: 14.038187 },
          { lng: -85.801295, lat: 13.836055 },
          { lng: -85.698665, lat: 13.960078 },
          { lng: -85.514413, lat: 14.079012 },
          { lng: -85.165365, lat: 14.35437 },
          { lng: -85.148751, lat: 14.560197 },
          { lng: -85.052787, lat: 14.551541 },
          { lng: -84.924501, lat: 14.790493 },
          { lng: -84.820037, lat: 14.819587 },
          { lng: -84.649582, lat: 14.666805 },
          { lng: -84.449336, lat: 14.621614 },
          { lng: -84.228342, lat: 14.748764 },
          { lng: -83.975721, lat: 14.749436 },
          { lng: -83.628585, lat: 14.880074 },
          { lng: -83.489989, lat: 15.016267 },
          { lng: -83.147219, lat: 14.995829 },
          { lng: -83.233234, lat: 14.899866 },
          { lng: -83.284162, lat: 14.676624 },
          { lng: -83.182126, lat: 14.310703 },
          { lng: -83.4125, lat: 13.970078 },
          { lng: -83.519832, lat: 13.567699 },
          { lng: -83.552207, lat: 13.127054 },
          { lng: -83.498515, lat: 12.869292 },
          { lng: -83.473323, lat: 12.419087 },
          { lng: -83.626104, lat: 12.32085 },
          { lng: -83.719613, lat: 11.893124 },
          { lng: -83.650858, lat: 11.629032 },
          { lng: -83.85547, lat: 11.373311 },
          { lng: -83.808936, lat: 11.103044 },
          { lng: -83.655612, lat: 10.938764 },
          { lng: -83.895054, lat: 10.726839 },
          { lng: -84.190179, lat: 10.79345 },
          { lng: -84.355931, lat: 10.999226 },
          { lng: -84.673069, lat: 11.082657 },
          { lng: -84.903003, lat: 10.952303 },
          { lng: -85.561852, lat: 11.217119 },
          { lng: -85.71254, lat: 11.088445 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'NLD',
    properties: { name: 'Netherlands' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 6.074183, lat: 53.510403 },
          { lng: 6.90514, lat: 53.482162 },
          { lng: 7.092053, lat: 53.144043 },
          { lng: 6.84287, lat: 52.22844 },
          { lng: 6.589397, lat: 51.852029 },
          { lng: 5.988658, lat: 51.851616 },
          { lng: 6.156658, lat: 50.803721 },
          { lng: 5.606976, lat: 51.037298 },
          { lng: 4.973991, lat: 51.475024 },
          { lng: 4.047071, lat: 51.267259 },
          { lng: 3.314971, lat: 51.345755 },
          { lng: 3.830289, lat: 51.620545 },
          { lng: 4.705997, lat: 53.091798 },
          { lng: 6.074183, lat: 53.510403 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'NOR',
    properties: { name: 'Norway' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 28.165547, lat: 71.185474 },
          { lng: 31.293418, lat: 70.453788 },
          { lng: 30.005435, lat: 70.186259 },
          { lng: 31.101079, lat: 69.55808 },
          { lng: 29.399581, lat: 69.156916 },
          { lng: 28.59193, lat: 69.064777 },
          { lng: 29.015573, lat: 69.766491 },
          { lng: 27.732292, lat: 70.164193 },
          { lng: 26.179622, lat: 69.825299 },
          { lng: 25.689213, lat: 69.092114 },
          { lng: 24.735679, lat: 68.649557 },
          { lng: 23.66205, lat: 68.891247 },
          { lng: 22.356238, lat: 68.841741 },
          { lng: 21.244936, lat: 69.370443 },
          { lng: 20.645593, lat: 69.106247 },
          { lng: 20.025269, lat: 69.065139 },
          { lng: 19.87856, lat: 68.407194 },
          { lng: 17.993868, lat: 68.567391 },
          { lng: 17.729182, lat: 68.010552 },
          { lng: 16.768879, lat: 68.013937 },
          { lng: 16.108712, lat: 67.302456 },
          { lng: 15.108411, lat: 66.193867 },
          { lng: 13.55569, lat: 64.787028 },
          { lng: 13.919905, lat: 64.445421 },
          { lng: 13.571916, lat: 64.049114 },
          { lng: 12.579935, lat: 64.066219 },
          { lng: 11.930569, lat: 63.128318 },
          { lng: 11.992064, lat: 61.800362 },
          { lng: 12.631147, lat: 61.293572 },
          { lng: 12.300366, lat: 60.117933 },
          { lng: 11.468272, lat: 59.432393 },
          { lng: 11.027369, lat: 58.856149 },
          { lng: 10.356557, lat: 59.469807 },
          { lng: 8.382, lat: 58.313288 },
          { lng: 7.048748, lat: 58.078884 },
          { lng: 5.665835, lat: 58.588155 },
          { lng: 5.308234, lat: 59.663232 },
          { lng: 4.992078, lat: 61.970998 },
          { lng: 5.9129, lat: 62.614473 },
          { lng: 8.553411, lat: 63.454008 },
          { lng: 10.527709, lat: 64.486038 },
          { lng: 12.358347, lat: 65.879726 },
          { lng: 14.761146, lat: 67.810642 },
          { lng: 16.435927, lat: 68.563205 },
          { lng: 19.184028, lat: 69.817444 },
          { lng: 21.378416, lat: 70.255169 },
          { lng: 23.023742, lat: 70.202072 },
          { lng: 24.546543, lat: 71.030497 },
          { lng: 26.37005, lat: 70.986262 },
          { lng: 28.165547, lat: 71.185474 },
        ],
        [
          { lng: 24.72412, lat: 77.85385 },
          { lng: 22.49032, lat: 77.44493 },
          { lng: 20.72601, lat: 77.67704 },
          { lng: 21.41611, lat: 77.93504 },
          { lng: 20.8119, lat: 78.25463 },
          { lng: 22.88426, lat: 78.45494 },
          { lng: 23.28134, lat: 78.07954 },
          { lng: 24.72412, lat: 77.85385 },
        ],
        [
          { lng: 18.25183, lat: 79.70175 },
          { lng: 21.54383, lat: 78.95611 },
          { lng: 19.02737, lat: 78.5626 },
          { lng: 18.47172, lat: 77.82669 },
          { lng: 17.59441, lat: 77.63796 },
          { lng: 17.1182, lat: 76.80941 },
          { lng: 15.91315, lat: 76.77045 },
          { lng: 13.76259, lat: 77.38035 },
          { lng: 14.66956, lat: 77.73565 },
          { lng: 13.1706, lat: 78.02493 },
          { lng: 11.22231, lat: 78.8693 },
          { lng: 10.44453, lat: 79.65239 },
          { lng: 13.17077, lat: 80.01046 },
          { lng: 13.71852, lat: 79.66039 },
          { lng: 15.14282, lat: 79.67431 },
          { lng: 15.52255, lat: 80.01608 },
          { lng: 16.99085, lat: 80.05086 },
          { lng: 18.25183, lat: 79.70175 },
        ],
        [
          { lng: 25.447625, lat: 80.40734 },
          { lng: 27.407506, lat: 80.056406 },
          { lng: 25.924651, lat: 79.517834 },
          { lng: 23.024466, lat: 79.400012 },
          { lng: 20.075188, lat: 79.566823 },
          { lng: 19.897266, lat: 79.842362 },
          { lng: 18.462264, lat: 79.85988 },
          { lng: 17.368015, lat: 80.318896 },
          { lng: 20.455992, lat: 80.598156 },
          { lng: 21.907945, lat: 80.357679 },
          { lng: 22.919253, lat: 80.657144 },
          { lng: 25.447625, lat: 80.40734 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'NPL',
    properties: { name: 'Nepal' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 88.120441, lat: 27.876542 },
          { lng: 88.043133, lat: 27.445819 },
          { lng: 88.174804, lat: 26.810405 },
          { lng: 88.060238, lat: 26.414615 },
          { lng: 87.227472, lat: 26.397898 },
          { lng: 86.024393, lat: 26.630985 },
          { lng: 85.251779, lat: 26.726198 },
          { lng: 84.675018, lat: 27.234901 },
          { lng: 83.304249, lat: 27.364506 },
          { lng: 81.999987, lat: 27.925479 },
          { lng: 81.057203, lat: 28.416095 },
          { lng: 80.088425, lat: 28.79447 },
          { lng: 80.476721, lat: 29.729865 },
          { lng: 81.111256, lat: 30.183481 },
          { lng: 81.525804, lat: 30.422717 },
          { lng: 82.327513, lat: 30.115268 },
          { lng: 83.337115, lat: 29.463732 },
          { lng: 83.898993, lat: 29.320226 },
          { lng: 84.23458, lat: 28.839894 },
          { lng: 85.011638, lat: 28.642774 },
          { lng: 85.82332, lat: 28.203576 },
          { lng: 86.954517, lat: 27.974262 },
          { lng: 88.120441, lat: 27.876542 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'NZL',
    properties: { name: 'New Zealand' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 173.020375, lat: -40.919052 },
          { lng: 173.247234, lat: -41.331999 },
          { lng: 173.958405, lat: -40.926701 },
          { lng: 174.247587, lat: -41.349155 },
          { lng: 174.248517, lat: -41.770008 },
          { lng: 173.876447, lat: -42.233184 },
          { lng: 173.22274, lat: -42.970038 },
          { lng: 172.711246, lat: -43.372288 },
          { lng: 173.080113, lat: -43.853344 },
          { lng: 172.308584, lat: -43.865694 },
          { lng: 171.452925, lat: -44.242519 },
          { lng: 171.185138, lat: -44.897104 },
          { lng: 170.616697, lat: -45.908929 },
          { lng: 169.831422, lat: -46.355775 },
          { lng: 169.332331, lat: -46.641235 },
          { lng: 168.411354, lat: -46.619945 },
          { lng: 167.763745, lat: -46.290197 },
          { lng: 166.676886, lat: -46.219917 },
          { lng: 166.509144, lat: -45.852705 },
          { lng: 167.046424, lat: -45.110941 },
          { lng: 168.303763, lat: -44.123973 },
          { lng: 168.949409, lat: -43.935819 },
          { lng: 169.667815, lat: -43.555326 },
          { lng: 170.52492, lat: -43.031688 },
          { lng: 171.12509, lat: -42.512754 },
          { lng: 171.569714, lat: -41.767424 },
          { lng: 171.948709, lat: -41.514417 },
          { lng: 172.097227, lat: -40.956104 },
          { lng: 172.79858, lat: -40.493962 },
          { lng: 173.020375, lat: -40.919052 },
        ],
        [
          { lng: 174.612009, lat: -36.156397 },
          { lng: 175.336616, lat: -37.209098 },
          { lng: 175.357596, lat: -36.526194 },
          { lng: 175.808887, lat: -36.798942 },
          { lng: 175.95849, lat: -37.555382 },
          { lng: 176.763195, lat: -37.881253 },
          { lng: 177.438813, lat: -37.961248 },
          { lng: 178.010354, lat: -37.579825 },
          { lng: 178.517094, lat: -37.695373 },
          { lng: 178.274731, lat: -38.582813 },
          { lng: 177.97046, lat: -39.166343 },
          { lng: 177.206993, lat: -39.145776 },
          { lng: 176.939981, lat: -39.449736 },
          { lng: 177.032946, lat: -39.879943 },
          { lng: 176.885824, lat: -40.065978 },
          { lng: 176.508017, lat: -40.604808 },
          { lng: 176.01244, lat: -41.289624 },
          { lng: 175.239567, lat: -41.688308 },
          { lng: 175.067898, lat: -41.425895 },
          { lng: 174.650973, lat: -41.281821 },
          { lng: 175.22763, lat: -40.459236 },
          { lng: 174.900157, lat: -39.908933 },
          { lng: 173.824047, lat: -39.508854 },
          { lng: 173.852262, lat: -39.146602 },
          { lng: 174.574802, lat: -38.797683 },
          { lng: 174.743474, lat: -38.027808 },
          { lng: 174.697017, lat: -37.381129 },
          { lng: 174.292028, lat: -36.711092 },
          { lng: 174.319004, lat: -36.534824 },
          { lng: 173.840997, lat: -36.121981 },
          { lng: 173.054171, lat: -35.237125 },
          { lng: 172.636005, lat: -34.529107 },
          { lng: 173.007042, lat: -34.450662 },
          { lng: 173.551298, lat: -35.006183 },
          { lng: 174.32939, lat: -35.265496 },
          { lng: 174.612009, lat: -36.156397 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'OMN',
    properties: { name: 'Oman' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 58.861141, lat: 21.114035 },
          { lng: 58.487986, lat: 20.428986 },
          { lng: 58.034318, lat: 20.481437 },
          { lng: 57.826373, lat: 20.243002 },
          { lng: 57.665762, lat: 19.736005 },
          { lng: 57.7887, lat: 19.06757 },
          { lng: 57.694391, lat: 18.94471 },
          { lng: 57.234264, lat: 18.947991 },
          { lng: 56.609651, lat: 18.574267 },
          { lng: 56.512189, lat: 18.087113 },
          { lng: 56.283521, lat: 17.876067 },
          { lng: 55.661492, lat: 17.884128 },
          { lng: 55.269939, lat: 17.632309 },
          { lng: 55.2749, lat: 17.228354 },
          { lng: 54.791002, lat: 16.950697 },
          { lng: 54.239253, lat: 17.044981 },
          { lng: 53.570508, lat: 16.707663 },
          { lng: 53.108573, lat: 16.651051 },
          { lng: 52.782184, lat: 17.349742 },
          { lng: 52.00001, lat: 19.000003 },
          { lng: 54.999982, lat: 19.999994 },
          { lng: 55.666659, lat: 22.000001 },
          { lng: 55.208341, lat: 22.70833 },
          { lng: 55.234489, lat: 23.110993 },
          { lng: 55.525841, lat: 23.524869 },
          { lng: 55.528632, lat: 23.933604 },
          { lng: 55.981214, lat: 24.130543 },
          { lng: 55.804119, lat: 24.269604 },
          { lng: 55.886233, lat: 24.920831 },
          { lng: 56.396847, lat: 24.924732 },
          { lng: 56.84514, lat: 24.241673 },
          { lng: 57.403453, lat: 23.878594 },
          { lng: 58.136948, lat: 23.747931 },
          { lng: 58.729211, lat: 23.565668 },
          { lng: 59.180502, lat: 22.992395 },
          { lng: 59.450098, lat: 22.660271 },
          { lng: 59.80806, lat: 22.533612 },
          { lng: 59.806148, lat: 22.310525 },
          { lng: 59.442191, lat: 21.714541 },
          { lng: 59.282408, lat: 21.433886 },
          { lng: 58.861141, lat: 21.114035 },
        ],
        [
          { lng: 56.391421, lat: 25.895991 },
          { lng: 56.261042, lat: 25.714606 },
          { lng: 56.070821, lat: 26.055464 },
          { lng: 56.362017, lat: 26.395934 },
          { lng: 56.485679, lat: 26.309118 },
          { lng: 56.391421, lat: 25.895991 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'PAK',
    properties: { name: 'Pakistan' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 75.158028, lat: 37.133031 },
          { lng: 75.896897, lat: 36.666806 },
          { lng: 76.192848, lat: 35.898403 },
          { lng: 77.837451, lat: 35.49401 },
          { lng: 76.871722, lat: 34.653544 },
          { lng: 75.757061, lat: 34.504923 },
          { lng: 74.240203, lat: 34.748887 },
          { lng: 73.749948, lat: 34.317699 },
          { lng: 74.104294, lat: 33.441473 },
          { lng: 74.451559, lat: 32.7649 },
          { lng: 75.258642, lat: 32.271105 },
          { lng: 74.405929, lat: 31.692639 },
          { lng: 74.42138, lat: 30.979815 },
          { lng: 73.450638, lat: 29.976413 },
          { lng: 72.823752, lat: 28.961592 },
          { lng: 71.777666, lat: 27.91318 },
          { lng: 70.616496, lat: 27.989196 },
          { lng: 69.514393, lat: 26.940966 },
          { lng: 70.168927, lat: 26.491872 },
          { lng: 70.282873, lat: 25.722229 },
          { lng: 70.844699, lat: 25.215102 },
          { lng: 71.04324, lat: 24.356524 },
          { lng: 68.842599, lat: 24.359134 },
          { lng: 68.176645, lat: 23.691965 },
          { lng: 67.443667, lat: 23.944844 },
          { lng: 67.145442, lat: 24.663611 },
          { lng: 66.372828, lat: 25.425141 },
          { lng: 64.530408, lat: 25.237039 },
          { lng: 62.905701, lat: 25.218409 },
          { lng: 61.497363, lat: 25.078237 },
          { lng: 61.874187, lat: 26.239975 },
          { lng: 63.316632, lat: 26.756532 },
          { lng: 63.233898, lat: 27.217047 },
          { lng: 62.755426, lat: 27.378923 },
          { lng: 62.72783, lat: 28.259645 },
          { lng: 61.771868, lat: 28.699334 },
          { lng: 61.369309, lat: 29.303276 },
          { lng: 60.874248, lat: 29.829239 },
          { lng: 62.549857, lat: 29.318572 },
          { lng: 63.550261, lat: 29.468331 },
          { lng: 64.148002, lat: 29.340819 },
          { lng: 64.350419, lat: 29.560031 },
          { lng: 65.046862, lat: 29.472181 },
          { lng: 66.346473, lat: 29.887943 },
          { lng: 66.381458, lat: 30.738899 },
          { lng: 66.938891, lat: 31.304911 },
          { lng: 67.683394, lat: 31.303154 },
          { lng: 67.792689, lat: 31.58293 },
          { lng: 68.556932, lat: 31.71331 },
          { lng: 68.926677, lat: 31.620189 },
          { lng: 69.317764, lat: 31.901412 },
          { lng: 69.262522, lat: 32.501944 },
          { lng: 69.687147, lat: 33.105499 },
          { lng: 70.323594, lat: 33.358533 },
          { lng: 69.930543, lat: 34.02012 },
          { lng: 70.881803, lat: 33.988856 },
          { lng: 71.156773, lat: 34.348911 },
          { lng: 71.115019, lat: 34.733126 },
          { lng: 71.613076, lat: 35.153203 },
          { lng: 71.498768, lat: 35.650563 },
          { lng: 71.262348, lat: 36.074388 },
          { lng: 71.846292, lat: 36.509942 },
          { lng: 72.920025, lat: 36.720007 },
          { lng: 74.067552, lat: 36.836176 },
          { lng: 74.575893, lat: 37.020841 },
          { lng: 75.158028, lat: 37.133031 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'PAN',
    properties: { name: 'Panama' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -77.881571, lat: 7.223771 },
          { lng: -78.214936, lat: 7.512255 },
          { lng: -78.429161, lat: 8.052041 },
          { lng: -78.182096, lat: 8.319182 },
          { lng: -78.435465, lat: 8.387705 },
          { lng: -78.622121, lat: 8.718124 },
          { lng: -79.120307, lat: 8.996092 },
          { lng: -79.557877, lat: 8.932375 },
          { lng: -79.760578, lat: 8.584515 },
          { lng: -80.164481, lat: 8.333316 },
          { lng: -80.382659, lat: 8.298409 },
          { lng: -80.480689, lat: 8.090308 },
          { lng: -80.00369, lat: 7.547524 },
          { lng: -80.276671, lat: 7.419754 },
          { lng: -80.421158, lat: 7.271572 },
          { lng: -80.886401, lat: 7.220541 },
          { lng: -81.059543, lat: 7.817921 },
          { lng: -81.189716, lat: 7.647906 },
          { lng: -81.519515, lat: 7.70661 },
          { lng: -81.721311, lat: 8.108963 },
          { lng: -82.131441, lat: 8.175393 },
          { lng: -82.390934, lat: 8.292362 },
          { lng: -82.820081, lat: 8.290864 },
          { lng: -82.850958, lat: 8.073823 },
          { lng: -82.965783, lat: 8.225028 },
          { lng: -82.913176, lat: 8.423517 },
          { lng: -82.829771, lat: 8.626295 },
          { lng: -82.868657, lat: 8.807266 },
          { lng: -82.719183, lat: 8.925709 },
          { lng: -82.927155, lat: 9.07433 },
          { lng: -82.932891, lat: 9.476812 },
          { lng: -82.546196, lat: 9.566135 },
          { lng: -82.187123, lat: 9.207449 },
          { lng: -82.207586, lat: 8.995575 },
          { lng: -81.808567, lat: 8.950617 },
          { lng: -81.714154, lat: 9.031955 },
          { lng: -81.439287, lat: 8.786234 },
          { lng: -80.947302, lat: 8.858504 },
          { lng: -80.521901, lat: 9.111072 },
          { lng: -79.9146, lat: 9.312765 },
          { lng: -79.573303, lat: 9.61161 },
          { lng: -79.021192, lat: 9.552931 },
          { lng: -79.05845, lat: 9.454565 },
          { lng: -78.500888, lat: 9.420459 },
          { lng: -78.055928, lat: 9.24773 },
          { lng: -77.729514, lat: 8.946844 },
          { lng: -77.353361, lat: 8.670505 },
          { lng: -77.474723, lat: 8.524286 },
          { lng: -77.242566, lat: 7.935278 },
          { lng: -77.431108, lat: 7.638061 },
          { lng: -77.753414, lat: 7.70984 },
          { lng: -77.881571, lat: 7.223771 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'PER',
    properties: { name: 'Peru' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -69.590424, lat: -17.580012 },
          { lng: -69.858444, lat: -18.092694 },
          { lng: -70.372572, lat: -18.347975 },
          { lng: -71.37525, lat: -17.773799 },
          { lng: -71.462041, lat: -17.363488 },
          { lng: -73.44453, lat: -16.359363 },
          { lng: -75.237883, lat: -15.265683 },
          { lng: -76.009205, lat: -14.649286 },
          { lng: -76.423469, lat: -13.823187 },
          { lng: -76.259242, lat: -13.535039 },
          { lng: -77.106192, lat: -12.222716 },
          { lng: -78.092153, lat: -10.377712 },
          { lng: -79.036953, lat: -8.386568 },
          { lng: -79.44592, lat: -7.930833 },
          { lng: -79.760578, lat: -7.194341 },
          { lng: -80.537482, lat: -6.541668 },
          { lng: -81.249996, lat: -6.136834 },
          { lng: -80.926347, lat: -5.690557 },
          { lng: -81.410943, lat: -4.736765 },
          { lng: -81.09967, lat: -4.036394 },
          { lng: -80.302561, lat: -3.404856 },
          { lng: -80.184015, lat: -3.821162 },
          { lng: -80.469295, lat: -4.059287 },
          { lng: -80.442242, lat: -4.425724 },
          { lng: -80.028908, lat: -4.346091 },
          { lng: -79.624979, lat: -4.454198 },
          { lng: -79.205289, lat: -4.959129 },
          { lng: -78.639897, lat: -4.547784 },
          { lng: -78.450684, lat: -3.873097 },
          { lng: -77.837905, lat: -3.003021 },
          { lng: -76.635394, lat: -2.608678 },
          { lng: -75.544996, lat: -1.56161 },
          { lng: -75.233723, lat: -0.911417 },
          { lng: -75.373223, lat: -0.152032 },
          { lng: -75.106625, lat: -0.057205 },
          { lng: -74.441601, lat: -0.53082 },
          { lng: -74.122395, lat: -1.002833 },
          { lng: -73.659504, lat: -1.260491 },
          { lng: -73.070392, lat: -2.308954 },
          { lng: -72.325787, lat: -2.434218 },
          { lng: -71.774761, lat: -2.16979 },
          { lng: -71.413646, lat: -2.342802 },
          { lng: -70.813476, lat: -2.256865 },
          { lng: -70.047709, lat: -2.725156 },
          { lng: -70.692682, lat: -3.742872 },
          { lng: -70.394044, lat: -3.766591 },
          { lng: -69.893635, lat: -4.298187 },
          { lng: -70.794769, lat: -4.251265 },
          { lng: -70.928843, lat: -4.401591 },
          { lng: -71.748406, lat: -4.593983 },
          { lng: -72.891928, lat: -5.274561 },
          { lng: -72.964507, lat: -5.741251 },
          { lng: -73.219711, lat: -6.089189 },
          { lng: -73.120027, lat: -6.629931 },
          { lng: -73.724487, lat: -6.918595 },
          { lng: -73.723401, lat: -7.340999 },
          { lng: -73.987235, lat: -7.52383 },
          { lng: -73.571059, lat: -8.424447 },
          { lng: -73.015383, lat: -9.032833 },
          { lng: -73.226713, lat: -9.462213 },
          { lng: -72.563033, lat: -9.520194 },
          { lng: -72.184891, lat: -10.053598 },
          { lng: -71.302412, lat: -10.079436 },
          { lng: -70.481894, lat: -9.490118 },
          { lng: -70.548686, lat: -11.009147 },
          { lng: -70.093752, lat: -11.123972 },
          { lng: -69.529678, lat: -10.951734 },
          { lng: -68.66508, lat: -12.5613 },
          { lng: -68.88008, lat: -12.899729 },
          { lng: -68.929224, lat: -13.602684 },
          { lng: -68.948887, lat: -14.453639 },
          { lng: -69.339535, lat: -14.953195 },
          { lng: -69.160347, lat: -15.323974 },
          { lng: -69.389764, lat: -15.660129 },
          { lng: -68.959635, lat: -16.500698 },
          { lng: -69.590424, lat: -17.580012 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'PHL',
    properties: { name: 'Philippines' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 126.376814, lat: 8.414706 },
          { lng: 126.478513, lat: 7.750354 },
          { lng: 126.537424, lat: 7.189381 },
          { lng: 126.196773, lat: 6.274294 },
          { lng: 125.831421, lat: 7.293715 },
          { lng: 125.363852, lat: 6.786485 },
          { lng: 125.683161, lat: 6.049657 },
          { lng: 125.396512, lat: 5.581003 },
          { lng: 124.219788, lat: 6.161355 },
          { lng: 123.93872, lat: 6.885136 },
          { lng: 124.243662, lat: 7.36061 },
          { lng: 123.610212, lat: 7.833527 },
          { lng: 123.296071, lat: 7.418876 },
          { lng: 122.825506, lat: 7.457375 },
          { lng: 122.085499, lat: 6.899424 },
          { lng: 121.919928, lat: 7.192119 },
          { lng: 122.312359, lat: 8.034962 },
          { lng: 122.942398, lat: 8.316237 },
          { lng: 123.487688, lat: 8.69301 },
          { lng: 123.841154, lat: 8.240324 },
          { lng: 124.60147, lat: 8.514158 },
          { lng: 124.764612, lat: 8.960409 },
          { lng: 125.471391, lat: 8.986997 },
          { lng: 125.412118, lat: 9.760335 },
          { lng: 126.222714, lat: 9.286074 },
          { lng: 126.306637, lat: 8.782487 },
          { lng: 126.376814, lat: 8.414706 },
        ],
        [
          { lng: 123.982438, lat: 10.278779 },
          { lng: 123.623183, lat: 9.950091 },
          { lng: 123.309921, lat: 9.318269 },
          { lng: 122.995883, lat: 9.022189 },
          { lng: 122.380055, lat: 9.713361 },
          { lng: 122.586089, lat: 9.981045 },
          { lng: 122.837081, lat: 10.261157 },
          { lng: 122.947411, lat: 10.881868 },
          { lng: 123.49885, lat: 10.940624 },
          { lng: 123.337774, lat: 10.267384 },
          { lng: 124.077936, lat: 11.232726 },
          { lng: 123.982438, lat: 10.278779 },
        ],
        [
          { lng: 118.504581, lat: 9.316383 },
          { lng: 117.174275, lat: 8.3675 },
          { lng: 117.664477, lat: 9.066889 },
          { lng: 118.386914, lat: 9.6845 },
          { lng: 118.987342, lat: 10.376292 },
          { lng: 119.511496, lat: 11.369668 },
          { lng: 119.689677, lat: 10.554291 },
          { lng: 119.029458, lat: 10.003653 },
          { lng: 118.504581, lat: 9.316383 },
        ],
        [
          { lng: 121.883548, lat: 11.891755 },
          { lng: 122.483821, lat: 11.582187 },
          { lng: 123.120217, lat: 11.58366 },
          { lng: 123.100838, lat: 11.165934 },
          { lng: 122.637714, lat: 10.741308 },
          { lng: 122.00261, lat: 10.441017 },
          { lng: 121.967367, lat: 10.905691 },
          { lng: 122.03837, lat: 11.415841 },
          { lng: 121.883548, lat: 11.891755 },
        ],
        [
          { lng: 125.502552, lat: 12.162695 },
          { lng: 125.783465, lat: 11.046122 },
          { lng: 125.011884, lat: 11.311455 },
          { lng: 125.032761, lat: 10.975816 },
          { lng: 125.277449, lat: 10.358722 },
          { lng: 124.801819, lat: 10.134679 },
          { lng: 124.760168, lat: 10.837995 },
          { lng: 124.459101, lat: 10.88993 },
          { lng: 124.302522, lat: 11.495371 },
          { lng: 124.891013, lat: 11.415583 },
          { lng: 124.87799, lat: 11.79419 },
          { lng: 124.266762, lat: 12.557761 },
          { lng: 125.227116, lat: 12.535721 },
          { lng: 125.502552, lat: 12.162695 },
        ],
        [
          { lng: 121.527394, lat: 13.06959 },
          { lng: 121.26219, lat: 12.20556 },
          { lng: 120.833896, lat: 12.704496 },
          { lng: 120.323436, lat: 13.466413 },
          { lng: 121.180128, lat: 13.429697 },
          { lng: 121.527394, lat: 13.06959 },
        ],
        [
          { lng: 121.321308, lat: 18.504065 },
          { lng: 121.937601, lat: 18.218552 },
          { lng: 122.246006, lat: 18.47895 },
          { lng: 122.336957, lat: 18.224883 },
          { lng: 122.174279, lat: 17.810283 },
          { lng: 122.515654, lat: 17.093505 },
          { lng: 122.252311, lat: 16.262444 },
          { lng: 121.662786, lat: 15.931018 },
          { lng: 121.50507, lat: 15.124814 },
          { lng: 121.728829, lat: 14.328376 },
          { lng: 122.258925, lat: 14.218202 },
          { lng: 122.701276, lat: 14.336541 },
          { lng: 123.950295, lat: 13.782131 },
          { lng: 123.855107, lat: 13.237771 },
          { lng: 124.181289, lat: 12.997527 },
          { lng: 124.077419, lat: 12.536677 },
          { lng: 123.298035, lat: 13.027526 },
          { lng: 122.928652, lat: 13.55292 },
          { lng: 122.671355, lat: 13.185836 },
          { lng: 122.03465, lat: 13.784482 },
          { lng: 121.126385, lat: 13.636687 },
          { lng: 120.628637, lat: 13.857656 },
          { lng: 120.679384, lat: 14.271016 },
          { lng: 120.991819, lat: 14.525393 },
          { lng: 120.693336, lat: 14.756671 },
          { lng: 120.564145, lat: 14.396279 },
          { lng: 120.070429, lat: 14.970869 },
          { lng: 119.920929, lat: 15.406347 },
          { lng: 119.883773, lat: 16.363704 },
          { lng: 120.286488, lat: 16.034629 },
          { lng: 120.390047, lat: 17.599081 },
          { lng: 120.715867, lat: 18.505227 },
          { lng: 121.321308, lat: 18.504065 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'PNG',
    properties: { name: 'Papua New Guinea' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 155.880026, lat: -6.819997 },
          { lng: 155.599991, lat: -6.919991 },
          { lng: 155.166994, lat: -6.535931 },
          { lng: 154.729192, lat: -5.900828 },
          { lng: 154.514114, lat: -5.139118 },
          { lng: 154.652504, lat: -5.042431 },
          { lng: 154.759991, lat: -5.339984 },
          { lng: 155.062918, lat: -5.566792 },
          { lng: 155.547746, lat: -6.200655 },
          { lng: 156.019965, lat: -6.540014 },
          { lng: 155.880026, lat: -6.819997 },
        ],
        [
          { lng: 151.982796, lat: -5.478063 },
          { lng: 151.459107, lat: -5.56028 },
          { lng: 151.30139, lat: -5.840728 },
          { lng: 150.754447, lat: -6.083763 },
          { lng: 150.241197, lat: -6.317754 },
          { lng: 149.709963, lat: -6.316513 },
          { lng: 148.890065, lat: -6.02604 },
          { lng: 148.318937, lat: -5.747142 },
          { lng: 148.401826, lat: -5.437756 },
          { lng: 149.298412, lat: -5.583742 },
          { lng: 149.845562, lat: -5.505503 },
          { lng: 149.99625, lat: -5.026101 },
          { lng: 150.139756, lat: -5.001348 },
          { lng: 150.236908, lat: -5.53222 },
          { lng: 150.807467, lat: -5.455842 },
          { lng: 151.089672, lat: -5.113693 },
          { lng: 151.647881, lat: -4.757074 },
          { lng: 151.537862, lat: -4.167807 },
          { lng: 152.136792, lat: -4.14879 },
          { lng: 152.338743, lat: -4.312966 },
          { lng: 152.318693, lat: -4.867661 },
          { lng: 151.982796, lat: -5.478063 },
        ],
        [
          { lng: 147.191874, lat: -7.388024 },
          { lng: 148.084636, lat: -8.044108 },
          { lng: 148.734105, lat: -9.104664 },
          { lng: 149.306835, lat: -9.071436 },
          { lng: 149.266631, lat: -9.514406 },
          { lng: 150.038728, lat: -9.684318 },
          { lng: 149.738798, lat: -9.872937 },
          { lng: 150.801628, lat: -10.293687 },
          { lng: 150.690575, lat: -10.582713 },
          { lng: 150.028393, lat: -10.652476 },
          { lng: 149.78231, lat: -10.393267 },
          { lng: 148.923138, lat: -10.280923 },
          { lng: 147.913018, lat: -10.130441 },
          { lng: 147.135443, lat: -9.492444 },
          { lng: 146.567881, lat: -8.942555 },
          { lng: 146.048481, lat: -8.067414 },
          { lng: 144.744168, lat: -7.630128 },
          { lng: 143.897088, lat: -7.91533 },
          { lng: 143.286376, lat: -8.245491 },
          { lng: 143.413913, lat: -8.983069 },
          { lng: 142.628431, lat: -9.326821 },
          { lng: 142.068259, lat: -9.159596 },
          { lng: 141.033852, lat: -9.117893 },
          { lng: 141.017057, lat: -5.859022 },
          { lng: 141.00021, lat: -2.600151 },
          { lng: 142.735247, lat: -3.289153 },
          { lng: 144.583971, lat: -3.861418 },
          { lng: 145.27318, lat: -4.373738 },
          { lng: 145.829786, lat: -4.876498 },
          { lng: 145.981922, lat: -5.465609 },
          { lng: 147.648073, lat: -6.083659 },
          { lng: 147.891108, lat: -6.614015 },
          { lng: 146.970905, lat: -6.721657 },
          { lng: 147.191874, lat: -7.388024 },
        ],
        [
          { lng: 153.140038, lat: -4.499983 },
          { lng: 152.827292, lat: -4.766427 },
          { lng: 152.638673, lat: -4.176127 },
          { lng: 152.406026, lat: -3.789743 },
          { lng: 151.953237, lat: -3.462062 },
          { lng: 151.384279, lat: -3.035422 },
          { lng: 150.66205, lat: -2.741486 },
          { lng: 150.939965, lat: -2.500002 },
          { lng: 151.479984, lat: -2.779985 },
          { lng: 151.820015, lat: -2.999972 },
          { lng: 152.239989, lat: -3.240009 },
          { lng: 152.640017, lat: -3.659983 },
          { lng: 153.019994, lat: -3.980015 },
          { lng: 153.140038, lat: -4.499983 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'POL',
    properties: { name: 'Poland' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 15.016996, lat: 51.106674 },
          { lng: 14.607098, lat: 51.745188 },
          { lng: 14.685026, lat: 52.089947 },
          { lng: 14.4376, lat: 52.62485 },
          { lng: 14.074521, lat: 52.981263 },
          { lng: 14.353315, lat: 53.248171 },
          { lng: 14.119686, lat: 53.757029 },
          { lng: 14.8029, lat: 54.050706 },
          { lng: 16.363477, lat: 54.513159 },
          { lng: 17.622832, lat: 54.851536 },
          { lng: 18.620859, lat: 54.682606 },
          { lng: 18.696255, lat: 54.438719 },
          { lng: 19.66064, lat: 54.426084 },
          { lng: 20.892245, lat: 54.312525 },
          { lng: 22.731099, lat: 54.327537 },
          { lng: 23.243987, lat: 54.220567 },
          { lng: 23.484128, lat: 53.912498 },
          { lng: 23.527536, lat: 53.470122 },
          { lng: 23.804935, lat: 53.089731 },
          { lng: 23.799199, lat: 52.691099 },
          { lng: 23.199494, lat: 52.486977 },
          { lng: 23.508002, lat: 52.023647 },
          { lng: 23.527071, lat: 51.578454 },
          { lng: 24.029986, lat: 50.705407 },
          { lng: 23.922757, lat: 50.424881 },
          { lng: 23.426508, lat: 50.308506 },
          { lng: 22.51845, lat: 49.476774 },
          { lng: 22.776419, lat: 49.027395 },
          { lng: 22.558138, lat: 49.085738 },
          { lng: 21.607808, lat: 49.470107 },
          { lng: 20.887955, lat: 49.328772 },
          { lng: 20.415839, lat: 49.431453 },
          { lng: 19.825023, lat: 49.217125 },
          { lng: 19.320713, lat: 49.571574 },
          { lng: 18.909575, lat: 49.435846 },
          { lng: 18.853144, lat: 49.49623 },
          { lng: 18.392914, lat: 49.988629 },
          { lng: 17.649445, lat: 50.049038 },
          { lng: 17.554567, lat: 50.362146 },
          { lng: 16.868769, lat: 50.473974 },
          { lng: 16.719476, lat: 50.215747 },
          { lng: 16.176253, lat: 50.422607 },
          { lng: 16.238627, lat: 50.697733 },
          { lng: 15.490972, lat: 50.78473 },
          { lng: 15.016996, lat: 51.106674 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'PRI',
    properties: { name: 'Puerto Rico' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -66.282434, lat: 18.514762 },
          { lng: -65.771303, lat: 18.426679 },
          { lng: -65.591004, lat: 18.228035 },
          { lng: -65.847164, lat: 17.975906 },
          { lng: -66.599934, lat: 17.981823 },
          { lng: -67.184162, lat: 17.946553 },
          { lng: -67.242428, lat: 18.37446 },
          { lng: -67.100679, lat: 18.520601 },
          { lng: -66.282434, lat: 18.514762 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'PRK',
    properties: { name: 'North Korea' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 130.640016, lat: 42.395009 },
          { lng: 130.780007, lat: 42.220007 },
          { lng: 130.400031, lat: 42.280004 },
          { lng: 129.965949, lat: 41.941368 },
          { lng: 129.667362, lat: 41.601104 },
          { lng: 129.705189, lat: 40.882828 },
          { lng: 129.188115, lat: 40.661808 },
          { lng: 129.0104, lat: 40.485436 },
          { lng: 128.633368, lat: 40.189847 },
          { lng: 127.967414, lat: 40.025413 },
          { lng: 127.533436, lat: 39.75685 },
          { lng: 127.50212, lat: 39.323931 },
          { lng: 127.385434, lat: 39.213472 },
          { lng: 127.783343, lat: 39.050898 },
          { lng: 128.349716, lat: 38.612243 },
          { lng: 128.205746, lat: 38.370397 },
          { lng: 127.780035, lat: 38.304536 },
          { lng: 127.073309, lat: 38.256115 },
          { lng: 126.68372, lat: 37.804773 },
          { lng: 126.237339, lat: 37.840378 },
          { lng: 126.174759, lat: 37.749686 },
          { lng: 125.689104, lat: 37.94001 },
          { lng: 125.568439, lat: 37.752089 },
          { lng: 125.27533, lat: 37.669071 },
          { lng: 125.240087, lat: 37.857224 },
          { lng: 124.981033, lat: 37.948821 },
          { lng: 124.712161, lat: 38.108346 },
          { lng: 124.985994, lat: 38.548474 },
          { lng: 125.221949, lat: 38.665857 },
          { lng: 125.132859, lat: 38.848559 },
          { lng: 125.38659, lat: 39.387958 },
          { lng: 125.321116, lat: 39.551385 },
          { lng: 124.737482, lat: 39.660344 },
          { lng: 124.265625, lat: 39.928493 },
          { lng: 125.079942, lat: 40.569824 },
          { lng: 126.182045, lat: 41.107336 },
          { lng: 126.869083, lat: 41.816569 },
          { lng: 127.343783, lat: 41.503152 },
          { lng: 128.208433, lat: 41.466772 },
          { lng: 128.052215, lat: 41.994285 },
          { lng: 129.596669, lat: 42.424982 },
          { lng: 129.994267, lat: 42.985387 },
          { lng: 130.640016, lat: 42.395009 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'PRT',
    properties: { name: 'Portugal' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -9.034818, lat: 41.880571 },
          { lng: -8.671946, lat: 42.134689 },
          { lng: -8.263857, lat: 42.280469 },
          { lng: -8.013175, lat: 41.790886 },
          { lng: -7.422513, lat: 41.792075 },
          { lng: -7.251309, lat: 41.918346 },
          { lng: -6.668606, lat: 41.883387 },
          { lng: -6.389088, lat: 41.381815 },
          { lng: -6.851127, lat: 41.111083 },
          { lng: -6.86402, lat: 40.330872 },
          { lng: -7.026413, lat: 40.184524 },
          { lng: -7.066592, lat: 39.711892 },
          { lng: -7.498632, lat: 39.629571 },
          { lng: -7.098037, lat: 39.030073 },
          { lng: -7.374092, lat: 38.373059 },
          { lng: -7.029281, lat: 38.075764 },
          { lng: -7.166508, lat: 37.803894 },
          { lng: -7.537105, lat: 37.428904 },
          { lng: -7.453726, lat: 37.097788 },
          { lng: -7.855613, lat: 36.838269 },
          { lng: -8.382816, lat: 36.97888 },
          { lng: -8.898857, lat: 36.868809 },
          { lng: -8.746101, lat: 37.651346 },
          { lng: -8.839998, lat: 38.266243 },
          { lng: -9.287464, lat: 38.358486 },
          { lng: -9.526571, lat: 38.737429 },
          { lng: -9.446989, lat: 39.392066 },
          { lng: -9.048305, lat: 39.755093 },
          { lng: -8.977353, lat: 40.159306 },
          { lng: -8.768684, lat: 40.760639 },
          { lng: -8.790853, lat: 41.184334 },
          { lng: -8.990789, lat: 41.543459 },
          { lng: -9.034818, lat: 41.880571 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'PRY',
    properties: { name: 'Paraguay' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -62.685057, lat: -22.249029 },
          { lng: -62.291179, lat: -21.051635 },
          { lng: -62.265961, lat: -20.513735 },
          { lng: -61.786326, lat: -19.633737 },
          { lng: -60.043565, lat: -19.342747 },
          { lng: -59.115042, lat: -19.356906 },
          { lng: -58.183471, lat: -19.868399 },
          { lng: -58.166392, lat: -20.176701 },
          { lng: -57.870674, lat: -20.732688 },
          { lng: -57.937156, lat: -22.090176 },
          { lng: -56.88151, lat: -22.282154 },
          { lng: -56.473317, lat: -22.0863 },
          { lng: -55.797958, lat: -22.35693 },
          { lng: -55.610683, lat: -22.655619 },
          { lng: -55.517639, lat: -23.571998 },
          { lng: -55.400747, lat: -23.956935 },
          { lng: -55.027902, lat: -24.001274 },
          { lng: -54.652834, lat: -23.839578 },
          { lng: -54.29296, lat: -24.021014 },
          { lng: -54.293476, lat: -24.5708 },
          { lng: -54.428946, lat: -25.162185 },
          { lng: -54.625291, lat: -25.739255 },
          { lng: -54.788795, lat: -26.621786 },
          { lng: -55.695846, lat: -27.387837 },
          { lng: -56.486702, lat: -27.548499 },
          { lng: -57.60976, lat: -27.395899 },
          { lng: -58.618174, lat: -27.123719 },
          { lng: -57.63366, lat: -25.603657 },
          { lng: -57.777217, lat: -25.16234 },
          { lng: -58.807128, lat: -24.771459 },
          { lng: -60.028966, lat: -24.032796 },
          { lng: -60.846565, lat: -23.880713 },
          { lng: -62.685057, lat: -22.249029 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'QAT',
    properties: { name: 'Qatar' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 50.810108, lat: 24.754743 },
          { lng: 50.743911, lat: 25.482424 },
          { lng: 51.013352, lat: 26.006992 },
          { lng: 51.286462, lat: 26.114582 },
          { lng: 51.589079, lat: 25.801113 },
          { lng: 51.6067, lat: 25.21567 },
          { lng: 51.389608, lat: 24.627386 },
          { lng: 51.112415, lat: 24.556331 },
          { lng: 50.810108, lat: 24.754743 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ROU',
    properties: { name: 'Romania' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 22.710531, lat: 47.882194 },
          { lng: 23.142236, lat: 48.096341 },
          { lng: 23.760958, lat: 47.985598 },
          { lng: 24.402056, lat: 47.981878 },
          { lng: 24.866317, lat: 47.737526 },
          { lng: 25.207743, lat: 47.891056 },
          { lng: 25.945941, lat: 47.987149 },
          { lng: 26.19745, lat: 48.220881 },
          { lng: 26.619337, lat: 48.220726 },
          { lng: 26.924176, lat: 48.123264 },
          { lng: 27.233873, lat: 47.826771 },
          { lng: 27.551166, lat: 47.405117 },
          { lng: 28.12803, lat: 46.810476 },
          { lng: 28.160018, lat: 46.371563 },
          { lng: 28.054443, lat: 45.944586 },
          { lng: 28.233554, lat: 45.488283 },
          { lng: 28.679779, lat: 45.304031 },
          { lng: 29.149725, lat: 45.464925 },
          { lng: 29.603289, lat: 45.293308 },
          { lng: 29.626543, lat: 45.035391 },
          { lng: 29.141612, lat: 44.82021 },
          { lng: 28.837858, lat: 44.913874 },
          { lng: 28.558081, lat: 43.707462 },
          { lng: 27.970107, lat: 43.812468 },
          { lng: 27.2424, lat: 44.175986 },
          { lng: 26.065159, lat: 43.943494 },
          { lng: 25.569272, lat: 43.688445 },
          { lng: 24.100679, lat: 43.741051 },
          { lng: 23.332302, lat: 43.897011 },
          { lng: 22.944832, lat: 43.823785 },
          { lng: 22.65715, lat: 44.234923 },
          { lng: 22.474008, lat: 44.409228 },
          { lng: 22.705726, lat: 44.578003 },
          { lng: 22.459022, lat: 44.702517 },
          { lng: 22.145088, lat: 44.478422 },
          { lng: 21.562023, lat: 44.768947 },
          { lng: 21.483526, lat: 45.18117 },
          { lng: 20.874313, lat: 45.416375 },
          { lng: 20.762175, lat: 45.734573 },
          { lng: 20.220192, lat: 46.127469 },
          { lng: 21.021952, lat: 46.316088 },
          { lng: 21.626515, lat: 46.994238 },
          { lng: 22.099768, lat: 47.672439 },
          { lng: 22.710531, lat: 47.882194 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'RUS',
    properties: { name: 'Russia' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 143.648007, lat: 50.7476 },
          { lng: 144.654148, lat: 48.976391 },
          { lng: 143.173928, lat: 49.306551 },
          { lng: 142.558668, lat: 47.861575 },
          { lng: 143.533492, lat: 46.836728 },
          { lng: 143.505277, lat: 46.137908 },
          { lng: 142.747701, lat: 46.740765 },
          { lng: 142.09203, lat: 45.966755 },
          { lng: 141.906925, lat: 46.805929 },
          { lng: 142.018443, lat: 47.780133 },
          { lng: 141.904445, lat: 48.859189 },
          { lng: 142.1358, lat: 49.615163 },
          { lng: 142.179983, lat: 50.952342 },
          { lng: 141.594076, lat: 51.935435 },
          { lng: 141.682546, lat: 53.301966 },
          { lng: 142.606934, lat: 53.762145 },
          { lng: 142.209749, lat: 54.225476 },
          { lng: 142.654786, lat: 54.365881 },
          { lng: 142.914616, lat: 53.704578 },
          { lng: 143.260848, lat: 52.74076 },
          { lng: 143.235268, lat: 51.75666 },
          { lng: 143.648007, lat: 50.7476 },
        ],
        [
          { lng: 22.731099, lat: 54.327537 },
          { lng: 20.892245, lat: 54.312525 },
          { lng: 19.66064, lat: 54.426084 },
          { lng: 19.888481, lat: 54.86616 },
          { lng: 21.268449, lat: 55.190482 },
          { lng: 22.315724, lat: 55.015299 },
          { lng: 22.757764, lat: 54.856574 },
          { lng: 22.651052, lat: 54.582741 },
          { lng: 22.731099, lat: 54.327537 },
        ],
        [
          { lng: -175.01425, lat: 66.58435 },
          { lng: -174.33983, lat: 66.33556 },
          { lng: -174.57182, lat: 67.06219 },
          { lng: -171.85731, lat: 66.91308 },
          { lng: -169.89958, lat: 65.97724 },
          { lng: -170.89107, lat: 65.54139 },
          { lng: -172.53025, lat: 65.43791 },
          { lng: -172.555, lat: 64.46079 },
          { lng: -172.95533, lat: 64.25269 },
          { lng: -173.89184, lat: 64.2826 },
          { lng: -174.65392, lat: 64.63125 },
          { lng: -175.98353, lat: 64.92288 },
          { lng: -176.20716, lat: 65.35667 },
          { lng: -177.22266, lat: 65.52024 },
          { lng: -178.35993, lat: 65.39052 },
          { lng: -178.90332, lat: 65.74044 },
          { lng: -178.68611, lat: 66.11211 },
          { lng: -179.88377, lat: 65.87456 },
          { lng: -179.43268, lat: 65.40411 },
          { lng: -180, lat: 64.979709 },
          { lng: -180, lat: 68.963636 },
          { lng: -177.55, lat: 68.2 },
          { lng: -174.92825, lat: 67.20589 },
          { lng: -175.01425, lat: 66.58435 },
        ],
        [
          { lng: 180, lat: 70.832199 },
          { lng: 178.903425, lat: 70.78114 },
          { lng: 178.7253, lat: 71.0988 },
          { lng: 180, lat: 71.515714 },
          { lng: 180, lat: 70.832199 },
        ],
        [
          { lng: -178.69378, lat: 70.89302 },
          { lng: -180, lat: 70.832199 },
          { lng: -180, lat: 71.515714 },
          { lng: -179.871875, lat: 71.55762 },
          { lng: -179.02433, lat: 71.55553 },
          { lng: -177.577945, lat: 71.26948 },
          { lng: -177.663575, lat: 71.13277 },
          { lng: -178.69378, lat: 70.89302 },
        ],
        [
          { lng: 143.60385, lat: 73.21244 },
          { lng: 142.08763, lat: 73.20544 },
          { lng: 140.038155, lat: 73.31692 },
          { lng: 139.86312, lat: 73.36983 },
          { lng: 140.81171, lat: 73.76506 },
          { lng: 142.06207, lat: 73.85758 },
          { lng: 143.48283, lat: 73.47525 },
          { lng: 143.60385, lat: 73.21244 },
        ],
        [
          { lng: 150.73167, lat: 75.08406 },
          { lng: 149.575925, lat: 74.68892 },
          { lng: 147.977465, lat: 74.778355 },
          { lng: 146.11919, lat: 75.17298 },
          { lng: 146.358485, lat: 75.49682 },
          { lng: 148.22223, lat: 75.345845 },
          { lng: 150.73167, lat: 75.08406 },
        ],
        [
          { lng: 145.086285, lat: 75.562625 },
          { lng: 144.3, lat: 74.82 },
          { lng: 140.61381, lat: 74.84768 },
          { lng: 138.95544, lat: 74.61148 },
          { lng: 136.97439, lat: 75.26167 },
          { lng: 137.51176, lat: 75.94917 },
          { lng: 138.831075, lat: 76.13676 },
          { lng: 141.471615, lat: 76.09289 },
          { lng: 145.086285, lat: 75.562625 },
        ],
        [
          { lng: 57.535693, lat: 70.720464 },
          { lng: 56.944979, lat: 70.632743 },
          { lng: 53.677375, lat: 70.762658 },
          { lng: 53.412017, lat: 71.206662 },
          { lng: 51.601895, lat: 71.474759 },
          { lng: 51.455754, lat: 72.014881 },
          { lng: 52.478275, lat: 72.229442 },
          { lng: 52.444169, lat: 72.774731 },
          { lng: 54.427614, lat: 73.627548 },
          { lng: 53.50829, lat: 73.749814 },
          { lng: 55.902459, lat: 74.627486 },
          { lng: 55.631933, lat: 75.081412 },
          { lng: 57.868644, lat: 75.60939 },
          { lng: 61.170044, lat: 76.251883 },
          { lng: 64.498368, lat: 76.439055 },
          { lng: 66.210977, lat: 76.809782 },
          { lng: 68.15706, lat: 76.939697 },
          { lng: 68.852211, lat: 76.544811 },
          { lng: 68.180573, lat: 76.233642 },
          { lng: 64.637326, lat: 75.737755 },
          { lng: 61.583508, lat: 75.260885 },
          { lng: 58.477082, lat: 74.309056 },
          { lng: 56.986786, lat: 73.333044 },
          { lng: 55.419336, lat: 72.371268 },
          { lng: 55.622838, lat: 71.540595 },
          { lng: 57.535693, lat: 70.720464 },
        ],
        [
          { lng: 106.97013, lat: 76.97419 },
          { lng: 107.24, lat: 76.48 },
          { lng: 108.1538, lat: 76.72335 },
          { lng: 111.07726, lat: 76.71 },
          { lng: 113.33151, lat: 76.22224 },
          { lng: 114.13417, lat: 75.84764 },
          { lng: 113.88539, lat: 75.32779 },
          { lng: 112.77918, lat: 75.03186 },
          { lng: 110.15125, lat: 74.47673 },
          { lng: 109.4, lat: 74.18 },
          { lng: 110.64, lat: 74.04 },
          { lng: 112.11919, lat: 73.78774 },
          { lng: 113.01954, lat: 73.97693 },
          { lng: 113.52958, lat: 73.33505 },
          { lng: 113.96881, lat: 73.59488 },
          { lng: 115.56782, lat: 73.75285 },
          { lng: 118.77633, lat: 73.58772 },
          { lng: 119.02, lat: 73.12 },
          { lng: 123.20066, lat: 72.97122 },
          { lng: 123.25777, lat: 73.73503 },
          { lng: 125.38, lat: 73.56 },
          { lng: 126.97644, lat: 73.56549 },
          { lng: 128.59126, lat: 73.03871 },
          { lng: 129.05157, lat: 72.39872 },
          { lng: 128.46, lat: 71.98 },
          { lng: 129.71599, lat: 71.19304 },
          { lng: 131.28858, lat: 70.78699 },
          { lng: 132.2535, lat: 71.8363 },
          { lng: 133.85766, lat: 71.38642 },
          { lng: 135.56193, lat: 71.65525 },
          { lng: 137.49755, lat: 71.34763 },
          { lng: 138.23409, lat: 71.62803 },
          { lng: 139.86983, lat: 71.48783 },
          { lng: 139.14791, lat: 72.41619 },
          { lng: 140.46817, lat: 72.84941 },
          { lng: 149.5, lat: 72.2 },
          { lng: 150.35118, lat: 71.60643 },
          { lng: 152.9689, lat: 70.84222 },
          { lng: 157.00688, lat: 71.03141 },
          { lng: 158.99779, lat: 70.86672 },
          { lng: 159.83031, lat: 70.45324 },
          { lng: 159.70866, lat: 69.72198 },
          { lng: 160.94053, lat: 69.43728 },
          { lng: 162.27907, lat: 69.64204 },
          { lng: 164.05248, lat: 69.66823 },
          { lng: 165.94037, lat: 69.47199 },
          { lng: 167.83567, lat: 69.58269 },
          { lng: 169.57763, lat: 68.6938 },
          { lng: 170.81688, lat: 69.01363 },
          { lng: 170.0082, lat: 69.65276 },
          { lng: 170.45345, lat: 70.09703 },
          { lng: 173.64391, lat: 69.81743 },
          { lng: 175.72403, lat: 69.87725 },
          { lng: 178.6, lat: 69.4 },
          { lng: 180, lat: 68.963636 },
          { lng: 180, lat: 64.979709 },
          { lng: 179.99281, lat: 64.97433 },
          { lng: 178.7072, lat: 64.53493 },
          { lng: 177.41128, lat: 64.60821 },
          { lng: 178.313, lat: 64.07593 },
          { lng: 178.90825, lat: 63.25197 },
          { lng: 179.37034, lat: 62.98262 },
          { lng: 179.48636, lat: 62.56894 },
          { lng: 179.22825, lat: 62.3041 },
          { lng: 177.3643, lat: 62.5219 },
          { lng: 174.56929, lat: 61.76915 },
          { lng: 173.68013, lat: 61.65261 },
          { lng: 172.15, lat: 60.95 },
          { lng: 170.6985, lat: 60.33618 },
          { lng: 170.33085, lat: 59.88177 },
          { lng: 168.90046, lat: 60.57355 },
          { lng: 166.29498, lat: 59.78855 },
          { lng: 165.84, lat: 60.16 },
          { lng: 164.87674, lat: 59.7316 },
          { lng: 163.53929, lat: 59.86871 },
          { lng: 163.21711, lat: 59.21101 },
          { lng: 162.01733, lat: 58.24328 },
          { lng: 162.05297, lat: 57.83912 },
          { lng: 163.19191, lat: 57.61503 },
          { lng: 163.05794, lat: 56.15924 },
          { lng: 162.12958, lat: 56.12219 },
          { lng: 161.70146, lat: 55.28568 },
          { lng: 162.11749, lat: 54.85514 },
          { lng: 160.36877, lat: 54.34433 },
          { lng: 160.02173, lat: 53.20257 },
          { lng: 158.53094, lat: 52.95868 },
          { lng: 158.23118, lat: 51.94269 },
          { lng: 156.78979, lat: 51.01105 },
          { lng: 156.42, lat: 51.7 },
          { lng: 155.99182, lat: 53.15895 },
          { lng: 155.43366, lat: 55.38103 },
          { lng: 155.91442, lat: 56.76792 },
          { lng: 156.75815, lat: 57.3647 },
          { lng: 156.81035, lat: 57.83204 },
          { lng: 158.36433, lat: 58.05575 },
          { lng: 160.15064, lat: 59.31477 },
          { lng: 161.87204, lat: 60.343 },
          { lng: 163.66969, lat: 61.1409 },
          { lng: 164.47355, lat: 62.55061 },
          { lng: 163.25842, lat: 62.46627 },
          { lng: 162.65791, lat: 61.6425 },
          { lng: 160.12148, lat: 60.54423 },
          { lng: 159.30232, lat: 61.77396 },
          { lng: 156.72068, lat: 61.43442 },
          { lng: 154.21806, lat: 59.75818 },
          { lng: 155.04375, lat: 59.14495 },
          { lng: 152.81185, lat: 58.88385 },
          { lng: 151.26573, lat: 58.78089 },
          { lng: 151.33815, lat: 59.50396 },
          { lng: 149.78371, lat: 59.65573 },
          { lng: 148.54481, lat: 59.16448 },
          { lng: 145.48722, lat: 59.33637 },
          { lng: 142.19782, lat: 59.03998 },
          { lng: 138.95848, lat: 57.08805 },
          { lng: 135.12619, lat: 54.72959 },
          { lng: 136.70171, lat: 54.60355 },
          { lng: 137.19342, lat: 53.97732 },
          { lng: 138.1647, lat: 53.75501 },
          { lng: 138.80463, lat: 54.25455 },
          { lng: 139.90151, lat: 54.18968 },
          { lng: 141.34531, lat: 53.08957 },
          { lng: 141.37923, lat: 52.23877 },
          { lng: 140.59742, lat: 51.23967 },
          { lng: 140.51308, lat: 50.04553 },
          { lng: 140.06193, lat: 48.44671 },
          { lng: 138.55472, lat: 46.99965 },
          { lng: 138.21971, lat: 46.30795 },
          { lng: 136.86232, lat: 45.1435 },
          { lng: 135.51535, lat: 43.989 },
          { lng: 134.86939, lat: 43.39821 },
          { lng: 133.53687, lat: 42.81147 },
          { lng: 132.90627, lat: 42.79849 },
          { lng: 132.27807, lat: 43.28456 },
          { lng: 130.93587, lat: 42.55274 },
          { lng: 130.78, lat: 42.22 },
          { lng: 130.64, lat: 42.395 },
          { lng: 130.633866, lat: 42.903015 },
          { lng: 131.144688, lat: 42.92999 },
          { lng: 131.288555, lat: 44.11152 },
          { lng: 131.02519, lat: 44.96796 },
          { lng: 131.883454, lat: 45.321162 },
          { lng: 133.09712, lat: 45.14409 },
          { lng: 133.769644, lat: 46.116927 },
          { lng: 134.11235, lat: 47.21248 },
          { lng: 134.50081, lat: 47.57845 },
          { lng: 135.026311, lat: 48.47823 },
          { lng: 133.373596, lat: 48.183442 },
          { lng: 132.50669, lat: 47.78896 },
          { lng: 130.98726, lat: 47.79013 },
          { lng: 130.582293, lat: 48.729687 },
          { lng: 129.397818, lat: 49.4406 },
          { lng: 127.6574, lat: 49.76027 },
          { lng: 127.287456, lat: 50.739797 },
          { lng: 126.939157, lat: 51.353894 },
          { lng: 126.564399, lat: 51.784255 },
          { lng: 125.946349, lat: 52.792799 },
          { lng: 125.068211, lat: 53.161045 },
          { lng: 123.57147, lat: 53.4588 },
          { lng: 122.245748, lat: 53.431726 },
          { lng: 121.003085, lat: 53.251401 },
          { lng: 120.177089, lat: 52.753886 },
          { lng: 120.725789, lat: 52.516226 },
          { lng: 120.7382, lat: 51.96411 },
          { lng: 120.18208, lat: 51.64355 },
          { lng: 119.27939, lat: 50.58292 },
          { lng: 119.288461, lat: 50.142883 },
          { lng: 117.879244, lat: 49.510983 },
          { lng: 116.678801, lat: 49.888531 },
          { lng: 115.485695, lat: 49.805177 },
          { lng: 114.96211, lat: 50.140247 },
          { lng: 114.362456, lat: 50.248303 },
          { lng: 112.89774, lat: 49.543565 },
          { lng: 111.581231, lat: 49.377968 },
          { lng: 110.662011, lat: 49.130128 },
          { lng: 109.402449, lat: 49.292961 },
          { lng: 108.475167, lat: 49.282548 },
          { lng: 107.868176, lat: 49.793705 },
          { lng: 106.888804, lat: 50.274296 },
          { lng: 105.886591, lat: 50.406019 },
          { lng: 104.62158, lat: 50.27532 },
          { lng: 103.676545, lat: 50.089966 },
          { lng: 102.25589, lat: 50.51056 },
          { lng: 102.06521, lat: 51.25991 },
          { lng: 100.88948, lat: 51.516856 },
          { lng: 99.981732, lat: 51.634006 },
          { lng: 98.861491, lat: 52.047366 },
          { lng: 97.82574, lat: 51.010995 },
          { lng: 98.231762, lat: 50.422401 },
          { lng: 97.25976, lat: 49.72605 },
          { lng: 95.81402, lat: 49.97746 },
          { lng: 94.815949, lat: 50.013433 },
          { lng: 94.147566, lat: 50.480537 },
          { lng: 93.10421, lat: 50.49529 },
          { lng: 92.234712, lat: 50.802171 },
          { lng: 90.713667, lat: 50.331812 },
          { lng: 88.805567, lat: 49.470521 },
          { lng: 87.751264, lat: 49.297198 },
          { lng: 87.35997, lat: 49.214981 },
          { lng: 86.829357, lat: 49.826675 },
          { lng: 85.54127, lat: 49.692859 },
          { lng: 85.11556, lat: 50.117303 },
          { lng: 84.416377, lat: 50.3114 },
          { lng: 83.935115, lat: 50.889246 },
          { lng: 83.383004, lat: 51.069183 },
          { lng: 81.945986, lat: 50.812196 },
          { lng: 80.568447, lat: 51.388336 },
          { lng: 80.03556, lat: 50.864751 },
          { lng: 77.800916, lat: 53.404415 },
          { lng: 76.525179, lat: 54.177003 },
          { lng: 76.8911, lat: 54.490524 },
          { lng: 74.38482, lat: 53.54685 },
          { lng: 73.425679, lat: 53.48981 },
          { lng: 73.508516, lat: 54.035617 },
          { lng: 72.22415, lat: 54.376655 },
          { lng: 71.180131, lat: 54.133285 },
          { lng: 70.865267, lat: 55.169734 },
          { lng: 69.068167, lat: 55.38525 },
          { lng: 68.1691, lat: 54.970392 },
          { lng: 65.66687, lat: 54.60125 },
          { lng: 65.178534, lat: 54.354228 },
          { lng: 61.4366, lat: 54.00625 },
          { lng: 60.978066, lat: 53.664993 },
          { lng: 61.699986, lat: 52.979996 },
          { lng: 60.739993, lat: 52.719986 },
          { lng: 60.927269, lat: 52.447548 },
          { lng: 59.967534, lat: 51.96042 },
          { lng: 61.588003, lat: 51.272659 },
          { lng: 61.337424, lat: 50.79907 },
          { lng: 59.932807, lat: 50.842194 },
          { lng: 59.642282, lat: 50.545442 },
          { lng: 58.36332, lat: 51.06364 },
          { lng: 56.77798, lat: 51.04355 },
          { lng: 55.71694, lat: 50.62171 },
          { lng: 54.532878, lat: 51.02624 },
          { lng: 52.328724, lat: 51.718652 },
          { lng: 50.766648, lat: 51.692762 },
          { lng: 48.702382, lat: 50.605128 },
          { lng: 48.577841, lat: 49.87476 },
          { lng: 47.54948, lat: 50.454698 },
          { lng: 46.751596, lat: 49.356006 },
          { lng: 47.043672, lat: 49.152039 },
          { lng: 46.466446, lat: 48.394152 },
          { lng: 47.31524, lat: 47.71585 },
          { lng: 48.05725, lat: 47.74377 },
          { lng: 48.694734, lat: 47.075628 },
          { lng: 48.59325, lat: 46.56104 },
          { lng: 49.10116, lat: 46.39933 },
          { lng: 48.64541, lat: 45.80629 },
          { lng: 47.67591, lat: 45.64149 },
          { lng: 46.68201, lat: 44.6092 },
          { lng: 47.59094, lat: 43.66016 },
          { lng: 47.49252, lat: 42.98658 },
          { lng: 48.58437, lat: 41.80888 },
          { lng: 47.987283, lat: 41.405819 },
          { lng: 47.815666, lat: 41.151416 },
          { lng: 47.373315, lat: 41.219732 },
          { lng: 46.686071, lat: 41.827137 },
          { lng: 46.404951, lat: 41.860675 },
          { lng: 45.7764, lat: 42.09244 },
          { lng: 45.470279, lat: 42.502781 },
          { lng: 44.537623, lat: 42.711993 },
          { lng: 43.93121, lat: 42.55496 },
          { lng: 43.75599, lat: 42.74083 },
          { lng: 42.3944, lat: 43.2203 },
          { lng: 40.92219, lat: 43.38215 },
          { lng: 40.076965, lat: 43.553104 },
          { lng: 39.955009, lat: 43.434998 },
          { lng: 38.68, lat: 44.28 },
          { lng: 37.53912, lat: 44.65721 },
          { lng: 36.67546, lat: 45.24469 },
          { lng: 37.40317, lat: 45.40451 },
          { lng: 38.23295, lat: 46.24087 },
          { lng: 37.67372, lat: 46.63657 },
          { lng: 39.14767, lat: 47.04475 },
          { lng: 39.1212, lat: 47.26336 },
          { lng: 38.223538, lat: 47.10219 },
          { lng: 38.255112, lat: 47.5464 },
          { lng: 38.77057, lat: 47.82562 },
          { lng: 39.738278, lat: 47.898937 },
          { lng: 39.89562, lat: 48.23241 },
          { lng: 39.67465, lat: 48.78382 },
          { lng: 40.080789, lat: 49.30743 },
          { lng: 40.06904, lat: 49.60105 },
          { lng: 38.594988, lat: 49.926462 },
          { lng: 38.010631, lat: 49.915662 },
          { lng: 37.39346, lat: 50.383953 },
          { lng: 36.626168, lat: 50.225591 },
          { lng: 35.356116, lat: 50.577197 },
          { lng: 35.37791, lat: 50.77394 },
          { lng: 35.022183, lat: 51.207572 },
          { lng: 34.224816, lat: 51.255993 },
          { lng: 34.141978, lat: 51.566413 },
          { lng: 34.391731, lat: 51.768882 },
          { lng: 33.7527, lat: 52.335075 },
          { lng: 32.715761, lat: 52.238465 },
          { lng: 32.412058, lat: 52.288695 },
          { lng: 32.15944, lat: 52.06125 },
          { lng: 31.78597, lat: 52.10168 },
          { lng: 31.540018, lat: 52.742052 },
          { lng: 31.305201, lat: 53.073996 },
          { lng: 31.49764, lat: 53.16743 },
          { lng: 32.304519, lat: 53.132726 },
          { lng: 32.693643, lat: 53.351421 },
          { lng: 32.405599, lat: 53.618045 },
          { lng: 31.731273, lat: 53.794029 },
          { lng: 31.791424, lat: 53.974639 },
          { lng: 31.384472, lat: 54.157056 },
          { lng: 30.757534, lat: 54.811771 },
          { lng: 30.971836, lat: 55.081548 },
          { lng: 30.873909, lat: 55.550976 },
          { lng: 29.896294, lat: 55.789463 },
          { lng: 29.371572, lat: 55.670091 },
          { lng: 29.229513, lat: 55.918344 },
          { lng: 28.176709, lat: 56.16913 },
          { lng: 27.855282, lat: 56.759326 },
          { lng: 27.770016, lat: 57.244258 },
          { lng: 27.288185, lat: 57.474528 },
          { lng: 27.716686, lat: 57.791899 },
          { lng: 27.42015, lat: 58.72457 },
          { lng: 28.131699, lat: 59.300825 },
          { lng: 27.98112, lat: 59.47537 },
          { lng: 29.1177, lat: 60.02805 },
          { lng: 28.07, lat: 60.50352 },
          { lng: 30.211107, lat: 61.780028 },
          { lng: 31.139991, lat: 62.357693 },
          { lng: 31.516092, lat: 62.867687 },
          { lng: 30.035872, lat: 63.552814 },
          { lng: 30.444685, lat: 64.204453 },
          { lng: 29.54443, lat: 64.948672 },
          { lng: 30.21765, lat: 65.80598 },
          { lng: 29.054589, lat: 66.944286 },
          { lng: 29.977426, lat: 67.698297 },
          { lng: 28.445944, lat: 68.364613 },
          { lng: 28.59193, lat: 69.064777 },
          { lng: 29.39955, lat: 69.15692 },
          { lng: 31.10108, lat: 69.55811 },
          { lng: 32.13272, lat: 69.90595 },
          { lng: 33.77547, lat: 69.30142 },
          { lng: 36.51396, lat: 69.06342 },
          { lng: 40.29234, lat: 67.9324 },
          { lng: 41.05987, lat: 67.45713 },
          { lng: 41.12595, lat: 66.79158 },
          { lng: 40.01583, lat: 66.26618 },
          { lng: 38.38295, lat: 65.99953 },
          { lng: 33.91871, lat: 66.75961 },
          { lng: 33.18444, lat: 66.63253 },
          { lng: 34.81477, lat: 65.90015 },
          { lng: 34.878574, lat: 65.436213 },
          { lng: 34.94391, lat: 64.41437 },
          { lng: 36.23129, lat: 64.10945 },
          { lng: 37.01273, lat: 63.84983 },
          { lng: 37.14197, lat: 64.33471 },
          { lng: 36.539579, lat: 64.76446 },
          { lng: 37.17604, lat: 65.14322 },
          { lng: 39.59345, lat: 64.52079 },
          { lng: 40.4356, lat: 64.76446 },
          { lng: 39.7626, lat: 65.49682 },
          { lng: 42.09309, lat: 66.47623 },
          { lng: 43.01604, lat: 66.41858 },
          { lng: 43.94975, lat: 66.06908 },
          { lng: 44.53226, lat: 66.75634 },
          { lng: 43.69839, lat: 67.35245 },
          { lng: 44.18795, lat: 67.95051 },
          { lng: 43.45282, lat: 68.57079 },
          { lng: 46.25, lat: 68.25 },
          { lng: 46.82134, lat: 67.68997 },
          { lng: 45.55517, lat: 67.56652 },
          { lng: 45.56202, lat: 67.01005 },
          { lng: 46.34915, lat: 66.66767 },
          { lng: 47.89416, lat: 66.88455 },
          { lng: 48.13876, lat: 67.52238 },
          { lng: 50.22766, lat: 67.99867 },
          { lng: 53.71743, lat: 68.85738 },
          { lng: 54.47171, lat: 68.80815 },
          { lng: 53.48582, lat: 68.20131 },
          { lng: 54.72628, lat: 68.09702 },
          { lng: 55.44268, lat: 68.43866 },
          { lng: 57.31702, lat: 68.46628 },
          { lng: 58.802, lat: 68.88082 },
          { lng: 59.94142, lat: 68.27844 },
          { lng: 61.07784, lat: 68.94069 },
          { lng: 60.03, lat: 69.52 },
          { lng: 60.55, lat: 69.85 },
          { lng: 63.504, lat: 69.54739 },
          { lng: 64.888115, lat: 69.234835 },
          { lng: 68.51216, lat: 68.09233 },
          { lng: 69.18068, lat: 68.61563 },
          { lng: 68.16444, lat: 69.14436 },
          { lng: 68.13522, lat: 69.35649 },
          { lng: 66.93008, lat: 69.45461 },
          { lng: 67.25976, lat: 69.92873 },
          { lng: 66.72492, lat: 70.70889 },
          { lng: 66.69466, lat: 71.02897 },
          { lng: 68.54006, lat: 71.9345 },
          { lng: 69.19636, lat: 72.84336 },
          { lng: 69.94, lat: 73.04 },
          { lng: 72.58754, lat: 72.77629 },
          { lng: 72.79603, lat: 72.22006 },
          { lng: 71.84811, lat: 71.40898 },
          { lng: 72.47011, lat: 71.09019 },
          { lng: 72.79188, lat: 70.39114 },
          { lng: 72.5647, lat: 69.02085 },
          { lng: 73.66787, lat: 68.4079 },
          { lng: 73.2387, lat: 67.7404 },
          { lng: 71.28, lat: 66.32 },
          { lng: 72.42301, lat: 66.17267 },
          { lng: 72.82077, lat: 66.53267 },
          { lng: 73.92099, lat: 66.78946 },
          { lng: 74.18651, lat: 67.28429 },
          { lng: 75.052, lat: 67.76047 },
          { lng: 74.46926, lat: 68.32899 },
          { lng: 74.93584, lat: 68.98918 },
          { lng: 73.84236, lat: 69.07146 },
          { lng: 73.60187, lat: 69.62763 },
          { lng: 74.3998, lat: 70.63175 },
          { lng: 73.1011, lat: 71.44717 },
          { lng: 74.89082, lat: 72.12119 },
          { lng: 74.65926, lat: 72.83227 },
          { lng: 75.15801, lat: 72.85497 },
          { lng: 75.68351, lat: 72.30056 },
          { lng: 75.28898, lat: 71.33556 },
          { lng: 76.35911, lat: 71.15287 },
          { lng: 75.90313, lat: 71.87401 },
          { lng: 77.57665, lat: 72.26717 },
          { lng: 79.65202, lat: 72.32011 },
          { lng: 81.5, lat: 71.75 },
          { lng: 80.61071, lat: 72.58285 },
          { lng: 80.51109, lat: 73.6482 },
          { lng: 82.25, lat: 73.85 },
          { lng: 84.65526, lat: 73.80591 },
          { lng: 86.8223, lat: 73.93688 },
          { lng: 86.00956, lat: 74.45967 },
          { lng: 87.16682, lat: 75.11643 },
          { lng: 88.31571, lat: 75.14393 },
          { lng: 90.26, lat: 75.64 },
          { lng: 92.90058, lat: 75.77333 },
          { lng: 93.23421, lat: 76.0472 },
          { lng: 95.86, lat: 76.14 },
          { lng: 96.67821, lat: 75.91548 },
          { lng: 98.92254, lat: 76.44689 },
          { lng: 100.75967, lat: 76.43028 },
          { lng: 101.03532, lat: 76.86189 },
          { lng: 101.99084, lat: 77.28754 },
          { lng: 104.3516, lat: 77.69792 },
          { lng: 106.06664, lat: 77.37389 },
          { lng: 104.705, lat: 77.1274 },
          { lng: 106.97013, lat: 76.97419 },
        ],
        [
          { lng: 105.07547, lat: 78.30689 },
          { lng: 99.43814, lat: 77.921 },
          { lng: 101.2649, lat: 79.23399 },
          { lng: 102.08635, lat: 79.34641 },
          { lng: 102.837815, lat: 79.28129 },
          { lng: 105.37243, lat: 78.71334 },
          { lng: 105.07547, lat: 78.30689 },
        ],
        [
          { lng: 51.136187, lat: 80.54728 },
          { lng: 49.793685, lat: 80.415428 },
          { lng: 48.894411, lat: 80.339567 },
          { lng: 48.754937, lat: 80.175468 },
          { lng: 47.586119, lat: 80.010181 },
          { lng: 46.502826, lat: 80.247247 },
          { lng: 47.072455, lat: 80.559424 },
          { lng: 44.846958, lat: 80.58981 },
          { lng: 46.799139, lat: 80.771918 },
          { lng: 48.318477, lat: 80.78401 },
          { lng: 48.522806, lat: 80.514569 },
          { lng: 49.09719, lat: 80.753986 },
          { lng: 50.039768, lat: 80.918885 },
          { lng: 51.522933, lat: 80.699726 },
          { lng: 51.136187, lat: 80.54728 },
        ],
        [
          { lng: 99.93976, lat: 78.88094 },
          { lng: 97.75794, lat: 78.7562 },
          { lng: 94.97259, lat: 79.044745 },
          { lng: 93.31288, lat: 79.4265 },
          { lng: 92.5454, lat: 80.14379 },
          { lng: 91.18107, lat: 80.34146 },
          { lng: 93.77766, lat: 81.0246 },
          { lng: 95.940895, lat: 81.2504 },
          { lng: 97.88385, lat: 80.746975 },
          { lng: 100.186655, lat: 79.780135 },
          { lng: 99.93976, lat: 78.88094 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'RWA',
    properties: { name: 'Rwanda' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 30.419105, lat: -1.134659 },
          { lng: 30.816135, lat: -1.698914 },
          { lng: 30.758309, lat: -2.28725 },
          { lng: 30.469696, lat: -2.413858 },
          { lng: 29.938359, lat: -2.348487 },
          { lng: 29.632176, lat: -2.917858 },
          { lng: 29.024926, lat: -2.839258 },
          { lng: 29.117479, lat: -2.292211 },
          { lng: 29.254835, lat: -2.21511 },
          { lng: 29.291887, lat: -1.620056 },
          { lng: 29.579466, lat: -1.341313 },
          { lng: 29.821519, lat: -1.443322 },
          { lng: 30.419105, lat: -1.134659 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ESH',
    properties: { name: 'Western Sahara' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -8.794884, lat: 27.120696 },
          { lng: -8.817828, lat: 27.656426 },
          { lng: -8.66559, lat: 27.656426 },
          { lng: -8.665124, lat: 27.589479 },
          { lng: -8.6844, lat: 27.395744 },
          { lng: -8.687294, lat: 25.881056 },
          { lng: -11.969419, lat: 25.933353 },
          { lng: -11.937224, lat: 23.374594 },
          { lng: -12.874222, lat: 23.284832 },
          { lng: -13.118754, lat: 22.77122 },
          { lng: -12.929102, lat: 21.327071 },
          { lng: -16.845194, lat: 21.333323 },
          { lng: -17.063423, lat: 20.999752 },
          { lng: -17.020428, lat: 21.42231 },
          { lng: -17.002962, lat: 21.420734 },
          { lng: -14.750955, lat: 21.5006 },
          { lng: -14.630833, lat: 21.86094 },
          { lng: -14.221168, lat: 22.310163 },
          { lng: -13.89111, lat: 23.691009 },
          { lng: -12.500963, lat: 24.770116 },
          { lng: -12.030759, lat: 26.030866 },
          { lng: -11.71822, lat: 26.104092 },
          { lng: -11.392555, lat: 26.883424 },
          { lng: -10.551263, lat: 26.990808 },
          { lng: -10.189424, lat: 26.860945 },
          { lng: -9.735343, lat: 26.860945 },
          { lng: -9.413037, lat: 27.088476 },
          { lng: -8.794884, lat: 27.120696 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'SAU',
    properties: { name: 'Saudi Arabia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 42.779332, lat: 16.347891 },
          { lng: 42.649573, lat: 16.774635 },
          { lng: 42.347989, lat: 17.075806 },
          { lng: 42.270888, lat: 17.474722 },
          { lng: 41.754382, lat: 17.833046 },
          { lng: 41.221391, lat: 18.6716 },
          { lng: 40.939341, lat: 19.486485 },
          { lng: 40.247652, lat: 20.174635 },
          { lng: 39.801685, lat: 20.338862 },
          { lng: 39.139399, lat: 21.291905 },
          { lng: 39.023696, lat: 21.986875 },
          { lng: 39.066329, lat: 22.579656 },
          { lng: 38.492772, lat: 23.688451 },
          { lng: 38.02386, lat: 24.078686 },
          { lng: 37.483635, lat: 24.285495 },
          { lng: 37.154818, lat: 24.858483 },
          { lng: 37.209491, lat: 25.084542 },
          { lng: 36.931627, lat: 25.602959 },
          { lng: 36.639604, lat: 25.826228 },
          { lng: 36.249137, lat: 26.570136 },
          { lng: 35.640182, lat: 27.37652 },
          { lng: 35.130187, lat: 28.063352 },
          { lng: 34.632336, lat: 28.058546 },
          { lng: 34.787779, lat: 28.607427 },
          { lng: 34.83222, lat: 28.957483 },
          { lng: 34.956037, lat: 29.356555 },
          { lng: 36.068941, lat: 29.197495 },
          { lng: 36.501214, lat: 29.505254 },
          { lng: 36.740528, lat: 29.865283 },
          { lng: 37.503582, lat: 30.003776 },
          { lng: 37.66812, lat: 30.338665 },
          { lng: 37.998849, lat: 30.5085 },
          { lng: 37.002166, lat: 31.508413 },
          { lng: 39.004886, lat: 32.010217 },
          { lng: 39.195468, lat: 32.161009 },
          { lng: 40.399994, lat: 31.889992 },
          { lng: 41.889981, lat: 31.190009 },
          { lng: 44.709499, lat: 29.178891 },
          { lng: 46.568713, lat: 29.099025 },
          { lng: 47.459822, lat: 29.002519 },
          { lng: 47.708851, lat: 28.526063 },
          { lng: 48.416094, lat: 28.552004 },
          { lng: 48.807595, lat: 27.689628 },
          { lng: 49.299554, lat: 27.461218 },
          { lng: 49.470914, lat: 27.109999 },
          { lng: 50.152422, lat: 26.689663 },
          { lng: 50.212935, lat: 26.277027 },
          { lng: 50.113303, lat: 25.943972 },
          { lng: 50.239859, lat: 25.60805 },
          { lng: 50.527387, lat: 25.327808 },
          { lng: 50.660557, lat: 24.999896 },
          { lng: 50.810108, lat: 24.754743 },
          { lng: 51.112415, lat: 24.556331 },
          { lng: 51.389608, lat: 24.627386 },
          { lng: 51.579519, lat: 24.245497 },
          { lng: 51.617708, lat: 24.014219 },
          { lng: 52.000733, lat: 23.001154 },
          { lng: 55.006803, lat: 22.496948 },
          { lng: 55.208341, lat: 22.70833 },
          { lng: 55.666659, lat: 22.000001 },
          { lng: 54.999982, lat: 19.999994 },
          { lng: 52.00001, lat: 19.000003 },
          { lng: 49.116672, lat: 18.616668 },
          { lng: 48.183344, lat: 18.166669 },
          { lng: 47.466695, lat: 17.116682 },
          { lng: 47.000005, lat: 16.949999 },
          { lng: 46.749994, lat: 17.283338 },
          { lng: 46.366659, lat: 17.233315 },
          { lng: 45.399999, lat: 17.333335 },
          { lng: 45.216651, lat: 17.433329 },
          { lng: 44.062613, lat: 17.410359 },
          { lng: 43.791519, lat: 17.319977 },
          { lng: 43.380794, lat: 17.579987 },
          { lng: 43.115798, lat: 17.08844 },
          { lng: 43.218375, lat: 16.66689 },
          { lng: 42.779332, lat: 16.347891 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'SDN',
    properties: { name: 'Sudan' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 33.963393, lat: 9.464285 },
          { lng: 33.824963, lat: 9.484061 },
          { lng: 33.842131, lat: 9.981915 },
          { lng: 33.721959, lat: 10.325262 },
          { lng: 33.206938, lat: 10.720112 },
          { lng: 33.086766, lat: 11.441141 },
          { lng: 33.206938, lat: 12.179338 },
          { lng: 32.743419, lat: 12.248008 },
          { lng: 32.67475, lat: 12.024832 },
          { lng: 32.073892, lat: 11.97333 },
          { lng: 32.314235, lat: 11.681484 },
          { lng: 32.400072, lat: 11.080626 },
          { lng: 31.850716, lat: 10.531271 },
          { lng: 31.352862, lat: 9.810241 },
          { lng: 30.837841, lat: 9.707237 },
          { lng: 29.996639, lat: 10.290927 },
          { lng: 29.618957, lat: 10.084919 },
          { lng: 29.515953, lat: 9.793074 },
          { lng: 29.000932, lat: 9.604232 },
          { lng: 28.966597, lat: 9.398224 },
          { lng: 27.97089, lat: 9.398224 },
          { lng: 27.833551, lat: 9.604232 },
          { lng: 27.112521, lat: 9.638567 },
          { lng: 26.752006, lat: 9.466893 },
          { lng: 26.477328, lat: 9.55273 },
          { lng: 25.962307, lat: 10.136421 },
          { lng: 25.790633, lat: 10.411099 },
          { lng: 25.069604, lat: 10.27376 },
          { lng: 24.794926, lat: 9.810241 },
          { lng: 24.537415, lat: 8.917538 },
          { lng: 24.194068, lat: 8.728696 },
          { lng: 23.88698, lat: 8.61973 },
          { lng: 23.805813, lat: 8.666319 },
          { lng: 23.459013, lat: 8.954286 },
          { lng: 23.394779, lat: 9.265068 },
          { lng: 23.55725, lat: 9.681218 },
          { lng: 23.554304, lat: 10.089255 },
          { lng: 22.977544, lat: 10.714463 },
          { lng: 22.864165, lat: 11.142395 },
          { lng: 22.87622, lat: 11.38461 },
          { lng: 22.50869, lat: 11.67936 },
          { lng: 22.49762, lat: 12.26024 },
          { lng: 22.28801, lat: 12.64605 },
          { lng: 21.93681, lat: 12.58818 },
          { lng: 22.03759, lat: 12.95546 },
          { lng: 22.29658, lat: 13.37232 },
          { lng: 22.18329, lat: 13.78648 },
          { lng: 22.51202, lat: 14.09318 },
          { lng: 22.30351, lat: 14.32682 },
          { lng: 22.56795, lat: 14.94429 },
          { lng: 23.02459, lat: 15.68072 },
          { lng: 23.88689, lat: 15.61084 },
          { lng: 23.83766, lat: 19.58047 },
          { lng: 23.85, lat: 20 },
          { lng: 25, lat: 20.00304 },
          { lng: 25, lat: 22 },
          { lng: 29.02, lat: 22 },
          { lng: 32.9, lat: 22 },
          { lng: 36.86623, lat: 22 },
          { lng: 37.18872, lat: 21.01885 },
          { lng: 36.96941, lat: 20.83744 },
          { lng: 37.1147, lat: 19.80796 },
          { lng: 37.48179, lat: 18.61409 },
          { lng: 37.86276, lat: 18.36786 },
          { lng: 38.41009, lat: 17.998307 },
          { lng: 37.904, lat: 17.42754 },
          { lng: 37.16747, lat: 17.26314 },
          { lng: 36.85253, lat: 16.95655 },
          { lng: 36.75389, lat: 16.29186 },
          { lng: 36.32322, lat: 14.82249 },
          { lng: 36.42951, lat: 14.42211 },
          { lng: 36.27022, lat: 13.56333 },
          { lng: 35.86363, lat: 12.57828 },
          { lng: 35.26049, lat: 12.08286 },
          { lng: 34.83163, lat: 11.31896 },
          { lng: 34.73115, lat: 10.91017 },
          { lng: 34.25745, lat: 10.63009 },
          { lng: 33.96162, lat: 9.58358 },
          { lng: 33.963393, lat: 9.464285 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'SSD',
    properties: { name: 'South Sudan' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 33.963393, lat: 9.464285 },
          { lng: 33.97498, lat: 8.68456 },
          { lng: 33.8255, lat: 8.37916 },
          { lng: 33.2948, lat: 8.35458 },
          { lng: 32.95418, lat: 7.78497 },
          { lng: 33.56829, lat: 7.71334 },
          { lng: 34.0751, lat: 7.22595 },
          { lng: 34.25032, lat: 6.82607 },
          { lng: 34.70702, lat: 6.59422 },
          { lng: 35.298007, lat: 5.506 },
          { lng: 34.620196, lat: 4.847123 },
          { lng: 34.005, lat: 4.249885 },
          { lng: 33.39, lat: 3.79 },
          { lng: 32.68642, lat: 3.79232 },
          { lng: 31.88145, lat: 3.55827 },
          { lng: 31.24556, lat: 3.7819 },
          { lng: 30.83385, lat: 3.50917 },
          { lng: 29.95349, lat: 4.1737 },
          { lng: 29.715995, lat: 4.600805 },
          { lng: 29.159078, lat: 4.389267 },
          { lng: 28.696678, lat: 4.455077 },
          { lng: 28.428994, lat: 4.287155 },
          { lng: 27.979977, lat: 4.408413 },
          { lng: 27.374226, lat: 5.233944 },
          { lng: 27.213409, lat: 5.550953 },
          { lng: 26.465909, lat: 5.946717 },
          { lng: 26.213418, lat: 6.546603 },
          { lng: 25.796648, lat: 6.979316 },
          { lng: 25.124131, lat: 7.500085 },
          { lng: 25.114932, lat: 7.825104 },
          { lng: 24.567369, lat: 8.229188 },
          { lng: 23.88698, lat: 8.61973 },
          { lng: 24.194068, lat: 8.728696 },
          { lng: 24.537415, lat: 8.917538 },
          { lng: 24.794926, lat: 9.810241 },
          { lng: 25.069604, lat: 10.27376 },
          { lng: 25.790633, lat: 10.411099 },
          { lng: 25.962307, lat: 10.136421 },
          { lng: 26.477328, lat: 9.55273 },
          { lng: 26.752006, lat: 9.466893 },
          { lng: 27.112521, lat: 9.638567 },
          { lng: 27.833551, lat: 9.604232 },
          { lng: 27.97089, lat: 9.398224 },
          { lng: 28.966597, lat: 9.398224 },
          { lng: 29.000932, lat: 9.604232 },
          { lng: 29.515953, lat: 9.793074 },
          { lng: 29.618957, lat: 10.084919 },
          { lng: 29.996639, lat: 10.290927 },
          { lng: 30.837841, lat: 9.707237 },
          { lng: 31.352862, lat: 9.810241 },
          { lng: 31.850716, lat: 10.531271 },
          { lng: 32.400072, lat: 11.080626 },
          { lng: 32.314235, lat: 11.681484 },
          { lng: 32.073892, lat: 11.97333 },
          { lng: 32.67475, lat: 12.024832 },
          { lng: 32.743419, lat: 12.248008 },
          { lng: 33.206938, lat: 12.179338 },
          { lng: 33.086766, lat: 11.441141 },
          { lng: 33.206938, lat: 10.720112 },
          { lng: 33.721959, lat: 10.325262 },
          { lng: 33.842131, lat: 9.981915 },
          { lng: 33.824963, lat: 9.484061 },
          { lng: 33.963393, lat: 9.464285 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'SEN',
    properties: { name: 'Senegal' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -16.713729, lat: 13.594959 },
          { lng: -17.126107, lat: 14.373516 },
          { lng: -17.625043, lat: 14.729541 },
          { lng: -17.185173, lat: 14.919477 },
          { lng: -16.700706, lat: 15.621527 },
          { lng: -16.463098, lat: 16.135036 },
          { lng: -16.12069, lat: 16.455663 },
          { lng: -15.623666, lat: 16.369337 },
          { lng: -15.135737, lat: 16.587282 },
          { lng: -14.577348, lat: 16.598264 },
          { lng: -14.099521, lat: 16.304302 },
          { lng: -13.435738, lat: 16.039383 },
          { lng: -12.830658, lat: 15.303692 },
          { lng: -12.17075, lat: 14.616834 },
          { lng: -12.124887, lat: 13.994727 },
          { lng: -11.927716, lat: 13.422075 },
          { lng: -11.553398, lat: 13.141214 },
          { lng: -11.467899, lat: 12.754519 },
          { lng: -11.513943, lat: 12.442988 },
          { lng: -11.658301, lat: 12.386583 },
          { lng: -12.203565, lat: 12.465648 },
          { lng: -12.278599, lat: 12.35444 },
          { lng: -12.499051, lat: 12.33209 },
          { lng: -13.217818, lat: 12.575874 },
          { lng: -13.700476, lat: 12.586183 },
          { lng: -15.548477, lat: 12.62817 },
          { lng: -15.816574, lat: 12.515567 },
          { lng: -16.147717, lat: 12.547762 },
          { lng: -16.677452, lat: 12.384852 },
          { lng: -16.841525, lat: 13.151394 },
          { lng: -15.931296, lat: 13.130284 },
          { lng: -15.691001, lat: 13.270353 },
          { lng: -15.511813, lat: 13.27857 },
          { lng: -15.141163, lat: 13.509512 },
          { lng: -14.712197, lat: 13.298207 },
          { lng: -14.277702, lat: 13.280585 },
          { lng: -13.844963, lat: 13.505042 },
          { lng: -14.046992, lat: 13.794068 },
          { lng: -14.376714, lat: 13.62568 },
          { lng: -14.687031, lat: 13.630357 },
          { lng: -15.081735, lat: 13.876492 },
          { lng: -15.39877, lat: 13.860369 },
          { lng: -15.624596, lat: 13.623587 },
          { lng: -16.713729, lat: 13.594959 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'SLB',
    properties: { name: 'Solomon Islands' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 162.119025, lat: -10.482719 },
          { lng: 162.398646, lat: -10.826367 },
          { lng: 161.700032, lat: -10.820011 },
          { lng: 161.319797, lat: -10.204751 },
          { lng: 161.917383, lat: -10.446701 },
          { lng: 162.119025, lat: -10.482719 },
        ],
        [
          { lng: 160.852229, lat: -9.872937 },
          { lng: 160.462588, lat: -9.89521 },
          { lng: 159.849447, lat: -9.794027 },
          { lng: 159.640003, lat: -9.63998 },
          { lng: 159.702945, lat: -9.24295 },
          { lng: 160.362956, lat: -9.400304 },
          { lng: 160.688518, lat: -9.610162 },
          { lng: 160.852229, lat: -9.872937 },
        ],
        [
          { lng: 161.679982, lat: -9.599982 },
          { lng: 161.529397, lat: -9.784312 },
          { lng: 160.788253, lat: -8.917543 },
          { lng: 160.579997, lat: -8.320009 },
          { lng: 160.920028, lat: -8.320009 },
          { lng: 161.280006, lat: -9.120011 },
          { lng: 161.679982, lat: -9.599982 },
        ],
        [
          { lng: 159.875027, lat: -8.33732 },
          { lng: 159.917402, lat: -8.53829 },
          { lng: 159.133677, lat: -8.114181 },
          { lng: 158.586114, lat: -7.754824 },
          { lng: 158.21115, lat: -7.421872 },
          { lng: 158.359978, lat: -7.320018 },
          { lng: 158.820001, lat: -7.560003 },
          { lng: 159.640003, lat: -8.020027 },
          { lng: 159.875027, lat: -8.33732 },
        ],
        [
          { lng: 157.538426, lat: -7.34782 },
          { lng: 157.33942, lat: -7.404767 },
          { lng: 156.90203, lat: -7.176874 },
          { lng: 156.491358, lat: -6.765943 },
          { lng: 156.542828, lat: -6.599338 },
          { lng: 157.14, lat: -7.021638 },
          { lng: 157.538426, lat: -7.34782 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'SLE',
    properties: { name: 'Sierra Leone' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -11.438779, lat: 6.785917 },
          { lng: -11.708195, lat: 6.860098 },
          { lng: -12.428099, lat: 7.262942 },
          { lng: -12.949049, lat: 7.798646 },
          { lng: -13.124025, lat: 8.163946 },
          { lng: -13.24655, lat: 8.903049 },
          { lng: -12.711958, lat: 9.342712 },
          { lng: -12.596719, lat: 9.620188 },
          { lng: -12.425929, lat: 9.835834 },
          { lng: -12.150338, lat: 9.858572 },
          { lng: -11.917277, lat: 10.046984 },
          { lng: -11.117481, lat: 10.045873 },
          { lng: -10.839152, lat: 9.688246 },
          { lng: -10.622395, lat: 9.26791 },
          { lng: -10.65477, lat: 8.977178 },
          { lng: -10.494315, lat: 8.715541 },
          { lng: -10.505477, lat: 8.348896 },
          { lng: -10.230094, lat: 8.406206 },
          { lng: -10.695595, lat: 7.939464 },
          { lng: -11.146704, lat: 7.396706 },
          { lng: -11.199802, lat: 7.105846 },
          { lng: -11.438779, lat: 6.785917 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'SLV',
    properties: { name: 'El Salvador' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -87.793111, lat: 13.38448 },
          { lng: -87.904112, lat: 13.149017 },
          { lng: -88.483302, lat: 13.163951 },
          { lng: -88.843228, lat: 13.259734 },
          { lng: -89.256743, lat: 13.458533 },
          { lng: -89.812394, lat: 13.520622 },
          { lng: -90.095555, lat: 13.735338 },
          { lng: -90.064678, lat: 13.88197 },
          { lng: -89.721934, lat: 14.134228 },
          { lng: -89.534219, lat: 14.244816 },
          { lng: -89.587343, lat: 14.362586 },
          { lng: -89.353326, lat: 14.424133 },
          { lng: -89.058512, lat: 14.340029 },
          { lng: -88.843073, lat: 14.140507 },
          { lng: -88.541231, lat: 13.980155 },
          { lng: -88.503998, lat: 13.845486 },
          { lng: -88.065343, lat: 13.964626 },
          { lng: -87.859515, lat: 13.893312 },
          { lng: -87.723503, lat: 13.78505 },
          { lng: -87.793111, lat: 13.38448 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: '-99',
    properties: { name: 'Somaliland' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 48.93813, lat: 9.451749 },
          { lng: 48.486736, lat: 8.837626 },
          { lng: 47.78942, lat: 8.003 },
          { lng: 46.948328, lat: 7.996877 },
          { lng: 43.67875, lat: 9.18358 },
          { lng: 43.296975, lat: 9.540477 },
          { lng: 42.92812, lat: 10.02194 },
          { lng: 42.55876, lat: 10.57258 },
          { lng: 42.776852, lat: 10.926879 },
          { lng: 43.145305, lat: 11.46204 },
          { lng: 43.47066, lat: 11.27771 },
          { lng: 43.666668, lat: 10.864169 },
          { lng: 44.117804, lat: 10.445538 },
          { lng: 44.614259, lat: 10.442205 },
          { lng: 45.556941, lat: 10.698029 },
          { lng: 46.645401, lat: 10.816549 },
          { lng: 47.525658, lat: 11.127228 },
          { lng: 48.021596, lat: 11.193064 },
          { lng: 48.378784, lat: 11.375482 },
          { lng: 48.948206, lat: 11.410622 },
          { lng: 48.942005, lat: 11.394266 },
          { lng: 48.938491, lat: 10.982327 },
          { lng: 48.938233, lat: 9.9735 },
          { lng: 48.93813, lat: 9.451749 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'SOM',
    properties: { name: 'Somalia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 49.72862, lat: 11.5789 },
          { lng: 50.25878, lat: 11.67957 },
          { lng: 50.73202, lat: 12.0219 },
          { lng: 51.1112, lat: 12.02464 },
          { lng: 51.13387, lat: 11.74815 },
          { lng: 51.04153, lat: 11.16651 },
          { lng: 51.04531, lat: 10.6409 },
          { lng: 50.83418, lat: 10.27972 },
          { lng: 50.55239, lat: 9.19874 },
          { lng: 50.07092, lat: 8.08173 },
          { lng: 49.4527, lat: 6.80466 },
          { lng: 48.59455, lat: 5.33911 },
          { lng: 47.74079, lat: 4.2194 },
          { lng: 46.56476, lat: 2.85529 },
          { lng: 45.56399, lat: 2.04576 },
          { lng: 44.06815, lat: 1.05283 },
          { lng: 43.13597, lat: 0.2922 },
          { lng: 42.04157, lat: -0.91916 },
          { lng: 41.81095, lat: -1.44647 },
          { lng: 41.58513, lat: -1.68325 },
          { lng: 40.993, lat: -0.85829 },
          { lng: 40.98105, lat: 2.78452 },
          { lng: 41.855083, lat: 3.918912 },
          { lng: 42.12861, lat: 4.23413 },
          { lng: 42.76967, lat: 4.25259 },
          { lng: 43.66087, lat: 4.95755 },
          { lng: 44.9636, lat: 5.00162 },
          { lng: 47.78942, lat: 8.003 },
          { lng: 48.486736, lat: 8.837626 },
          { lng: 48.93813, lat: 9.451749 },
          { lng: 48.938233, lat: 9.9735 },
          { lng: 48.938491, lat: 10.982327 },
          { lng: 48.942005, lat: 11.394266 },
          { lng: 48.948205, lat: 11.410617 },
          { lng: 49.26776, lat: 11.43033 },
          { lng: 49.72862, lat: 11.5789 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'SRB',
    properties: { name: 'Serbia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 20.874313, lat: 45.416375 },
          { lng: 21.483526, lat: 45.18117 },
          { lng: 21.562023, lat: 44.768947 },
          { lng: 22.145088, lat: 44.478422 },
          { lng: 22.459022, lat: 44.702517 },
          { lng: 22.705726, lat: 44.578003 },
          { lng: 22.474008, lat: 44.409228 },
          { lng: 22.65715, lat: 44.234923 },
          { lng: 22.410446, lat: 44.008063 },
          { lng: 22.500157, lat: 43.642814 },
          { lng: 22.986019, lat: 43.211161 },
          { lng: 22.604801, lat: 42.898519 },
          { lng: 22.436595, lat: 42.580321 },
          { lng: 22.545012, lat: 42.461362 },
          { lng: 22.380526, lat: 42.32026 },
          { lng: 21.91708, lat: 42.30364 },
          { lng: 21.576636, lat: 42.245224 },
          { lng: 21.54332, lat: 42.32025 },
          { lng: 21.66292, lat: 42.43922 },
          { lng: 21.77505, lat: 42.6827 },
          { lng: 21.63302, lat: 42.67717 },
          { lng: 21.43866, lat: 42.86255 },
          { lng: 21.27421, lat: 42.90959 },
          { lng: 21.143395, lat: 43.068685 },
          { lng: 20.95651, lat: 43.13094 },
          { lng: 20.81448, lat: 43.27205 },
          { lng: 20.63508, lat: 43.21671 },
          { lng: 20.49679, lat: 42.88469 },
          { lng: 20.25758, lat: 42.81275 },
          { lng: 20.3398, lat: 42.89852 },
          { lng: 19.95857, lat: 43.10604 },
          { lng: 19.63, lat: 43.21378 },
          { lng: 19.48389, lat: 43.35229 },
          { lng: 19.21852, lat: 43.52384 },
          { lng: 19.454, lat: 43.5681 },
          { lng: 19.59976, lat: 44.03847 },
          { lng: 19.11761, lat: 44.42307 },
          { lng: 19.36803, lat: 44.863 },
          { lng: 19.00548, lat: 44.86023 },
          { lng: 19.390476, lat: 45.236516 },
          { lng: 19.072769, lat: 45.521511 },
          { lng: 18.82982, lat: 45.90888 },
          { lng: 19.596045, lat: 46.17173 },
          { lng: 20.220192, lat: 46.127469 },
          { lng: 20.762175, lat: 45.734573 },
          { lng: 20.874313, lat: 45.416375 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'SUR',
    properties: { name: 'Suriname' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -57.147436, lat: 5.97315 },
          { lng: -55.949318, lat: 5.772878 },
          { lng: -55.84178, lat: 5.953125 },
          { lng: -55.03325, lat: 6.025291 },
          { lng: -53.958045, lat: 5.756548 },
          { lng: -54.478633, lat: 4.896756 },
          { lng: -54.399542, lat: 4.212611 },
          { lng: -54.006931, lat: 3.620038 },
          { lng: -54.181726, lat: 3.18978 },
          { lng: -54.269705, lat: 2.732392 },
          { lng: -54.524754, lat: 2.311849 },
          { lng: -55.097587, lat: 2.523748 },
          { lng: -55.569755, lat: 2.421506 },
          { lng: -55.973322, lat: 2.510364 },
          { lng: -56.073342, lat: 2.220795 },
          { lng: -55.9056, lat: 2.021996 },
          { lng: -55.995698, lat: 1.817667 },
          { lng: -56.539386, lat: 1.899523 },
          { lng: -57.150098, lat: 2.768927 },
          { lng: -57.281433, lat: 3.333492 },
          { lng: -57.601569, lat: 3.334655 },
          { lng: -58.044694, lat: 4.060864 },
          { lng: -57.86021, lat: 4.576801 },
          { lng: -57.914289, lat: 4.812626 },
          { lng: -57.307246, lat: 5.073567 },
          { lng: -57.147436, lat: 5.97315 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'SVK',
    properties: { name: 'Slovakia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 18.853144, lat: 49.49623 },
          { lng: 18.909575, lat: 49.435846 },
          { lng: 19.320713, lat: 49.571574 },
          { lng: 19.825023, lat: 49.217125 },
          { lng: 20.415839, lat: 49.431453 },
          { lng: 20.887955, lat: 49.328772 },
          { lng: 21.607808, lat: 49.470107 },
          { lng: 22.558138, lat: 49.085738 },
          { lng: 22.280842, lat: 48.825392 },
          { lng: 22.085608, lat: 48.422264 },
          { lng: 21.872236, lat: 48.319971 },
          { lng: 20.801294, lat: 48.623854 },
          { lng: 20.473562, lat: 48.56285 },
          { lng: 20.239054, lat: 48.327567 },
          { lng: 19.769471, lat: 48.202691 },
          { lng: 19.661364, lat: 48.266615 },
          { lng: 19.174365, lat: 48.111379 },
          { lng: 18.777025, lat: 48.081768 },
          { lng: 18.696513, lat: 47.880954 },
          { lng: 17.857133, lat: 47.758429 },
          { lng: 17.488473, lat: 47.867466 },
          { lng: 16.979667, lat: 48.123497 },
          { lng: 16.879983, lat: 48.470013 },
          { lng: 16.960288, lat: 48.596982 },
          { lng: 17.101985, lat: 48.816969 },
          { lng: 17.545007, lat: 48.800019 },
          { lng: 17.886485, lat: 48.903475 },
          { lng: 17.913512, lat: 48.996493 },
          { lng: 18.104973, lat: 49.043983 },
          { lng: 18.170498, lat: 49.271515 },
          { lng: 18.399994, lat: 49.315001 },
          { lng: 18.554971, lat: 49.495015 },
          { lng: 18.853144, lat: 49.49623 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'SVN',
    properties: { name: 'Slovenia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 13.806475, lat: 46.509306 },
          { lng: 14.632472, lat: 46.431817 },
          { lng: 15.137092, lat: 46.658703 },
          { lng: 16.011664, lat: 46.683611 },
          { lng: 16.202298, lat: 46.852386 },
          { lng: 16.370505, lat: 46.841327 },
          { lng: 16.564808, lat: 46.503751 },
          { lng: 15.768733, lat: 46.238108 },
          { lng: 15.67153, lat: 45.834154 },
          { lng: 15.323954, lat: 45.731783 },
          { lng: 15.327675, lat: 45.452316 },
          { lng: 14.935244, lat: 45.471695 },
          { lng: 14.595109, lat: 45.634941 },
          { lng: 14.411968, lat: 45.466166 },
          { lng: 13.71506, lat: 45.500324 },
          { lng: 13.93763, lat: 45.591016 },
          { lng: 13.69811, lat: 46.016778 },
          { lng: 13.806475, lat: 46.509306 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'SWE',
    properties: { name: 'Sweden' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 22.183173, lat: 65.723741 },
          { lng: 21.213517, lat: 65.026005 },
          { lng: 21.369631, lat: 64.413588 },
          { lng: 19.778876, lat: 63.609554 },
          { lng: 17.847779, lat: 62.7494 },
          { lng: 17.119555, lat: 61.341166 },
          { lng: 17.831346, lat: 60.636583 },
          { lng: 18.787722, lat: 60.081914 },
          { lng: 17.869225, lat: 58.953766 },
          { lng: 16.829185, lat: 58.719827 },
          { lng: 16.44771, lat: 57.041118 },
          { lng: 15.879786, lat: 56.104302 },
          { lng: 14.666681, lat: 56.200885 },
          { lng: 14.100721, lat: 55.407781 },
          { lng: 12.942911, lat: 55.361737 },
          { lng: 12.625101, lat: 56.30708 },
          { lng: 11.787942, lat: 57.441817 },
          { lng: 11.027369, lat: 58.856149 },
          { lng: 11.468272, lat: 59.432393 },
          { lng: 12.300366, lat: 60.117933 },
          { lng: 12.631147, lat: 61.293572 },
          { lng: 11.992064, lat: 61.800362 },
          { lng: 11.930569, lat: 63.128318 },
          { lng: 12.579935, lat: 64.066219 },
          { lng: 13.571916, lat: 64.049114 },
          { lng: 13.919905, lat: 64.445421 },
          { lng: 13.55569, lat: 64.787028 },
          { lng: 15.108411, lat: 66.193867 },
          { lng: 16.108712, lat: 67.302456 },
          { lng: 16.768879, lat: 68.013937 },
          { lng: 17.729182, lat: 68.010552 },
          { lng: 17.993868, lat: 68.567391 },
          { lng: 19.87856, lat: 68.407194 },
          { lng: 20.025269, lat: 69.065139 },
          { lng: 20.645593, lat: 69.106247 },
          { lng: 21.978535, lat: 68.616846 },
          { lng: 23.539473, lat: 67.936009 },
          { lng: 23.56588, lat: 66.396051 },
          { lng: 23.903379, lat: 66.006927 },
          { lng: 22.183173, lat: 65.723741 },
        ],
        [
          { lng: 17.061767, lat: 57.385783 },
          { lng: 17.210083, lat: 57.326521 },
          { lng: 16.430053, lat: 56.179196 },
          { lng: 16.364135, lat: 56.556455 },
          { lng: 17.061767, lat: 57.385783 },
        ],
        [
          { lng: 19.35791, lat: 57.958588 },
          { lng: 18.8031, lat: 57.651279 },
          { lng: 18.825073, lat: 57.444949 },
          { lng: 18.995361, lat: 57.441993 },
          { lng: 18.951416, lat: 57.370976 },
          { lng: 18.693237, lat: 57.305756 },
          { lng: 18.709716, lat: 57.204734 },
          { lng: 18.462524, lat: 57.127295 },
          { lng: 18.319702, lat: 56.926992 },
          { lng: 18.105468, lat: 56.891003 },
          { lng: 18.187866, lat: 57.109402 },
          { lng: 18.072509, lat: 57.267163 },
          { lng: 18.154907, lat: 57.394664 },
          { lng: 18.094482, lat: 57.545312 },
          { lng: 18.660278, lat: 57.929434 },
          { lng: 19.039306, lat: 57.941098 },
          { lng: 19.105224, lat: 57.993543 },
          { lng: 19.374389, lat: 57.996454 },
          { lng: 19.35791, lat: 57.958588 },
        ],
        [
          { lng: 20.846557, lat: 63.82371 },
          { lng: 21.066284, lat: 63.829768 },
          { lng: 20.9729, lat: 63.71567 },
          { lng: 20.824584, lat: 63.579121 },
          { lng: 20.695495, lat: 63.59134 },
          { lng: 20.819091, lat: 63.714454 },
          { lng: 20.799865, lat: 63.780059 },
          { lng: 20.846557, lat: 63.82371 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'SWZ',
    properties: { name: 'Swaziland' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 32.071665, lat: -26.73382 },
          { lng: 31.86806, lat: -27.177927 },
          { lng: 31.282773, lat: -27.285879 },
          { lng: 30.685962, lat: -26.743845 },
          { lng: 30.676609, lat: -26.398078 },
          { lng: 30.949667, lat: -26.022649 },
          { lng: 31.04408, lat: -25.731452 },
          { lng: 31.333158, lat: -25.660191 },
          { lng: 31.837778, lat: -25.843332 },
          { lng: 31.985779, lat: -26.29178 },
          { lng: 32.071665, lat: -26.73382 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'SYR',
    properties: { name: 'Syria' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 38.792341, lat: 33.378686 },
          { lng: 36.834062, lat: 32.312938 },
          { lng: 35.719918, lat: 32.709192 },
          { lng: 35.700798, lat: 32.716014 },
          { lng: 35.836397, lat: 32.868123 },
          { lng: 35.821101, lat: 33.277426 },
          { lng: 36.06646, lat: 33.824912 },
          { lng: 36.61175, lat: 34.201789 },
          { lng: 36.448194, lat: 34.593935 },
          { lng: 35.998403, lat: 34.644914 },
          { lng: 35.905023, lat: 35.410009 },
          { lng: 36.149763, lat: 35.821535 },
          { lng: 36.41755, lat: 36.040617 },
          { lng: 36.685389, lat: 36.259699 },
          { lng: 36.739494, lat: 36.81752 },
          { lng: 37.066761, lat: 36.623036 },
          { lng: 38.167727, lat: 36.90121 },
          { lng: 38.699891, lat: 36.712927 },
          { lng: 39.52258, lat: 36.716054 },
          { lng: 40.673259, lat: 37.091276 },
          { lng: 41.212089, lat: 37.074352 },
          { lng: 42.349591, lat: 37.229873 },
          { lng: 41.837064, lat: 36.605854 },
          { lng: 41.289707, lat: 36.358815 },
          { lng: 41.383965, lat: 35.628317 },
          { lng: 41.006159, lat: 34.419372 },
          { lng: 38.792341, lat: 33.378686 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'TCD',
    properties: { name: 'Chad' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 14.495787, lat: 12.859396 },
          { lng: 14.595781, lat: 13.330427 },
          { lng: 13.954477, lat: 13.353449 },
          { lng: 13.956699, lat: 13.996691 },
          { lng: 13.540394, lat: 14.367134 },
          { lng: 13.97217, lat: 15.68437 },
          { lng: 15.247731, lat: 16.627306 },
          { lng: 15.300441, lat: 17.92795 },
          { lng: 15.685741, lat: 19.95718 },
          { lng: 15.903247, lat: 20.387619 },
          { lng: 15.487148, lat: 20.730415 },
          { lng: 15.47106, lat: 21.04845 },
          { lng: 15.096888, lat: 21.308519 },
          { lng: 14.8513, lat: 22.86295 },
          { lng: 15.86085, lat: 23.40972 },
          { lng: 19.84926, lat: 21.49509 },
          { lng: 23.83766, lat: 19.58047 },
          { lng: 23.88689, lat: 15.61084 },
          { lng: 23.02459, lat: 15.68072 },
          { lng: 22.56795, lat: 14.94429 },
          { lng: 22.30351, lat: 14.32682 },
          { lng: 22.51202, lat: 14.09318 },
          { lng: 22.18329, lat: 13.78648 },
          { lng: 22.29658, lat: 13.37232 },
          { lng: 22.03759, lat: 12.95546 },
          { lng: 21.93681, lat: 12.58818 },
          { lng: 22.28801, lat: 12.64605 },
          { lng: 22.49762, lat: 12.26024 },
          { lng: 22.50869, lat: 11.67936 },
          { lng: 22.87622, lat: 11.38461 },
          { lng: 22.864165, lat: 11.142395 },
          { lng: 22.231129, lat: 10.971889 },
          { lng: 21.723822, lat: 10.567056 },
          { lng: 21.000868, lat: 9.475985 },
          { lng: 20.059685, lat: 9.012706 },
          { lng: 19.094008, lat: 9.074847 },
          { lng: 18.81201, lat: 8.982915 },
          { lng: 18.911022, lat: 8.630895 },
          { lng: 18.389555, lat: 8.281304 },
          { lng: 17.96493, lat: 7.890914 },
          { lng: 16.705988, lat: 7.508328 },
          { lng: 16.456185, lat: 7.734774 },
          { lng: 16.290562, lat: 7.754307 },
          { lng: 16.106232, lat: 7.497088 },
          { lng: 15.27946, lat: 7.421925 },
          { lng: 15.436092, lat: 7.692812 },
          { lng: 15.120866, lat: 8.38215 },
          { lng: 14.979996, lat: 8.796104 },
          { lng: 14.544467, lat: 8.965861 },
          { lng: 13.954218, lat: 9.549495 },
          { lng: 14.171466, lat: 10.021378 },
          { lng: 14.627201, lat: 9.920919 },
          { lng: 14.909354, lat: 9.992129 },
          { lng: 15.467873, lat: 9.982337 },
          { lng: 14.923565, lat: 10.891325 },
          { lng: 14.960152, lat: 11.555574 },
          { lng: 14.89336, lat: 12.21905 },
          { lng: 14.495787, lat: 12.859396 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'TGO',
    properties: { name: 'Togo' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 1.865241, lat: 6.142158 },
          { lng: 1.060122, lat: 5.928837 },
          { lng: 0.836931, lat: 6.279979 },
          { lng: 0.570384, lat: 6.914359 },
          { lng: 0.490957, lat: 7.411744 },
          { lng: 0.712029, lat: 8.312465 },
          { lng: 0.461192, lat: 8.677223 },
          { lng: 0.365901, lat: 9.465004 },
          { lng: 0.36758, lat: 10.191213 },
          { lng: -0.049785, lat: 10.706918 },
          { lng: 0.023803, lat: 11.018682 },
          { lng: 0.899563, lat: 10.997339 },
          { lng: 0.772336, lat: 10.470808 },
          { lng: 1.077795, lat: 10.175607 },
          { lng: 1.425061, lat: 9.825395 },
          { lng: 1.463043, lat: 9.334624 },
          { lng: 1.664478, lat: 9.12859 },
          { lng: 1.618951, lat: 6.832038 },
          { lng: 1.865241, lat: 6.142158 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'THA',
    properties: { name: 'Thailand' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 102.584932, lat: 12.186595 },
          { lng: 101.687158, lat: 12.64574 },
          { lng: 100.83181, lat: 12.627085 },
          { lng: 100.978467, lat: 13.412722 },
          { lng: 100.097797, lat: 13.406856 },
          { lng: 100.018733, lat: 12.307001 },
          { lng: 99.478921, lat: 10.846367 },
          { lng: 99.153772, lat: 9.963061 },
          { lng: 99.222399, lat: 9.239255 },
          { lng: 99.873832, lat: 9.207862 },
          { lng: 100.279647, lat: 8.295153 },
          { lng: 100.459274, lat: 7.429573 },
          { lng: 101.017328, lat: 6.856869 },
          { lng: 101.623079, lat: 6.740622 },
          { lng: 102.141187, lat: 6.221636 },
          { lng: 101.814282, lat: 5.810808 },
          { lng: 101.154219, lat: 5.691384 },
          { lng: 101.075516, lat: 6.204867 },
          { lng: 100.259596, lat: 6.642825 },
          { lng: 100.085757, lat: 6.464489 },
          { lng: 99.690691, lat: 6.848213 },
          { lng: 99.519642, lat: 7.343454 },
          { lng: 98.988253, lat: 7.907993 },
          { lng: 98.503786, lat: 8.382305 },
          { lng: 98.339662, lat: 7.794512 },
          { lng: 98.150009, lat: 8.350007 },
          { lng: 98.25915, lat: 8.973923 },
          { lng: 98.553551, lat: 9.93296 },
          { lng: 99.038121, lat: 10.960546 },
          { lng: 99.587286, lat: 11.892763 },
          { lng: 99.196354, lat: 12.804748 },
          { lng: 99.212012, lat: 13.269294 },
          { lng: 99.097755, lat: 13.827503 },
          { lng: 98.430819, lat: 14.622028 },
          { lng: 98.192074, lat: 15.123703 },
          { lng: 98.537376, lat: 15.308497 },
          { lng: 98.903348, lat: 16.177824 },
          { lng: 98.493761, lat: 16.837836 },
          { lng: 97.859123, lat: 17.567946 },
          { lng: 97.375896, lat: 18.445438 },
          { lng: 97.797783, lat: 18.62708 },
          { lng: 98.253724, lat: 19.708203 },
          { lng: 98.959676, lat: 19.752981 },
          { lng: 99.543309, lat: 20.186598 },
          { lng: 100.115988, lat: 20.41785 },
          { lng: 100.548881, lat: 20.109238 },
          { lng: 100.606294, lat: 19.508344 },
          { lng: 101.282015, lat: 19.462585 },
          { lng: 101.035931, lat: 18.408928 },
          { lng: 101.059548, lat: 17.512497 },
          { lng: 102.113592, lat: 18.109102 },
          { lng: 102.413005, lat: 17.932782 },
          { lng: 102.998706, lat: 17.961695 },
          { lng: 103.200192, lat: 18.309632 },
          { lng: 103.956477, lat: 18.240954 },
          { lng: 104.716947, lat: 17.428859 },
          { lng: 104.779321, lat: 16.441865 },
          { lng: 105.589039, lat: 15.570316 },
          { lng: 105.544338, lat: 14.723934 },
          { lng: 105.218777, lat: 14.273212 },
          { lng: 104.281418, lat: 14.416743 },
          { lng: 102.988422, lat: 14.225721 },
          { lng: 102.348099, lat: 13.394247 },
          { lng: 102.584932, lat: 12.186595 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'TJK',
    properties: { name: 'Tajikistan' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 71.014198, lat: 40.244366 },
          { lng: 70.648019, lat: 39.935754 },
          { lng: 69.55961, lat: 40.103211 },
          { lng: 69.464887, lat: 39.526683 },
          { lng: 70.549162, lat: 39.604198 },
          { lng: 71.784694, lat: 39.279463 },
          { lng: 73.675379, lat: 39.431237 },
          { lng: 73.928852, lat: 38.505815 },
          { lng: 74.257514, lat: 38.606507 },
          { lng: 74.864816, lat: 38.378846 },
          { lng: 74.829986, lat: 37.990007 },
          { lng: 74.980002, lat: 37.41999 },
          { lng: 73.948696, lat: 37.421566 },
          { lng: 73.260056, lat: 37.495257 },
          { lng: 72.63689, lat: 37.047558 },
          { lng: 72.193041, lat: 36.948288 },
          { lng: 71.844638, lat: 36.738171 },
          { lng: 71.448693, lat: 37.065645 },
          { lng: 71.541918, lat: 37.905774 },
          { lng: 71.239404, lat: 37.953265 },
          { lng: 71.348131, lat: 38.258905 },
          { lng: 70.806821, lat: 38.486282 },
          { lng: 70.376304, lat: 38.138396 },
          { lng: 70.270574, lat: 37.735165 },
          { lng: 70.116578, lat: 37.588223 },
          { lng: 69.518785, lat: 37.608997 },
          { lng: 69.196273, lat: 37.151144 },
          { lng: 68.859446, lat: 37.344336 },
          { lng: 68.135562, lat: 37.023115 },
          { lng: 67.83, lat: 37.144994 },
          { lng: 68.392033, lat: 38.157025 },
          { lng: 68.176025, lat: 38.901553 },
          { lng: 67.44222, lat: 39.140144 },
          { lng: 67.701429, lat: 39.580478 },
          { lng: 68.536416, lat: 39.533453 },
          { lng: 69.011633, lat: 40.086158 },
          { lng: 69.329495, lat: 40.727824 },
          { lng: 70.666622, lat: 40.960213 },
          { lng: 70.45816, lat: 40.496495 },
          { lng: 70.601407, lat: 40.218527 },
          { lng: 71.014198, lat: 40.244366 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'TKM',
    properties: { name: 'Turkmenistan' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 61.210817, lat: 35.650072 },
          { lng: 61.123071, lat: 36.491597 },
          { lng: 60.377638, lat: 36.527383 },
          { lng: 59.234762, lat: 37.412988 },
          { lng: 58.436154, lat: 37.522309 },
          { lng: 57.330434, lat: 38.029229 },
          { lng: 56.619366, lat: 38.121394 },
          { lng: 56.180375, lat: 37.935127 },
          { lng: 55.511578, lat: 37.964117 },
          { lng: 54.800304, lat: 37.392421 },
          { lng: 53.921598, lat: 37.198918 },
          { lng: 53.735511, lat: 37.906136 },
          { lng: 53.880929, lat: 38.952093 },
          { lng: 53.101028, lat: 39.290574 },
          { lng: 53.357808, lat: 39.975286 },
          { lng: 52.693973, lat: 40.033629 },
          { lng: 52.915251, lat: 40.876523 },
          { lng: 53.858139, lat: 40.631034 },
          { lng: 54.736845, lat: 40.951015 },
          { lng: 54.008311, lat: 41.551211 },
          { lng: 53.721713, lat: 42.123191 },
          { lng: 52.91675, lat: 41.868117 },
          { lng: 52.814689, lat: 41.135371 },
          { lng: 52.50246, lat: 41.783316 },
          { lng: 52.944293, lat: 42.116034 },
          { lng: 54.079418, lat: 42.324109 },
          { lng: 54.755345, lat: 42.043971 },
          { lng: 55.455251, lat: 41.259859 },
          { lng: 55.968191, lat: 41.308642 },
          { lng: 57.096391, lat: 41.32231 },
          { lng: 56.932215, lat: 41.826026 },
          { lng: 57.78653, lat: 42.170553 },
          { lng: 58.629011, lat: 42.751551 },
          { lng: 59.976422, lat: 42.223082 },
          { lng: 60.083341, lat: 41.425146 },
          { lng: 60.465953, lat: 41.220327 },
          { lng: 61.547179, lat: 41.26637 },
          { lng: 61.882714, lat: 41.084857 },
          { lng: 62.37426, lat: 40.053886 },
          { lng: 63.518015, lat: 39.363257 },
          { lng: 64.170223, lat: 38.892407 },
          { lng: 65.215999, lat: 38.402695 },
          { lng: 66.54615, lat: 37.974685 },
          { lng: 66.518607, lat: 37.362784 },
          { lng: 66.217385, lat: 37.39379 },
          { lng: 65.745631, lat: 37.661164 },
          { lng: 65.588948, lat: 37.305217 },
          { lng: 64.746105, lat: 37.111818 },
          { lng: 64.546479, lat: 36.312073 },
          { lng: 63.982896, lat: 36.007957 },
          { lng: 63.193538, lat: 35.857166 },
          { lng: 62.984662, lat: 35.404041 },
          { lng: 62.230651, lat: 35.270664 },
          { lng: 61.210817, lat: 35.650072 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'TLS',
    properties: { name: 'East Timor' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 124.968682, lat: -8.89279 },
          { lng: 125.086246, lat: -8.656887 },
          { lng: 125.947072, lat: -8.432095 },
          { lng: 126.644704, lat: -8.398247 },
          { lng: 126.957243, lat: -8.273345 },
          { lng: 127.335928, lat: -8.397317 },
          { lng: 126.967992, lat: -8.668256 },
          { lng: 125.925885, lat: -9.106007 },
          { lng: 125.08852, lat: -9.393173 },
          { lng: 125.07002, lat: -9.089987 },
          { lng: 124.968682, lat: -8.89279 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'TTO',
    properties: { name: 'Trinidad and Tobago' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -61.68, lat: 10.76 },
          { lng: -61.105, lat: 10.89 },
          { lng: -60.895, lat: 10.855 },
          { lng: -60.935, lat: 10.11 },
          { lng: -61.77, lat: 10 },
          { lng: -61.95, lat: 10.09 },
          { lng: -61.66, lat: 10.365 },
          { lng: -61.68, lat: 10.76 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'TUN',
    properties: { name: 'Tunisia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 9.48214, lat: 30.307556 },
          { lng: 9.055603, lat: 32.102692 },
          { lng: 8.439103, lat: 32.506285 },
          { lng: 8.430473, lat: 32.748337 },
          { lng: 7.612642, lat: 33.344115 },
          { lng: 7.524482, lat: 34.097376 },
          { lng: 8.140981, lat: 34.655146 },
          { lng: 8.376368, lat: 35.479876 },
          { lng: 8.217824, lat: 36.433177 },
          { lng: 8.420964, lat: 36.946427 },
          { lng: 9.509994, lat: 37.349994 },
          { lng: 10.210002, lat: 37.230002 },
          { lng: 10.18065, lat: 36.724038 },
          { lng: 11.028867, lat: 37.092103 },
          { lng: 11.100026, lat: 36.899996 },
          { lng: 10.600005, lat: 36.41 },
          { lng: 10.593287, lat: 35.947444 },
          { lng: 10.939519, lat: 35.698984 },
          { lng: 10.807847, lat: 34.833507 },
          { lng: 10.149593, lat: 34.330773 },
          { lng: 10.339659, lat: 33.785742 },
          { lng: 10.856836, lat: 33.76874 },
          { lng: 11.108501, lat: 33.293343 },
          { lng: 11.488787, lat: 33.136996 },
          { lng: 11.432253, lat: 32.368903 },
          { lng: 10.94479, lat: 32.081815 },
          { lng: 10.636901, lat: 31.761421 },
          { lng: 9.950225, lat: 31.37607 },
          { lng: 10.056575, lat: 30.961831 },
          { lng: 9.970017, lat: 30.539325 },
          { lng: 9.48214, lat: 30.307556 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'TUR',
    properties: { name: 'Turkey' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 36.913127, lat: 41.335358 },
          { lng: 38.347665, lat: 40.948586 },
          { lng: 39.512607, lat: 41.102763 },
          { lng: 40.373433, lat: 41.013673 },
          { lng: 41.554084, lat: 41.535656 },
          { lng: 42.619549, lat: 41.583173 },
          { lng: 43.582746, lat: 41.092143 },
          { lng: 43.752658, lat: 40.740201 },
          { lng: 43.656436, lat: 40.253564 },
          { lng: 44.400009, lat: 40.005 },
          { lng: 44.79399, lat: 39.713003 },
          { lng: 44.109225, lat: 39.428136 },
          { lng: 44.421403, lat: 38.281281 },
          { lng: 44.225756, lat: 37.971584 },
          { lng: 44.772699, lat: 37.170445 },
          { lng: 44.293452, lat: 37.001514 },
          { lng: 43.942259, lat: 37.256228 },
          { lng: 42.779126, lat: 37.385264 },
          { lng: 42.349591, lat: 37.229873 },
          { lng: 41.212089, lat: 37.074352 },
          { lng: 40.673259, lat: 37.091276 },
          { lng: 39.52258, lat: 36.716054 },
          { lng: 38.699891, lat: 36.712927 },
          { lng: 38.167727, lat: 36.90121 },
          { lng: 37.066761, lat: 36.623036 },
          { lng: 36.739494, lat: 36.81752 },
          { lng: 36.685389, lat: 36.259699 },
          { lng: 36.41755, lat: 36.040617 },
          { lng: 36.149763, lat: 35.821535 },
          { lng: 35.782085, lat: 36.274995 },
          { lng: 36.160822, lat: 36.650606 },
          { lng: 35.550936, lat: 36.565443 },
          { lng: 34.714553, lat: 36.795532 },
          { lng: 34.026895, lat: 36.21996 },
          { lng: 32.509158, lat: 36.107564 },
          { lng: 31.699595, lat: 36.644275 },
          { lng: 30.621625, lat: 36.677865 },
          { lng: 30.391096, lat: 36.262981 },
          { lng: 29.699976, lat: 36.144357 },
          { lng: 28.732903, lat: 36.676831 },
          { lng: 27.641187, lat: 36.658822 },
          { lng: 27.048768, lat: 37.653361 },
          { lng: 26.318218, lat: 38.208133 },
          { lng: 26.8047, lat: 38.98576 },
          { lng: 26.170785, lat: 39.463612 },
          { lng: 27.28002, lat: 40.420014 },
          { lng: 28.819978, lat: 40.460011 },
          { lng: 29.240004, lat: 41.219991 },
          { lng: 31.145934, lat: 41.087622 },
          { lng: 32.347979, lat: 41.736264 },
          { lng: 33.513283, lat: 42.01896 },
          { lng: 35.167704, lat: 42.040225 },
          { lng: 36.913127, lat: 41.335358 },
        ],
        [
          { lng: 27.192377, lat: 40.690566 },
          { lng: 26.358009, lat: 40.151994 },
          { lng: 26.043351, lat: 40.617754 },
          { lng: 26.056942, lat: 40.824123 },
          { lng: 26.294602, lat: 40.936261 },
          { lng: 26.604196, lat: 41.562115 },
          { lng: 26.117042, lat: 41.826905 },
          { lng: 27.135739, lat: 42.141485 },
          { lng: 27.99672, lat: 42.007359 },
          { lng: 28.115525, lat: 41.622886 },
          { lng: 28.988443, lat: 41.299934 },
          { lng: 28.806438, lat: 41.054962 },
          { lng: 27.619017, lat: 40.999823 },
          { lng: 27.192377, lat: 40.690566 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'TWN',
    properties: { name: 'Taiwan' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 121.777818, lat: 24.394274 },
          { lng: 121.175632, lat: 22.790857 },
          { lng: 120.74708, lat: 21.970571 },
          { lng: 120.220083, lat: 22.814861 },
          { lng: 120.106189, lat: 23.556263 },
          { lng: 120.69468, lat: 24.538451 },
          { lng: 121.495044, lat: 25.295459 },
          { lng: 121.951244, lat: 24.997596 },
          { lng: 121.777818, lat: 24.394274 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'TZA',
    properties: {
      name: 'Tanzania',
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 33.903711, lat: -0.95 },
          { lng: 34.07262, lat: -1.05982 },
          { lng: 37.69869, lat: -3.09699 },
          { lng: 37.7669, lat: -3.67712 },
          { lng: 39.20222, lat: -4.67677 },
          { lng: 38.74054, lat: -5.90895 },
          { lng: 38.79977, lat: -6.47566 },
          { lng: 39.44, lat: -6.84 },
          { lng: 39.47, lat: -7.1 },
          { lng: 39.19469, lat: -7.7039 },
          { lng: 39.25203, lat: -8.00781 },
          { lng: 39.18652, lat: -8.48551 },
          { lng: 39.53574, lat: -9.11237 },
          { lng: 39.9496, lat: -10.0984 },
          { lng: 40.31659, lat: -10.3171 },
          { lng: 39.521, lat: -10.89688 },
          { lng: 38.427557, lat: -11.285202 },
          { lng: 37.82764, lat: -11.26879 },
          { lng: 37.47129, lat: -11.56876 },
          { lng: 36.775151, lat: -11.594537 },
          { lng: 36.514082, lat: -11.720938 },
          { lng: 35.312398, lat: -11.439146 },
          { lng: 34.559989, lat: -11.52002 },
          { lng: 34.28, lat: -10.16 },
          { lng: 33.940838, lat: -9.693674 },
          { lng: 33.73972, lat: -9.41715 },
          { lng: 32.759375, lat: -9.230599 },
          { lng: 32.191865, lat: -8.930359 },
          { lng: 31.556348, lat: -8.762049 },
          { lng: 31.157751, lat: -8.594579 },
          { lng: 30.74, lat: -8.34 },
          { lng: 30.2, lat: -7.08 },
          { lng: 29.62, lat: -6.52 },
          { lng: 29.419993, lat: -5.939999 },
          { lng: 29.519987, lat: -5.419979 },
          { lng: 29.339998, lat: -4.499983 },
          { lng: 29.753512, lat: -4.452389 },
          { lng: 30.11632, lat: -4.09012 },
          { lng: 30.50554, lat: -3.56858 },
          { lng: 30.75224, lat: -3.35931 },
          { lng: 30.74301, lat: -3.03431 },
          { lng: 30.52766, lat: -2.80762 },
          { lng: 30.46967, lat: -2.41383 },
          { lng: 30.758309, lat: -2.28725 },
          { lng: 30.816135, lat: -1.698914 },
          { lng: 30.419105, lat: -1.134659 },
          { lng: 30.76986, lat: -1.01455 },
          { lng: 31.86617, lat: -1.02736 },
          { lng: 33.903711, lat: -0.95 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'UGA',
    properties: { name: 'Uganda' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 31.86617, lat: -1.02736 },
          { lng: 30.76986, lat: -1.01455 },
          { lng: 30.419105, lat: -1.134659 },
          { lng: 29.821519, lat: -1.443322 },
          { lng: 29.579466, lat: -1.341313 },
          { lng: 29.587838, lat: -0.587406 },
          { lng: 29.8195, lat: -0.2053 },
          { lng: 29.875779, lat: 0.59738 },
          { lng: 30.086154, lat: 1.062313 },
          { lng: 30.468508, lat: 1.583805 },
          { lng: 30.85267, lat: 1.849396 },
          { lng: 31.174149, lat: 2.204465 },
          { lng: 30.77332, lat: 2.33989 },
          { lng: 30.83385, lat: 3.50917 },
          { lng: 31.24556, lat: 3.7819 },
          { lng: 31.88145, lat: 3.55827 },
          { lng: 32.68642, lat: 3.79232 },
          { lng: 33.39, lat: 3.79 },
          { lng: 34.005, lat: 4.249885 },
          { lng: 34.47913, lat: 3.5556 },
          { lng: 34.59607, lat: 3.05374 },
          { lng: 35.03599, lat: 1.90584 },
          { lng: 34.6721, lat: 1.17694 },
          { lng: 34.18, lat: 0.515 },
          { lng: 33.893569, lat: 0.109814 },
          { lng: 33.903711, lat: -0.95 },
          { lng: 31.86617, lat: -1.02736 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'UKR',
    properties: { name: 'Ukraine' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 31.785998, lat: 52.101678 },
          { lng: 32.159412, lat: 52.061267 },
          { lng: 32.412058, lat: 52.288695 },
          { lng: 32.715761, lat: 52.238465 },
          { lng: 33.7527, lat: 52.335075 },
          { lng: 34.391731, lat: 51.768882 },
          { lng: 34.141978, lat: 51.566413 },
          { lng: 34.224816, lat: 51.255993 },
          { lng: 35.022183, lat: 51.207572 },
          { lng: 35.377924, lat: 50.773955 },
          { lng: 35.356116, lat: 50.577197 },
          { lng: 36.626168, lat: 50.225591 },
          { lng: 37.39346, lat: 50.383953 },
          { lng: 38.010631, lat: 49.915662 },
          { lng: 38.594988, lat: 49.926462 },
          { lng: 40.069058, lat: 49.601055 },
          { lng: 40.080789, lat: 49.30743 },
          { lng: 39.674664, lat: 48.783818 },
          { lng: 39.895632, lat: 48.232405 },
          { lng: 39.738278, lat: 47.898937 },
          { lng: 38.770585, lat: 47.825608 },
          { lng: 38.255112, lat: 47.5464 },
          { lng: 38.223538, lat: 47.10219 },
          { lng: 37.425137, lat: 47.022221 },
          { lng: 36.759855, lat: 46.6987 },
          { lng: 35.823685, lat: 46.645964 },
          { lng: 34.962342, lat: 46.273197 },
          { lng: 35.020788, lat: 45.651219 },
          { lng: 35.510009, lat: 45.409993 },
          { lng: 36.529998, lat: 45.46999 },
          { lng: 36.334713, lat: 45.113216 },
          { lng: 35.239999, lat: 44.939996 },
          { lng: 33.882511, lat: 44.361479 },
          { lng: 33.326421, lat: 44.564877 },
          { lng: 33.546924, lat: 45.034771 },
          { lng: 32.454174, lat: 45.327466 },
          { lng: 32.630804, lat: 45.519186 },
          { lng: 33.588162, lat: 45.851569 },
          { lng: 33.298567, lat: 46.080598 },
          { lng: 31.74414, lat: 46.333348 },
          { lng: 31.675307, lat: 46.706245 },
          { lng: 30.748749, lat: 46.5831 },
          { lng: 30.377609, lat: 46.03241 },
          { lng: 29.603289, lat: 45.293308 },
          { lng: 29.149725, lat: 45.464925 },
          { lng: 28.679779, lat: 45.304031 },
          { lng: 28.233554, lat: 45.488283 },
          { lng: 28.485269, lat: 45.596907 },
          { lng: 28.659987, lat: 45.939987 },
          { lng: 28.933717, lat: 46.25883 },
          { lng: 28.862972, lat: 46.437889 },
          { lng: 29.072107, lat: 46.517678 },
          { lng: 29.170654, lat: 46.379262 },
          { lng: 29.759972, lat: 46.349988 },
          { lng: 30.024659, lat: 46.423937 },
          { lng: 29.83821, lat: 46.525326 },
          { lng: 29.908852, lat: 46.674361 },
          { lng: 29.559674, lat: 46.928583 },
          { lng: 29.415135, lat: 47.346645 },
          { lng: 29.050868, lat: 47.510227 },
          { lng: 29.122698, lat: 47.849095 },
          { lng: 28.670891, lat: 48.118149 },
          { lng: 28.259547, lat: 48.155562 },
          { lng: 27.522537, lat: 48.467119 },
          { lng: 26.857824, lat: 48.368211 },
          { lng: 26.619337, lat: 48.220726 },
          { lng: 26.19745, lat: 48.220881 },
          { lng: 25.945941, lat: 47.987149 },
          { lng: 25.207743, lat: 47.891056 },
          { lng: 24.866317, lat: 47.737526 },
          { lng: 24.402056, lat: 47.981878 },
          { lng: 23.760958, lat: 47.985598 },
          { lng: 23.142236, lat: 48.096341 },
          { lng: 22.710531, lat: 47.882194 },
          { lng: 22.64082, lat: 48.15024 },
          { lng: 22.085608, lat: 48.422264 },
          { lng: 22.280842, lat: 48.825392 },
          { lng: 22.558138, lat: 49.085738 },
          { lng: 22.776419, lat: 49.027395 },
          { lng: 22.51845, lat: 49.476774 },
          { lng: 23.426508, lat: 50.308506 },
          { lng: 23.922757, lat: 50.424881 },
          { lng: 24.029986, lat: 50.705407 },
          { lng: 23.527071, lat: 51.578454 },
          { lng: 24.005078, lat: 51.617444 },
          { lng: 24.553106, lat: 51.888461 },
          { lng: 25.327788, lat: 51.910656 },
          { lng: 26.337959, lat: 51.832289 },
          { lng: 27.454066, lat: 51.592303 },
          { lng: 28.241615, lat: 51.572227 },
          { lng: 28.617613, lat: 51.427714 },
          { lng: 28.992835, lat: 51.602044 },
          { lng: 29.254938, lat: 51.368234 },
          { lng: 30.157364, lat: 51.416138 },
          { lng: 30.555117, lat: 51.319503 },
          { lng: 30.619454, lat: 51.822806 },
          { lng: 30.927549, lat: 52.042353 },
          { lng: 31.785998, lat: 52.101678 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'URY',
    properties: { name: 'Uruguay' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -57.625133, lat: -30.216295 },
          { lng: -56.976026, lat: -30.109686 },
          { lng: -55.973245, lat: -30.883076 },
          { lng: -55.60151, lat: -30.853879 },
          { lng: -54.572452, lat: -31.494511 },
          { lng: -53.787952, lat: -32.047243 },
          { lng: -53.209589, lat: -32.727666 },
          { lng: -53.650544, lat: -33.202004 },
          { lng: -53.373662, lat: -33.768378 },
          { lng: -53.806426, lat: -34.396815 },
          { lng: -54.935866, lat: -34.952647 },
          { lng: -55.67409, lat: -34.752659 },
          { lng: -56.215297, lat: -34.859836 },
          { lng: -57.139685, lat: -34.430456 },
          { lng: -57.817861, lat: -34.462547 },
          { lng: -58.427074, lat: -33.909454 },
          { lng: -58.349611, lat: -33.263189 },
          { lng: -58.132648, lat: -33.040567 },
          { lng: -58.14244, lat: -32.044504 },
          { lng: -57.874937, lat: -31.016556 },
          { lng: -57.625133, lat: -30.216295 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'USA',
    properties: {
      name: 'United States',
    },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: -155.54211, lat: 19.08348 },
          { lng: -155.68817, lat: 18.91619 },
          { lng: -155.93665, lat: 19.05939 },
          { lng: -155.90806, lat: 19.33888 },
          { lng: -156.07347, lat: 19.70294 },
          { lng: -156.02368, lat: 19.81422 },
          { lng: -155.85008, lat: 19.97729 },
          { lng: -155.91907, lat: 20.17395 },
          { lng: -155.86108, lat: 20.26721 },
          { lng: -155.78505, lat: 20.2487 },
          { lng: -155.40214, lat: 20.07975 },
          { lng: -155.22452, lat: 19.99302 },
          { lng: -155.06226, lat: 19.8591 },
          { lng: -154.80741, lat: 19.50871 },
          { lng: -154.83147, lat: 19.45328 },
          { lng: -155.22217, lat: 19.23972 },
          { lng: -155.54211, lat: 19.08348 },
        ],
        [
          { lng: -156.07926, lat: 20.64397 },
          { lng: -156.41445, lat: 20.57241 },
          { lng: -156.58673, lat: 20.783 },
          { lng: -156.70167, lat: 20.8643 },
          { lng: -156.71055, lat: 20.92676 },
          { lng: -156.61258, lat: 21.01249 },
          { lng: -156.25711, lat: 20.91745 },
          { lng: -155.99566, lat: 20.76404 },
          { lng: -156.07926, lat: 20.64397 },
        ],
        [
          { lng: -156.75824, lat: 21.17684 },
          { lng: -156.78933, lat: 21.06873 },
          { lng: -157.32521, lat: 21.09777 },
          { lng: -157.25027, lat: 21.21958 },
          { lng: -156.75824, lat: 21.17684 },
        ],
        [
          { lng: -157.65283, lat: 21.32217 },
          { lng: -157.70703, lat: 21.26442 },
          { lng: -157.7786, lat: 21.27729 },
          { lng: -158.12667, lat: 21.31244 },
          { lng: -158.2538, lat: 21.53919 },
          { lng: -158.29265, lat: 21.57912 },
          { lng: -158.0252, lat: 21.71696 },
          { lng: -157.94161, lat: 21.65272 },
          { lng: -157.65283, lat: 21.32217 },
        ],
        [
          { lng: -159.34512, lat: 21.982 },
          { lng: -159.46372, lat: 21.88299 },
          { lng: -159.80051, lat: 22.06533 },
          { lng: -159.74877, lat: 22.1382 },
          { lng: -159.5962, lat: 22.23618 },
          { lng: -159.36569, lat: 22.21494 },
          { lng: -159.34512, lat: 21.982 },
        ],
        [
          { lng: -94.81758, lat: 49.38905 },
          { lng: -94.64, lat: 48.84 },
          { lng: -94.32914, lat: 48.67074 },
          { lng: -93.63087, lat: 48.60926 },
          { lng: -92.61, lat: 48.45 },
          { lng: -91.64, lat: 48.14 },
          { lng: -90.83, lat: 48.27 },
          { lng: -89.6, lat: 48.01 },
          { lng: -89.272917, lat: 48.019808 },
          { lng: -88.378114, lat: 48.302918 },
          { lng: -87.439793, lat: 47.94 },
          { lng: -86.461991, lat: 47.553338 },
          { lng: -85.652363, lat: 47.220219 },
          { lng: -84.87608, lat: 46.900083 },
          { lng: -84.779238, lat: 46.637102 },
          { lng: -84.543749, lat: 46.538684 },
          { lng: -84.6049, lat: 46.4396 },
          { lng: -84.3367, lat: 46.40877 },
          { lng: -84.14212, lat: 46.512226 },
          { lng: -84.091851, lat: 46.275419 },
          { lng: -83.890765, lat: 46.116927 },
          { lng: -83.616131, lat: 46.116927 },
          { lng: -83.469551, lat: 45.994686 },
          { lng: -83.592851, lat: 45.816894 },
          { lng: -82.550925, lat: 45.347517 },
          { lng: -82.337763, lat: 44.44 },
          { lng: -82.137642, lat: 43.571088 },
          { lng: -82.43, lat: 42.98 },
          { lng: -82.9, lat: 42.43 },
          { lng: -83.12, lat: 42.08 },
          { lng: -83.142, lat: 41.975681 },
          { lng: -83.02981, lat: 41.832796 },
          { lng: -82.690089, lat: 41.675105 },
          { lng: -82.439278, lat: 41.675105 },
          { lng: -81.277747, lat: 42.209026 },
          { lng: -80.247448, lat: 42.3662 },
          { lng: -78.939362, lat: 42.863611 },
          { lng: -78.92, lat: 42.965 },
          { lng: -79.01, lat: 43.27 },
          { lng: -79.171674, lat: 43.466339 },
          { lng: -78.72028, lat: 43.625089 },
          { lng: -77.737885, lat: 43.629056 },
          { lng: -76.820034, lat: 43.628784 },
          { lng: -76.5, lat: 44.018459 },
          { lng: -76.375, lat: 44.09631 },
          { lng: -75.31821, lat: 44.81645 },
          { lng: -74.867, lat: 45.00048 },
          { lng: -73.34783, lat: 45.00738 },
          { lng: -71.50506, lat: 45.0082 },
          { lng: -71.405, lat: 45.255 },
          { lng: -71.08482, lat: 45.30524 },
          { lng: -70.66, lat: 45.46 },
          { lng: -70.305, lat: 45.915 },
          { lng: -69.99997, lat: 46.69307 },
          { lng: -69.237216, lat: 47.447781 },
          { lng: -68.905, lat: 47.185 },
          { lng: -68.23444, lat: 47.35486 },
          { lng: -67.79046, lat: 47.06636 },
          { lng: -67.79134, lat: 45.70281 },
          { lng: -67.13741, lat: 45.13753 },
          { lng: -66.96466, lat: 44.8097 },
          { lng: -68.03252, lat: 44.3252 },
          { lng: -69.06, lat: 43.98 },
          { lng: -70.11617, lat: 43.68405 },
          { lng: -70.645476, lat: 43.090238 },
          { lng: -70.81489, lat: 42.8653 },
          { lng: -70.825, lat: 42.335 },
          { lng: -70.495, lat: 41.805 },
          { lng: -70.08, lat: 41.78 },
          { lng: -70.185, lat: 42.145 },
          { lng: -69.88497, lat: 41.92283 },
          { lng: -69.96503, lat: 41.63717 },
          { lng: -70.64, lat: 41.475 },
          { lng: -71.12039, lat: 41.49445 },
          { lng: -71.86, lat: 41.32 },
          { lng: -72.295, lat: 41.27 },
          { lng: -72.87643, lat: 41.22065 },
          { lng: -73.71, lat: 40.931102 },
          { lng: -72.24126, lat: 41.11948 },
          { lng: -71.945, lat: 40.93 },
          { lng: -73.345, lat: 40.63 },
          { lng: -73.982, lat: 40.628 },
          { lng: -73.952325, lat: 40.75075 },
          { lng: -74.25671, lat: 40.47351 },
          { lng: -73.96244, lat: 40.42763 },
          { lng: -74.17838, lat: 39.70926 },
          { lng: -74.90604, lat: 38.93954 },
          { lng: -74.98041, lat: 39.1964 },
          { lng: -75.20002, lat: 39.24845 },
          { lng: -75.52805, lat: 39.4985 },
          { lng: -75.32, lat: 38.96 },
          { lng: -75.071835, lat: 38.782032 },
          { lng: -75.05673, lat: 38.40412 },
          { lng: -75.37747, lat: 38.01551 },
          { lng: -75.94023, lat: 37.21689 },
          { lng: -76.03127, lat: 37.2566 },
          { lng: -75.72205, lat: 37.93705 },
          { lng: -76.23287, lat: 38.319215 },
          { lng: -76.35, lat: 39.15 },
          { lng: -76.542725, lat: 38.717615 },
          { lng: -76.32933, lat: 38.08326 },
          { lng: -76.989998, lat: 38.239992 },
          { lng: -76.30162, lat: 37.917945 },
          { lng: -76.25874, lat: 36.9664 },
          { lng: -75.9718, lat: 36.89726 },
          { lng: -75.86804, lat: 36.55125 },
          { lng: -75.72749, lat: 35.55074 },
          { lng: -76.36318, lat: 34.80854 },
          { lng: -77.397635, lat: 34.51201 },
          { lng: -78.05496, lat: 33.92547 },
          { lng: -78.55435, lat: 33.86133 },
          { lng: -79.06067, lat: 33.49395 },
          { lng: -79.20357, lat: 33.15839 },
          { lng: -80.301325, lat: 32.509355 },
          { lng: -80.86498, lat: 32.0333 },
          { lng: -81.33629, lat: 31.44049 },
          { lng: -81.49042, lat: 30.72999 },
          { lng: -81.31371, lat: 30.03552 },
          { lng: -80.98, lat: 29.18 },
          { lng: -80.535585, lat: 28.47213 },
          { lng: -80.53, lat: 28.04 },
          { lng: -80.056539, lat: 26.88 },
          { lng: -80.088015, lat: 26.205765 },
          { lng: -80.13156, lat: 25.816775 },
          { lng: -80.38103, lat: 25.20616 },
          { lng: -80.68, lat: 25.08 },
          { lng: -81.17213, lat: 25.20126 },
          { lng: -81.33, lat: 25.64 },
          { lng: -81.71, lat: 25.87 },
          { lng: -82.24, lat: 26.73 },
          { lng: -82.70515, lat: 27.49504 },
          { lng: -82.85526, lat: 27.88624 },
          { lng: -82.65, lat: 28.55 },
          { lng: -82.93, lat: 29.1 },
          { lng: -83.70959, lat: 29.93656 },
          { lng: -84.1, lat: 30.09 },
          { lng: -85.10882, lat: 29.63615 },
          { lng: -85.28784, lat: 29.68612 },
          { lng: -85.7731, lat: 30.15261 },
          { lng: -86.4, lat: 30.4 },
          { lng: -87.53036, lat: 30.27433 },
          { lng: -88.41782, lat: 30.3849 },
          { lng: -89.18049, lat: 30.31598 },
          { lng: -89.593831, lat: 30.159994 },
          { lng: -89.413735, lat: 29.89419 },
          { lng: -89.43, lat: 29.48864 },
          { lng: -89.21767, lat: 29.29108 },
          { lng: -89.40823, lat: 29.15961 },
          { lng: -89.77928, lat: 29.30714 },
          { lng: -90.15463, lat: 29.11743 },
          { lng: -90.880225, lat: 29.148535 },
          { lng: -91.626785, lat: 29.677 },
          { lng: -92.49906, lat: 29.5523 },
          { lng: -93.22637, lat: 29.78375 },
          { lng: -93.84842, lat: 29.71363 },
          { lng: -94.69, lat: 29.48 },
          { lng: -95.60026, lat: 28.73863 },
          { lng: -96.59404, lat: 28.30748 },
          { lng: -97.14, lat: 27.83 },
          { lng: -97.37, lat: 27.38 },
          { lng: -97.38, lat: 26.69 },
          { lng: -97.33, lat: 26.21 },
          { lng: -97.14, lat: 25.87 },
          { lng: -97.53, lat: 25.84 },
          { lng: -98.24, lat: 26.06 },
          { lng: -99.02, lat: 26.37 },
          { lng: -99.3, lat: 26.84 },
          { lng: -99.52, lat: 27.54 },
          { lng: -100.11, lat: 28.11 },
          { lng: -100.45584, lat: 28.69612 },
          { lng: -100.9576, lat: 29.38071 },
          { lng: -101.6624, lat: 29.7793 },
          { lng: -102.48, lat: 29.76 },
          { lng: -103.11, lat: 28.97 },
          { lng: -103.94, lat: 29.27 },
          { lng: -104.45697, lat: 29.57196 },
          { lng: -104.70575, lat: 30.12173 },
          { lng: -105.03737, lat: 30.64402 },
          { lng: -105.63159, lat: 31.08383 },
          { lng: -106.1429, lat: 31.39995 },
          { lng: -106.50759, lat: 31.75452 },
          { lng: -108.24, lat: 31.754854 },
          { lng: -108.24194, lat: 31.34222 },
          { lng: -109.035, lat: 31.34194 },
          { lng: -111.02361, lat: 31.33472 },
          { lng: -113.30498, lat: 32.03914 },
          { lng: -114.815, lat: 32.52528 },
          { lng: -114.72139, lat: 32.72083 },
          { lng: -115.99135, lat: 32.61239 },
          { lng: -117.12776, lat: 32.53534 },
          { lng: -117.295938, lat: 33.046225 },
          { lng: -117.944, lat: 33.621236 },
          { lng: -118.410602, lat: 33.740909 },
          { lng: -118.519895, lat: 34.027782 },
          { lng: -119.081, lat: 34.078 },
          { lng: -119.438841, lat: 34.348477 },
          { lng: -120.36778, lat: 34.44711 },
          { lng: -120.62286, lat: 34.60855 },
          { lng: -120.74433, lat: 35.15686 },
          { lng: -121.71457, lat: 36.16153 },
          { lng: -122.54747, lat: 37.55176 },
          { lng: -122.51201, lat: 37.78339 },
          { lng: -122.95319, lat: 38.11371 },
          { lng: -123.7272, lat: 38.95166 },
          { lng: -123.86517, lat: 39.76699 },
          { lng: -124.39807, lat: 40.3132 },
          { lng: -124.17886, lat: 41.14202 },
          { lng: -124.2137, lat: 41.99964 },
          { lng: -124.53284, lat: 42.76599 },
          { lng: -124.14214, lat: 43.70838 },
          { lng: -124.020535, lat: 44.615895 },
          { lng: -123.89893, lat: 45.52341 },
          { lng: -124.079635, lat: 46.86475 },
          { lng: -124.39567, lat: 47.72017 },
          { lng: -124.68721, lat: 48.184433 },
          { lng: -124.566101, lat: 48.379715 },
          { lng: -123.12, lat: 48.04 },
          { lng: -122.58736, lat: 47.096 },
          { lng: -122.34, lat: 47.36 },
          { lng: -122.5, lat: 48.18 },
          { lng: -122.84, lat: 49 },
          { lng: -120, lat: 49 },
          { lng: -117.03121, lat: 49 },
          { lng: -116.04818, lat: 49 },
          { lng: -113, lat: 49 },
          { lng: -110.05, lat: 49 },
          { lng: -107.05, lat: 49 },
          { lng: -104.04826, lat: 48.99986 },
          { lng: -100.65, lat: 49 },
          { lng: -97.22872, lat: 49.0007 },
          { lng: -95.15907, lat: 49 },
          { lng: -95.15609, lat: 49.38425 },
          { lng: -94.81758, lat: 49.38905 },
        ],
        [
          { lng: -153.006314, lat: 57.115842 },
          { lng: -154.00509, lat: 56.734677 },
          { lng: -154.516403, lat: 56.992749 },
          { lng: -154.670993, lat: 57.461196 },
          { lng: -153.76278, lat: 57.816575 },
          { lng: -153.228729, lat: 57.968968 },
          { lng: -152.564791, lat: 57.901427 },
          { lng: -152.141147, lat: 57.591059 },
          { lng: -153.006314, lat: 57.115842 },
        ],
        [
          { lng: -165.579164, lat: 59.909987 },
          { lng: -166.19277, lat: 59.754441 },
          { lng: -166.848337, lat: 59.941406 },
          { lng: -167.455277, lat: 60.213069 },
          { lng: -166.467792, lat: 60.38417 },
          { lng: -165.67443, lat: 60.293607 },
          { lng: -165.579164, lat: 59.909987 },
        ],
        [
          { lng: -171.731657, lat: 63.782515 },
          { lng: -171.114434, lat: 63.592191 },
          { lng: -170.491112, lat: 63.694975 },
          { lng: -169.682505, lat: 63.431116 },
          { lng: -168.689439, lat: 63.297506 },
          { lng: -168.771941, lat: 63.188598 },
          { lng: -169.52944, lat: 62.976931 },
          { lng: -170.290556, lat: 63.194438 },
          { lng: -170.671386, lat: 63.375822 },
          { lng: -171.553063, lat: 63.317789 },
          { lng: -171.791111, lat: 63.405846 },
          { lng: -171.731657, lat: 63.782515 },
        ],
        [
          { lng: -155.06779, lat: 71.147776 },
          { lng: -154.344165, lat: 70.696409 },
          { lng: -153.900006, lat: 70.889989 },
          { lng: -152.210006, lat: 70.829992 },
          { lng: -152.270002, lat: 70.600006 },
          { lng: -150.739992, lat: 70.430017 },
          { lng: -149.720003, lat: 70.53001 },
          { lng: -147.613362, lat: 70.214035 },
          { lng: -145.68999, lat: 70.12001 },
          { lng: -144.920011, lat: 69.989992 },
          { lng: -143.589446, lat: 70.152514 },
          { lng: -142.07251, lat: 69.851938 },
          { lng: -140.985988, lat: 69.711998 },
          { lng: -140.992499, lat: 66.000029 },
          { lng: -140.99777, lat: 60.306397 },
          { lng: -140.012998, lat: 60.276838 },
          { lng: -139.039, lat: 60.000007 },
          { lng: -138.34089, lat: 59.56211 },
          { lng: -137.4525, lat: 58.905 },
          { lng: -136.47972, lat: 59.46389 },
          { lng: -135.47583, lat: 59.78778 },
          { lng: -134.945, lat: 59.27056 },
          { lng: -134.27111, lat: 58.86111 },
          { lng: -133.355549, lat: 58.410285 },
          { lng: -132.73042, lat: 57.69289 },
          { lng: -131.70781, lat: 56.55212 },
          { lng: -130.00778, lat: 55.91583 },
          { lng: -129.979994, lat: 55.284998 },
          { lng: -130.53611, lat: 54.802753 },
          { lng: -131.085818, lat: 55.178906 },
          { lng: -131.967211, lat: 55.497776 },
          { lng: -132.250011, lat: 56.369996 },
          { lng: -133.539181, lat: 57.178887 },
          { lng: -134.078063, lat: 58.123068 },
          { lng: -135.038211, lat: 58.187715 },
          { lng: -136.628062, lat: 58.212209 },
          { lng: -137.800006, lat: 58.499995 },
          { lng: -139.867787, lat: 59.537762 },
          { lng: -140.825274, lat: 59.727517 },
          { lng: -142.574444, lat: 60.084447 },
          { lng: -143.958881, lat: 59.99918 },
          { lng: -145.925557, lat: 60.45861 },
          { lng: -147.114374, lat: 60.884656 },
          { lng: -148.224306, lat: 60.672989 },
          { lng: -148.018066, lat: 59.978329 },
          { lng: -148.570823, lat: 59.914173 },
          { lng: -149.727858, lat: 59.705658 },
          { lng: -150.608243, lat: 59.368211 },
          { lng: -151.716393, lat: 59.155821 },
          { lng: -151.859433, lat: 59.744984 },
          { lng: -151.409719, lat: 60.725803 },
          { lng: -150.346941, lat: 61.033588 },
          { lng: -150.621111, lat: 61.284425 },
          { lng: -151.895839, lat: 60.727198 },
          { lng: -152.57833, lat: 60.061657 },
          { lng: -154.019172, lat: 59.350279 },
          { lng: -153.287511, lat: 58.864728 },
          { lng: -154.232492, lat: 58.146374 },
          { lng: -155.307491, lat: 57.727795 },
          { lng: -156.308335, lat: 57.422774 },
          { lng: -156.556097, lat: 56.979985 },
          { lng: -158.117217, lat: 56.463608 },
          { lng: -158.433321, lat: 55.994154 },
          { lng: -159.603327, lat: 55.566686 },
          { lng: -160.28972, lat: 55.643581 },
          { lng: -161.223048, lat: 55.364735 },
          { lng: -162.237766, lat: 55.024187 },
          { lng: -163.069447, lat: 54.689737 },
          { lng: -164.785569, lat: 54.404173 },
          { lng: -164.942226, lat: 54.572225 },
          { lng: -163.84834, lat: 55.039431 },
          { lng: -162.870001, lat: 55.348043 },
          { lng: -161.804175, lat: 55.894986 },
          { lng: -160.563605, lat: 56.008055 },
          { lng: -160.07056, lat: 56.418055 },
          { lng: -158.684443, lat: 57.016675 },
          { lng: -158.461097, lat: 57.216921 },
          { lng: -157.72277, lat: 57.570001 },
          { lng: -157.550274, lat: 58.328326 },
          { lng: -157.041675, lat: 58.918885 },
          { lng: -158.194731, lat: 58.615802 },
          { lng: -158.517218, lat: 58.787781 },
          { lng: -159.058606, lat: 58.424186 },
          { lng: -159.711667, lat: 58.93139 },
          { lng: -159.981289, lat: 58.572549 },
          { lng: -160.355271, lat: 59.071123 },
          { lng: -161.355003, lat: 58.670838 },
          { lng: -161.968894, lat: 58.671665 },
          { lng: -162.054987, lat: 59.266925 },
          { lng: -161.874171, lat: 59.633621 },
          { lng: -162.518059, lat: 59.989724 },
          { lng: -163.818341, lat: 59.798056 },
          { lng: -164.662218, lat: 60.267484 },
          { lng: -165.346388, lat: 60.507496 },
          { lng: -165.350832, lat: 61.073895 },
          { lng: -166.121379, lat: 61.500019 },
          { lng: -165.734452, lat: 62.074997 },
          { lng: -164.919179, lat: 62.633076 },
          { lng: -164.562508, lat: 63.146378 },
          { lng: -163.753332, lat: 63.219449 },
          { lng: -163.067224, lat: 63.059459 },
          { lng: -162.260555, lat: 63.541936 },
          { lng: -161.53445, lat: 63.455817 },
          { lng: -160.772507, lat: 63.766108 },
          { lng: -160.958335, lat: 64.222799 },
          { lng: -161.518068, lat: 64.402788 },
          { lng: -160.777778, lat: 64.788604 },
          { lng: -161.391926, lat: 64.777235 },
          { lng: -162.45305, lat: 64.559445 },
          { lng: -162.757786, lat: 64.338605 },
          { lng: -163.546394, lat: 64.55916 },
          { lng: -164.96083, lat: 64.446945 },
          { lng: -166.425288, lat: 64.686672 },
          { lng: -166.845004, lat: 65.088896 },
          { lng: -168.11056, lat: 65.669997 },
          { lng: -166.705271, lat: 66.088318 },
          { lng: -164.47471, lat: 66.57666 },
          { lng: -163.652512, lat: 66.57666 },
          { lng: -163.788602, lat: 66.077207 },
          { lng: -161.677774, lat: 66.11612 },
          { lng: -162.489715, lat: 66.735565 },
          { lng: -163.719717, lat: 67.116395 },
          { lng: -164.430991, lat: 67.616338 },
          { lng: -165.390287, lat: 68.042772 },
          { lng: -166.764441, lat: 68.358877 },
          { lng: -166.204707, lat: 68.883031 },
          { lng: -164.430811, lat: 68.915535 },
          { lng: -163.168614, lat: 69.371115 },
          { lng: -162.930566, lat: 69.858062 },
          { lng: -161.908897, lat: 70.33333 },
          { lng: -160.934797, lat: 70.44769 },
          { lng: -159.039176, lat: 70.891642 },
          { lng: -158.119723, lat: 70.824721 },
          { lng: -156.580825, lat: 71.357764 },
          { lng: -155.06779, lat: 71.147776 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'UZB',
    properties: { name: 'Uzbekistan' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 66.518607, lat: 37.362784 },
          { lng: 66.54615, lat: 37.974685 },
          { lng: 65.215999, lat: 38.402695 },
          { lng: 64.170223, lat: 38.892407 },
          { lng: 63.518015, lat: 39.363257 },
          { lng: 62.37426, lat: 40.053886 },
          { lng: 61.882714, lat: 41.084857 },
          { lng: 61.547179, lat: 41.26637 },
          { lng: 60.465953, lat: 41.220327 },
          { lng: 60.083341, lat: 41.425146 },
          { lng: 59.976422, lat: 42.223082 },
          { lng: 58.629011, lat: 42.751551 },
          { lng: 57.78653, lat: 42.170553 },
          { lng: 56.932215, lat: 41.826026 },
          { lng: 57.096391, lat: 41.32231 },
          { lng: 55.968191, lat: 41.308642 },
          { lng: 55.928917, lat: 44.995858 },
          { lng: 58.503127, lat: 45.586804 },
          { lng: 58.689989, lat: 45.500014 },
          { lng: 60.239972, lat: 44.784037 },
          { lng: 61.05832, lat: 44.405817 },
          { lng: 62.0133, lat: 43.504477 },
          { lng: 63.185787, lat: 43.650075 },
          { lng: 64.900824, lat: 43.728081 },
          { lng: 66.098012, lat: 42.99766 },
          { lng: 66.023392, lat: 41.994646 },
          { lng: 66.510649, lat: 41.987644 },
          { lng: 66.714047, lat: 41.168444 },
          { lng: 67.985856, lat: 41.135991 },
          { lng: 68.259896, lat: 40.662325 },
          { lng: 68.632483, lat: 40.668681 },
          { lng: 69.070027, lat: 41.384244 },
          { lng: 70.388965, lat: 42.081308 },
          { lng: 70.962315, lat: 42.266154 },
          { lng: 71.259248, lat: 42.167711 },
          { lng: 70.420022, lat: 41.519998 },
          { lng: 71.157859, lat: 41.143587 },
          { lng: 71.870115, lat: 41.3929 },
          { lng: 73.055417, lat: 40.866033 },
          { lng: 71.774875, lat: 40.145844 },
          { lng: 71.014198, lat: 40.244366 },
          { lng: 70.601407, lat: 40.218527 },
          { lng: 70.45816, lat: 40.496495 },
          { lng: 70.666622, lat: 40.960213 },
          { lng: 69.329495, lat: 40.727824 },
          { lng: 69.011633, lat: 40.086158 },
          { lng: 68.536416, lat: 39.533453 },
          { lng: 67.701429, lat: 39.580478 },
          { lng: 67.44222, lat: 39.140144 },
          { lng: 68.176025, lat: 38.901553 },
          { lng: 68.392033, lat: 38.157025 },
          { lng: 67.83, lat: 37.144994 },
          { lng: 67.075782, lat: 37.356144 },
          { lng: 66.518607, lat: 37.362784 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'VEN',
    properties: { name: 'Venezuela' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: -71.331584, lat: 11.776284 },
          { lng: -71.360006, lat: 11.539994 },
          { lng: -71.94705, lat: 11.423282 },
          { lng: -71.620868, lat: 10.96946 },
          { lng: -71.633064, lat: 10.446494 },
          { lng: -72.074174, lat: 9.865651 },
          { lng: -71.695644, lat: 9.072263 },
          { lng: -71.264559, lat: 9.137195 },
          { lng: -71.039999, lat: 9.859993 },
          { lng: -71.350084, lat: 10.211935 },
          { lng: -71.400623, lat: 10.968969 },
          { lng: -70.155299, lat: 11.375482 },
          { lng: -70.293843, lat: 11.846822 },
          { lng: -69.943245, lat: 12.162307 },
          { lng: -69.5843, lat: 11.459611 },
          { lng: -68.882999, lat: 11.443385 },
          { lng: -68.233271, lat: 10.885744 },
          { lng: -68.194127, lat: 10.554653 },
          { lng: -67.296249, lat: 10.545868 },
          { lng: -66.227864, lat: 10.648627 },
          { lng: -65.655238, lat: 10.200799 },
          { lng: -64.890452, lat: 10.077215 },
          { lng: -64.329479, lat: 10.389599 },
          { lng: -64.318007, lat: 10.641418 },
          { lng: -63.079322, lat: 10.701724 },
          { lng: -61.880946, lat: 10.715625 },
          { lng: -62.730119, lat: 10.420269 },
          { lng: -62.388512, lat: 9.948204 },
          { lng: -61.588767, lat: 9.873067 },
          { lng: -60.830597, lat: 9.38134 },
          { lng: -60.671252, lat: 8.580174 },
          { lng: -60.150096, lat: 8.602757 },
          { lng: -59.758285, lat: 8.367035 },
          { lng: -60.550588, lat: 7.779603 },
          { lng: -60.637973, lat: 7.415 },
          { lng: -60.295668, lat: 7.043911 },
          { lng: -60.543999, lat: 6.856584 },
          { lng: -61.159336, lat: 6.696077 },
          { lng: -61.139415, lat: 6.234297 },
          { lng: -61.410303, lat: 5.959068 },
          { lng: -60.733574, lat: 5.200277 },
          { lng: -60.601179, lat: 4.918098 },
          { lng: -60.966893, lat: 4.536468 },
          { lng: -62.08543, lat: 4.162124 },
          { lng: -62.804533, lat: 4.006965 },
          { lng: -63.093198, lat: 3.770571 },
          { lng: -63.888343, lat: 4.02053 },
          { lng: -64.628659, lat: 4.148481 },
          { lng: -64.816064, lat: 4.056445 },
          { lng: -64.368494, lat: 3.79721 },
          { lng: -64.408828, lat: 3.126786 },
          { lng: -64.269999, lat: 2.497006 },
          { lng: -63.422867, lat: 2.411068 },
          { lng: -63.368788, lat: 2.2009 },
          { lng: -64.083085, lat: 1.916369 },
          { lng: -64.199306, lat: 1.492855 },
          { lng: -64.611012, lat: 1.328731 },
          { lng: -65.354713, lat: 1.095282 },
          { lng: -65.548267, lat: 0.789254 },
          { lng: -66.325765, lat: 0.724452 },
          { lng: -66.876326, lat: 1.253361 },
          { lng: -67.181294, lat: 2.250638 },
          { lng: -67.447092, lat: 2.600281 },
          { lng: -67.809938, lat: 2.820655 },
          { lng: -67.303173, lat: 3.318454 },
          { lng: -67.337564, lat: 3.542342 },
          { lng: -67.621836, lat: 3.839482 },
          { lng: -67.823012, lat: 4.503937 },
          { lng: -67.744697, lat: 5.221129 },
          { lng: -67.521532, lat: 5.55687 },
          { lng: -67.34144, lat: 6.095468 },
          { lng: -67.695087, lat: 6.267318 },
          { lng: -68.265052, lat: 6.153268 },
          { lng: -68.985319, lat: 6.206805 },
          { lng: -69.38948, lat: 6.099861 },
          { lng: -70.093313, lat: 6.960376 },
          { lng: -70.674234, lat: 7.087785 },
          { lng: -71.960176, lat: 6.991615 },
          { lng: -72.198352, lat: 7.340431 },
          { lng: -72.444487, lat: 7.423785 },
          { lng: -72.479679, lat: 7.632506 },
          { lng: -72.360901, lat: 8.002638 },
          { lng: -72.439862, lat: 8.405275 },
          { lng: -72.660495, lat: 8.625288 },
          { lng: -72.78873, lat: 9.085027 },
          { lng: -73.304952, lat: 9.152 },
          { lng: -73.027604, lat: 9.73677 },
          { lng: -72.905286, lat: 10.450344 },
          { lng: -72.614658, lat: 10.821975 },
          { lng: -72.227575, lat: 11.108702 },
          { lng: -71.973922, lat: 11.608672 },
          { lng: -71.331584, lat: 11.776284 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'VNM',
    properties: { name: 'Vietnam' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 108.05018, lat: 21.55238 },
          { lng: 106.715068, lat: 20.696851 },
          { lng: 105.881682, lat: 19.75205 },
          { lng: 105.662006, lat: 19.058165 },
          { lng: 106.426817, lat: 18.004121 },
          { lng: 107.361954, lat: 16.697457 },
          { lng: 108.269495, lat: 16.079742 },
          { lng: 108.877107, lat: 15.276691 },
          { lng: 109.33527, lat: 13.426028 },
          { lng: 109.200136, lat: 11.666859 },
          { lng: 108.36613, lat: 11.008321 },
          { lng: 107.220929, lat: 10.364484 },
          { lng: 106.405113, lat: 9.53084 },
          { lng: 105.158264, lat: 8.59976 },
          { lng: 104.795185, lat: 9.241038 },
          { lng: 105.076202, lat: 9.918491 },
          { lng: 104.334335, lat: 10.486544 },
          { lng: 105.199915, lat: 10.88931 },
          { lng: 106.24967, lat: 10.961812 },
          { lng: 105.810524, lat: 11.567615 },
          { lng: 107.491403, lat: 12.337206 },
          { lng: 107.614548, lat: 13.535531 },
          { lng: 107.382727, lat: 14.202441 },
          { lng: 107.564525, lat: 15.202173 },
          { lng: 107.312706, lat: 15.908538 },
          { lng: 106.556008, lat: 16.604284 },
          { lng: 105.925762, lat: 17.485315 },
          { lng: 105.094598, lat: 18.666975 },
          { lng: 103.896532, lat: 19.265181 },
          { lng: 104.183388, lat: 19.624668 },
          { lng: 104.822574, lat: 19.886642 },
          { lng: 104.435, lat: 20.758733 },
          { lng: 103.203861, lat: 20.766562 },
          { lng: 102.754896, lat: 21.675137 },
          { lng: 102.170436, lat: 22.464753 },
          { lng: 102.706992, lat: 22.708795 },
          { lng: 103.504515, lat: 22.703757 },
          { lng: 104.476858, lat: 22.81915 },
          { lng: 105.329209, lat: 23.352063 },
          { lng: 105.811247, lat: 22.976892 },
          { lng: 106.725403, lat: 22.794268 },
          { lng: 106.567273, lat: 22.218205 },
          { lng: 107.04342, lat: 21.811899 },
          { lng: 108.05018, lat: 21.55238 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'VUT',
    properties: { name: 'Vanuatu' },
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          { lng: 167.844877, lat: -16.466333 },
          { lng: 167.515181, lat: -16.59785 },
          { lng: 167.180008, lat: -16.159995 },
          { lng: 167.216801, lat: -15.891846 },
          { lng: 167.844877, lat: -16.466333 },
        ],
        [
          { lng: 167.107712, lat: -14.93392 },
          { lng: 167.270028, lat: -15.740021 },
          { lng: 167.001207, lat: -15.614602 },
          { lng: 166.793158, lat: -15.668811 },
          { lng: 166.649859, lat: -15.392704 },
          { lng: 166.629137, lat: -14.626497 },
          { lng: 167.107712, lat: -14.93392 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'PSE',
    properties: { name: 'West Bank' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 35.545665, lat: 32.393992 },
          { lng: 35.545252, lat: 31.782505 },
          { lng: 35.397561, lat: 31.489086 },
          { lng: 34.927408, lat: 31.353435 },
          { lng: 34.970507, lat: 31.616778 },
          { lng: 35.225892, lat: 31.754341 },
          { lng: 34.974641, lat: 31.866582 },
          { lng: 35.18393, lat: 32.532511 },
          { lng: 35.545665, lat: 32.393992 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'YEM',
    properties: { name: 'Yemen' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 53.108573, lat: 16.651051 },
          { lng: 52.385206, lat: 16.382411 },
          { lng: 52.191729, lat: 15.938433 },
          { lng: 52.168165, lat: 15.59742 },
          { lng: 51.172515, lat: 15.17525 },
          { lng: 49.574576, lat: 14.708767 },
          { lng: 48.679231, lat: 14.003202 },
          { lng: 48.238947, lat: 13.94809 },
          { lng: 47.938914, lat: 14.007233 },
          { lng: 47.354454, lat: 13.59222 },
          { lng: 46.717076, lat: 13.399699 },
          { lng: 45.877593, lat: 13.347764 },
          { lng: 45.62505, lat: 13.290946 },
          { lng: 45.406459, lat: 13.026905 },
          { lng: 45.144356, lat: 12.953938 },
          { lng: 44.989533, lat: 12.699587 },
          { lng: 44.494576, lat: 12.721653 },
          { lng: 44.175113, lat: 12.58595 },
          { lng: 43.482959, lat: 12.6368 },
          { lng: 43.222871, lat: 13.22095 },
          { lng: 43.251448, lat: 13.767584 },
          { lng: 43.087944, lat: 14.06263 },
          { lng: 42.892245, lat: 14.802249 },
          { lng: 42.604873, lat: 15.213335 },
          { lng: 42.805015, lat: 15.261963 },
          { lng: 42.702438, lat: 15.718886 },
          { lng: 42.823671, lat: 15.911742 },
          { lng: 42.779332, lat: 16.347891 },
          { lng: 43.218375, lat: 16.66689 },
          { lng: 43.115798, lat: 17.08844 },
          { lng: 43.380794, lat: 17.579987 },
          { lng: 43.791519, lat: 17.319977 },
          { lng: 44.062613, lat: 17.410359 },
          { lng: 45.216651, lat: 17.433329 },
          { lng: 45.399999, lat: 17.333335 },
          { lng: 46.366659, lat: 17.233315 },
          { lng: 46.749994, lat: 17.283338 },
          { lng: 47.000005, lat: 16.949999 },
          { lng: 47.466695, lat: 17.116682 },
          { lng: 48.183344, lat: 18.166669 },
          { lng: 49.116672, lat: 18.616668 },
          { lng: 52.00001, lat: 19.000003 },
          { lng: 52.782184, lat: 17.349742 },
          { lng: 53.108573, lat: 16.651051 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ZAF',
    properties: { name: 'South Africa' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 31.521001, lat: -29.257387 },
          { lng: 31.325561, lat: -29.401978 },
          { lng: 30.901763, lat: -29.909957 },
          { lng: 30.622813, lat: -30.423776 },
          { lng: 30.055716, lat: -31.140269 },
          { lng: 28.925553, lat: -32.172041 },
          { lng: 28.219756, lat: -32.771953 },
          { lng: 27.464608, lat: -33.226964 },
          { lng: 26.419452, lat: -33.61495 },
          { lng: 25.909664, lat: -33.66704 },
          { lng: 25.780628, lat: -33.944646 },
          { lng: 25.172862, lat: -33.796851 },
          { lng: 24.677853, lat: -33.987176 },
          { lng: 23.594043, lat: -33.794474 },
          { lng: 22.988189, lat: -33.916431 },
          { lng: 22.574157, lat: -33.864083 },
          { lng: 21.542799, lat: -34.258839 },
          { lng: 20.689053, lat: -34.417175 },
          { lng: 20.071261, lat: -34.795137 },
          { lng: 19.616405, lat: -34.819166 },
          { lng: 19.193278, lat: -34.462599 },
          { lng: 18.855315, lat: -34.444306 },
          { lng: 18.424643, lat: -33.997873 },
          { lng: 18.377411, lat: -34.136521 },
          { lng: 18.244499, lat: -33.867752 },
          { lng: 18.25008, lat: -33.281431 },
          { lng: 17.92519, lat: -32.611291 },
          { lng: 18.24791, lat: -32.429131 },
          { lng: 18.221762, lat: -31.661633 },
          { lng: 17.566918, lat: -30.725721 },
          { lng: 17.064416, lat: -29.878641 },
          { lng: 17.062918, lat: -29.875954 },
          { lng: 16.344977, lat: -28.576705 },
          { lng: 16.824017, lat: -28.082162 },
          { lng: 17.218929, lat: -28.355943 },
          { lng: 17.387497, lat: -28.783514 },
          { lng: 17.836152, lat: -28.856378 },
          { lng: 18.464899, lat: -29.045462 },
          { lng: 19.002127, lat: -28.972443 },
          { lng: 19.894734, lat: -28.461105 },
          { lng: 19.895768, lat: -24.76779 },
          { lng: 20.165726, lat: -24.917962 },
          { lng: 20.758609, lat: -25.868136 },
          { lng: 20.66647, lat: -26.477453 },
          { lng: 20.889609, lat: -26.828543 },
          { lng: 21.605896, lat: -26.726534 },
          { lng: 22.105969, lat: -26.280256 },
          { lng: 22.579532, lat: -25.979448 },
          { lng: 22.824271, lat: -25.500459 },
          { lng: 23.312097, lat: -25.26869 },
          { lng: 23.73357, lat: -25.390129 },
          { lng: 24.211267, lat: -25.670216 },
          { lng: 25.025171, lat: -25.71967 },
          { lng: 25.664666, lat: -25.486816 },
          { lng: 25.765849, lat: -25.174845 },
          { lng: 25.941652, lat: -24.696373 },
          { lng: 26.485753, lat: -24.616327 },
          { lng: 26.786407, lat: -24.240691 },
          { lng: 27.11941, lat: -23.574323 },
          { lng: 28.017236, lat: -22.827754 },
          { lng: 29.432188, lat: -22.091313 },
          { lng: 29.839037, lat: -22.102216 },
          { lng: 30.322883, lat: -22.271612 },
          { lng: 30.659865, lat: -22.151567 },
          { lng: 31.191409, lat: -22.25151 },
          { lng: 31.670398, lat: -23.658969 },
          { lng: 31.930589, lat: -24.369417 },
          { lng: 31.752408, lat: -25.484284 },
          { lng: 31.837778, lat: -25.843332 },
          { lng: 31.333158, lat: -25.660191 },
          { lng: 31.04408, lat: -25.731452 },
          { lng: 30.949667, lat: -26.022649 },
          { lng: 30.676609, lat: -26.398078 },
          { lng: 30.685962, lat: -26.743845 },
          { lng: 31.282773, lat: -27.285879 },
          { lng: 31.86806, lat: -27.177927 },
          { lng: 32.071665, lat: -26.73382 },
          { lng: 32.83012, lat: -26.742192 },
          { lng: 32.580265, lat: -27.470158 },
          { lng: 32.462133, lat: -28.301011 },
          { lng: 32.203389, lat: -28.752405 },
          { lng: 31.521001, lat: -29.257387 },
        ],
        [
          { lng: 28.978263, lat: -28.955597 },
          { lng: 28.5417, lat: -28.647502 },
          { lng: 28.074338, lat: -28.851469 },
          { lng: 27.532511, lat: -29.242711 },
          { lng: 26.999262, lat: -29.875954 },
          { lng: 27.749397, lat: -30.645106 },
          { lng: 28.107205, lat: -30.545732 },
          { lng: 28.291069, lat: -30.226217 },
          { lng: 28.8484, lat: -30.070051 },
          { lng: 29.018415, lat: -29.743766 },
          { lng: 29.325166, lat: -29.257387 },
          { lng: 28.978263, lat: -28.955597 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ZMB',
    properties: { name: 'Zambia' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 32.759375, lat: -9.230599 },
          { lng: 33.231388, lat: -9.676722 },
          { lng: 33.485688, lat: -10.525559 },
          { lng: 33.31531, lat: -10.79655 },
          { lng: 33.114289, lat: -11.607198 },
          { lng: 33.306422, lat: -12.435778 },
          { lng: 32.991764, lat: -12.783871 },
          { lng: 32.688165, lat: -13.712858 },
          { lng: 33.214025, lat: -13.97186 },
          { lng: 30.179481, lat: -14.796099 },
          { lng: 30.274256, lat: -15.507787 },
          { lng: 29.516834, lat: -15.644678 },
          { lng: 28.947463, lat: -16.043051 },
          { lng: 28.825869, lat: -16.389749 },
          { lng: 28.467906, lat: -16.4684 },
          { lng: 27.598243, lat: -17.290831 },
          { lng: 27.044427, lat: -17.938026 },
          { lng: 26.706773, lat: -17.961229 },
          { lng: 26.381935, lat: -17.846042 },
          { lng: 25.264226, lat: -17.73654 },
          { lng: 25.084443, lat: -17.661816 },
          { lng: 25.07695, lat: -17.578823 },
          { lng: 24.682349, lat: -17.353411 },
          { lng: 24.033862, lat: -17.295843 },
          { lng: 23.215048, lat: -17.523116 },
          { lng: 22.562478, lat: -16.898451 },
          { lng: 21.887843, lat: -16.08031 },
          { lng: 21.933886, lat: -12.898437 },
          { lng: 24.016137, lat: -12.911046 },
          { lng: 23.930922, lat: -12.565848 },
          { lng: 24.079905, lat: -12.191297 },
          { lng: 23.904154, lat: -11.722282 },
          { lng: 24.017894, lat: -11.237298 },
          { lng: 23.912215, lat: -10.926826 },
          { lng: 24.257155, lat: -10.951993 },
          { lng: 24.314516, lat: -11.262826 },
          { lng: 24.78317, lat: -11.238694 },
          { lng: 25.418118, lat: -11.330936 },
          { lng: 25.75231, lat: -11.784965 },
          { lng: 26.553088, lat: -11.92444 },
          { lng: 27.16442, lat: -11.608748 },
          { lng: 27.388799, lat: -12.132747 },
          { lng: 28.155109, lat: -12.272481 },
          { lng: 28.523562, lat: -12.698604 },
          { lng: 28.934286, lat: -13.248958 },
          { lng: 29.699614, lat: -13.257227 },
          { lng: 29.616001, lat: -12.178895 },
          { lng: 29.341548, lat: -12.360744 },
          { lng: 28.642417, lat: -11.971569 },
          { lng: 28.372253, lat: -11.793647 },
          { lng: 28.49607, lat: -10.789884 },
          { lng: 28.673682, lat: -9.605925 },
          { lng: 28.449871, lat: -9.164918 },
          { lng: 28.734867, lat: -8.526559 },
          { lng: 29.002912, lat: -8.407032 },
          { lng: 30.346086, lat: -8.238257 },
          { lng: 30.740015, lat: -8.340007 },
          { lng: 31.157751, lat: -8.594579 },
          { lng: 31.556348, lat: -8.762049 },
          { lng: 32.191865, lat: -8.930359 },
          { lng: 32.759375, lat: -9.230599 },
        ],
      ],
    },
  },
  {
    type: 'Feature',
    id: 'ZWE',
    properties: { name: 'Zimbabwe' },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          { lng: 31.191409, lat: -22.25151 },
          { lng: 30.659865, lat: -22.151567 },
          { lng: 30.322883, lat: -22.271612 },
          { lng: 29.839037, lat: -22.102216 },
          { lng: 29.432188, lat: -22.091313 },
          { lng: 28.794656, lat: -21.639454 },
          { lng: 28.02137, lat: -21.485975 },
          { lng: 27.727228, lat: -20.851802 },
          { lng: 27.724747, lat: -20.499059 },
          { lng: 27.296505, lat: -20.39152 },
          { lng: 26.164791, lat: -19.293086 },
          { lng: 25.850391, lat: -18.714413 },
          { lng: 25.649163, lat: -18.536026 },
          { lng: 25.264226, lat: -17.73654 },
          { lng: 26.381935, lat: -17.846042 },
          { lng: 26.706773, lat: -17.961229 },
          { lng: 27.044427, lat: -17.938026 },
          { lng: 27.598243, lat: -17.290831 },
          { lng: 28.467906, lat: -16.4684 },
          { lng: 28.825869, lat: -16.389749 },
          { lng: 28.947463, lat: -16.043051 },
          { lng: 29.516834, lat: -15.644678 },
          { lng: 30.274256, lat: -15.507787 },
          { lng: 30.338955, lat: -15.880839 },
          { lng: 31.173064, lat: -15.860944 },
          { lng: 31.636498, lat: -16.07199 },
          { lng: 31.852041, lat: -16.319417 },
          { lng: 32.328239, lat: -16.392074 },
          { lng: 32.847639, lat: -16.713398 },
          { lng: 32.849861, lat: -17.979057 },
          { lng: 32.654886, lat: -18.67209 },
          { lng: 32.611994, lat: -19.419383 },
          { lng: 32.772708, lat: -19.715592 },
          { lng: 32.659743, lat: -20.30429 },
          { lng: 32.508693, lat: -20.395292 },
          { lng: 32.244988, lat: -21.116489 },
          { lng: 31.191409, lat: -22.25151 },
        ],
      ],
    },
  },
];

export default geoJson;
